import { FlashMessages } from 'models/web';
import * as React from 'react';
import { AuditListingDetails, AuditSection } from '../../../services/audit/auditModels';
import { MessagesWidget } from '../messagesWidget';
import { buildClassName } from '../utility';

export default class BeginAssessmentView extends React.Component<BeginAssessmentViewProps, any> {
  constructor(props: BeginAssessmentViewProps) {
    super(props);
    this.state = {
      showAuditList: false,
      selectedAudit: null
    };
  }

  render() {
    const { siteId, audits, elements } = this.props;
    const { showAuditList, selectedAudit } = this.state;

    return <div className='section'>
      <h1 className='title'>New assessment</h1>
      <MessagesWidget {...this.props.messages} />
      <div className='columns mt-4'>
        <form action={`/assessment/carry-over/${siteId}`} method='post'>
          <button type='submit' className='button option-button mx-2'>
            <div className='is-size-4'>Start from blank</div>
            <div>Do not carry information over from another assessment</div>
          </button>
        </form>
        <button className='button option-button' onClick={(evt) => {evt.preventDefault(); this.setState({showAuditList: true});}}>
          <div className='is-size-4'>Carry over from past assessment</div>
          <div>Copy responses and attachments from selected sections</div>
        </button>
      </div>
      {
        showAuditList &&
        <div className='columns mt-4'>
          <div className='column is-one-third'>
            <AuditList
              {...{audits, selectedAudit}}
              setSelectedAudit={(audit: AuditListingDetails) => this.setSelectedAudit(audit)}
            />
          </div>
          <div className='column is-two-thirds'>
            <ElementList 
              audit={selectedAudit}
              siteId={siteId}
              targetElements={elements}
            />
          </div>
        </div>
      }
    </div>;
  }

  setSelectedAudit(selectedAudit: AuditListingDetails) {
    this.setState({selectedAudit});
  }
}

function AuditList(props: AuditListProps) {
  const { audits, selectedAudit, setSelectedAudit } = props;
  return <>
    <div className='ml-2 mb-2'>
      <strong>Step 1.</strong> Pick past assessment
    </div>
    {audits.map(audit => <div
      key={audit.auditId}
      className={buildClassName('is-selectable', 'p-2', selectedAudit === audit && 'is-selected')}
      onClick={() => setSelectedAudit(audit)}
    >
      <span className='has-text-weight-bold'>{audit.calendarYear}</span>
      {audit.submittedOn && <i className='fas fa-lock ml-2'></i>}
      <div className='is-size-7'>Started by {audit.startedBy} on {audit.startedOn}</div>
    </div>)}
  </>;
}

function ElementList(props: ElementListProps) {
  const {audit, siteId, targetElements} = props;
  const [matchingElements, setMatchingElements] = React.useState<MatchingElement[]>(null);
  const [selectedElements, setSelectedElements] = React.useState<string[]>([]);
  const [isSubmitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    (async function() {
      if (audit) {
        const fetchResult = await fetch(`/assessment/elements/${audit.auditId}`);
        const sourceElements = (await fetchResult.json() as AuditSection[]);

        setMatchingElements(sourceElements
          .map(source => ({
            source,
            target: targetElements.find(target => target.id === source.id && target.id),
            id: source.id
          }))
          .filter(match => match.target)
        );
        setSelectedElements(selectedElements.filter(id => matchingElements.some(elem => elem.id === id)));
      }
    })();
  },
  [audit]
  );

  const toggleElement = (id: string, include: boolean) =>
    setSelectedElements(include
      ? [...selectedElements, id]
      : selectedElements.filter(elem => elem !== id)
    );

  const selectAll = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();
    setSelectedElements(matchingElements.map(elem => elem.id));
  };

  const onSubmit = () => {
    setSubmitted(true);
  };

  if (!audit) { return <></>; }

  return <form method='POST' action={`/assessment/carry-over/${siteId}`} onSubmit={onSubmit}>
    <div className='ml-4 mb-2'>
      <strong>&nbsp;Step 2.</strong> Select all sections to copy over to new assessment
    </div>
    <input type='hidden' name='source' value={audit.auditId} />
    <ul>
      {matchingElements?.map(match => <li key={match.id}>
        <label className='checkbox'>
          {
            match.source.layout === 'guidelines only'
              ? <input type='checkbox' className='is-invisible mr-2' />
              : <input type='checkbox' value={match.id} name='elements' className={buildClassName(match.source.colour && 'ml-5', 'mr-2')}
                checked={selectedElements.indexOf(match.id) >= 0}
                onChange={evt => toggleElement(match.id, evt.target.checked)}
              />
          }
          {match.id} - {match.source.title} {match.source.title !== match.target.title ? `/ ${match.target.title}` : ''}
        </label>
      </li>)}
    </ul>
    <button
      className='button mt-4 mr-2'
      onClick={selectAll}>
      Select all
    </button>
    <button
      className={buildClassName('button is-primary mt-4', isSubmitted && 'is-loading')}
      disabled={!selectedElements.length || isSubmitted}
    >
      Carry over
    </button>
  </form>;
}

type BeginAssessmentViewProps = {
  siteId: number;
  audits: AuditListingDetails[];
  elements: AuditSection[];
  messages: FlashMessages;
};

type AuditListProps = {
  audits: AuditListingDetails[];
  selectedAudit: AuditListingDetails;
  setSelectedAudit: (audit: AuditListingDetails) => void;
};

type ElementListProps = {
  audit: AuditListingDetails;
  siteId: number;
  targetElements: AuditSection[];
};

type MatchingElement = {
  id: string;
  source: AuditSection;
  target: AuditSection;
};
