import moment from 'moment';
import React from 'react';
import DatePicker from 'react-date-picker';

export class DateEditor extends React.Component<DateEditorProps, DateEditorState> {
  private static readonly dateFormat = 'MMM dd y';

  public constructor(props: DateEditorProps) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  render(): JSX.Element {
    const value = this.state.value && !isNaN(new Date(this.state.value).valueOf())
      ? new Date(moment(this.state.value).toISOString(true))
      : null;
    return <div className='date-editor-container' id={this.props.id}>
      <span className='mr-2'>{this.props.placeholder}</span>
      <DatePicker
        value={value}
        format={DateEditor.dateFormat}
        className='date-editor date-display-z'
        onChange={(value: any) => this.onChange(value)}
      />
    </div>;
  }

  private onChange(dateValue: Date): void {
    const value = dateValue ? moment(dateValue).format('YYYY-MM-DD') : null;
    this.props.onChange(value);
    this.setState({ value });
  }
}

class DateEditorProps {
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
  id?: string;
  onFocus?: () => Promise<void>;
}

class DateEditorState {
  value: string;
}
