import React from 'react';

type SubsectionTitleProps = {
  id: string;
  title: string;
};

/* eslint-disable @typescript-eslint/naming-convention */
export function SubsectionTitle(props: SubsectionTitleProps): JSX.Element {
  return <div className={`subsection-title title-display-z my-2`}>
    <span className='subsection-title-id'>{props.id}</span>
    <span className='subsection-title-title'>{props.title}</span>
  </div>;
}