import React, { ReactElement } from 'react';
import { LockErrorModal } from './assessmentLock';

export default class NotificationBar extends React.Component<NotificationBarProps, NotificationBarState> {
  private intervalID: number;

  constructor(props: NotificationBarProps) {
    super(props);
    this.state = {
      timeoutSeconds: props.timeoutSeconds,
      showPopup: false
    };

    if (props.timeoutSeconds) {
      this.intervalID = window.setInterval(() => this.tickTimeout(), 1000);
    }
  }

  componentDidUpdate(): void {
    if (!this.intervalID && this.props.timeoutSeconds) {
      this.setState({ timeoutSeconds: this.props.timeoutSeconds });
      this.intervalID = window.setInterval(() => this.tickTimeout(), 1000);
    }
  }

  render(): JSX.Element {
    return <>
      <div className={`assessment-banner-message ${this.props.appearance === 'error' && 'assessment-banner-message-error'}`}>
        {this.props.message}
        {
          !!this.props.children &&
          <span className='link ml-3' onClick={() => this.setState({ showPopup: true })}>More info</span>
        }
        {
          !!this.state.timeoutSeconds &&
          <span className='ml-3'>Retrying in {this.state.timeoutSeconds}</span>
        }
      </div>
      {
        this.state.showPopup &&
        <LockErrorModal
          isVisible={true}
          close={() => this.setState({ showPopup: false })}
          title={this.props.message}
        >
          {this.props.children}
        </LockErrorModal>
      }
    </> ;
  }

  private tickTimeout() {
    this.setState({ timeoutSeconds: this.state.timeoutSeconds as number - 1 });
    if (!this.state.timeoutSeconds) {
      clearInterval(this.intervalID);
      this.intervalID = 0;
    }
  }
}

export type NotificationBarProps = {
  message: string;
  timeoutSeconds?: number;
  children?: ReactElement;
  appearance: 'info' | 'error';
};

type NotificationBarState = {
  timeoutSeconds?: number;
  showPopup: boolean;
};