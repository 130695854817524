import React from 'react';
import { AssessmentSidebar } from '..';
import { FlashMessages } from '../../../models/web';
import { AuditResponseVersion, AuditSection, SiteContributorAssignments } from '../../../services/audit/auditModels';
import { MessagesWidget } from '../messagesWidget';
import { AttachmentModal, ContractorInjuryRateModal, FormattedTextModal, HistoryModalTypes, ParticipantInjuryRateModal } from './historyModals';
import { AssessmentHistoryRow } from './row';

type AssessmentHistoryProps = {
  auditId: number;
  calendarYear?: number;
  canEdit: boolean;
  canLock: boolean;
  dueDate: string;
  elements: AuditSection[];
  history: AuditResponseVersion[];
  initialSection: string;
  isLockPage: boolean;
  isLinkedFilesPage: boolean;
  messages: FlashMessages;
  siteId: number;
  startedById: number;
  startedOn: Date;
  submittedOn: string;
  loggedInUserId: number;
  siteContributorAssignments: SiteContributorAssignments[];
};

type AssessmentHistoryState = {
  activeModal: HistoryModalTypes;
  modalValue: AuditResponseVersion | string | null;
  sections: Set<string>;
  filteredSection: string;
};

export default class AssessmentHistory extends React.Component<AssessmentHistoryProps, AssessmentHistoryState> {
  constructor(props: AssessmentHistoryProps) {
    super(props);
    this.state = {
      activeModal: 'none',
      modalValue: null,
      sections: new Set(props.history.map(r => r.sectionId)),
      filteredSection: props.initialSection
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(modal: HistoryModalTypes, value: AuditResponseVersion | string | null): void {
    this.setState({ activeModal: modal, modalValue: value });
  }

  render(): JSX.Element {
    let filteredSection = this.state.filteredSection;
    const sections = Array.from(this.state.sections).sort();
    if (!this.state.sections.has(filteredSection)) {
      filteredSection = 'All';
    }
    sections.push('All');
    return <React.Fragment>
      <AssessmentSidebar
        auditId={this.props.auditId}
        calendarYear={this.props.calendarYear}
        canLock={this.props.canLock}
        siteId={this.props.siteId}
        dueDate={this.props.dueDate}
        elements={this.props.elements}
        isLockPage={this.props.isLockPage}
        isLinkedFilesPage={this.props.isLinkedFilesPage}
        submittedOn={this.props.submittedOn}
        siteContributorAssignments={this.props.siteContributorAssignments}
        loggedInUserId={this.props.loggedInUserId}
      />
      <div className='main'>
        <div className='section'>
          <div className='container'>
            <h1 className='title mt-4'>Assessment History</h1>
            <MessagesWidget {...this.props.messages} />
            <div className=''>
              <div className='is-flex is-justify-content-start m-2'>
                <p className="subtitle is-5 m-2">Choose Section: </p>
                <select value={filteredSection} onChange={evt => this.setState({ filteredSection: evt.currentTarget.value })} >
                  {sections.map(section => <option key={`section-filter-${section}`} value={section} label={section} />)}
                </select>
              </div>
              <div className=''></div>
              <div className='history-log'>
                <div>
                  <table className='table is-fullwidth'>
                    <thead>
                      <tr>
                        <th className='relcol-15'>Edited By</th>
                        <th className='relcol-15'>Last Edited</th>
                        <th className='relcol-10'>Section</th>
                        <th className='relcol-10'>Question</th>
                        <th className='relcol-50'>New Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.props.history.map((row) => <AssessmentHistoryRow key={`${row.responseId}-${row.versionId}`}
                          row={row}
                          toggleModal={this.toggleModal}
                          sectionFilter={filteredSection} />)
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <FormattedTextModal isActive={this.state.activeModal === 'formattedText'}
                formattedText={this.state.modalValue as string}
                toggleClose={() => this.toggleModal('none', null)} />
              <ParticipantInjuryRateModal isActive={this.state.activeModal === 'participantInjury'}
                row={this.state.modalValue as AuditResponseVersion}
                toggleClose={() => this.toggleModal('none', null)} />
              <ContractorInjuryRateModal isActive={this.state.activeModal === 'contractorInjury'}
                row={this.state.modalValue as AuditResponseVersion}
                toggleClose={() => this.toggleModal('none', null)} />
              <AttachmentModal isActive={this.state.activeModal === 'attachment'}
                row={this.state.modalValue as AuditResponseVersion}
                toggleClose={() => this.toggleModal('none', null)} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>;
  }
}