import React from 'react';
import FileIcon from './fileIcon';

export class AttachmentEditor extends React.Component<AttachmentEditorProps, AttachmentEditorState> {
  constructor(props: AttachmentEditorProps) {
    super(props);
    this.state = {
      attachments: [...props.existingAttachments],
      dragHover: false
    };
  }

  render(): JSX.Element {
    const uploadButtonId = `file-upload-button-${this.props.responseId}`;
    return <div className={`full-width-component attachment-card card mb-4 ${this.props.className || ''}`}>
      <div className='card-content'>
        <div className='content'>
          <div className='card-content-title'>Attachments for {this.props.title}</div>
          {this.state.attachments.map((attachment, idx) =>
            <div className={`attachment-container ${attachment.error && 'attachment-error' || ''}`} key={attachment.attachmentId || idx}>
              {
                attachment.error
                  ? <React.Fragment>
                    <div className='icon is-large'><i className='fas fa-2x fa-exclamation-circle has-text-danger' /></div>
                    <div className='attachment-error-message'>
                      <div>{attachment.filename}</div>
                      <strong>A problem occurred while attaching the file</strong>
                    </div>
                  </React.Fragment>
                  :
                  <a id={`${attachment.questionId}-${attachment.attachmentId}`}>
                    <div className='icon is-large'><FileIcon file={attachment} /></div>
                    <div className='attachment-file'>
                      <a href={`/attachment/${attachment.attachmentId}`}>{attachment.filename}</a>
                      {
                        !this.props.readOnly && (
                          attachment.uploadProgress
                            ? <div className='attachment-progress-bar'>
                              <div className='attachment-progress' style={{ width: `${attachment.uploadProgress}%` }} />
                            </div>
                            : <button className='delete' onClick={() => window.confirm(`Are you sure you want to remove '${attachment.filename}'?`) && this.delete(attachment)} title='Delete' />
                        )
                      }
                    </div>
                  </a>
              }
            </div>
          )}
          {!this.props.readOnly && <div
            className={`attachment-drag-target ${this.state.dragHover ? 'drag-hover' : ''}`}
            onDragEnter={evt => this.drag(evt)}
            onDragOver={evt => this.drag(evt)}
            onDrop={evt => this.drop(evt)}
            onDragLeave={() => this.leave()}>
            Drop files here, or
            <label htmlFor={uploadButtonId}>pick a file from your computer</label>
            <input id={uploadButtonId} type='file' multiple onChange={evt => this.fileAdded(evt)} title='pick a file from your computer' />
          </div>}
        </div>
      </div>
    </div>;
  }

  private drag(event: React.DragEvent) {
    if (event.dataTransfer.types[0] === 'Files') {
      event.preventDefault();
      if (!this.state.dragHover) {
        this.setState({ dragHover: true });
      }
    }
  }

  private leave() {
    this.setState({ dragHover: false });
  }

  private drop(event: React.DragEvent) {
    event.preventDefault();
    this.setState({ dragHover: false });

    if (event.dataTransfer.types[0] === 'Files') {
      this.uploadAll([...event.dataTransfer.files]);
    }
  }

  private fileAdded(evt: React.ChangeEvent<HTMLInputElement>): void {
    this.uploadAll([...evt.currentTarget.files]);
  }

  private uploadAll(files: File[]) {
    const toUpload = files.map(file => ({
      file,
      attachment: {
        filename: file.name,
        uploadProgress: 1
      } as Attachment
    }));
    this.setState({ attachments: this.state.attachments.concat(toUpload.map(item => item.attachment)) });
    toUpload.forEach(async item => {
      try {
        await this.upload(item.file, item.attachment);
      } catch (error) {
        console.log(error);
        item.attachment.error = true;
        this.setState({ attachments: this.state.attachments });
      }
    });
  }

  private async upload(file: File, attachment: Attachment) {
    const createResponse = await fetch(
      '/attachment',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filename: file.name,
          mimetype: file.type,
          auditId: this.props.auditId,
          responseId: this.props.responseId
        })
      }
    );

    if (!createResponse.ok) {
      throw new Error('Upload failed');
    }

    const { attachment: attachmentResponse, url } = await createResponse.json();

    attachment.attachmentId = attachmentResponse.attachmentId;
    attachment.extension = attachmentResponse.extension;

    const update = (uploaded: number, total = 1) => {
      const progress = Math.round(uploaded / total * 100);
      attachment.uploadProgress = progress;
      this.setState({
        attachments: this.state.attachments
      });
    };

    // Need to use XHR here instead of fetch in order to get upload progress
    // fetch API will get this whenever this page turns green:
    // https://caniuse.com/mdn-api_request_request_readablestream_request_body

    const uploadRequest = new XMLHttpRequest();
    uploadRequest.upload.onprogress = e => {
      if (e.lengthComputable) {
        update(e.loaded / e.total);
      }
    };
    const uploadPromise = new Promise<void>((res, rej) => {
      uploadRequest.onloadend = () => res();
      uploadRequest.onerror = () => rej('Upload failed');
    });
    uploadRequest.open('PUT', url, true);
    uploadRequest.send(file);
    await uploadPromise;
    if (uploadRequest.status >= 400) {
      throw new Error('Upload failed');
    }

    const finaliseResponse = await fetch(
      '/attachment/finalise',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          auditId: this.props.auditId,
          attachmentId: attachment.attachmentId
        })
      }
    );

    if (!finaliseResponse.ok) {
      throw new Error('Upload failed');
    }

    update(0);
  }

  async delete(attachment: Attachment): Promise<React.MouseEventHandler<HTMLButtonElement>> {
    const response = await fetch(
      '/attachment',
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          attachmentId: attachment.attachmentId,
          auditId: this.props.auditId
        })
      }
    );


    if (!response.ok) {
      // Log error
      return;
    }

    this.setState({
      attachments: this.state.attachments.filter(att => att.attachmentId !== attachment.attachmentId)
    });
  }
}

interface Attachment {
  filename: string;
  attachmentId?: string;
  questionId?: string;
  extension?: string;
  uploadProgress?: number;
  error?: boolean;
}

class AttachmentEditorProps {
  auditId: number;
  responseId: number;
  title: string;
  existingAttachments: Attachment[];
  className?: string;
  readOnly?: boolean;
}

class AttachmentEditorState {
  attachments: Attachment[];
  dragHover: boolean;
}
