import * as React from 'react';
import { CompanyProfileType, UserLog } from '../../../services/system/systemModels';
import { UserProfile } from '../../../services/user/userModels';
import { UserLogList } from './userLog';
import { useState } from 'react';
import { AccessRemoveModal } from './accessRemoveModal';
import { ConfirmImpersonation } from './manageUsers';

/* eslint-disable @typescript-eslint/naming-convention */
function EditUserForm(props: AdminEditUserViewProps): JSX.Element {
  const { userProfile, loggedInUserProfileId } = props;
  const [revokeAccess, setRevokeAccess] = useState(false);
  const [revokeProfileType, setRevokeProfileType] = useState<CompanyProfileType>();
  const [revokeProfileId, setRevokeProfileId] = useState<number>();
  const [revokeProfileName, setRevokeProfileName] = useState<string>();
  const [revokeProfileRole, setRevokeProfileRole] = useState<string>();
  return <>
    <form action='/admin/user' method='post'>
      <div className='field is-horizontal'>
        <input type='hidden' name='userProfileId' value={userProfile.userProfileId} />
        <div className='field-label is-normal'><label className='label'>First name</label></div>
        <div className='field-body'>
          <div className='field'>
            <p className='control'>
              <input required type='text' className='input' defaultValue={userProfile.firstName} name='firstName' />
            </p>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label is-normal'><label className='label'>Last name</label></div>
        <div className='field-body'>
          <div className='field'>
            <p className='control'>
              <input required type='text' className='input' defaultValue={userProfile.lastName} name='lastName' />
            </p>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label is-normal'><label className='label'>Email</label></div>
        <div className='field-body'>
          <div className='field'>
            <p className='control'>
              <input required type='email' className='input' defaultValue={userProfile.email} name='email' />
            </p>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label' />
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <button type='submit' className='button is-primary'>Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
    {
      userProfile.userProfileId !== loggedInUserProfileId
        ? <div className='field is-horizontal mt-2'>
          <div className='field-label' />
          <div className='field-body'>
            <div className='field'>
              <div className='control'>
                <ConfirmImpersonation companyProfiles={
                  userProfile.companyProfiles.filter(profile => !profile.isImpersonationProfile)
                    .map(profile => {
                      return {
                        companyProfileId: profile.companyProfileId,
                        companyId: profile.companyId,
                        companyName: profile.companyName,
                        roles: profile.roles
                      };
                    })
                }/>
              </div>
            </div>
          </div>
        </div>
        : <></>
    }
    <hr />
    <form action={`/admin/activate-user/${userProfile.userProfileId}`} method='post'>
      {
        userProfile.activatedAt
          ? <div className='field is-horizontal'>
            <div className='field-label is-normal'><label className='label'>Activated on</label></div>
            <div className='field-body'>
              <p className='field-static'>{userProfile.activatedAt}</p>
            </div>
          </div>
          : <div className='field is-horizontal'>
            <div className='field-label' />
            <div className='field-body'>
              <div className='field'>
                <p className='control'>
                  <span className='field-static'>Account not activated</span>
                  <button type='submit' className='button is-small is-primary is-light mt-1'>Activate now</button>
                </p>
              </div>
            </div>
          </div>
      }
    </form>
    <hr />
    <h3 className='title is-3'>Companies</h3>
    <ul>
      {userProfile.companyProfiles.filter(profile => !profile.isImpersonationProfile).map(profile => <li key={profile.companyProfileId}
        className='is-flex'>
        <a href={`/admin/company/${profile.companyId}`}>{profile.companyName}</a> -&nbsp;
        {profile.roles.join(', ')}, {profile.sites.length} {profile.sites.length === 1 ? 'site' : 'sites'}
        <div className='dropdown is-align-self-center company-site-remove ml-1'>
          <div className='dropdown-trigger'>
            <button className='delete is-small' title='remove site or company access'
              aria-haspopup='true' aria-controls='dropdown-menu4' >
            </button>
          </div>
          <div className='dropdown-menu' id='dropdown-menu4' role='menu'>
            <div className='dropdown-content p-0'>
              {
                profile.roles.map((companyRole, idx) => <div className='dropdown-item pt-0 pb-0' key={idx}>
                  <button className='button is-ghost is-small' onClick={() => {
                    setRevokeProfileId(profile.companyId); setRevokeProfileName(profile.companyName);
                    setRevokeProfileRole(companyRole); setRevokeProfileType('company'); setRevokeAccess(true);
                  }}>
                    {profile.companyName} - company {companyRole}
                  </button>
                </div>)
              }
              {
                profile.sites.map(site => site.roles.map((siteRole, idx) => <div className='dropdown-item pt-0 pb-0' key={idx}>
                  <button className='button is-ghost is-small' onClick={() => {
                    setRevokeProfileId(site.siteId); setRevokeProfileName(site.siteName);
                    setRevokeProfileRole(siteRole); setRevokeProfileType('site'); setRevokeAccess(true);
                  }}>
                    {site.siteName} - site {siteRole}
                  </button>  
                </div>))
              }
            </div>
          </div>
        </div>
        {
          revokeAccess
            ? <AccessRemoveModal type={revokeProfileType} id={revokeProfileId} role={revokeProfileRole} name={revokeProfileName}
              userProfileId={userProfile.userProfileId} onCancel={() => {setRevokeAccess(false); window.location.reload();}}/>
            : <></>
        }
      </li>)}
    </ul>
  </>;
}

export class AdminEditUserView extends React.Component<AdminEditUserViewProps, AdminEditUserViewState> {
  constructor(props: AdminEditUserViewProps) {
    super(props);
    this.state = {
      currentTab: 'edit',
      log: []
    };

    this.fetchLogs();
  }

  async fetchLogs(): Promise<void> {
    try {
      const result = await fetch(`/admin/logs/user/${this.props.userProfile.userProfileId}`);
      if (!result.ok) {
        throw new Error('Could not get search results');
      }
      this.setState({ log: await result.json() });
    } catch (err) {
      console.log(err);
    }
  }

  render(): JSX.Element {
    const { userProfile } = this.props;
    return <div className='section'>
      <nav className='tabs is-large'>
        <ul>
          <li><h1 className='title mx-5'>{userProfile.firstName} {userProfile.lastName}</h1></li>
          <li onClick={() => this.setTab('edit')} className={this.state.currentTab === 'edit' ? 'is-active' : undefined}><a>Edit</a></li>
          <li onClick={() => this.setTab('logs')} className={this.state.currentTab === 'logs' ? 'is-active' : undefined}><a>Logs</a></li>
        </ul>
      </nav>
      {this.state.currentTab === 'edit' && <EditUserForm {...this.props} />}
      {this.state.currentTab === 'logs' && <UserLogList log={this.state.log} />}
    </div>;
  }

  setTab(currentTab: string): void {
    this.setState({ currentTab });
  }
}

type AdminEditUserViewProps = {
  userProfile: UserProfile;
  loggedInUserProfileId: number;
};

type AdminEditUserViewState = {
  currentTab: string;
  log: UserLog[];
};
