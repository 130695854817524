import React, { useState } from 'react';
import { AuditLaunchProfile, AdminProfile } from '../../../services/system/systemModels';

export type ProtocolModal = 'none' | 'testAudit' | 'publish';

type TestAuditModalProps = {
  isActive: boolean;
  toggleClose: () => void;
  protocolId?: string;
  profiles: AuditLaunchProfile[];
};

/* eslint-disable @typescript-eslint/naming-convention */
export function TestAuditModal(props: TestAuditModalProps): JSX.Element {
  const [siteId, setSite] = useState(props.profiles[0].siteId);

  return props.protocolId !== undefined ?
    <div className={props.isActive ? 'modal is-active' : 'modal'}>
      <div className='modal-background is-flex is-justify-content-flex-end is-align-content-flex-start' onClick={() => props.toggleClose()}>
        <button className='delete m-5' aria-label='close' onClick={() => props.toggleClose()}></button>
      </div>
      <div className='admin-protocol-modal'>
        <div className='admin-form is-flex is-flex-direction-column'>
          <h1 className='title m-5 has-text-centered'>Choose a Profile to Test Audit</h1>
          <form className='m-5' action='/admin/test-protocol' method='POST'>
            <input type='hidden' name='profileId' value={(props.profiles.find(profile => profile.siteId === siteId)).profileId} />
            <input type='hidden' name='protocolId' value={props.protocolId} />
            <div className="field has-addons">
              <div className="control is-expanded">
                <div className="select is-fullwidth">
                  <select name='siteId' value={siteId} onChange={evt => { setSite(Number(evt.target.value));  } }>
                    {
                      props.profiles.map(profile => <option key={`profile-opt-${profile.profileId}-${profile.siteId}`} value={profile.siteId}>{profile.siteName}</option>)
                    }
                  </select>
                </div>
              </div>
              <div className="control">
                <button type="submit" className="button is-primary">Launch Test Audit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    : <div></div>
  ;
}

type PublishModalProps = {
  isActive: boolean;
  toggleClose: () => void;
  protocolId?: string;
  systemProfiles: AdminProfile[];
};

/* eslint-disable @typescript-eslint/naming-convention */
export function PublishModal(props: PublishModalProps): JSX.Element {
  const [reviewer, setReviewer] = useState(props.systemProfiles[0].userProfileId);
  return props.protocolId !== undefined ?
    <div className={props.isActive ? 'modal is-active' : 'modal'}>
      <div className='modal-background is-flex is-justify-content-flex-end is-align-content-flex-start' onClick={() => props.toggleClose()}>
        <button className='delete m-5' aria-label='close' onClick={() => props.toggleClose()}></button>
      </div>
      <div className='admin-protocol-modal'>
        <div className='admin-form is-flex is-flex-direction-column'>
          <h1 className='title m-5 has-text-centered'>Choose Administrator to Review</h1>
          <form className='m-5' action='/admin/publish-protocol' method='POST'>
            <input type='hidden' name='protocolId' value={props.protocolId} />
            <div className="field has-addons">
              <div className="control is-expanded">
                <div className="select is-fullwidth">
                  <select name='reviewerId' value={reviewer} onChange={evt => { setReviewer(Number(evt.target.value));  } }>
                    {
                      props.systemProfiles.map(profile => <option key={`profile-row-${profile.userProfileId}`} value={profile.userProfileId}>{profile.name}</option>)
                    }
                  </select>
                </div>
              </div>
              <div className="control">
                <button type="submit" className="button is-primary">Request Publish Review</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    : <div></div>
  ;
}