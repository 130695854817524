import * as QuillCore from 'quill';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactQuill, { Quill } from 'react-quill';
import { Lock } from './editLock';
import ImageResizeModule from './imageResizeModule';
import ImageUploadModule from './imageUploadModule';

(function oneTimeQuillInit() {
  Quill.register('modules/imageResize', ImageResizeModule);
  Quill.register('modules/imageUpload', ImageUploadModule);
})();

const FOCUS_MONITOR_SECONDS = 3;

export class FormattedTextEditor extends React.Component<FormattedTextEditorProps, FormattedTextEditorState> {
  private static allEditors: FormattedTextEditor[] = [];
  private quillRef: ReactQuill;
  private imageUploader: ImageUploadModule;
  private imageResizer: ImageResizeModule;

  public constructor(props: FormattedTextEditorProps) {
    super(props);
    this.state = new FormattedTextEditorState;
    FormattedTextEditor.allEditors.push(this);
    setInterval(() => this.focusMonitor(), FOCUS_MONITOR_SECONDS * 1000);
  }

  private getQuillContainerId(): string {
    return `quillId-${this.props.questionId}`;
  }
  /**
   * focusMonitor is a silly hack to maximize the likelihood that
   * the Quill toolbar will be present. There are several cases where
   * a child in the Quill toolbar does not send a blur call correctly,
   * i.e. when a user clicks out of the browser.  In this case the
   * FormattedTextEditor releases the Quill toolback but the Quill Editor
   * retains focus.  This means that when the user comes back to the browser
   * they have focus in the editor but no toolbar.
   *
   * This function checks a fixed depth of elements from the active
   * element and refocuses the FormattedTextEditor so that the toolbar
   * is active should a child element of the Quill be active.
   */
  public async focusMonitor(): Promise<void> {
    const quillContainerEl = document.getElementById(this.getQuillContainerId());
    if (quillContainerEl === undefined || quillContainerEl === null) {
      return;
    }
    const quillInFocus = quillContainerEl.contains(document.activeElement);
    const haveWriteLock = this.props.lock?.lockType === 'WRITE';
    if (quillInFocus && haveWriteLock) {
      this.setState({ active: true });
      this.props.onActiveChange(true);
    }
  }

  render(): JSX.Element {
    const editLock = this.props.lock?.lockType === 'WRITE';
    const readOnly = this.props.readOnly || !editLock;
    
    return <div
      onBlur={readOnly ? undefined : evt => this.onBlur(evt)}>
      <ReactQuill
        id={this.getQuillContainerId()}
        className={this.state.active ? 'quill-active' : 'quill-inactive'}
        defaultValue={this.props.defaultValue}
        placeholder={this.props.placeholder}
        onChange={(content, delta, source, editor) => this.onChange(content, delta, source, editor)}
        onFocus={this.props.readOnly ? undefined : () => this.onFocus()}
        readOnly={readOnly}
        ref={ref => this.setQuillRef(ref)}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['image', 'clean']                                // remove formatting button
          ],
          imageResize: {},
          imageUpload: {
            auditId: this.props.auditId
          }
        }}
      />
      {
        !this.props.readOnly && !!this.props.options?.example &&
        <button className='button is-primary mt-2' onClick={() => this.addExampleText()}>Add example</button>
      }
    </div>;
  }

  private setQuillRef(ref: ReactQuill): void {
    this.quillRef = ref;
    this.imageUploader = ref?.getEditor()?.getModule('imageUpload');
    this.imageResizer = ref?.getEditor()?.getModule('imageResize');
    if (this.imageResizer) {
      this.imageResizer.onActivate = () => this.onFocus(false);
    }
  }

  private async onFocus(shouldRefocus = true): Promise<void> {
    await this.props.onFocus();
    // Deactivate all other editors (in case they remained active after leaving via the toolbar)
    FormattedTextEditor.allEditors.forEach(editor => editor !== this && editor.deactivate());
    setTimeout(() => {
      if (shouldRefocus) {
        // Re-focus, in case the editor was readonly at the time of gaining focus
        this.quillRef?.focus();
      }
      if (this.props.lock?.lockType === 'WRITE') {
        this.setState({ active: true });
        this.props.onActiveChange(true);
      }
    }, 1);
  }

  private onBlur(evt: React.FocusEvent<HTMLDivElement>): void {
    // Don't do this if the new focus is our own toolbar
    const thisElement = ReactDOM.findDOMNode(this.quillRef);
    if (!thisElement.contains(evt.relatedTarget as Node)) {
      this.props.onActiveChange(false);
      setTimeout(() => {
        if (this.props.onBlur) {
          this.props.onBlur(evt);
        }
        this.setState({ active: false });
      }, 1);
    }
  }

  private deactivate(): void {
    this.setState({ active: false });
  }

  private onChange(content: string, delta: QuillCore.Delta, source: QuillCore.Sources, editor: any) {
    if (this.imageUploader?.deltaIncludesDataUrls(delta)) {
      // We're in the middle of uploading images, don't send the change event
      return;
    }

    this.props.onChange(content, delta, source, editor);
  }

  private async addExampleText(): Promise<void> {
    const haveLock = await this.props.onFocus();
    if (!haveLock) {
      return;
    }
    const editor = this.quillRef.getEditor();
    const existingContent = editor.getContents();
    const newContent = existingContent.length() > 1 // length = 1 is actually blank. Replace all content.
      ? existingContent.concat(editor.clipboard.convert('<p><br/></p>' + this.props.options.example))
      : editor.clipboard.convert(this.props.options.example);
    editor.setContents(newContent);
    this.onFocus(true);
  }
}


class FormattedTextEditorProps {
  defaultValue?: string | QuillCore.Delta;
  placeholder?: string;
  onChange?: (
    content: string,
    delta: QuillCore.Delta,
    source: QuillCore.Sources,
    editor: any
  ) => void;
  onActiveChange?: (isActive: boolean) => void;
  readOnly?: boolean;
  /** Return true if lock is obtained */
  onFocus?: () => Promise<boolean>;
  onBlur?: (evt?: React.FocusEvent) => void;
  lock?: Lock;
  auditId: number;
  questionId: string;
  options?: any;
}

class FormattedTextEditorState {
  active = false;
}