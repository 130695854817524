export function rateLimit(func: (...args: any[]) => void, rate: number): (...args: any[]) => void {
  let lastCall = 0;
  return (...args) => {
    if (Date.now() > lastCall + rate) {
      func(...args);
      lastCall = Date.now();
    }
  };
}

export function capitalize(value: string): string {
  return value[0].toUpperCase() + value.substring(1);
}

/**
 * Variadic function which joins any string arguments with a space. Non-string
 * arguments are discarded.
 * @example
 * const isNew = true;
 * // Returns: 'container new-item'
 * buildClassName(
 *   'container'
 *   isNew && 'new-item',
 *   isNew || 'is-old'
 * );
 */
export function buildClassName(...args: any[]): string {
  return args
    .filter(value => typeof value === 'string' || value instanceof String)
    .join(' ');
}


export class AsyncQueue<Key = string> {
  private queue: Array<{
    key: Key;
    f: () => Promise<any>;
    resolves: Array<(result: any) => void>;
    rejects: Array<(err: any) => void>;
  }> = [];
  private processing = false;
 
  add<Result>(key: Key, f: () => Promise<Result>): Promise<Result> {
    let item = this.queue.find(({key: existingKey}) => key === existingKey);
    if (!item) {
      item = {key, f, resolves: [], rejects: []};
      this.queue.push(item);
    }

    const promise = new Promise<Result>((resolve, reject) => {
      item.resolves.push(resolve);
      item.rejects.push(reject);
    });

    this.processQueue();

    return promise;
  }

  private async processQueue() {
    if (this.processing) {
      return;
    }
    this.processing = true;
    while (this.queue.length) {
      const item = this.queue.shift();
      try {
        const result = await item.f();
        for (const resolve of item.resolves) {
          resolve(result);
        }
      } catch (err) {
        for (const reject of item.rejects) {
          reject(err);
        }
      }
    }
    this.processing = false;
  }
}

export function clearCookie(cookieName: CookieName, accessPath: string) {
  document.cookie = `${cookieName}=; session=true; path=${accessPath}`;
}

export function getCookieValue(cookieName: CookieName): string | null {
  const cookieValue = document.cookie.split(';').map(cookie => cookie.trim()).find(cookie => cookie.startsWith(`${cookieName}=`));
  return cookieValue ? cookieValue.split('=')[1] : null; 
}

export function setCookieValue(cookieName: CookieName, accessPath: string, value: any) {
  document.cookie = `${cookieName}=${value}; session=true; path=${accessPath}`;
}

type CookieName = 'filterUserId';