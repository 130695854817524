import * as React from 'react';
import { AccessProfile, ManagedSite } from '../../services/user/userModels';

export class SiteSwitcherWidget extends React.Component<SiteSwiterWidgetProps, SiteSwiterWidgetState> {
  constructor(props: SiteSwiterWidgetProps) {
    super(props);
    fetch('/user/current-user-navigation-data/' + props.siteId).then(res => res.json().then(state => this.setState(state)));
  }

  render(): React.ReactNode {
    if (!this.state?.currentProfile) {
      return <div className='site-switcher-container'></div>;
    }

    return <div className='site-switcher-container' onClick={() => this.props.saveContent()}>
      <div className={'site-switcher'}>
        <a href='/dashboard/company' title='Home' className='button home-button'><span className='icon has-text-white'>
          <i className='fas fa-home'></i></span></a> 
        {
          this.state.canSwitchSiteOrProfile
            ? <React.Fragment>

              <h2 className='is-flex is-justify-content-space-between subtitle has-text-white profile-switcher-head' tabIndex={1}>
                <span className='profile-switcher'>{this.state.currentSite?.fullName || this.state.currentProfile.companyName || 'System administrator'}</span>
                <i className='fas fa-sort-down is-pulled-right mr-1'></i>
              </h2>
              <div className='profile-switcher-body'>
                <a href='/dashboard/company'>{this.state.currentProfile.companyName}</a>
                {this.state.currentProfile.sites.map(site =>
                  <a key={site.siteId} href={`/dashboard/site/${site.siteId}`} className='site-indent' >{site.fullName}</a>
                )}
                {this.state.canSwitchCompany && <a href='/user/switch-company'>Other companies&hellip;</a>}
                {this.state.hasSystemProfile && <a href='/user/switch-to-system'>System administrator</a>}
              </div>
            </React.Fragment>
            : <h2 className='is-flex is-justify-content-space-between subtitle has-text-white profile-switcher-single'>
              <span className='profile-switcher'>{this.state.currentSite?.fullName || this.state.currentProfile.companyName}</span>
            </h2>
        }
      </div>
      <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
        <div className='site-switcher-logo'>
          <img className='image' src='/images/vpppa_logo.png' />
        </div>
      </div>
      {
        this.state.currentSite?.status === 'evaluation' &&
        <div className='tag is-warning is-align-self-center my-2' title='This site is in evaluation mode. To upgrade, please contact vppsupport@auditsoft.co'>Evaluation</div>
      }
    </div>;
  }
}

interface SiteSwiterWidgetProps {
  siteId: number;
  backLink?: string;
  saveContent?: () => Promise<void>;
}

interface SiteSwiterWidgetState {
  canSwitchSiteOrProfile: boolean;
  canSwitchCompany: boolean;
  hasSystemProfile: boolean;

  currentSite: ManagedSite;
  currentProfile: AccessProfile;
}
