import React from 'react';

export default class YesNoEditor extends React.Component<YesNoEditorProps, null> {
  private value?: string;

  constructor(props: YesNoEditorProps) {
    super(props);
    this.value = props.value;
  }

  render(): JSX.Element {
    return <div>
      <div className='mb-2 is-flex'>
        <div className='is-flex is-flex-direction-column is-flex-grow-1 mt-2'>
          <label className=''>{this.props.label}</label>
          {this.value === 'yes' && this.props.yesText && <label>{this.props.yesText}</label>}
          {this.value === 'no' && this.props.noText && <label>{this.props.noText}</label>}
        </div>
        <div className='field has-addons'>
          <p className='control'>
            <button onClick={() => this.setValue('yes')} className={`button ${this.value === 'yes' ? 'is-info' : ''}`}>Yes</button>
          </p>
          <p className='control'>
            <button onClick={() => this.setValue('no')} className={`button ${this.value === 'no' ? 'is-info' : ''}`}>No</button>
          </p>
        </div>
      </div>
    </div>;
  }

  setValue(value?: string): void {
    if (this.props.readOnly) {
      return;
    }

    this.value = value;
    this.props.onChange(value);
    this.forceUpdate();
  }
}

class YesNoEditorProps {
  public label: string;
  public value?: any;
  onChange?: (value: any) => void;
  yesText?: string;
  noText?: string;
  readOnly?: boolean;
}
