import React from 'react';
import { Site, SiteType } from '../../services/company/companyModels';
import { StateInfo } from '../../services/system';
import { companyRoles, ProfileDetails, siteRoles } from '../../services/user/userModels';
import CreateSiteForm from './createSiteForm';
import InviteForm from './inviteForm';
import { capitalize } from './utility';

type RequestSiteAccessProps = {
  userProfileId: number;
  siteId: number;
  awaitingAccess: boolean;
};

/* eslint-disable @typescript-eslint/naming-convention */
function RequestSiteAccess(props: RequestSiteAccessProps): JSX.Element {
  if (props.awaitingAccess) {
    return <span className="has-text-info">Access Requested</span>;
  } else {
    return <form action='/company/request-site-access' method='post'>
      <input type='hidden' name='userProfileId' value={props.userProfileId} />
      <input type='hidden' name='siteId' value={props.siteId} />
      <button type='submit' className='button is-small is-primary'>Request access</button>
    </form>;
  }
}

export default class CompanyAdminView extends React.Component<CompanyAdminViewProps, CompanyAdminViewState> {
  constructor(props: CompanyAdminViewProps) {
    super(props);
    this.state = {
      isFederal: props.formData.isFederal || false,
      managerEmail: props.formData?.managerEmail || '',
      managerFirstName: props.formData?.managerFirstName || '',
      managerLastName: props.formData?.managerLastName || '',
      showAddManager: false,
      showAddSite: false,
      siteManager: props.formData?.siteManager || 'me',
      siteName: props.formData?.siteName || '',
      siteType: props.formData.siteType,
      state: props.formData?.state || '',
      tab: (document.location.hash || 'sites').replace('#', ''),
    };
  }

  private setTab(tab: string): void {
    document.location.hash = tab;
    this.setState({ tab });
  }

  render(): JSX.Element {
    const { tab, error } = this.state;

    return <React.Fragment>

      {error && <div className='section'>
        <div className='notification is-danger'>{error}</div>
      </div>}
      <div className='section pb-0'>
        <nav className='tabs is-large'>
          <ul>
            <li><h1 className='title mx-5'>Manage company</h1></li>
            <li onClick={() => this.setTab('sites')} className={tab === 'sites' ? 'is-active' : undefined}><a>Sites</a></li>
            <li onClick={() => this.setTab('people')} className={tab === 'people' ? 'is-active' : undefined}><a>People</a></li>
          </ul>
        </nav>
      </div>
      <div className='section tab'>
        {tab === 'sites' && this.renderSites()}
        {tab === 'people' && this.renderPeople()}
      </div>
    </React.Fragment>;
  }

  private renderSites(): JSX.Element {
    return <div>
      {
        this.props.sites.length > 0 && <table className='table'>
          <thead>
            <tr>
              <td className='relcol-40'>Site</td>
              <td className='relcol-10'>State</td>
              <td className='relcol-20'>Managed by</td>
              <td className='relcol-15'>Access</td>
              <td className='relcol-15'>My access</td>
            </tr>
          </thead>
          <tbody>
            {this.props.sites.map(site => <tr key={site.siteId}>
              <td>
                {
                  site.currentUserRoles?.length
                    ? <a title='site' href={`/dashboard/site/${site.siteId}`}>{site.name}</a>
                    : site.name
                }
              </td>
              <td>{site.state}</td>
              <td className='comma-separated-list'>{site.managers.map(manager => <span key={manager.email} title={manager.email}>{manager.name}</span>)}</td>
              <td className={`company-admin-site-status-${site.status}`}>{capitalize(site.status)}</td>
              <td>
                {
                  site.currentUserRoles?.map(role => this.getRoleName(role)).join(', ') ||
                  <RequestSiteAccess userProfileId={this.props.userProfileId}
                    siteId={site.siteId}
                    awaitingAccess={site.awaitingAccessRequest} />
                }
              </td>
            </tr>)}
          </tbody>
        </table>
      }
      {
        this.props.isManager && <React.Fragment>
          {this.props.sites.length > 0 || <p className='mb-4'>Before you can perform an assessment you'll need to add a <i>site</i></p>}
          {
            this.state.showAddSite
              ? <CreateSiteForm {...this.props} isCreatedBySysadmin={false} endpoint='/company/add-site' />
              : <div><button className='button is-primary' onClick={() => this.setState({ showAddSite: true })}>Request new site</button></div>
          }
        </React.Fragment>
      }
    </div>;
  }

  private renderPeople(): JSX.Element {
    return <div>
      {
        this.props.sites.length > 0 && <table className='table'>
          <thead>
            <tr>
              <td>Name</td>
              <td>Email</td>
              <td>Role</td>
            </tr>
          </thead>
          <tbody>
            {this.props.companyUsers.map(user => <tr key={user.userId}>
              <td>{user.firstName} {user.lastName}</td>
              <td>{user.email}</td>
              {
                (this.props.isManager && user.userId !== this.props.userProfileId)
                  ? <td className='p-1'>
                    <div className='select is-small'>
                      <select onChange={evt => this.setUserRole(evt, user)} defaultValue={user.roles[0]}>
                        {companyRoles.map(role => <option key={role.value} value={role.value}>{role.name}</option>)}
                      </select>
                    </div>
                  </td>
                  : <td><div className='py-1 is-size-7'>{user.roles.map(role => companyRoles.find(sr => sr.value === role).name).join(', ')}</div></td>
              }
            </tr>)}
          </tbody>
        </table>
      }
      {
        this.props.isManager &&
        (this.state.showAddManager
          ? <InviteForm
            redirectTo='/dashboard/company#people'
            showRoles={false}
            title='Invite a company manager'
            users={this.props.companyUsers}
          />
          : <div>
            <button className='button is-primary' onClick={() => this.setState({ showAddManager: true })}>Invite company manager</button>
          </div>
        )
      }
    </div>;
  }


  private setManagerEmail(managerEmail: string): void {
    const knownUser = this.props.companyUsers.find(user => user.email.toLowerCase() === managerEmail.toLowerCase());
    if (knownUser) {
      this.setState({ managerEmail: managerEmail, managerFirstName: knownUser.firstName, managerLastName: knownUser.lastName });
    } else {
      this.setState({ managerEmail: managerEmail });
    }
  }

  private getRoleName(role: string): string {
    return siteRoles.find(r => r.value === role)?.name || role;
  }

  async setUserRole(evt: React.ChangeEvent<HTMLSelectElement>, user: ProfileDetails): Promise<void> {
    const role = evt.currentTarget.value;
    user.roles = [role];
    let success = false;
    let result: Response;
    let errorMessage = 'Could not change user role';
    try {
      result = await fetch(
        '/company/set-role',
        {
          method: 'POST',
          body: JSON.stringify({
            userId: user.userId,
            roles: user.roles
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      success = result.ok;

    } catch (err) {
      console.log(err);
    }

    if (!success) {
      try {
        const responseJson = await result.json();
        if (responseJson.message) {
          errorMessage = responseJson.message;
        }
        // eslint-disable-next-line no-empty
      } catch {
        // Don't set error message if result was not JSON
      }
    }

    this.setState({ error: success ? null : errorMessage });
  }
}

interface CompanyAdminViewProps {
  formData: any;
  sites: Site[];
  companyUsers: ProfileDetails[];
  companyId: number;
  states: StateInfo[];
  userProfileId: number;
  isManager: boolean;
}

interface CompanyAdminViewState {
  isFederal: boolean;
  managerEmail: string;
  managerFirstName: string;
  managerLastName: string;
  showAddManager: boolean;
  showAddSite: boolean;
  siteManager: string;
  siteName: string;
  siteType: SiteType;
  state: string;
  tab: string;
  error?: string;
}
