import * as React from 'react';
import { FlashMessages } from '../../models/web';

export class MessagesWidget extends React.Component<FlashMessages, null> {
  render(): React.ReactNode {
    return <React.Fragment>
      {(this.props.error || []).map((message, idx) =>
        <div key={idx} className='message is-danger is-small'>
          <div className='message-body'>{message}</div>
        </div>
      )}
      {(this.props.info || []).map((message, idx) =>
        <div key={idx} className='message is-primary is-small'>
          <div className='message-body'>{message}</div>
        </div>
      )}

    </React.Fragment>;
  }
}
