import * as React from 'react';
import { useState } from 'react';
import { SiteListItem, SiteStatus } from 'services/company/companyModels';
import { InviteUserModal } from './inviteUserModal';

export function CompanySiteTable(props: CompanySiteTableProps): JSX.Element {
  const companySites = props.companySites;
  return <>
    {
      companySites.length
        ? companySites.map((site, idx) => <SiteRow key={idx} site={site} forManageSites={props.forManageSites}
          siteType={site.siteType ?? ''} totalUsers={site.managers?.length ?? 0} companyId={props.companyId} />
        )
        : <tr>
          <td colSpan={7}>No sites</td>
        </tr>
    }
  </>;
}

export function SiteRow(props: SiteRowProps): JSX.Element {
  const { site, forManageSites, siteType, totalUsers } = props;
  const [siteStatus, setSiteStatus] = useState(site.status);
  const [addSiteuser, setAddSiteUser] = useState<boolean>(false);

  return (
    <tr className={props.isFirstRow ? 'first-site-row' : ''}>
      {
        forManageSites && props.isFirstRow === true
          ? 
          <td rowSpan={props.totalCompanySites}>
            <a href={`/admin/company/${props.companyId}`}>
              {props.companyName}
            </a>
          </td>
          : <></>
      }
      <td>{site.name}</td>
      <td>{site.state}</td>
      <td>{siteType}</td>
      <td>{new Date(site.createdAt).toDateString()}</td>
      <td className='admin-user-count' tabIndex={0}>
        <div className='dropdown is-hoverable'>
          <div className='dropdown-trigger'>
            <button className='button is-small is-clickable' disabled={totalUsers === 0} aria-haspopup='true' aria-controls='dropdown-menu4'>
              <span>{totalUsers}</span>
            </button>
          </div>
          {
            totalUsers
              ? <div className='dropdown-menu' id='dropdown-menu4' role='menu'>
                <div className='dropdown-content'>
                  {
                    (forManageSites ? site.siteUsers : site.managers).map((user, idx) => <div key={idx} className='dropdown-item'>
                      <a href={`/admin/user/${user.userId}`}>{`${user.name} (${user.roles[0]})`}</a>
                    </div>)
                  }
                </div>
              </div>
              : <></>
          }
        </div>
      </td>
      <td>
        <div className='select is-small'>
          <select
            name={`siteId_${site.siteId}`}
            value={siteStatus}
            onChange={(evt) => {
              const status = evt.currentTarget.value as SiteStatus;
              if (props.onSiteStatusChange) {
                props.onSiteStatusChange(status);
              }
              setSiteStatus(status);
            }}
            disabled={props.saving}
          >
            <option value='active'>Active</option>
            <option value='evaluation'>Evaluation</option>
            <option value='suspended'>Suspended</option>
          </select>
        </div>
      </td>
      {
        forManageSites ?
          <td>
            <input
              className="input is-small"
              value={site.memberId}
              onChange={(evt) => { if (props.onMemberIdChange) { props.onMemberIdChange(evt.currentTarget.value); } }}
              disabled={props.saving}
            />
          </td>
          : <></>
      }
      <td>
        <button className='button is-link is-small' type='button' onClick={() => setAddSiteUser(true)}>
          Add User
        </button>
      </td>
      {
        addSiteuser
          ? <InviteUserModal companyId={props.companyId} site={site} onCancel={() => setAddSiteUser(false)}
            redirectUrl={window.location.href}/>
          : <></>
      }
    </tr>
  );

}

type CompanySiteTableProps = {
  companyId: number;
  companySites: SiteListItem[];
  forManageSites: boolean;
};

type SiteRowProps = {
  site: SiteListItem;
  forManageSites: boolean;
  siteType: string;
  totalUsers: number;
  totalCompanySites?: number;
  onSiteStatusChange?: (siteStatus: SiteStatus) => void;
  onMemberIdChange?: (id: string) => void;
  saving?: boolean;
  companyId: number;
  companyName?: string;
  isFirstRow?: boolean;
};