import React from 'react';
import { createRoot as CreateRoot } from 'react-dom/client';
import AssessmentHistory from './assessmentHistory/assessmentHistory';
import CompanyAdminView from './companyAdminView';
import CreateSiteForm from './createSiteForm';
import AssessmentSidebar from './editAssessment/assessmentSidebar';
import AttachedFilesView from './editAssessment/attachedFilesView';
import EditAssessmentView from './editAssessment/editAssessmentView';
import BeginAssessmentView from './editAssessment/beginAssessmentView';
import SiteAdminView from './siteAdminView';
import { ManageSitesView } from './manageSites/manageSitesView';
import { CompanySiteTable } from './manageSites/siteTable';

export * from './systemAdmin';
export {
  BeginAssessmentView,
  EditAssessmentView,
  CompanyAdminView,
  CreateSiteForm,
  SiteAdminView,
  React,
  CreateRoot,
  AssessmentHistory,
  AssessmentSidebar,
  AttachedFilesView,
  ManageSitesView,
  CompanySiteTable
};

export type MessageCallback = (message: string) => void;
export interface MessageSource {
  register: (callback: MessageCallback) => void;
}
