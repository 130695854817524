import React, { useState } from 'react';
import { ProtocolManagementListView } from '../../../services/system/systemModels';
import { ProtocolModal } from './protocolModal';

const ALL_JURISDICTIONS = 'All';

type ProtocolListViewRowProps = {
  protocol: ProtocolManagementListView;
  isVisible: boolean;
  toggleModal(modal: ProtocolModal, protocolId: string | null): void;
};

/* eslint-disable @typescript-eslint/naming-convention */
function ProtocolListViewRow(props: ProtocolListViewRowProps): JSX.Element {
  if (!props.isVisible) {
    return null;
  }
  return <tr className={props.protocol.isLive ? 'has-background-success' : ''}>
    <td>{props.protocol.jurisdiction}</td>
    <td>{props.protocol.version}</td>
    <td>{props.protocol.dateCreated}</td>
    <td className='has-text-centered'>{props.protocol.isLive && <i className='fas fa-check' />}</td>
    <td className='has-text-centered'>{props.protocol.canDownload && 
      <a href={`/admin/protocol/json/${props.protocol.protocolId}`}>
        <i className='fas fa-download is-clickable'></i>
      </a>}
    </td>
    <td className='has-text-centered'><i onClick={() => props.toggleModal('testAudit', props.protocol.protocolId)} className='fas fa-edit is-clickable' /></td>
    <td className='has-text-centered'><i onClick={() => props.toggleModal('publish', props.protocol.protocolId)} className='fas fa-play-circle is-clickable' /></td>
  </tr>;
}

type ProtocolListProps = {
  protocols: ProtocolManagementListView[];
  jurisdictions: string[];
  toggleModal(modal: ProtocolModal, protocolId: string | null): void;
};

/* eslint-disable @typescript-eslint/naming-convention */
export function ProtocolList(props: ProtocolListProps): JSX.Element {
  const allJurisdictions = [ALL_JURISDICTIONS, ...props.jurisdictions];
  const [jurisdiction, setJurisdiction] = useState('USA');

  return <React.Fragment>
    <div className=''>
      <div className='is-flex is-justify-content-start m-2'>
        <p className='subtitle is-5 m-2'>Jurisdiction: </p>
        <select value={jurisdiction} onChange={evt => setJurisdiction(evt.currentTarget.value)} >
          { allJurisdictions.map(jurisdiction => <option key={`protocol-list-${jurisdiction}`} value={jurisdiction} label={jurisdiction} />) }
        </select>
      </div>
      <div className=''></div>
      <div>
        <div>
          <table className='table is-fullwidth'>
            <thead>
              <tr>
                <th className='relcol-15'>Jurisdiction</th>
                <th className='relcol-25'>Version</th>
                <th className='relcol-20'>Created</th>
                <th className='relcol-10 has-text-centered'>Is Live?</th>
                <th className='relcol-10 has-text-centered'>Download</th>
                <th className='relcol-10 has-text-centered'>Test Audit</th>
                <th className='relcol-10 has-text-centered'>Publish</th>
              </tr>
            </thead>
            <tbody>
              {
                props.protocols.map((protocol) => <ProtocolListViewRow key={`plr-${protocol.protocolId}`}
                  protocol={protocol}
                  isVisible={jurisdiction === ALL_JURISDICTIONS || protocol.jurisdiction === jurisdiction} 
                  toggleModal={props.toggleModal} />)
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </React.Fragment>;
}