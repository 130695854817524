import React from 'react';
import { AdminViewAuditDetails } from '../../../services/audit/auditModels';

export class ShowAssessmentsView extends React.Component<ShowAssessmentsViewProps, ShowAssessmentsViewState> {
  constructor(props: ShowAssessmentsViewProps) {
    super(props);
    this.state = {};
  }
  render(): JSX.Element {
    return <>
      <h1 className='title'>Open assessments by year</h1>
      <table className='table open-assessments-table'>
        <thead>
          <tr>
            <th>Company</th>
            <th>Site</th>
            <th>State</th>
            <th>Created by</th>
            <th>Year</th>
            <th>Admins</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.audits.map((audit, idx) => <tr key={idx}>
              <td>{audit.companyName}</td>
              <td>{audit.siteName}</td>
              <td>{audit.state}</td>
              <td>{audit.createdBy}</td>
              <td>{audit.calendarYear}</td>
              <td>{audit.adminsOnSite?.map((admin, idx) => <div key={idx}>{admin.userName}</div>)}</td>
              <td>{audit.adminsOnSite?.map((admin, idx) => <div key={idx}>{admin.emailID}</div>)}</td>
            </tr>)
          }
        </tbody>
      </table>
    </>;
  }
}
type ShowAssessmentsViewProps = {
  audits: AdminViewAuditDetails[];
};

type ShowAssessmentsViewState = Record<string, never>;