import { Site, SiteStatus } from 'services/company/companyModels';

export interface CreateUserRequest {
  invitingUser: UserProfile;
  companyId: number;
  siteId?: number;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  roles: string[];
  redirectTo?: string;
}

export class ReinviteRequest {
  public invitingUser: UserProfile;
  public companyId: number;
  public siteId: number;
  public userProfileId: number;
  public email: string;
}

export class SetUserRolesRequest {
  public userId: number;
  public siteId?: number;
  public companyId: number;
  public roles: string[];
}

export class UserProfile {
  public userProfileId: number;
  public createdAt: string;
  public activatedAt: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public companyProfiles: CompanyProfile[] = [];
  public systemProfile: SystemProfile;
  public isNewUser?: boolean;

  public canSwitchSiteOrProfile(): boolean {
    const companyProfileCount = this.companyProfiles.length;
    const systemProfileCount = this.systemProfile ? 1 : 0;
    const profileCount = companyProfileCount + systemProfileCount;
    const companyProfileCanSwitch = !!this.companyProfiles[0]?.canSwitch();
    return profileCount > 1 || companyProfileCanSwitch;
  }

  public canSwitchCompany(): boolean {
    return this.companyProfiles.length > 1 || !!this.systemProfile;
  }

  public findProfile(profileId: number): AccessProfile {
    return (
      this.companyProfiles.find(profile => profile.id === profileId) ||
      (this.systemProfile?.id === profileId && this.systemProfile)
    );
  }
}

export abstract class AccessProfile {
  public type: ProfileType;
  public sites: ManagedSite[] = [];
  public companyName: string = null;
  public companyId: number;
  public roles: string[] = [];
  public isImpersonationProfile: boolean;
  public impersonatingEmail: string;
  public impersonatingName: string;

  public abstract get id(): number;

  public get isCompanyManager(): boolean {
    return false;
  }

  public getManagedSite(_siteId: number): ManagedSite {
    return null;
  }

  public hasRole(_siteId: number, ..._roles: UserRole[]): boolean {
    return false;
  }

  public canSwitch(): boolean {
    return false;
  }
}

export class CompanyProfile extends AccessProfile {
  public companyProfileId: number;

  constructor() {
    super();
    this.type = 'Company';
  }

  public get id(): number {
    return this.companyProfileId;
  }

  public override get isCompanyManager(): boolean {
    return this.roles.indexOf('manager') >= 0;
  }

  public override canSwitch(): boolean {
    return !!this.sites.length;
  }

  public override getManagedSite(siteId: number): ManagedSite {
    return this.sites.find(site => site.siteId === siteId);
  }

  public override hasRole(siteId: number, ...roles: UserRole[]): boolean {
    const siteProfile = this.sites.find(site => site.siteId === siteId);
    return siteProfile && roles.some(role => siteProfile.isInRole(role));
  }
}

export class SystemProfile extends AccessProfile {
  public systemProfileId: number;

  constructor() {
    super();
    this.type = 'System';
  }

  public get id(): number {
    return this.systemProfileId;
  }
}

export class ManagedSite {
  public siteId: number;
  public siteName: string;
  public state: string;
  public status: SiteStatus;
  public roles: string[];

  constructor(site: ManagedSite);
  constructor(siteId: number, siteName: string, state: string, status: SiteStatus, roles: string[]);
  constructor(
    siteIdOrSite: number | ManagedSite,
    siteName?: string,
    state?: string,
    status?: SiteStatus,
    roles?: string[]
  ) {
    if (siteIdOrSite instanceof Object) {
      this.siteId = siteIdOrSite.siteId;
      this.siteName = siteIdOrSite.siteName;
      this.state = siteIdOrSite.state;
      this.status = siteIdOrSite.status;
      this.roles = siteIdOrSite.roles;
    } else {
      this.siteId = siteIdOrSite;
      this.siteName = siteName;
      this.state = state;
      this.status = status;
      this.roles = roles;
    }
  }

  public get fullName(): string {
    return `${this.siteName}, ${this.state}`;
  }

  public isInRole(role: UserRole): boolean {
    return this.roles?.indexOf(role) >= 0;
  }
}

export type ProfileType = 'Company' | 'System';

export interface SiteManagerProfileRecord {
  companyId: number;
  siteId: number;
  siteName: string;
}

export interface UserProfileResultSet {
  userProfiles: UserProfile[];
  companyProfiles: CompanyProfileResult[];
  systemProfiles: SystemProfileResult[];
}

export interface CompanyProfileResult {
  roles: string[];
  companyId: number;
  companyProfileId: number;
  companyName: string;
  isCompanyManager: boolean;
  siteId: number;
  siteName: string;
  siteState: string;
  siteStatus: SiteStatus;
  siteRoles: string[];
  isImpersonationProfile: boolean;
}

export interface SystemProfileResult {
  systemProfileId: number;
}

export class DuplicateUserError extends Error { }

/**
 * A flattened view of a user's record at a site or company
 */
export interface ProfileDetails {
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  isVerified: boolean;
  roles: string[];
}

export const siteRoles: RoleDescription[] = [
  { value: 'manager', name: 'Site manager' },
  { value: 'viewer', name: 'View only' },
  { value: 'auditor', name: 'Contributor' },
];

export const companyRoles: RoleDescription[] = [
  { value: 'manager', name: 'Company manager' },
  { value: 'viewer', name: 'Site access only' }
];

export interface RoleDescription {
  value: UserRole;
  name: string;
}

export type UserRole = 'manager' | 'viewer' | 'auditor';

export interface PasswordResetRequestInfo {
  email: string;
  firstName: string;
  lastName: string;
  token: string;
}

export interface CompletePasswordResetRequest {
  token: string;
  password: string;
}

export const requestResetPasswordSchema = {
  type: 'object',
  properties: {
    email: { type: 'string', format: 'email' }
  },
  required: ['email',]
};

export const completeResetPasswordSchema = {
  type: 'object',
  properties: {
    token: { type: 'string', format: 'uuid' },
    password: { type: 'string', minLength: 10 }
  },
  required: ['token', 'password',]
};

export type UpdateUserProfileRequest = {
  userProfileId: number;
  email: string;
  firstName: string;
  lastName: string;
};

export type UpdatePasswordRequest = {
  userProfileId: number;
  oldPassword: string;
  newPassword: string;
};

export type RequestSiteAccessParams = {
  userProfileId: number;
  site: Site;
};

export type SiteAccessRequest = {
  siteAccessRequestId: number;
  userProfileId: number;
  email: string;
  userName: string;
  completedAt: string;
  accessGranted: boolean;
};