import React from 'react';
import { AuditQuestion } from '../../../services/audit/auditModels';
import { PlainTextLock } from './assessmentLock';
import { AttachmentEditor } from './attachmentEditor';
import ContactEditor from './contactEditor';
import ContractorInjuryRateTableEditor from './contractorInjuryRateTableEditor';
import { DateEditor } from './dateEditor';
import { DropDownEditor } from './dropDownEditor';
import { AssessmentEditor, EditAssessmentRenderer } from './editAssessmentModels';
import { Lock } from './editLock';
import { FormattedTextEditor } from './formattedTextEditor';
import ParticipantInjuryRateTableEditor from './participantInjuryRateTableEditor';
import { PlainTextEditor } from './plainTextEditor';
import { RecommendationListEditor } from './recommendationListEditor';
import { SubsectionTitle } from './subsectionTitle';
import YesNoEditor from './yesNoEditor';

export default class EditAssessmentComponents implements EditAssessmentRenderer {
  constructor(private editor: AssessmentEditor) { }

  public render(item: AuditQuestion, lock: Lock = null): JSX.Element {
    const renderFunc = this[item.answerType as keyof EditAssessmentComponents];
    return renderFunc ? renderFunc.bind(this)(item, lock) : this.default(item, lock);
  }

  public attachmentEditor(item: AuditQuestion): JSX.Element {
    return <AttachmentEditor
      className='mt-4'
      auditId={this.editor.auditId}
      responseId={item.responseId}
      title={item.text}
      existingAttachments={item.attachments}
    />;
  }

  private auditCalendarYear(item: AuditQuestion): JSX.Element {
    return <PlainTextEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      label={item.text}
      value={item.answer}
      onChange={value => this.editor.setAnswerNow(item, value)}
      type='number'
      min={0}
      max={9999}
      maxLength={4}
    />;
  }

  private contactWithAddress(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <ContactEditor
      label={item.text}
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      value={item.answerJSON}
      onChange={value => this.editor.setAnswerJSON(item, value)}
      includeAddress={true}
      onFocus={() => this.editor.lockQuestion(item.responseId)}
      lock={lock}
    />;
  }

  private contactWithEmail(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <ContactEditor
      label={item.text}
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      value={item.answerJSON}
      onChange={value => this.editor.setAnswerJSON(item, value)}
      includeEmail={true}
      onFocus={() => this.editor.lockQuestion(item.responseId)}
      lock={lock}
    />;
  }

  private contractorInjuryRateTable(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <ContractorInjuryRateTableEditor
      year={this.editor.calendarYear || parseInt(this.editor.startedOn.split('-', 1)[0])}
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      auditId={this.editor.auditId}
      value={item.answerJSON}
      options={item.options}
      onChange={value => this.editor.setAnswerJSON(item, value)} 
      onFocus={() => this.editor.lockQuestion(item.responseId)}
      lock={lock}
      text={item.text}
      auditCalendarYear={this.editor.calendarYear}
      auditStartedOn={new Date(this.editor.startedOn).getFullYear()}
    />;
  }

  private date(item: AuditQuestion): JSX.Element {
    return this.renderDate(item);
  }

  private auditSubmissionDate(item: AuditQuestion): JSX.Element {
    return this.renderDate(item);
  }

  private renderDate(item: AuditQuestion): JSX.Element {
    return <DateEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      value={item.answer}
      onChange={value => this.editor.setAnswer(item, value)}
      placeholder={item.text}
    />;
  }

  private formattedText(item: AuditQuestion, lock: Lock | null): JSX.Element {
    const editLock = lock?.lockType === 'WRITE';
    return <div className='field' key={`${item.id}-${item.updatedAt.toString()}`} id={'question-' + item.id}>
      <div className='control'>
        <div className='has-placeholder'>
          { lock !== null && <PlainTextLock lock={lock} editLock={editLock} /> }
          <FormattedTextEditor
            placeholder={item.text}
            onChange={value => this.editor.setAnswer(item, value)}
            onActiveChange={isActive => this.editor.setEditorActive(isActive)}
            defaultValue={item.answer || ''}
            onFocus={() => this.editor.lockQuestion(item.responseId)}
            lock={lock}
            auditId={this.editor.auditId}
            questionId={item.id}
            options={item.options}
          />
        </div>
      </div>
    </div>;
  }

  private naicsCode(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <PlainTextEditor
      id={'question-' + item.id}
      key={`${item.id}-${item.updatedAt.toString()}`}
      label={item.text}
      value={item.answer}
      onChange={value => this.editor.setAnswer(item, value)}
      list='naics-codes'
      onFocus={() => this.editor.lockQuestion(item.responseId)}
      lock={lock}
    />;
  }

  private number(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <PlainTextEditor
      label={item.text}
      value={item.answer}
      onChange={value => this.editor.setAnswer(item, value)}
      type='number'
      onFocus={() => this.editor.lockQuestion(item.responseId)}
      lock={lock}
    />;
  }

  private participantInjuryRateTable(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <ParticipantInjuryRateTableEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      auditId={this.editor.auditId}
      value={item.answerJSON}
      onChange={value => this.editor.setAnswerJSON(item, value)}
      onFocus={() => this.editor.lockQuestion(item.responseId)}
      auditCalendarYear={this.editor.calendarYear}
      auditStartedOn={new Date(this.editor.startedOn).getFullYear()}
      lock={lock}
    />;
  }

  private recommendationList(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <RecommendationListEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      value={item.answerJSON}
      onChange={value => this.editor.setAnswerJSON(item, value)}
      onActiveChange={isActive => this.editor.setEditorActive(isActive)}
      onFocus={() => this.editor.lockQuestion(item.responseId)}
      lock={lock}
      auditId={this.editor.auditId}
      questionId={item.id}
    />;
  }

  private staticText(item: AuditQuestion): JSX.Element {
    return <div className='static-text' key={`${item.id}-${item.updatedAt.toString()}`} id={'question-' + item.id} dangerouslySetInnerHTML={{ __html: item.text }} />;
  }

  private yesNo(item: AuditQuestion): JSX.Element {
    return <YesNoEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      value={item.answer}
      label={item.text}
      onChange={value => this.editor.setAnswerNow(item, value)}
      {...item.options}
    />;
  }

  private injuryRateReferenceTable(): JSX.Element {
    return null;
  }

  private contractorInjuryRateReferenceTable(): JSX.Element {
    return null;
  }

  private subsectionTitle(item: AuditQuestion): JSX.Element {
    return <SubsectionTitle id={item.id} title={item.text} />;
  }

  private vppStatus(item: AuditQuestion): JSX.Element {
    const defaultOpt = 'Choose VPP Status';
    return <DropDownEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      label={item.text}
      value={item.answer}
      onChange={value => this.editor.setAnswerNow(item, value)}
      options={[defaultOpt, 'Star', 'Merit', 'Demonstration']}
      defaultOption={defaultOpt}
    />;
  }

  private singleChoice(item: AuditQuestion): JSX.Element {
    const defaultOpt = '';
    return <DropDownEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      label={item.text}
      value={item.answer}
      onChange={value => this.editor.setAnswerNow(item, value)}
      options={[defaultOpt, ...item.options.choices]}
      defaultOption={defaultOpt}
    />;
  }

  private default(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <PlainTextEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      label={item.text}
      value={item.answer}
      onChange={value => this.editor.setAnswer(item, value)}
      onFocus={() => this.editor.lockQuestion(item.responseId)}
      lock={lock}
    />;
  }
}