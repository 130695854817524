import React, { useState } from 'react';
import { CompanyProfileSearchResult, Impersonation, UserSearchResult } from '../../../services/system/systemModels';

type ImpersonationTab = 'search' | 'profiles';

async function searchForUserProfiles(searchTerm: string): Promise<UserSearchResult[]> {
  try {
    const result = await fetch(`/admin/user/search?searchTerm=${searchTerm}`);
    if (!result.ok) {
      throw new Error('Could not get search results');
    }
    const resp = await result.json();
    return resp;
  } catch (err) {
    return [];
  }
}

type ConfirmImpersonationProps = {
  companyProfiles: CompanyProfileSearchResult[];
};

/* eslint-disable @typescript-eslint/naming-convention */
export function ConfirmImpersonation(props: ConfirmImpersonationProps): JSX.Element {
  const { companyProfiles: profiles } = props;
  switch (profiles.length) {
    case 0:
      return null;
    case 1:
      return <form action='/admin/impersonation/confirm' method='post'>
        <input type='hidden' name='companyProfileId' value={profiles[0].companyProfileId} />
        <button type='submit' className='button is-primary px-5'>Impersonate</button>
      </form>;
    default:
      return <div className='dropdown is-right'>
        <div className='dropdown-trigger'>
          <button className='button is-primary'>
            <span>Impersonate</span>
            <span className='icon is-small'>
              <i className='fas fa-angle-down' aria-hidden='true'></i>
            </span>
          </button>
        </div>
        <div className='dropdown-menu'>
          <div className='dropdown-content'>
            {profiles.map(profile =>
              <form action='/admin/impersonation/confirm' method='post' key={profile.companyProfileId}>
                <input type='hidden' name='companyProfileId' value={profile.companyProfileId} />
                <button type='submit' className='dropdown-item'>{profile.companyName} - {profile.roles.join(' ')}</button>
              </form>
            )}
          </div>
        </div>
      </div>;
  }
}

type RetireImpersonationProps = {
  companyProfileId: number;
};

/* eslint-disable @typescript-eslint/naming-convention */
function RetireImpersonation(props: RetireImpersonationProps): JSX.Element {
  return <form action='/admin/impersonation/retire' method='post' className='is-inline'>
    <input type='hidden' name='companyProfileId' value={props.companyProfileId} />
    <button type='submit' className={`button is-danger`}>Retire impersonation</button>
  </form>;
}

type UserSearchBarProps = {
  searchFunc: (searchTerm: string) => Promise<void>;
};

function UserSearchBar(props: UserSearchBarProps): JSX.Element {
  const [searchTerm, setSearchTerm] = useState('');
  return <form className='columns' onSubmit={evt => { evt.preventDefault(); props.searchFunc(searchTerm); }}>
    <div className='field has-addons column'>
      <div className='control'>
        <input className='input' type='text' placeholder='User name or email' value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} />
      </div>
      <div className='control'>
        <button type='submit' className='button is-info'>Search</button>
      </div>
    </div>
  </form>;
}

export class ManageUsers extends React.Component<ManageUsersProps, ManageUsersState> {
  constructor(props: ManageUsersProps) {
    super(props);
    const [currentTab, searchTerm] = document.location.hash.replace('#', '').split('/');
    this.state = {
      currentTab: (currentTab || 'search').replace('#', '') as ImpersonationTab,
      searchResults: [],
      emptyResultError: false,
      searchTerm
    };
    this.setTab = this.setTab.bind(this);
    this.populateSearch = this.populateSearch.bind(this);
    if (searchTerm) {
      this.populateSearch(searchTerm);
    }
  }

  setTab(newTab: ImpersonationTab): void {
    this.setHash(newTab);
    this.setState({ currentTab: newTab });
  }

  private setHash(newTab?: string, searchTerm?: string) {
    document.location.hash = `${newTab || this.state.currentTab}/${searchTerm || this.state.searchTerm}`;
  }

  async populateSearch(searchTerm: string): Promise<void> {
    this.setState({ searchResults: [], emptyResultError: false, searchTerm });
    this.setHash(null, searchTerm);
    const results = await searchForUserProfiles(searchTerm);
    const searchError = results.length === 0;
    this.setState({ searchResults: results, emptyResultError: searchError });
  }

  render(): JSX.Element {
    return <div className='section pb-0'>
      <nav className='tabs is-large'>
        <ul>
          <li><h1 className='title mx-5'>Manage Users</h1></li>
          <li onClick={() => this.setTab('search')} className={this.state.currentTab === 'search' ? 'is-active' : undefined}><a>Search</a></li>
          <li onClick={() => this.setTab('profiles')} className={this.state.currentTab === 'profiles' ? 'is-active' : undefined}><a>Active impersonations</a></li>
        </ul>
      </nav>

      {this.state.currentTab === 'search' &&
        <div>
          <UserSearchBar searchFunc={this.populateSearch} />
          {this.state.searchResults.length > 0 ?
            <table className='table is-fullwidth'>
              <thead>
                <tr>
                  <td className='relcol-30'>Email</td>
                  <td className='relcol-30'>Name</td>
                  <td className='relcol-30'>Company</td>
                  <td className='relcol-10' />
                </tr>
              </thead>
              <tbody>
                {this.state.searchResults.map(result => <tr key={result.userProfileId}>
                  <td className='impersonation-text-row'><a href={`/admin/user/${result.userProfileId}`}>{result.email}</a></td>
                  <td className='impersonation-text-row'>{`${result.firstName} ${result.lastName}`}</td>
                  <td className='impersonation-text-row'>
                    {result.companyProfiles.length === 1
                      ? result.companyProfiles[0].companyName
                      : <span title={result.companyProfiles.map(p => p.companyName).join(', ')}>{result.companyProfiles.length} companies</span>
                    }
                  </td>
                  <td>
                    {
                      result.userProfileId !== this.props.loggedInUserProfileId &&
                      <ConfirmImpersonation companyProfiles={result.companyProfiles} />
                    }
                  </td>
                </tr>
                )}
              </tbody>
            </table>
            : this.state.emptyResultError ?
              <div>Sorry there were no results for that search term&hellip;</div>
              : <div>Please search for an email or name to get started&hellip;</div>
          }
        </div>
      }

      {this.state.currentTab === 'profiles' &&
        <div>
          {this.props.impersonations.length > 0 ?
            <table className='table is-fullwidth'>
              <thead>
                <tr>
                  <td>Company Name</td>
                  <td>Impersonating</td>
                  <td>Roles</td>
                  <td />
                </tr>
              </thead>
              <tbody>
                {this.props.impersonations.map(result => <tr key={result.companyProfileId}>
                  <td className='impersonation-text-row'><a href={`/admin/company/${result.companyId}`}>{result.companyName}</a></td>
                  <td className='impersonation-text-row'>{result.impersonatingEmail}</td>
                  <td className='impersonation-text-row'>{result.roles}</td>
                  <td>
                    <a className='button is-primary mr-1' href={`/user/switch-company/${result.companyProfileId}`}>Use now</a>
                    <RetireImpersonation companyProfileId={result.companyProfileId} />
                  </td>
                </tr>
                )}
              </tbody>
            </table>
            : <div>You do not have any current impersonations&hellip;</div>
          }
        </div>
      }
    </div>;
  }
}

type ManageUsersProps = {
  impersonations: Impersonation[];
  loggedInUserProfileId: number;
};

type ManageUsersState = {
  currentTab: ImpersonationTab;
  searchResults: UserSearchResult[];
  emptyResultError: boolean;
  searchTerm: string;
};