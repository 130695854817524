import React from 'react';

interface Iconable {
  extension?: string;
}

class FileIconProps {
  file: Iconable;
}

export default class FileIcon extends React.Component<FileIconProps, null> {
  private static readonly icons = {
    doc: 'fa-file-word has-text-info',
    docx: 'fa-file-word has-text-info',
    gif: 'fa-file-image has-text-info',
    jpeg: 'fa-file-image has-text-info',
    jpg: 'fa-file-image has-text-info',
    pdf: 'fa-file-pdf has-text-danger',
    png: 'fa-file-image has-text-info',
    xls: 'fa-file-excel has-text-green',
    xlsx: 'fa-file-excel has-text-green',
  };
  private file?: Iconable;

  constructor(props: FileIconProps) {
    super(props);
    this.file = props.file;
  }

  private getIcon(file: Iconable): string {
    const extensionKey = file.extension?.substring(1).toLocaleLowerCase();
    if (!this.hasIconForExtension(extensionKey)) {
      return 'fa-file';
    }
    return FileIcon.icons[extensionKey];
  }

  private hasIconForExtension(
    extensionKey: string
  ): extensionKey is keyof typeof FileIcon.icons {
    return extensionKey in FileIcon.icons;
  }

  render(): JSX.Element {
    return <React.Fragment>
      <i className={`fas fa-2x ${this.getIcon(this.file)}`} />
    </React.Fragment>;
  }
}
