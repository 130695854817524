import React from 'react';

/* eslint-disable @typescript-eslint/naming-convention */
export function ProtocolUpload(): JSX.Element {
  return <React.Fragment>
    <div className="container">
      <form action="/admin/add-protocol" method="POST" encType="multipart/form-data">
        <div className="field is-grouped">
          <div className="control">
            <div className="file">
              <label className="file-label">
                <input className="file-input" type="file" name="protocol" required />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">
                    Choose a file…
                  </span>
                </span>
              </label>
            </div>
          </div>
          <div className="control">
            <input type="submit" className="button is-primary" value="Upload" />
          </div>
        </div>
      </form>
    </div>
  </React.Fragment>;
}