import * as Quill from 'quill';
import { rateLimit } from '../utility';

(function oneTimeQuillInit() {
  /* eslint-disable @typescript-eslint/naming-convention */
  const BaseImageFormat = Quill.default.import('formats/image');
  class ImageFormat extends BaseImageFormat {
    private domNode: Element;
    static formats(domNode: Element) {
      return domNode.hasAttribute('style') ? { style: domNode.getAttribute('style') } : {};
    }

    format(name: string, value: string) {
      if (name === 'style') {
        if (value) {
          this.domNode.setAttribute(name, value);
        } else {
          this.domNode.removeAttribute(name);
        }
      } else {
        super.format(name, value);
      }
    }
  }

  Quill.default.register(ImageFormat, true);
})();

export default class ImageResizeModule {
  private selectedImage: HTMLImageElement;
  private overlay: HTMLDivElement;
  private origWidth: number;
  private origPos: number;
  private readonly corners: Corner[] = [
    { position: ['top', 'left'], cursor: 'nwse-resize' },
    { position: ['bottom', 'right'], cursor: 'nwse-resize' },
    { position: ['bottom', 'left'], cursor: 'nesw-resize' },
    { position: ['top', 'right'], cursor: 'nesw-resize' },
  ];
  private readonly onDrag = rateLimit(this.handleDrag.bind(this), 50);

  public onActivate: () => void;

  constructor(private quill: Quill.Quill) {
    quill.root.addEventListener('click', evt => this.onClick(evt), false);

    this.overlay = document.createElement('div');
    this.overlay.classList.add('editor-resize-overlay');
    this.overlay.tabIndex = 0;
    this.overlay.addEventListener('blur', () => this.hide());
    this.overlay.addEventListener('keydown', evt => this.onKeyPress(evt));
    quill.root.parentNode.appendChild(this.overlay);

    this.corners.forEach(corner => {
      const resizeHandle = document.createElement('div');
      resizeHandle.classList.add('editor-resize-handle');
      resizeHandle.style.cursor = corner.cursor;
      corner.position.forEach(pos => resizeHandle.style[pos as any] = '-8px');
      resizeHandle.addEventListener('pointerdown', evt => this.handleDragStart(resizeHandle, evt, corner));
      resizeHandle.addEventListener('pointerup', evt => this.handleDragEnd(resizeHandle, evt));
      this.overlay.appendChild(resizeHandle);
    });
  }

  private onClick(evt: MouseEvent) {
    const target = evt.target as Element;

    if (target?.tagName?.toUpperCase() === 'IMG') {
      if (this.selectedImage === target) {
        // we are already focused on this image
        return;
      }
      // clicked on an image inside the editor 
      this.show(target as HTMLImageElement);
      if (this.onActivate) {
        this.onActivate();
      }
    } else if (this.selectedImage) {
      // clicked on a non image
      this.hide();
    }
  }

  private hide() {
    this.selectedImage = null;
    this.overlay.style.visibility = 'hidden';
    this.quill.blur();
  }

  private show(image: HTMLImageElement) {
    this.selectedImage = image;

    this.resizeOverlay();
    this.overlay.style.visibility = 'inherit';
    this.overlay.focus();
  }

  private resizeOverlay() {
    const parent = this.quill.root.parentNode as Element;
    const imgRect = this.selectedImage.getBoundingClientRect();
    const containerRect = parent.getBoundingClientRect();

    Object.assign(this.overlay.style, {
      left: `${imgRect.left - containerRect.left - 1 + parent.scrollLeft}px`,
      top: `${imgRect.top - containerRect.top + parent.scrollTop}px`,
      width: `${imgRect.width}px`,
      height: `${imgRect.height}px`,
    });
  }

  private handleDragStart(handle: HTMLElement, evt: PointerEvent, corner: Corner) {
    this.origWidth = this.selectedImage.getBoundingClientRect().width;
    this.origPos = evt.clientX;
    handle.onpointermove = pointerEvt => this.onDrag(pointerEvt, corner);
    handle.setPointerCapture(evt.pointerId);
  }

  private handleDragEnd(handle: HTMLElement, evt: PointerEvent) {
    this.origWidth = this.selectedImage.getBoundingClientRect().width;
    this.origPos = evt.clientX;
    handle.onpointermove = null;
    handle.releasePointerCapture(evt.pointerId);
  }

  private handleDrag(evt: PointerEvent, corner: Corner) {
    const dragPosition = evt.clientX;

    if (dragPosition === 0) {
      return;
    }

    const newWidth = corner.position.indexOf('right') >= 0
      ? Math.max(dragPosition - this.origPos + this.origWidth, 10)
      : Math.max(this.origPos - dragPosition + this.origWidth, 10);
    this.selectedImage.style.width = newWidth + 'px';

    this.resizeOverlay();
  }

  private onKeyPress(evt: KeyboardEvent): any {
    if (this.selectedImage && (evt.key === 'Backspace' || evt.key === 'Delete')) {
      Quill.default.find(this.selectedImage).deleteAt(0);
      this.hide();
    }
  }
}

type Corner = {
  position: string[];
  cursor: string;
};
