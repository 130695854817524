import React from 'react';
import { AdminProfile, AuditLaunchProfile, ProtocolManagementListView, ProtocolPublishRequst } from '../../../services/system';
import ProtocolContentUpdate from './protocolContentUpdate';
import { ProtocolList } from './protocolList';
import { ProtocolModal, PublishModal, TestAuditModal } from './protocolModal';
import { ProtocolReview } from './protocolReview';
import { ProtocolUpload } from './protocolUpload';

type ProtocolTab = 'dashboard' | 'upload' | 'list' | 'request' | 'update';

export class ProtocolManagementDashboard extends React.Component<ProtocolManagementDashboardProps, ProtocolManagementDashboardState> {

  constructor(props: ProtocolManagementDashboardProps) {
    super(props);
    this.state = {
      currentTab: 'list',
      activeModal: null,
      modalProtocolId: null
    };
    this.setTab = this.setTab.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  setTab(newTab: ProtocolTab): void {
    this.setState({ currentTab: newTab });
  }

  toggleModal(modal: ProtocolModal, protocolId: string | null): void {
    this.setState({ activeModal: modal, modalProtocolId: protocolId });
  }

  get jurisdictions(): string[] {
    const allJurisdictions = new Set<string>();
    this.props.protocolList.forEach(protocol => allJurisdictions.add(protocol.jurisdiction));
    return Array.from(allJurisdictions);
  }

  render(): JSX.Element {
    return <div className='section pb-0'>
      <nav className='tabs is-large'>
        <ul>
          <li><h1 className='title mx-5'>Protocol Management</h1></li>
          <li onClick={() => this.setTab('list')} className={this.state.currentTab === 'list' ? 'is-active' : undefined}><a>List</a></li>
          <li onClick={() => this.setTab('upload')} className={this.state.currentTab === 'upload' ? 'is-active' : undefined}><a>Upload</a></li>
          <li onClick={() => this.setTab('request')} className={this.state.currentTab === 'request' ? 'is-active' : undefined}><a>Requests</a></li>
          <li onClick={() => this.setTab('update')} className={this.state.currentTab === 'update' ? 'is-active' : undefined}><a>Content update</a></li>
        </ul>
      </nav>
      
      { this.state.currentTab === 'upload' && <ProtocolUpload /> }
      { this.state.currentTab === 'list' && <ProtocolList protocols={this.props.protocolList}
        jurisdictions={this.jurisdictions}
        toggleModal={this.toggleModal}/> }
      { this.state.currentTab === 'request' && <ProtocolReview reviewRequests={this.props.protocolPublishRequests} /> }
      {this.state.currentTab === 'update' && <ProtocolContentUpdate />}
    
      <TestAuditModal isActive={this.state.activeModal === 'testAudit'}
        protocolId={this.state.modalProtocolId}
        toggleClose={() => this.toggleModal('none', null)} 
        profiles={this.props.profiles} />
      <PublishModal isActive={this.state.activeModal === 'publish'}
        protocolId={this.state.modalProtocolId}
        toggleClose={() => this.toggleModal('none', null)} 
        systemProfiles={this.props.admins} />
    </div>
    ;
  }
}

type ProtocolManagementDashboardProps = {
  protocolList: ProtocolManagementListView[];
  profiles: AuditLaunchProfile[];
  admins: AdminProfile[];
  protocolPublishRequests: ProtocolPublishRequst[];
};

type ProtocolManagementDashboardState = {
  currentTab: ProtocolTab;
  activeModal: ProtocolModal | null;
  modalProtocolId: string | null;
};