import React from 'react';
import { UserLog } from '../../../services/system';

type UserEventRowProps = {
  eventDetails: any;
};

/* eslint-disable @typescript-eslint/naming-convention */
function UserEventRow(props: UserEventRowProps): JSX.Element {
  const values: string[] = [];
  if (Object.keys(props.eventDetails).length === 0) {
    values.push('N/A');
  }
  else {
    for (const _key of Object.keys(props.eventDetails).sort()) {
      values.push(`<b>${_key}:</b> ${props.eventDetails[_key]}`);
    }
  }
  return <td className='history-multiline-cell' dangerouslySetInnerHTML={{ __html: values.join('\n') }}></td>;
}

export function UserLogList(props: { log: UserLog[] }): JSX.Element {
  return <div>
    {props.log?.length > 0 ?
      <table className='table'>
        <thead>
          <tr>
            <td className='relcol-20'>Type</td>
            <td className='relcol-30'>Occurred At</td>
            <td className='relcol-50'>Details</td>
          </tr>
        </thead>
        <tbody>
          {props.log.map(result => <tr key={result.eventId}>
            <td className='impersonation-text-row'>{result.eventType}</td>
            <td className='impersonation-text-row'>{result.occurredAt}</td>
            <UserEventRow eventDetails={result.eventDetails} />
          </tr>
          )}
        </tbody>
      </table>
      : <div>No log events for this user</div>
    }
  </div>;
}
