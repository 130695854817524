import React from 'react';
import { AuditListingDetails } from '../../services/audit/auditModels';
import { Site, SiteType, siteTypeNames } from '../../services/company/companyModels';
import { StateInfo } from '../../services/system';
import { CompanyProfile, ManagedSite, ProfileDetails, SiteAccessRequest, siteRoles } from '../../services/user/userModels';
import { GeneratePdfWidget } from './generatePdfWidget';
import InviteForm from './inviteForm';

type AccessRequestActionProps = {
  siteAccessRequestId: number;
  action: 'Accept' | 'Reject';
  siteId: number;
};

/* eslint-disable @typescript-eslint/naming-convention */
function AccessRequestAction(props: AccessRequestActionProps): JSX.Element {
  const btnColor = props.action === 'Accept' ? 'is-success' : 'is-danger';
  return <form action='/company/resolve-site-access' method='post'>
    <input type='hidden' name='siteAccessRequestId' value={props.siteAccessRequestId} />
    <input type='hidden' name='action' value={props.action} />
    <input type='hidden' name='siteId' value={props.siteId} />
    <button type='submit' className={`button ${btnColor} is-primary`}>{props.action}</button>
  </form>;
}

export default class SiteAdminView extends React.Component<SiteAdminViewProps, SiteAdminViewState> {
  canEditSite: boolean;
  canEditAudits: boolean;

  constructor(props: SiteAdminViewProps) {
    super(props);
    this.state = {
      ...new SiteAdminViewState,
      tab: (document.location.hash || 'audits').replace('#', ''),
      users: props.users,
      invitesSent: [],
      siteType: props.site.siteType,
      isFederal: props.site.isFederal || false,
      name: props.site.name,
      state: props.site.state,
      isRestoringAssessment: false
    };

    const profile = new ManagedSite(this.props.userProfile.sites.find(s => s.siteId === this.props.site.siteId));
    this.canEditSite = profile.isInRole('manager');
    this.canEditAudits = this.canEditSite || profile.isInRole('auditor');
  }

  render(): JSX.Element {
    const audits = this.props.audits;
    const { tab, users, removingUser, error, auditToDelete } = this.state;
    const showAccessRequests = this.props.accessRequests.length > 0 && this.canEditSite;

    return <React.Fragment>
      <div className='section pb-0'>
        <nav className='tabs is-large'>
          <ul>
            <li><h1 className='title mx-5'>Manage site</h1></li>
            <li onClick={() => this.setTab('audits')} className={tab === 'audits' ? 'is-active' : undefined}><a>Assessments</a></li>
            <li onClick={() => this.setTab('people')} className={tab === 'people' ? 'is-active' : undefined}><a>People</a></li>
            {this.canEditSite && <li onClick={() => this.setTab('site')} className={tab === 'site' ? 'is-active' : undefined}><a>Edit details</a></li>}
            {showAccessRequests && <li onClick={() => this.setTab('access')} className={tab === 'access' ? 'is-active' : undefined}><a>Access Requests</a></li>}
          </ul>
        </nav>
      </div>
      {tab === 'audits' && this.renderAudits(audits)}
      {tab === 'people' && this.renderUsers(users)}
      {tab === 'site' && this.renderSite()}
      {tab === 'access' && this.renderAccess()}
      {removingUser && this.renderRemoveUserConfirm(removingUser)}
      {auditToDelete && this.renderAuditDeleteConfirm()}
      {error && <div className='section'>
        <div className='notification is-danger'>{error}</div>
      </div>}
    </React.Fragment>;
  }

  private renderAudits(audits: AuditListingDetails[]) {
    const activeAudits = audits.filter(a => !a.isDeleted);
    const deletedAudits = audits.filter(a => a.isDeleted);
    const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' } as any);
    return <div className='section tab tab-audits'>
      {
        activeAudits.length > 0 &&
        <table className='table'>
          <thead>
            <tr>
              <td></td>
              <td>Calendar year</td>
              <td>Started on</td>
              <td>Started by</td>
              <td>Form</td>
              <td></td>
              {
                <React.Fragment>
                  <td></td>
                  <td></td>
                </React.Fragment>
              }
            </tr>
          </thead>
          <tbody>
            {activeAudits.map(audit => <tr key={audit.auditId} className={this.props.auditFlash === audit.auditId ? 'table-row-flash' : ''}>
              <td>{audit.submittedOn && <i className='fas fa-lock' title={`Locked on ${formatter.format(new Date(audit.submittedOn))}`} />}</td>
              <td>{audit.calendarYear}</td>
              <td>{audit.startedOn ? formatter.format(new Date(audit.startedOn)) : 'Not started'}</td>
              <td>{audit.startedBy}</td>
              <td title={audit.protocolId}>{audit.form}</td>
              <td><a href={`/assessment/edit/${audit.auditId}`}>{audit.submittedOn || !this.canEditAudits ? 'View' : 'Edit'}</a></td>
              {
                this.canEditSite &&
                <React.Fragment>
                  <td><GeneratePdfWidget auditId={audit.auditId} /></td>
                  <td><button className='delete' onClick={() => this.setState({ auditToDelete: audit })} /></td>
                </React.Fragment>
              }
            </tr>
            )}
          </tbody>
        </table>
      }
      {
        this.canEditSite &&
        <form className='mt-4' action={`/assessment/start/${this.props.site.siteId}`} method='POST'>
          <div className='field'>
            <button type='submit' className='button is-primary'>Start a new assessment</button>
          </div>
        </form>
      }
      {
        deletedAudits.length > 0 && this.canEditSite &&
        <button className='button my-4' onClick={() => this.toggleRestoreAudits()}>
          {
            this.state.isRestoringAssessment ?
              <span>
                Restore deleted assessment
                <i className='ml-2 fas fa-chevron-up' />
              </span>
              :
              <span>
                Restore deleted assessment
                <i className='ml-2 fas fa-chevron-down' />
              </span>
          } 
        </button>
      }
      {
        deletedAudits.length > 0 && this.state.isRestoringAssessment &&
        <table className='table'>
          <thead>
            <tr>
              <td></td>
              <td>Calendar year</td>
              <td>Started on</td>
              <td>Started by</td>
              {
                this.canEditSite &&
                <React.Fragment>
                  <td>Restore Audit</td>
                </React.Fragment>
              }
            </tr>
          </thead>
          <tbody>
            {deletedAudits.map(audit => <tr key={audit.auditId} className={this.props.auditFlash === audit.auditId ? 'table-row-flash' : ''}>
              <td>{audit.submittedOn && <i className='fas fa-lock' title={`Locked on ${audit.submittedOn}`} />}</td>
              <td>{audit.calendarYear}</td>
              <td>{audit.startedOn}</td>
              <td>{audit.startedBy}</td>
              <td>
                <form method='POST' action='/assessment/restore'>
                  <input type='hidden' name='auditId' value={audit.auditId} />
                  <input type='hidden' name='siteId' value={this.props.site.siteId} />
                  <div className='field'>
                    <div className='control'>
                      <button type='submit' className='button is-primary'>Restore Assessment</button>
                    </div>
                  </div>
                </form>
              </td>
            </tr>
            )}
          </tbody>
        </table>
      }
    </div>;
  }

  private renderUsers(users: ProfileDetails[]): JSX.Element {
    return <div className='section tab tab-people'>
      <table className='table'>
        <thead>
          <tr>
            <td>Name</td>
            <td>Email</td>
            <td>Role</td>
            {this.canEditSite && <td>Has logged in</td>}
            {this.canEditSite && <td></td>}
          </tr>
        </thead>
        <tbody>
          {users.map(user => <tr key={user.email}>
            <td>{user.firstName} {user.lastName}</td>
            <td>{user.email}</td>
            {
              (this.canEditSite && user.userId !== this.props.userProfileId)
                ? <td className='p-1'>
                  <div className='select is-small'>
                    <select onChange={evt => this.setUserRole(evt, user)} defaultValue={user.roles[0]}>
                      {siteRoles.map(role => <option key={role.value} value={role.value}>{role.name}</option>)}
                    </select>
                  </div>
                </td>
                : <td><div className='py-1 is-size-7'>{user.roles.map(role => siteRoles.find(sr => sr.value === role).name).join(', ')}</div></td>
            }
            {
              this.canEditSite && <React.Fragment>

                {user.isVerified
                  ? <td>Yes</td>
                  : <td className='p-1'>
                    {this.state.invitesSent.indexOf(user.email) >= 0
                      ? <button className='button is-small' disabled>Invite sent</button>
                      : <button className='button is-small is-primary' onClick={() => this.reinvite(user)}>Resend invite</button>}
                  </td>
                }
                <td>
                  <button className='delete' onClick={() => this.setState({ removingUser: user })} />
                </td>
              </React.Fragment>
            }
          </tr>
          )}
        </tbody>
      </table>
      {
        this.canEditSite && (
          this.state.inviteVisible
            ? <InviteForm users={this.props.users} showRoles={true} siteId={this.props.site.siteId} />
            : <button className='button' onClick={() => this.setState({ inviteVisible: true })}>Invite a user</button>
        )
      }
    </div>;
  }

  private renderSite(): JSX.Element {
    const selectedState = this.props.states.find(state => this.state.state === state.stateId);
    const invalidStateSelected = selectedState && !selectedState.hasProtocol && !this.state.isFederal;

    return <div className='section'>
      <form method='POST' action='/company/edit-site'>
        <input type='hidden' value={this.props.site.siteId} name='siteId' />
        <div className='field'>
          <label className='label'>Site name</label>
          <p className='control'>
            <input
              className='input' required type='text' placeholder='Site name' name='siteName'
              value={this.state.name} onChange={evt => this.setState({ name: evt.currentTarget.value })} />
          </p>
        </div>
        <div className='field'>
          <div className='select'>
            <select value={this.state.siteType} onChange={evt => this.setState({ siteType: evt.currentTarget.value as SiteType })} name='siteType'>
              {Object.keys(siteTypeNames).map((siteType: SiteType) =>
                <option value={siteType} label={siteTypeNames[siteType]} />
              )}
            </select>
          </div>
        </div>
        <div className='field'>
          <label className='checkbox'>
            <input type='checkbox' checked={this.state.isFederal} onChange={evt => this.setState({ isFederal: evt.currentTarget.checked })} name='isFederal' /> This is a Federal VPP Program site
          </label>
        </div>
        <div className='field'>
          <div className='select'>
            <select required name='state' value={this.state.state} onChange={evt => this.setState({ state: evt.currentTarget.value })}>
              <option value=''>Select a state or region</option>
              {this.props.states.map(state => <option value={state.stateId}>{state.name}</option>)}
            </select>
          </div>
          {this.state.siteType === 'mobile' &&
            <p className='help'>If this site is a mobile workforce, please pick any state in which the workforce operates</p>}
          {
            invalidStateSelected &&
            <p className='help is-danger'>
              Sorry, we don't have an annual self-evaluation form for {selectedState.name}.<br />
              If applicable, you may use the federal evaluation instead by selecting <em>This is a Federal VPP Program site</em>,
              or you can <a href={`mailto:vppsupport@auditsoft.co?subject=VPP self-evaluation form for ${selectedState.name}`}>contact AuditSoft support</a> for more information.
            </p>
          }
        </div>
        <div className='field'>
          <div className='control'>
            <button type='submit' className='button is-primary' disabled={invalidStateSelected}>Save</button>
          </div>
        </div>
      </form>
    </div>;
  }

  renderAccess(): JSX.Element {
    return <div className='section tab tab-people'>
      <table className='table'>
        <thead>
          <tr>
            <td>Name</td>
            <td>Email</td>
            <td>Status</td>
            <td>Approve</td>
            <td>Reject</td>
          </tr>
        </thead>
        <tbody>
          {this.props.accessRequests.map(accessRequest => <tr key={accessRequest.email}>
            <td>{accessRequest.userName}</td>
            <td>{accessRequest.email}</td>
            <td>{accessRequest.completedAt === null ? 'Pending' : accessRequest.accessGranted ? 'Granted' : 'Rejected'}</td>
            <td>{accessRequest.completedAt === null && <AccessRequestAction siteAccessRequestId={accessRequest.siteAccessRequestId} action={'Accept'} siteId={this.props.site.siteId}/>}</td>
            <td>{accessRequest.completedAt === null && <AccessRequestAction siteAccessRequestId={accessRequest.siteAccessRequestId} action={'Reject'} siteId={this.props.site.siteId}/>}</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>;
  }

  async setUserRole(evt: React.ChangeEvent<HTMLSelectElement>, user: ProfileDetails): Promise<void> {
    const role = evt.currentTarget.value;
    user.roles = [role];
    let success = false;
    let result: Response;
    let errorMessage = 'Could not change user role';
    try {
      result = await fetch(
        '/user/site-roles',
        {
          method: 'POST',
          body: JSON.stringify({
            userId: user.userId,
            roles: user.roles,
            siteId: this.props.site.siteId
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      success = result.ok;
    } catch (err) {
      // TODO : Notify on fail
      console.log(err);
    }

    if (!success) {
      try {
        const responseJson = await result.json();
        if (responseJson.message) {
          errorMessage = responseJson.message;
        }
        // eslint-disable-next-line no-empty
      } catch {
        // Don't set error message if result was not JSON
      }
    }

    this.setState({ error: success ? null : errorMessage });
  }

  private async reinvite(user: ProfileDetails): Promise<void> {
    this.setState({ invitesSent: [...this.state.invitesSent, user.email] });
    try {
      const result = await fetch(
        '/user/reinvite',
        {
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ siteId: this.props.site.siteId, userProfileId: user.userId, email: user.email }),
          method: 'POST'
        }
      );
      if (result.ok) {
        return;
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ error: 'The user could not be re-invited' });
  }

  private renderAuditDeleteConfirm(): JSX.Element {
    return <div className='modal is-active'>
      <div className='modal-background' />
      <div className='modal-card'>
        <section className='modal-card-body'>Delete assessment from {this.state.auditToDelete.startedOn}?</section>
        <footer className='modal-card-foot'>
          <form action={`/assessment/delete/${this.state.auditToDelete.auditId}`} method='POST'>
            <button type='submit' className='button is-danger'>Delete</button>
            <button className='button' onClick={() => this.setState({ auditToDelete: null })}>Cancel</button>
          </form>
        </footer>
      </div>
      <button className='modal-close is-large' title='Cancel' onClick={() => this.setState({ auditToDelete: null })} />
    </div>;
  }

  renderRemoveUserConfirm(removingUser: ProfileDetails): JSX.Element {
    return <div className='modal is-active'>
      <div className='modal-background' />
      <div className='modal-card'>
        <section className='modal-card-body'>Remove access to this site from {removingUser.firstName} {removingUser.lastName}?</section>
        <footer className='modal-card-foot'>
          <button className='button is-danger' onClick={() => this.removeUserFromSite(removingUser)}>Remove</button>
          <button className='button' onClick={() => this.setState({ removingUser: null })}>Cancel</button>
        </footer>
      </div>
      <button className='modal-close is-large' title='Cancel' onClick={() => this.setState({ removingUser: null })} />
    </div>;
  }

  async removeUserFromSite(user: ProfileDetails): Promise<void> {
    this.setState({
      users: this.state.users.filter(u => u !== user),
      removingUser: null
    });
    let success = false;
    try {
      const result = await fetch(
        '/user/remove-site',
        {
          method: 'POST',
          body: JSON.stringify({ userId: user.userId, siteId: this.props.site.siteId }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      success = result.ok;
    } catch (err) {
      console.log(err);
    }

    this.setState({ error: success ? null : 'Could not remove user from site' });
  }

  private setTab(tab: string): void {
    document.location.hash = tab;
    this.setState({ tab });
  }

  public toggleRestoreAudits(): void {
    this.setState({ isRestoringAssessment: !this.state.isRestoringAssessment });
  }
}

class SiteAdminViewProps {
  public audits: AuditListingDetails[];
  public users: ProfileDetails[];
  public site: Site;
  public auditFlash?: number;
  public userProfile: CompanyProfile;
  public states: StateInfo[];
  public accessRequests: SiteAccessRequest[];
  public userProfileId: number;
}

class SiteAdminViewState {
  public tab: string;
  public inviteVisible = false;
  public removingUser: ProfileDetails = null;
  public users: ProfileDetails[];
  public error: string = null;
  public auditToDelete?: AuditListingDetails;
  public invitesSent: string[];

  public siteType: SiteType;
  public isFederal: boolean;
  public name: string;
  public state: string;

  public isRestoringAssessment: boolean;
}