import * as React from 'react';
import { useState } from 'react';
import { CompanyProfileType } from 'services/system';

export function AccessRemoveModal(props: AccessRemoveModal): JSX.Element {
  const [removalConfirmed, setRemovalConfirmed] = useState<boolean>();
  const [accessRemoved, setAccessRemoved] = useState<boolean>();

  return <>
    <div className='modal is-active'>
      <div className='modal-background' onClick={() => props.onCancel()}></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Remove Access</p>
          <button className='delete' aria-label='close' onClick={() => props.onCancel()}></button>
        </header>
        {
          removalConfirmed
            ? <section className='modal-card-body'>
              {
                accessRemoved === true
                  ? <p className='has-text-primary-dark'>{props.type} access is successfully removed.</p>
                  : <p className='has-text-danger-dark'>{props.type} access removal failed.</p>
              }
            </section>
            : <>
              <section className='modal-card-body'>
                Confirm removal of user access as {props.role} to {props.name} {props.type}
              </section>
              <footer className='modal-card-foot'>
                <button className='button is-success' onClick={() => removeUserAccess()}>Confirm</button>
                <button className='button' onClick={() => props.onCancel()}>Cancel</button>
              </footer>
            </>
        }
      </div>
    </div>

  </>;

  async function removeUserAccess(): Promise<void> {
    try {
      const response = await fetch(`/user/revoke-${props.type}-access`,
        {
          method: 'POST',
          body: JSON.stringify({ userId: props.userProfileId, id: props.id, role: props.role }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setRemovalConfirmed(true);
      if (response.ok) {
        setAccessRemoved(true);
      } else {
        setAccessRemoved(false);
      }
    } catch(err) {
      console.error(`could not remove ${props.type} access`, {
        userId: props.userProfileId,
        id: props.id,
        err
      });
    }
  }
}

type AccessRemoveModal = {
  type: CompanyProfileType;
  userProfileId: number;
  id: number;
  name: string;
  role: string;
  onCancel: () => void;
};