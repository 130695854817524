export type NAICSCode = {
  code: string;
  title: string;
};

/* eslint-disable @typescript-eslint/quotes */
export const codes: NAICSCode[] = [
  {
    "code": "11",
    "title": "Agriculture, Forestry, Fishing and Hunting"
  },
  {
    "code": "111",
    "title": "Crop Production"
  },
  {
    "code": "1111",
    "title": "Oilseed and Grain Farming"
  },
  {
    "code": "11111",
    "title": "Soybean Farming"
  },
  {
    "code": "111110",
    "title": "Soybean Farming"
  },
  {
    "code": "11112",
    "title": "Oilseed (except Soybean) Farming"
  },
  {
    "code": "111120",
    "title": "Oilseed (except Soybean) Farming"
  },
  {
    "code": "11113",
    "title": "Dry Pea and Bean Farming"
  },
  {
    "code": "111130",
    "title": "Dry Pea and Bean Farming"
  },
  {
    "code": "11114",
    "title": "Wheat Farming"
  },
  {
    "code": "111140",
    "title": "Wheat Farming"
  },
  {
    "code": "11115",
    "title": "Corn Farming"
  },
  {
    "code": "111150",
    "title": "Corn Farming"
  },
  {
    "code": "11116",
    "title": "Rice Farming"
  },
  {
    "code": "111160",
    "title": "Rice Farming"
  },
  {
    "code": "11119",
    "title": "Other Grain Farming"
  },
  {
    "code": "111191",
    "title": "Oilseed and Grain Combination Farming"
  },
  {
    "code": "111199",
    "title": "All Other Grain Farming"
  },
  {
    "code": "1112",
    "title": "Vegetable and Melon Farming"
  },
  {
    "code": "11121",
    "title": "Vegetable and Melon Farming"
  },
  {
    "code": "111211",
    "title": "Potato Farming"
  },
  {
    "code": "111219",
    "title": "Other Vegetable (except Potato) and Melon Farming"
  },
  {
    "code": "1113",
    "title": "Fruit and Tree Nut Farming"
  },
  {
    "code": "11131",
    "title": "Orange Groves"
  },
  {
    "code": "111310",
    "title": "Orange Groves"
  },
  {
    "code": "11132",
    "title": "Citrus (except Orange) Groves"
  },
  {
    "code": "111320",
    "title": "Citrus (except Orange) Groves"
  },
  {
    "code": "11133",
    "title": "Noncitrus Fruit and Tree Nut Farming"
  },
  {
    "code": "111331",
    "title": "Apple Orchards"
  },
  {
    "code": "111332",
    "title": "Grape Vineyards"
  },
  {
    "code": "111333",
    "title": "Strawberry Farming"
  },
  {
    "code": "111334",
    "title": "Berry (except Strawberry) Farming"
  },
  {
    "code": "111335",
    "title": "Tree Nut Farming"
  },
  {
    "code": "111336",
    "title": "Fruit and Tree Nut Combination Farming"
  },
  {
    "code": "111339",
    "title": "Other Noncitrus Fruit Farming"
  },
  {
    "code": "1114",
    "title": "Greenhouse, Nursery, and Floriculture Production"
  },
  {
    "code": "11141",
    "title": "Food Crops Grown Under Cover"
  },
  {
    "code": "111411",
    "title": "Mushroom Production"
  },
  {
    "code": "111419",
    "title": "Other Food Crops Grown Under Cover"
  },
  {
    "code": "11142",
    "title": "Nursery and Floriculture Production"
  },
  {
    "code": "111421",
    "title": "Nursery and Tree Production"
  },
  {
    "code": "111422",
    "title": "Floriculture Production"
  },
  {
    "code": "1119",
    "title": "Other Crop Farming"
  },
  {
    "code": "11191",
    "title": "Tobacco Farming"
  },
  {
    "code": "111910",
    "title": "Tobacco Farming"
  },
  {
    "code": "11192",
    "title": "Cotton Farming"
  },
  {
    "code": "111920",
    "title": "Cotton Farming"
  },
  {
    "code": "11193",
    "title": "Sugarcane Farming"
  },
  {
    "code": "111930",
    "title": "Sugarcane Farming"
  },
  {
    "code": "11194",
    "title": "Hay Farming"
  },
  {
    "code": "111940",
    "title": "Hay Farming"
  },
  {
    "code": "11199",
    "title": "All Other Crop Farming"
  },
  {
    "code": "111991",
    "title": "Sugar Beet Farming"
  },
  {
    "code": "111992",
    "title": "Peanut Farming"
  },
  {
    "code": "111998",
    "title": "All Other Miscellaneous Crop Farming"
  },
  {
    "code": "112",
    "title": "Animal Production and Aquaculture"
  },
  {
    "code": "1121",
    "title": "Cattle Ranching and Farming"
  },
  {
    "code": "11211",
    "title": "Beef Cattle Ranching and Farming, including Feedlots"
  },
  {
    "code": "112111",
    "title": "Beef Cattle Ranching and Farming"
  },
  {
    "code": "112112",
    "title": "Cattle Feedlots"
  },
  {
    "code": "11212",
    "title": "Dairy Cattle and Milk Production"
  },
  {
    "code": "112120",
    "title": "Dairy Cattle and Milk Production"
  },
  {
    "code": "11213",
    "title": "Dual-Purpose Cattle Ranching and Farming"
  },
  {
    "code": "112130",
    "title": "Dual-Purpose Cattle Ranching and Farming"
  },
  {
    "code": "1122",
    "title": "Hog and Pig Farming"
  },
  {
    "code": "11221",
    "title": "Hog and Pig Farming"
  },
  {
    "code": "112210",
    "title": "Hog and Pig Farming"
  },
  {
    "code": "1123",
    "title": "Poultry and Egg Production"
  },
  {
    "code": "11231",
    "title": "Chicken Egg Production"
  },
  {
    "code": "112310",
    "title": "Chicken Egg Production"
  },
  {
    "code": "11232",
    "title": "Broilers and Other Meat Type Chicken Production"
  },
  {
    "code": "112320",
    "title": "Broilers and Other Meat Type Chicken Production"
  },
  {
    "code": "11233",
    "title": "Turkey Production"
  },
  {
    "code": "112330",
    "title": "Turkey Production"
  },
  {
    "code": "11234",
    "title": "Poultry Hatcheries"
  },
  {
    "code": "112340",
    "title": "Poultry Hatcheries"
  },
  {
    "code": "11239",
    "title": "Other Poultry Production"
  },
  {
    "code": "112390",
    "title": "Other Poultry Production"
  },
  {
    "code": "1124",
    "title": "Sheep and Goat Farming"
  },
  {
    "code": "11241",
    "title": "Sheep Farming"
  },
  {
    "code": "112410",
    "title": "Sheep Farming"
  },
  {
    "code": "11242",
    "title": "Goat Farming"
  },
  {
    "code": "112420",
    "title": "Goat Farming"
  },
  {
    "code": "1125",
    "title": "Aquaculture"
  },
  {
    "code": "11251",
    "title": "Aquaculture"
  },
  {
    "code": "112511",
    "title": "Finfish Farming and Fish Hatcheries"
  },
  {
    "code": "112512",
    "title": "Shellfish Farming"
  },
  {
    "code": "112519",
    "title": "Other Aquaculture"
  },
  {
    "code": "1129",
    "title": "Other Animal Production"
  },
  {
    "code": "11291",
    "title": "Apiculture"
  },
  {
    "code": "112910",
    "title": "Apiculture"
  },
  {
    "code": "11292",
    "title": "Horses and Other Equine Production"
  },
  {
    "code": "112920",
    "title": "Horses and Other Equine Production"
  },
  {
    "code": "11293",
    "title": "Fur-Bearing Animal and Rabbit Production"
  },
  {
    "code": "112930",
    "title": "Fur-Bearing Animal and Rabbit Production"
  },
  {
    "code": "11299",
    "title": "All Other Animal Production"
  },
  {
    "code": "112990",
    "title": "All Other Animal Production"
  },
  {
    "code": "113",
    "title": "Forestry and Logging"
  },
  {
    "code": "1131",
    "title": "Timber Tract Operations"
  },
  {
    "code": "11311",
    "title": "Timber Tract Operations"
  },
  {
    "code": "113110",
    "title": "Timber Tract Operations"
  },
  {
    "code": "1132",
    "title": "Forest Nurseries and Gathering of Forest Products"
  },
  {
    "code": "11321",
    "title": "Forest Nurseries and Gathering of Forest Products"
  },
  {
    "code": "113210",
    "title": "Forest Nurseries and Gathering of Forest Products"
  },
  {
    "code": "1133",
    "title": "Logging"
  },
  {
    "code": "11331",
    "title": "Logging"
  },
  {
    "code": "113310",
    "title": "Logging"
  },
  {
    "code": "114",
    "title": "Fishing, Hunting and Trapping"
  },
  {
    "code": "1141",
    "title": "Fishing"
  },
  {
    "code": "11411",
    "title": "Fishing"
  },
  {
    "code": "114111",
    "title": "Finfish Fishing"
  },
  {
    "code": "114112",
    "title": "Shellfish Fishing"
  },
  {
    "code": "114119",
    "title": "Other Marine Fishing"
  },
  {
    "code": "1142",
    "title": "Hunting and Trapping"
  },
  {
    "code": "11421",
    "title": "Hunting and Trapping"
  },
  {
    "code": "114210",
    "title": "Hunting and Trapping"
  },
  {
    "code": "115",
    "title": "Support Activities for Agriculture and Forestry"
  },
  {
    "code": "1151",
    "title": "Support Activities for Crop Production"
  },
  {
    "code": "11511",
    "title": "Support Activities for Crop Production"
  },
  {
    "code": "115111",
    "title": "Cotton Ginning"
  },
  {
    "code": "115112",
    "title": "Soil Preparation, Planting, and Cultivating"
  },
  {
    "code": "115113",
    "title": "Crop Harvesting, Primarily by Machine"
  },
  {
    "code": "115114",
    "title": "Postharvest Crop Activities (except Cotton Ginning)"
  },
  {
    "code": "115115",
    "title": "Farm Labor Contractors and Crew Leaders"
  },
  {
    "code": "115116",
    "title": "Farm Management Services"
  },
  {
    "code": "1152",
    "title": "Support Activities for Animal Production"
  },
  {
    "code": "11521",
    "title": "Support Activities for Animal Production"
  },
  {
    "code": "115210",
    "title": "Support Activities for Animal Production"
  },
  {
    "code": "1153",
    "title": "Support Activities for Forestry"
  },
  {
    "code": "11531",
    "title": "Support Activities for Forestry"
  },
  {
    "code": "115310",
    "title": "Support Activities for Forestry"
  },
  {
    "code": "21",
    "title": "Mining, Quarrying, and Oil and Gas Extraction"
  },
  {
    "code": "211",
    "title": "Oil and Gas Extraction"
  },
  {
    "code": "2111",
    "title": "Oil and Gas Extraction"
  },
  {
    "code": "21112",
    "title": "Crude Petroleum Extraction"
  },
  {
    "code": "211120",
    "title": "Crude Petroleum Extraction"
  },
  {
    "code": "21113",
    "title": "Natural Gas Extraction"
  },
  {
    "code": "211130",
    "title": "Natural Gas Extraction"
  },
  {
    "code": "212",
    "title": "Mining (except Oil and Gas)"
  },
  {
    "code": "2121",
    "title": "Coal Mining"
  },
  {
    "code": "21211",
    "title": "Coal Mining"
  },
  {
    "code": "212111",
    "title": "Bituminous Coal and Lignite Surface Mining"
  },
  {
    "code": "212112",
    "title": "Bituminous Coal Underground Mining"
  },
  {
    "code": "212113",
    "title": "Anthracite Mining"
  },
  {
    "code": "2122",
    "title": "Metal Ore Mining"
  },
  {
    "code": "21221",
    "title": "Iron Ore Mining"
  },
  {
    "code": "212210",
    "title": "Iron Ore Mining"
  },
  {
    "code": "21222",
    "title": "Gold Ore and Silver Ore Mining"
  },
  {
    "code": "212221",
    "title": "Gold Ore Mining"
  },
  {
    "code": "212222",
    "title": "Silver Ore Mining"
  },
  {
    "code": "21223",
    "title": "Copper, Nickel, Lead, and Zinc Mining"
  },
  {
    "code": "212230",
    "title": "Copper, Nickel, Lead, and Zinc Mining"
  },
  {
    "code": "21229",
    "title": "Other Metal Ore Mining"
  },
  {
    "code": "212291",
    "title": "Uranium-Radium-Vanadium Ore Mining"
  },
  {
    "code": "212299",
    "title": "All Other Metal Ore Mining"
  },
  {
    "code": "2123",
    "title": "Nonmetallic Mineral Mining and Quarrying"
  },
  {
    "code": "21231",
    "title": "Stone Mining and Quarrying"
  },
  {
    "code": "212311",
    "title": "Dimension Stone Mining and Quarrying"
  },
  {
    "code": "212312",
    "title": "Crushed and Broken Limestone Mining and Quarrying"
  },
  {
    "code": "212313",
    "title": "Crushed and Broken Granite Mining and Quarrying"
  },
  {
    "code": "212319",
    "title": "Other Crushed and Broken Stone Mining and Quarrying"
  },
  {
    "code": "21232",
    "title": "Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying"
  },
  {
    "code": "212321",
    "title": "Construction Sand and Gravel Mining"
  },
  {
    "code": "212322",
    "title": "Industrial Sand Mining"
  },
  {
    "code": "212324",
    "title": "Kaolin and Ball Clay Mining"
  },
  {
    "code": "212325",
    "title": "Clay and Ceramic and Refractory Minerals Mining"
  },
  {
    "code": "21239",
    "title": "Other Nonmetallic Mineral Mining and Quarrying"
  },
  {
    "code": "212391",
    "title": "Potash, Soda, and Borate Mineral Mining"
  },
  {
    "code": "212392",
    "title": "Phosphate Rock Mining"
  },
  {
    "code": "212393",
    "title": "Other Chemical and Fertilizer Mineral Mining"
  },
  {
    "code": "212399",
    "title": "All Other Nonmetallic Mineral Mining"
  },
  {
    "code": "213",
    "title": "Support Activities for Mining"
  },
  {
    "code": "2131",
    "title": "Support Activities for Mining"
  },
  {
    "code": "21311",
    "title": "Support Activities for Mining"
  },
  {
    "code": "213111",
    "title": "Drilling Oil and Gas Wells"
  },
  {
    "code": "213112",
    "title": "Support Activities for Oil and Gas Operations"
  },
  {
    "code": "213113",
    "title": "Support Activities for Coal Mining"
  },
  {
    "code": "213114",
    "title": "Support Activities for Metal Mining"
  },
  {
    "code": "213115",
    "title": "Support Activities for Nonmetallic Minerals (except Fuels) Mining"
  },
  {
    "code": "22",
    "title": "Utilities"
  },
  {
    "code": "221",
    "title": "Utilities"
  },
  {
    "code": "2211",
    "title": "Electric Power Generation, Transmission and Distribution"
  },
  {
    "code": "22111",
    "title": "Electric Power Generation"
  },
  {
    "code": "221111",
    "title": "Hydroelectric Power Generation"
  },
  {
    "code": "221112",
    "title": "Fossil Fuel Electric Power Generation"
  },
  {
    "code": "221113",
    "title": "Nuclear Electric Power Generation"
  },
  {
    "code": "221114",
    "title": "Solar Electric Power Generation"
  },
  {
    "code": "221115",
    "title": "Wind Electric Power Generation"
  },
  {
    "code": "221116",
    "title": "Geothermal Electric Power Generation"
  },
  {
    "code": "221117",
    "title": "Biomass Electric Power Generation"
  },
  {
    "code": "221118",
    "title": "Other Electric Power Generation"
  },
  {
    "code": "22112",
    "title": "Electric Power Transmission, Control, and Distribution"
  },
  {
    "code": "221121",
    "title": "Electric Bulk Power Transmission and Control"
  },
  {
    "code": "221122",
    "title": "Electric Power Distribution"
  },
  {
    "code": "2212",
    "title": "Natural Gas Distribution"
  },
  {
    "code": "22121",
    "title": "Natural Gas Distribution"
  },
  {
    "code": "221210",
    "title": "Natural Gas Distribution"
  },
  {
    "code": "2213",
    "title": "Water, Sewage and Other Systems"
  },
  {
    "code": "22131",
    "title": "Water Supply and Irrigation Systems"
  },
  {
    "code": "221310",
    "title": "Water Supply and Irrigation Systems"
  },
  {
    "code": "22132",
    "title": "Sewage Treatment Facilities"
  },
  {
    "code": "221320",
    "title": "Sewage Treatment Facilities"
  },
  {
    "code": "22133",
    "title": "Steam and Air-Conditioning Supply"
  },
  {
    "code": "221330",
    "title": "Steam and Air-Conditioning Supply"
  },
  {
    "code": "23",
    "title": "Construction"
  },
  {
    "code": "236",
    "title": "Construction of Buildings"
  },
  {
    "code": "2361",
    "title": "Residential Building Construction"
  },
  {
    "code": "23611",
    "title": "Residential Building Construction"
  },
  {
    "code": "236115",
    "title": "New Single-Family Housing Construction (except For-Sale Builders)"
  },
  {
    "code": "236116",
    "title": "New Multifamily Housing Construction (except For-Sale Builders)"
  },
  {
    "code": "236117",
    "title": "New Housing For-Sale Builders"
  },
  {
    "code": "236118",
    "title": "Residential Remodelers"
  },
  {
    "code": "2362",
    "title": "Nonresidential Building Construction"
  },
  {
    "code": "23621",
    "title": "Industrial Building Construction"
  },
  {
    "code": "236210",
    "title": "Industrial Building Construction"
  },
  {
    "code": "23622",
    "title": "Commercial and Institutional Building Construction"
  },
  {
    "code": "236220",
    "title": "Commercial and Institutional Building Construction"
  },
  {
    "code": "237",
    "title": "Heavy and Civil Engineering Construction"
  },
  {
    "code": "2371",
    "title": "Utility System Construction"
  },
  {
    "code": "23711",
    "title": "Water and Sewer Line and Related Structures Construction"
  },
  {
    "code": "237110",
    "title": "Water and Sewer Line and Related Structures Construction"
  },
  {
    "code": "23712",
    "title": "Oil and Gas Pipeline and Related Structures Construction"
  },
  {
    "code": "237120",
    "title": "Oil and Gas Pipeline and Related Structures Construction"
  },
  {
    "code": "23713",
    "title": "Power and Communication Line and Related Structures Construction"
  },
  {
    "code": "237130",
    "title": "Power and Communication Line and Related Structures Construction"
  },
  {
    "code": "2372",
    "title": "Land Subdivision"
  },
  {
    "code": "23721",
    "title": "Land Subdivision"
  },
  {
    "code": "237210",
    "title": "Land Subdivision"
  },
  {
    "code": "2373",
    "title": "Highway, Street, and Bridge Construction"
  },
  {
    "code": "23731",
    "title": "Highway, Street, and Bridge Construction"
  },
  {
    "code": "237310",
    "title": "Highway, Street, and Bridge Construction"
  },
  {
    "code": "2379",
    "title": "Other Heavy and Civil Engineering Construction"
  },
  {
    "code": "23799",
    "title": "Other Heavy and Civil Engineering Construction"
  },
  {
    "code": "237990",
    "title": "Other Heavy and Civil Engineering Construction"
  },
  {
    "code": "238",
    "title": "Specialty Trade Contractors"
  },
  {
    "code": "2381",
    "title": "Foundation, Structure, and Building Exterior Contractors"
  },
  {
    "code": "23811",
    "title": "Poured Concrete Foundation and Structure Contractors"
  },
  {
    "code": "238110",
    "title": "Poured Concrete Foundation and Structure Contractors"
  },
  {
    "code": "23812",
    "title": "Structural Steel and Precast Concrete Contractors"
  },
  {
    "code": "238120",
    "title": "Structural Steel and Precast Concrete Contractors"
  },
  {
    "code": "23813",
    "title": "Framing Contractors"
  },
  {
    "code": "238130",
    "title": "Framing Contractors"
  },
  {
    "code": "23814",
    "title": "Masonry Contractors"
  },
  {
    "code": "238140",
    "title": "Masonry Contractors"
  },
  {
    "code": "23815",
    "title": "Glass and Glazing Contractors"
  },
  {
    "code": "238150",
    "title": "Glass and Glazing Contractors"
  },
  {
    "code": "23816",
    "title": "Roofing Contractors"
  },
  {
    "code": "238160",
    "title": "Roofing Contractors"
  },
  {
    "code": "23817",
    "title": "Siding Contractors"
  },
  {
    "code": "238170",
    "title": "Siding Contractors"
  },
  {
    "code": "23819",
    "title": "Other Foundation, Structure, and Building Exterior Contractors"
  },
  {
    "code": "238190",
    "title": "Other Foundation, Structure, and Building Exterior Contractors"
  },
  {
    "code": "2382",
    "title": "Building Equipment Contractors"
  },
  {
    "code": "23821",
    "title": "Electrical Contractors and Other Wiring Installation Contractors"
  },
  {
    "code": "238210",
    "title": "Electrical Contractors and Other Wiring Installation Contractors"
  },
  {
    "code": "23822",
    "title": "Plumbing, Heating, and Air-Conditioning Contractors"
  },
  {
    "code": "238220",
    "title": "Plumbing, Heating, and Air-Conditioning Contractors"
  },
  {
    "code": "23829",
    "title": "Other Building Equipment Contractors"
  },
  {
    "code": "238290",
    "title": "Other Building Equipment Contractors"
  },
  {
    "code": "2383",
    "title": "Building Finishing Contractors"
  },
  {
    "code": "23831",
    "title": "Drywall and Insulation Contractors"
  },
  {
    "code": "238310",
    "title": "Drywall and Insulation Contractors"
  },
  {
    "code": "23832",
    "title": "Painting and Wall Covering Contractors"
  },
  {
    "code": "238320",
    "title": "Painting and Wall Covering Contractors"
  },
  {
    "code": "23833",
    "title": "Flooring Contractors"
  },
  {
    "code": "238330",
    "title": "Flooring Contractors"
  },
  {
    "code": "23834",
    "title": "Tile and Terrazzo Contractors"
  },
  {
    "code": "238340",
    "title": "Tile and Terrazzo Contractors"
  },
  {
    "code": "23835",
    "title": "Finish Carpentry Contractors"
  },
  {
    "code": "238350",
    "title": "Finish Carpentry Contractors"
  },
  {
    "code": "23839",
    "title": "Other Building Finishing Contractors"
  },
  {
    "code": "238390",
    "title": "Other Building Finishing Contractors"
  },
  {
    "code": "2389",
    "title": "Other Specialty Trade Contractors"
  },
  {
    "code": "23891",
    "title": "Site Preparation Contractors"
  },
  {
    "code": "238910",
    "title": "Site Preparation Contractors"
  },
  {
    "code": "23899",
    "title": "All Other Specialty Trade Contractors"
  },
  {
    "code": "238990",
    "title": "All Other Specialty Trade Contractors"
  },
  {
    "code": "31-33",
    "title": "Manufacturing"
  },
  {
    "code": "311",
    "title": "Food Manufacturing"
  },
  {
    "code": "3111",
    "title": "Animal Food Manufacturing"
  },
  {
    "code": "31111",
    "title": "Animal Food Manufacturing"
  },
  {
    "code": "311111",
    "title": "Dog and Cat Food Manufacturing"
  },
  {
    "code": "311119",
    "title": "Other Animal Food Manufacturing"
  },
  {
    "code": "3112",
    "title": "Grain and Oilseed Milling"
  },
  {
    "code": "31121",
    "title": "Flour Milling and Malt Manufacturing"
  },
  {
    "code": "311211",
    "title": "Flour Milling"
  },
  {
    "code": "311212",
    "title": "Rice Milling"
  },
  {
    "code": "311213",
    "title": "Malt Manufacturing"
  },
  {
    "code": "31122",
    "title": "Starch and Vegetable Fats and Oils Manufacturing"
  },
  {
    "code": "311221",
    "title": "Wet Corn Milling"
  },
  {
    "code": "311224",
    "title": "Soybean and Other Oilseed Processing"
  },
  {
    "code": "311225",
    "title": "Fats and Oils Refining and Blending"
  },
  {
    "code": "31123",
    "title": "Breakfast Cereal Manufacturing"
  },
  {
    "code": "311230",
    "title": "Breakfast Cereal Manufacturing"
  },
  {
    "code": "3113",
    "title": "Sugar and Confectionery Product Manufacturing"
  },
  {
    "code": "31131",
    "title": "Sugar Manufacturing"
  },
  {
    "code": "311313",
    "title": "Beet Sugar Manufacturing"
  },
  {
    "code": "311314",
    "title": "Cane Sugar Manufacturing"
  },
  {
    "code": "31134",
    "title": "Nonchocolate Confectionery Manufacturing"
  },
  {
    "code": "311340",
    "title": "Nonchocolate Confectionery Manufacturing"
  },
  {
    "code": "31135",
    "title": "Chocolate and Confectionery Manufacturing"
  },
  {
    "code": "311351",
    "title": "Chocolate and Confectionery Manufacturing from Cacao Beans"
  },
  {
    "code": "311352",
    "title": "Confectionery Manufacturing from Purchased Chocolate"
  },
  {
    "code": "3114",
    "title": "Fruit and Vegetable Preserving and Specialty Food Manufacturing"
  },
  {
    "code": "31141",
    "title": "Frozen Food Manufacturing"
  },
  {
    "code": "311411",
    "title": "Frozen Fruit, Juice, and Vegetable Manufacturing"
  },
  {
    "code": "311412",
    "title": "Frozen Specialty Food Manufacturing"
  },
  {
    "code": "31142",
    "title": "Fruit and Vegetable Canning, Pickling, and Drying"
  },
  {
    "code": "311421",
    "title": "Fruit and Vegetable Canning"
  },
  {
    "code": "311422",
    "title": "Specialty Canning"
  },
  {
    "code": "311423",
    "title": "Dried and Dehydrated Food Manufacturing"
  },
  {
    "code": "3115",
    "title": "Dairy Product Manufacturing"
  },
  {
    "code": "31151",
    "title": "Dairy Product (except Frozen) Manufacturing"
  },
  {
    "code": "311511",
    "title": "Fluid Milk Manufacturing"
  },
  {
    "code": "311512",
    "title": "Creamery Butter Manufacturing"
  },
  {
    "code": "311513",
    "title": "Cheese Manufacturing"
  },
  {
    "code": "311514",
    "title": "Dry, Condensed, and Evaporated Dairy Product Manufacturing"
  },
  {
    "code": "31152",
    "title": "Ice Cream and Frozen Dessert Manufacturing"
  },
  {
    "code": "311520",
    "title": "Ice Cream and Frozen Dessert Manufacturing"
  },
  {
    "code": "3116",
    "title": "Animal Slaughtering and Processing"
  },
  {
    "code": "31161",
    "title": "Animal Slaughtering and Processing"
  },
  {
    "code": "311611",
    "title": "Animal (except Poultry) Slaughtering"
  },
  {
    "code": "311612",
    "title": "Meat Processed from Carcasses"
  },
  {
    "code": "311613",
    "title": "Rendering and Meat Byproduct Processing"
  },
  {
    "code": "311615",
    "title": "Poultry Processing"
  },
  {
    "code": "3117",
    "title": "Seafood Product Preparation and Packaging"
  },
  {
    "code": "31171",
    "title": "Seafood Product Preparation and Packaging"
  },
  {
    "code": "311710",
    "title": "Seafood Product Preparation and Packaging"
  },
  {
    "code": "3118",
    "title": "Bakeries and Tortilla Manufacturing"
  },
  {
    "code": "31181",
    "title": "Bread and Bakery Product Manufacturing"
  },
  {
    "code": "311811",
    "title": "Retail Bakeries"
  },
  {
    "code": "311812",
    "title": "Commercial Bakeries"
  },
  {
    "code": "311813",
    "title": "Frozen Cakes, Pies, and Other Pastries Manufacturing"
  },
  {
    "code": "31182",
    "title": "Cookie, Cracker, and Pasta Manufacturing"
  },
  {
    "code": "311821",
    "title": "Cookie and Cracker Manufacturing"
  },
  {
    "code": "311824",
    "title": "Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour"
  },
  {
    "code": "31183",
    "title": "Tortilla Manufacturing"
  },
  {
    "code": "311830",
    "title": "Tortilla Manufacturing"
  },
  {
    "code": "3119",
    "title": "Other Food Manufacturing"
  },
  {
    "code": "31191",
    "title": "Snack Food Manufacturing"
  },
  {
    "code": "311911",
    "title": "Roasted Nuts and Peanut Butter Manufacturing"
  },
  {
    "code": "311919",
    "title": "Other Snack Food Manufacturing"
  },
  {
    "code": "31192",
    "title": "Coffee and Tea Manufacturing"
  },
  {
    "code": "311920",
    "title": "Coffee and Tea Manufacturing"
  },
  {
    "code": "31193",
    "title": "Flavoring Syrup and Concentrate Manufacturing"
  },
  {
    "code": "311930",
    "title": "Flavoring Syrup and Concentrate Manufacturing"
  },
  {
    "code": "31194",
    "title": "Seasoning and Dressing Manufacturing"
  },
  {
    "code": "311941",
    "title": "Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing"
  },
  {
    "code": "311942",
    "title": "Spice and Extract Manufacturing"
  },
  {
    "code": "31199",
    "title": "All Other Food Manufacturing"
  },
  {
    "code": "311991",
    "title": "Perishable Prepared Food Manufacturing"
  },
  {
    "code": "311999",
    "title": "All Other Miscellaneous Food Manufacturing"
  },
  {
    "code": "312",
    "title": "Beverage and Tobacco Product Manufacturing"
  },
  {
    "code": "3121",
    "title": "Beverage Manufacturing"
  },
  {
    "code": "31211",
    "title": "Soft Drink and Ice Manufacturing"
  },
  {
    "code": "312111",
    "title": "Soft Drink Manufacturing"
  },
  {
    "code": "312112",
    "title": "Bottled Water Manufacturing"
  },
  {
    "code": "312113",
    "title": "Ice Manufacturing"
  },
  {
    "code": "31212",
    "title": "Breweries"
  },
  {
    "code": "312120",
    "title": "Breweries"
  },
  {
    "code": "31213",
    "title": "Wineries"
  },
  {
    "code": "312130",
    "title": "Wineries"
  },
  {
    "code": "31214",
    "title": "Distilleries"
  },
  {
    "code": "312140",
    "title": "Distilleries"
  },
  {
    "code": "3122",
    "title": "Tobacco Manufacturing"
  },
  {
    "code": "31223",
    "title": "Tobacco Manufacturing"
  },
  {
    "code": "312230",
    "title": "Tobacco Manufacturing"
  },
  {
    "code": "313",
    "title": "Textile Mills"
  },
  {
    "code": "3131",
    "title": "Fiber, Yarn, and Thread Mills"
  },
  {
    "code": "31311",
    "title": "Fiber, Yarn, and Thread Mills"
  },
  {
    "code": "313110",
    "title": "Fiber, Yarn, and Thread Mills"
  },
  {
    "code": "3132",
    "title": "Fabric Mills"
  },
  {
    "code": "31321",
    "title": "Broadwoven Fabric Mills"
  },
  {
    "code": "313210",
    "title": "Broadwoven Fabric Mills"
  },
  {
    "code": "31322",
    "title": "Narrow Fabric Mills and Schiffli Machine Embroidery"
  },
  {
    "code": "313220",
    "title": "Narrow Fabric Mills and Schiffli Machine Embroidery"
  },
  {
    "code": "31323",
    "title": "Nonwoven Fabric Mills"
  },
  {
    "code": "313230",
    "title": "Nonwoven Fabric Mills"
  },
  {
    "code": "31324",
    "title": "Knit Fabric Mills"
  },
  {
    "code": "313240",
    "title": "Knit Fabric Mills"
  },
  {
    "code": "3133",
    "title": "Textile and Fabric Finishing and Fabric Coating Mills"
  },
  {
    "code": "31331",
    "title": "Textile and Fabric Finishing Mills"
  },
  {
    "code": "313310",
    "title": "Textile and Fabric Finishing Mills"
  },
  {
    "code": "31332",
    "title": "Fabric Coating Mills"
  },
  {
    "code": "313320",
    "title": "Fabric Coating Mills"
  },
  {
    "code": "314",
    "title": "Textile Product Mills"
  },
  {
    "code": "3141",
    "title": "Textile Furnishings Mills"
  },
  {
    "code": "31411",
    "title": "Carpet and Rug Mills"
  },
  {
    "code": "314110",
    "title": "Carpet and Rug Mills"
  },
  {
    "code": "31412",
    "title": "Curtain and Linen Mills"
  },
  {
    "code": "314120",
    "title": "Curtain and Linen Mills"
  },
  {
    "code": "3149",
    "title": "Other Textile Product Mills"
  },
  {
    "code": "31491",
    "title": "Textile Bag and Canvas Mills"
  },
  {
    "code": "314910",
    "title": "Textile Bag and Canvas Mills"
  },
  {
    "code": "31499",
    "title": "All Other Textile Product Mills"
  },
  {
    "code": "314994",
    "title": "Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills"
  },
  {
    "code": "314999",
    "title": "All Other Miscellaneous Textile Product Mills"
  },
  {
    "code": "315",
    "title": "Apparel Manufacturing"
  },
  {
    "code": "3151",
    "title": "Apparel Knitting Mills"
  },
  {
    "code": "31511",
    "title": "Hosiery and Sock Mills"
  },
  {
    "code": "315110",
    "title": "Hosiery and Sock Mills"
  },
  {
    "code": "31519",
    "title": "Other Apparel Knitting Mills"
  },
  {
    "code": "315190",
    "title": "Other Apparel Knitting Mills"
  },
  {
    "code": "3152",
    "title": "Cut and Sew Apparel Manufacturing"
  },
  {
    "code": "31521",
    "title": "Cut and Sew Apparel Contractors"
  },
  {
    "code": "315210",
    "title": "Cut and Sew Apparel Contractors"
  },
  {
    "code": "31522",
    "title": "Men\u2019s and Boys\u2019 Cut and Sew Apparel Manufacturing"
  },
  {
    "code": "315220",
    "title": "Men\u2019s and Boys\u2019 Cut and Sew Apparel Manufacturing"
  },
  {
    "code": "31524",
    "title": "Women\u2019s, Girls\u2019, and Infants\u2019 Cut and Sew Apparel Manufacturing"
  },
  {
    "code": "315240",
    "title": "Women\u2019s, Girls\u2019, and Infants\u2019 Cut and Sew Apparel Manufacturing"
  },
  {
    "code": "31528",
    "title": "Other Cut and Sew Apparel Manufacturing"
  },
  {
    "code": "315280",
    "title": "Other Cut and Sew Apparel Manufacturing"
  },
  {
    "code": "3159",
    "title": "Apparel Accessories and Other Apparel Manufacturing"
  },
  {
    "code": "31599",
    "title": "Apparel Accessories and Other Apparel Manufacturing"
  },
  {
    "code": "315990",
    "title": "Apparel Accessories and Other Apparel Manufacturing"
  },
  {
    "code": "316",
    "title": "Leather and Allied Product Manufacturing"
  },
  {
    "code": "3161",
    "title": "Leather and Hide Tanning and Finishing"
  },
  {
    "code": "31611",
    "title": "Leather and Hide Tanning and Finishing"
  },
  {
    "code": "316110",
    "title": "Leather and Hide Tanning and Finishing"
  },
  {
    "code": "3162",
    "title": "Footwear Manufacturing"
  },
  {
    "code": "31621",
    "title": "Footwear Manufacturing"
  },
  {
    "code": "316210",
    "title": "Footwear Manufacturing"
  },
  {
    "code": "3169",
    "title": "Other Leather and Allied Product Manufacturing"
  },
  {
    "code": "31699",
    "title": "Other Leather and Allied Product Manufacturing"
  },
  {
    "code": "316992",
    "title": "Women's Handbag and Purse Manufacturing"
  },
  {
    "code": "316998",
    "title": "All Other Leather Good and Allied Product Manufacturing"
  },
  {
    "code": "321",
    "title": "Wood Product Manufacturing"
  },
  {
    "code": "3211",
    "title": "Sawmills and Wood Preservation"
  },
  {
    "code": "32111",
    "title": "Sawmills and Wood Preservation"
  },
  {
    "code": "321113",
    "title": "Sawmills"
  },
  {
    "code": "321114",
    "title": "Wood Preservation"
  },
  {
    "code": "3212",
    "title": "Veneer, Plywood, and Engineered Wood Product Manufacturing"
  },
  {
    "code": "32121",
    "title": "Veneer, Plywood, and Engineered Wood Product Manufacturing"
  },
  {
    "code": "321211",
    "title": "Hardwood Veneer and Plywood Manufacturing"
  },
  {
    "code": "321212",
    "title": "Softwood Veneer and Plywood Manufacturing"
  },
  {
    "code": "321213",
    "title": "Engineered Wood Member (except Truss) Manufacturing"
  },
  {
    "code": "321214",
    "title": "Truss Manufacturing"
  },
  {
    "code": "321219",
    "title": "Reconstituted Wood Product Manufacturing"
  },
  {
    "code": "3219",
    "title": "Other Wood Product Manufacturing"
  },
  {
    "code": "32191",
    "title": "Millwork"
  },
  {
    "code": "321911",
    "title": "Wood Window and Door Manufacturing"
  },
  {
    "code": "321912",
    "title": "Cut Stock, Resawing Lumber, and Planing"
  },
  {
    "code": "321918",
    "title": "Other Millwork (including Flooring)"
  },
  {
    "code": "32192",
    "title": "Wood Container and Pallet Manufacturing"
  },
  {
    "code": "321920",
    "title": "Wood Container and Pallet Manufacturing"
  },
  {
    "code": "32199",
    "title": "All Other Wood Product Manufacturing"
  },
  {
    "code": "321991",
    "title": "Manufactured Home (Mobile Home) Manufacturing"
  },
  {
    "code": "321992",
    "title": "Prefabricated Wood Building Manufacturing"
  },
  {
    "code": "321999",
    "title": "All Other Miscellaneous Wood Product Manufacturing"
  },
  {
    "code": "322",
    "title": "Paper Manufacturing"
  },
  {
    "code": "3221",
    "title": "Pulp, Paper, and Paperboard Mills"
  },
  {
    "code": "32211",
    "title": "Pulp Mills"
  },
  {
    "code": "322110",
    "title": "Pulp Mills"
  },
  {
    "code": "32212",
    "title": "Paper Mills"
  },
  {
    "code": "322121",
    "title": "Paper (except Newsprint) Mills"
  },
  {
    "code": "322122",
    "title": "Newsprint Mills"
  },
  {
    "code": "32213",
    "title": "Paperboard Mills"
  },
  {
    "code": "322130",
    "title": "Paperboard Mills"
  },
  {
    "code": "3222",
    "title": "Converted Paper Product Manufacturing"
  },
  {
    "code": "32221",
    "title": "Paperboard Container Manufacturing"
  },
  {
    "code": "322211",
    "title": "Corrugated and Solid Fiber Box Manufacturing"
  },
  {
    "code": "322212",
    "title": "Folding Paperboard Box Manufacturing"
  },
  {
    "code": "322219",
    "title": "Other Paperboard Container Manufacturing"
  },
  {
    "code": "32222",
    "title": "Paper Bag and Coated and Treated Paper Manufacturing"
  },
  {
    "code": "322220",
    "title": "Paper Bag and Coated and Treated Paper Manufacturing"
  },
  {
    "code": "32223",
    "title": "Stationery Product Manufacturing"
  },
  {
    "code": "322230",
    "title": "Stationery Product Manufacturing"
  },
  {
    "code": "32229",
    "title": "Other Converted Paper Product Manufacturing"
  },
  {
    "code": "322291",
    "title": "Sanitary Paper Product Manufacturing"
  },
  {
    "code": "322299",
    "title": "All Other Converted Paper Product Manufacturing"
  },
  {
    "code": "323",
    "title": "Printing and Related Support Activities"
  },
  {
    "code": "3231",
    "title": "Printing and Related Support Activities"
  },
  {
    "code": "32311",
    "title": "Printing"
  },
  {
    "code": "323111",
    "title": "Commercial Printing (except Screen and Books)"
  },
  {
    "code": "323113",
    "title": "Commercial Screen Printing"
  },
  {
    "code": "323117",
    "title": "Books Printing"
  },
  {
    "code": "32312",
    "title": "Support Activities for Printing"
  },
  {
    "code": "323120",
    "title": "Support Activities for Printing"
  },
  {
    "code": "324",
    "title": "Petroleum and Coal Products Manufacturing"
  },
  {
    "code": "3241",
    "title": "Petroleum and Coal Products Manufacturing"
  },
  {
    "code": "32411",
    "title": "Petroleum Refineries"
  },
  {
    "code": "324110",
    "title": "Petroleum Refineries"
  },
  {
    "code": "32412",
    "title": "Asphalt Paving, Roofing, and Saturated Materials Manufacturing"
  },
  {
    "code": "324121",
    "title": "Asphalt Paving Mixture and Block Manufacturing"
  },
  {
    "code": "324122",
    "title": "Asphalt Shingle and Coating Materials Manufacturing"
  },
  {
    "code": "32419",
    "title": "Other Petroleum and Coal Products Manufacturing"
  },
  {
    "code": "324191",
    "title": "Petroleum Lubricating Oil and Grease Manufacturing"
  },
  {
    "code": "324199",
    "title": "All Other Petroleum and Coal Products Manufacturing"
  },
  {
    "code": "325",
    "title": "Chemical Manufacturing"
  },
  {
    "code": "3251",
    "title": "Basic Chemical Manufacturing"
  },
  {
    "code": "32511",
    "title": "Petrochemical Manufacturing"
  },
  {
    "code": "325110",
    "title": "Petrochemical Manufacturing"
  },
  {
    "code": "32512",
    "title": "Industrial Gas Manufacturing"
  },
  {
    "code": "325120",
    "title": "Industrial Gas Manufacturing"
  },
  {
    "code": "32513",
    "title": "Synthetic Dye and Pigment Manufacturing"
  },
  {
    "code": "325130",
    "title": "Synthetic Dye and Pigment Manufacturing"
  },
  {
    "code": "32518",
    "title": "Other Basic Inorganic Chemical Manufacturing"
  },
  {
    "code": "325180",
    "title": "Other Basic Inorganic Chemical Manufacturing"
  },
  {
    "code": "32519",
    "title": "Other Basic Organic Chemical Manufacturing"
  },
  {
    "code": "325193",
    "title": "Ethyl Alcohol Manufacturing"
  },
  {
    "code": "325194",
    "title": "Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing"
  },
  {
    "code": "325199",
    "title": "All Other Basic Organic Chemical Manufacturing"
  },
  {
    "code": "3252",
    "title": "Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing"
  },
  {
    "code": "32521",
    "title": "Resin and Synthetic Rubber Manufacturing"
  },
  {
    "code": "325211",
    "title": "Plastics Material and Resin Manufacturing"
  },
  {
    "code": "325212",
    "title": "Synthetic Rubber Manufacturing"
  },
  {
    "code": "32522",
    "title": "Artificial and Synthetic Fibers and Filaments Manufacturing"
  },
  {
    "code": "325220",
    "title": "Artificial and Synthetic Fibers and Filaments Manufacturing"
  },
  {
    "code": "3253",
    "title": "Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing"
  },
  {
    "code": "32531",
    "title": "Fertilizer Manufacturing"
  },
  {
    "code": "325311",
    "title": "Nitrogenous Fertilizer Manufacturing"
  },
  {
    "code": "325312",
    "title": "Phosphatic Fertilizer Manufacturing"
  },
  {
    "code": "325314",
    "title": "Fertilizer (Mixing Only) Manufacturing"
  },
  {
    "code": "32532",
    "title": "Pesticide and Other Agricultural Chemical Manufacturing"
  },
  {
    "code": "325320",
    "title": "Pesticide and Other Agricultural Chemical Manufacturing"
  },
  {
    "code": "3254",
    "title": "Pharmaceutical and Medicine Manufacturing"
  },
  {
    "code": "32541",
    "title": "Pharmaceutical and Medicine Manufacturing"
  },
  {
    "code": "325411",
    "title": "Medicinal and Botanical Manufacturing"
  },
  {
    "code": "325412",
    "title": "Pharmaceutical Preparation Manufacturing"
  },
  {
    "code": "325413",
    "title": "In-Vitro Diagnostic Substance Manufacturing"
  },
  {
    "code": "325414",
    "title": "Biological Product (except Diagnostic) Manufacturing"
  },
  {
    "code": "3255",
    "title": "Paint, Coating, and Adhesive Manufacturing"
  },
  {
    "code": "32551",
    "title": "Paint and Coating Manufacturing"
  },
  {
    "code": "325510",
    "title": "Paint and Coating Manufacturing"
  },
  {
    "code": "32552",
    "title": "Adhesive Manufacturing"
  },
  {
    "code": "325520",
    "title": "Adhesive Manufacturing"
  },
  {
    "code": "3256",
    "title": "Soap, Cleaning Compound, and Toilet Preparation Manufacturing"
  },
  {
    "code": "32561",
    "title": "Soap and Cleaning Compound Manufacturing"
  },
  {
    "code": "325611",
    "title": "Soap and Other Detergent Manufacturing"
  },
  {
    "code": "325612",
    "title": "Polish and Other Sanitation Good Manufacturing"
  },
  {
    "code": "325613",
    "title": "Surface Active Agent Manufacturing"
  },
  {
    "code": "32562",
    "title": "Toilet Preparation Manufacturing"
  },
  {
    "code": "325620",
    "title": "Toilet Preparation Manufacturing"
  },
  {
    "code": "3259",
    "title": "Other Chemical Product and Preparation Manufacturing"
  },
  {
    "code": "32591",
    "title": "Printing Ink Manufacturing"
  },
  {
    "code": "325910",
    "title": "Printing Ink Manufacturing"
  },
  {
    "code": "32592",
    "title": "Explosives Manufacturing"
  },
  {
    "code": "325920",
    "title": "Explosives Manufacturing"
  },
  {
    "code": "32599",
    "title": "All Other Chemical Product and Preparation Manufacturing"
  },
  {
    "code": "325991",
    "title": "Custom Compounding of Purchased Resins"
  },
  {
    "code": "325992",
    "title": "Photographic Film, Paper, Plate, and Chemical Manufacturing"
  },
  {
    "code": "325998",
    "title": "All Other Miscellaneous Chemical Product and Preparation Manufacturing"
  },
  {
    "code": "326",
    "title": "Plastics and Rubber Products Manufacturing"
  },
  {
    "code": "3261",
    "title": "Plastics Product Manufacturing"
  },
  {
    "code": "32611",
    "title": "Plastics Packaging Materials and Unlaminated Film and Sheet Manufacturing"
  },
  {
    "code": "326111",
    "title": "Plastics Bag and Pouch Manufacturing"
  },
  {
    "code": "326112",
    "title": "Plastics Packaging Film and Sheet (including Laminated) Manufacturing"
  },
  {
    "code": "326113",
    "title": "Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing"
  },
  {
    "code": "32612",
    "title": "Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing"
  },
  {
    "code": "326121",
    "title": "Unlaminated Plastics Profile Shape Manufacturing"
  },
  {
    "code": "326122",
    "title": "Plastics Pipe and Pipe Fitting Manufacturing"
  },
  {
    "code": "32613",
    "title": "Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing"
  },
  {
    "code": "326130",
    "title": "Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing"
  },
  {
    "code": "32614",
    "title": "Polystyrene Foam Product Manufacturing"
  },
  {
    "code": "326140",
    "title": "Polystyrene Foam Product Manufacturing"
  },
  {
    "code": "32615",
    "title": "Urethane and Other Foam Product (except Polystyrene) Manufacturing"
  },
  {
    "code": "326150",
    "title": "Urethane and Other Foam Product (except Polystyrene) Manufacturing"
  },
  {
    "code": "32616",
    "title": "Plastics Bottle Manufacturing"
  },
  {
    "code": "326160",
    "title": "Plastics Bottle Manufacturing"
  },
  {
    "code": "32619",
    "title": "Other Plastics Product Manufacturing"
  },
  {
    "code": "326191",
    "title": "Plastics Plumbing Fixture Manufacturing"
  },
  {
    "code": "326199",
    "title": "All Other Plastics Product Manufacturing"
  },
  {
    "code": "3262",
    "title": "Rubber Product Manufacturing"
  },
  {
    "code": "32621",
    "title": "Tire Manufacturing"
  },
  {
    "code": "326211",
    "title": "Tire Manufacturing (except Retreading)"
  },
  {
    "code": "326212",
    "title": "Tire Retreading"
  },
  {
    "code": "32622",
    "title": "Rubber and Plastics Hoses and Belting Manufacturing"
  },
  {
    "code": "326220",
    "title": "Rubber and Plastics Hoses and Belting Manufacturing"
  },
  {
    "code": "32629",
    "title": "Other Rubber Product Manufacturing"
  },
  {
    "code": "326291",
    "title": "Rubber Product Manufacturing for Mechanical Use"
  },
  {
    "code": "326299",
    "title": "All Other Rubber Product Manufacturing"
  },
  {
    "code": "327",
    "title": "Nonmetallic Mineral Product Manufacturing"
  },
  {
    "code": "3271",
    "title": "Clay Product and Refractory Manufacturing"
  },
  {
    "code": "32711",
    "title": "Pottery, Ceramics, and Plumbing Fixture Manufacturing"
  },
  {
    "code": "327110",
    "title": "Pottery, Ceramics, and Plumbing Fixture Manufacturing"
  },
  {
    "code": "32712",
    "title": "Clay Building Material and Refractories Manufacturing"
  },
  {
    "code": "327120",
    "title": "Clay Building Material and Refractories Manufacturing"
  },
  {
    "code": "3272",
    "title": "Glass and Glass Product Manufacturing"
  },
  {
    "code": "32721",
    "title": "Glass and Glass Product Manufacturing"
  },
  {
    "code": "327211",
    "title": "Flat Glass Manufacturing"
  },
  {
    "code": "327212",
    "title": "Other Pressed and Blown Glass and Glassware Manufacturing"
  },
  {
    "code": "327213",
    "title": "Glass Container Manufacturing"
  },
  {
    "code": "327215",
    "title": "Glass Product Manufacturing Made of Purchased Glass"
  },
  {
    "code": "3273",
    "title": "Cement and Concrete Product Manufacturing"
  },
  {
    "code": "32731",
    "title": "Cement Manufacturing"
  },
  {
    "code": "327310",
    "title": "Cement Manufacturing"
  },
  {
    "code": "32732",
    "title": "Ready-Mix Concrete Manufacturing"
  },
  {
    "code": "327320",
    "title": "Ready-Mix Concrete Manufacturing"
  },
  {
    "code": "32733",
    "title": "Concrete Pipe, Brick, and Block Manufacturing"
  },
  {
    "code": "327331",
    "title": "Concrete Block and Brick Manufacturing"
  },
  {
    "code": "327332",
    "title": "Concrete Pipe Manufacturing"
  },
  {
    "code": "32739",
    "title": "Other Concrete Product Manufacturing"
  },
  {
    "code": "327390",
    "title": "Other Concrete Product Manufacturing"
  },
  {
    "code": "3274",
    "title": "Lime and Gypsum Product Manufacturing"
  },
  {
    "code": "32741",
    "title": "Lime Manufacturing"
  },
  {
    "code": "327410",
    "title": "Lime Manufacturing"
  },
  {
    "code": "32742",
    "title": "Gypsum Product Manufacturing"
  },
  {
    "code": "327420",
    "title": "Gypsum Product Manufacturing"
  },
  {
    "code": "3279",
    "title": "Other Nonmetallic Mineral Product Manufacturing"
  },
  {
    "code": "32791",
    "title": "Abrasive Product Manufacturing"
  },
  {
    "code": "327910",
    "title": "Abrasive Product Manufacturing"
  },
  {
    "code": "32799",
    "title": "All Other Nonmetallic Mineral Product Manufacturing"
  },
  {
    "code": "327991",
    "title": "Cut Stone and Stone Product Manufacturing"
  },
  {
    "code": "327992",
    "title": "Ground or Treated Mineral and Earth Manufacturing"
  },
  {
    "code": "327993",
    "title": "Mineral Wool Manufacturing"
  },
  {
    "code": "327999",
    "title": "All Other Miscellaneous Nonmetallic Mineral Product Manufacturing"
  },
  {
    "code": "331",
    "title": "Primary Metal Manufacturing"
  },
  {
    "code": "3311",
    "title": "Iron and Steel Mills and Ferroalloy Manufacturing"
  },
  {
    "code": "33111",
    "title": "Iron and Steel Mills and Ferroalloy Manufacturing"
  },
  {
    "code": "331110",
    "title": "Iron and Steel Mills and Ferroalloy Manufacturing"
  },
  {
    "code": "3312",
    "title": "Steel Product Manufacturing from Purchased Steel"
  },
  {
    "code": "33121",
    "title": "Iron and Steel Pipe and Tube Manufacturing from Purchased Steel"
  },
  {
    "code": "331210",
    "title": "Iron and Steel Pipe and Tube Manufacturing from Purchased Steel"
  },
  {
    "code": "33122",
    "title": "Rolling and Drawing of Purchased Steel"
  },
  {
    "code": "331221",
    "title": "Rolled Steel Shape Manufacturing"
  },
  {
    "code": "331222",
    "title": "Steel Wire Drawing"
  },
  {
    "code": "3313",
    "title": "Alumina and Aluminum Production and Processing"
  },
  {
    "code": "33131",
    "title": "Alumina and Aluminum Production and Processing"
  },
  {
    "code": "331313",
    "title": "Alumina Refining and Primary Aluminum Production"
  },
  {
    "code": "331314",
    "title": "Secondary Smelting and Alloying of Aluminum"
  },
  {
    "code": "331315",
    "title": "Aluminum Sheet, Plate, and Foil Manufacturing"
  },
  {
    "code": "331318",
    "title": "Other Aluminum Rolling, Drawing, and Extruding"
  },
  {
    "code": "3314",
    "title": "Nonferrous Metal (except Aluminum) Production and Processing"
  },
  {
    "code": "33141",
    "title": "Nonferrous Metal (except Aluminum) Smelting and Refining"
  },
  {
    "code": "331410",
    "title": "Nonferrous Metal (except Aluminum) Smelting and Refining"
  },
  {
    "code": "33142",
    "title": "Copper Rolling, Drawing, Extruding, and Alloying"
  },
  {
    "code": "331420",
    "title": "Copper Rolling, Drawing, Extruding, and Alloying"
  },
  {
    "code": "33149",
    "title": "Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and Alloying"
  },
  {
    "code": "331491",
    "title": "Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding"
  },
  {
    "code": "331492",
    "title": "Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)"
  },
  {
    "code": "3315",
    "title": "Foundries"
  },
  {
    "code": "33151",
    "title": "Ferrous Metal Foundries"
  },
  {
    "code": "331511",
    "title": "Iron Foundries"
  },
  {
    "code": "331512",
    "title": "Steel Investment Foundries"
  },
  {
    "code": "331513",
    "title": "Steel Foundries (except Investment)"
  },
  {
    "code": "33152",
    "title": "Nonferrous Metal Foundries"
  },
  {
    "code": "331523",
    "title": "Nonferrous Metal Die-Casting Foundries"
  },
  {
    "code": "331524",
    "title": "Aluminum Foundries (except Die-Casting)"
  },
  {
    "code": "331529",
    "title": "Other Nonferrous Metal Foundries (except Die-Casting)"
  },
  {
    "code": "332",
    "title": "Fabricated Metal Product Manufacturing"
  },
  {
    "code": "3321",
    "title": "Forging and Stamping"
  },
  {
    "code": "33211",
    "title": "Forging and Stamping"
  },
  {
    "code": "332111",
    "title": "Iron and Steel Forging"
  },
  {
    "code": "332112",
    "title": "Nonferrous Forging"
  },
  {
    "code": "332114",
    "title": "Custom Roll Forming"
  },
  {
    "code": "332117",
    "title": "Powder Metallurgy Part Manufacturing"
  },
  {
    "code": "332119",
    "title": "Metal Crown, Closure, and Other Metal Stamping (except Automotive)"
  },
  {
    "code": "3322",
    "title": "Cutlery and Handtool Manufacturing"
  },
  {
    "code": "33221",
    "title": "Cutlery and Handtool Manufacturing"
  },
  {
    "code": "332215",
    "title": "Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing"
  },
  {
    "code": "332216",
    "title": "Saw Blade and Handtool Manufacturing"
  },
  {
    "code": "3323",
    "title": "Architectural and Structural Metals Manufacturing"
  },
  {
    "code": "33231",
    "title": "Plate Work and Fabricated Structural Product Manufacturing"
  },
  {
    "code": "332311",
    "title": "Prefabricated Metal Building and Component Manufacturing"
  },
  {
    "code": "332312",
    "title": "Fabricated Structural Metal Manufacturing"
  },
  {
    "code": "332313",
    "title": "Plate Work Manufacturing"
  },
  {
    "code": "33232",
    "title": "Ornamental and Architectural Metal Products Manufacturing"
  },
  {
    "code": "332321",
    "title": "Metal Window and Door Manufacturing"
  },
  {
    "code": "332322",
    "title": "Sheet Metal Work Manufacturing"
  },
  {
    "code": "332323",
    "title": "Ornamental and Architectural Metal Work Manufacturing"
  },
  {
    "code": "3324",
    "title": "Boiler, Tank, and Shipping Container Manufacturing"
  },
  {
    "code": "33241",
    "title": "Power Boiler and Heat Exchanger Manufacturing"
  },
  {
    "code": "332410",
    "title": "Power Boiler and Heat Exchanger Manufacturing"
  },
  {
    "code": "33242",
    "title": "Metal Tank (Heavy Gauge) Manufacturing"
  },
  {
    "code": "332420",
    "title": "Metal Tank (Heavy Gauge) Manufacturing"
  },
  {
    "code": "33243",
    "title": "Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing"
  },
  {
    "code": "332431",
    "title": "Metal Can Manufacturing"
  },
  {
    "code": "332439",
    "title": "Other Metal Container Manufacturing"
  },
  {
    "code": "3325",
    "title": "Hardware Manufacturing"
  },
  {
    "code": "33251",
    "title": "Hardware Manufacturing"
  },
  {
    "code": "332510",
    "title": "Hardware Manufacturing"
  },
  {
    "code": "3326",
    "title": "Spring and Wire Product Manufacturing"
  },
  {
    "code": "33261",
    "title": "Spring and Wire Product Manufacturing"
  },
  {
    "code": "332613",
    "title": "Spring Manufacturing"
  },
  {
    "code": "332618",
    "title": "Other Fabricated Wire Product Manufacturing"
  },
  {
    "code": "3327",
    "title": "Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing"
  },
  {
    "code": "33271",
    "title": "Machine Shops"
  },
  {
    "code": "332710",
    "title": "Machine Shops"
  },
  {
    "code": "33272",
    "title": "Turned Product and Screw, Nut, and Bolt Manufacturing"
  },
  {
    "code": "332721",
    "title": "Precision Turned Product Manufacturing"
  },
  {
    "code": "332722",
    "title": "Bolt, Nut, Screw, Rivet, and Washer Manufacturing"
  },
  {
    "code": "3328",
    "title": "Coating, Engraving, Heat Treating, and Allied Activities"
  },
  {
    "code": "33281",
    "title": "Coating, Engraving, Heat Treating, and Allied Activities"
  },
  {
    "code": "332811",
    "title": "Metal Heat Treating"
  },
  {
    "code": "332812",
    "title": "Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers"
  },
  {
    "code": "332813",
    "title": "Electroplating, Plating, Polishing, Anodizing, and Coloring"
  },
  {
    "code": "3329",
    "title": "Other Fabricated Metal Product Manufacturing"
  },
  {
    "code": "33291",
    "title": "Metal Valve Manufacturing"
  },
  {
    "code": "332911",
    "title": "Industrial Valve Manufacturing"
  },
  {
    "code": "332912",
    "title": "Fluid Power Valve and Hose Fitting Manufacturing"
  },
  {
    "code": "332913",
    "title": "Plumbing Fixture Fitting and Trim Manufacturing"
  },
  {
    "code": "332919",
    "title": "Other Metal Valve and Pipe Fitting Manufacturing"
  },
  {
    "code": "33299",
    "title": "All Other Fabricated Metal Product Manufacturing"
  },
  {
    "code": "332991",
    "title": "Ball and Roller Bearing Manufacturing"
  },
  {
    "code": "332992",
    "title": "Small Arms Ammunition Manufacturing"
  },
  {
    "code": "332993",
    "title": "Ammunition (except Small Arms) Manufacturing"
  },
  {
    "code": "332994",
    "title": "Small Arms, Ordnance, and Ordnance Accessories Manufacturing"
  },
  {
    "code": "332996",
    "title": "Fabricated Pipe and Pipe Fitting Manufacturing"
  },
  {
    "code": "332999",
    "title": "All Other Miscellaneous Fabricated Metal Product Manufacturing"
  },
  {
    "code": "333",
    "title": "Machinery Manufacturing"
  },
  {
    "code": "3331",
    "title": "Agriculture, Construction, and Mining Machinery Manufacturing"
  },
  {
    "code": "33311",
    "title": "Agricultural Implement Manufacturing"
  },
  {
    "code": "333111",
    "title": "Farm Machinery and Equipment Manufacturing"
  },
  {
    "code": "333112",
    "title": "Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing"
  },
  {
    "code": "33312",
    "title": "Construction Machinery Manufacturing"
  },
  {
    "code": "333120",
    "title": "Construction Machinery Manufacturing"
  },
  {
    "code": "33313",
    "title": "Mining and Oil and Gas Field Machinery Manufacturing"
  },
  {
    "code": "333131",
    "title": "Mining Machinery and Equipment Manufacturing"
  },
  {
    "code": "333132",
    "title": "Oil and Gas Field Machinery and Equipment Manufacturing"
  },
  {
    "code": "3332",
    "title": "Industrial Machinery Manufacturing"
  },
  {
    "code": "33324",
    "title": "Industrial Machinery Manufacturing"
  },
  {
    "code": "333241",
    "title": "Food Product Machinery Manufacturing"
  },
  {
    "code": "333242",
    "title": "Semiconductor Machinery Manufacturing"
  },
  {
    "code": "333243",
    "title": "Sawmill, Woodworking, and Paper Machinery Manufacturing"
  },
  {
    "code": "333244",
    "title": "Printing Machinery and Equipment Manufacturing"
  },
  {
    "code": "333249",
    "title": "Other Industrial Machinery Manufacturing"
  },
  {
    "code": "3333",
    "title": "Commercial and Service Industry Machinery Manufacturing"
  },
  {
    "code": "33331",
    "title": "Commercial and Service Industry Machinery Manufacturing"
  },
  {
    "code": "333314",
    "title": "Optical Instrument and Lens Manufacturing"
  },
  {
    "code": "333316",
    "title": "Photographic and Photocopying Equipment Manufacturing"
  },
  {
    "code": "333318",
    "title": "Other Commercial and Service Industry Machinery Manufacturing"
  },
  {
    "code": "3334",
    "title": "Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing"
  },
  {
    "code": "33341",
    "title": "Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing"
  },
  {
    "code": "333413",
    "title": "Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing"
  },
  {
    "code": "333414",
    "title": "Heating Equipment (except Warm Air Furnaces) Manufacturing"
  },
  {
    "code": "333415",
    "title": "Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing"
  },
  {
    "code": "3335",
    "title": "Metalworking Machinery Manufacturing"
  },
  {
    "code": "33351",
    "title": "Metalworking Machinery Manufacturing"
  },
  {
    "code": "333511",
    "title": "Industrial Mold Manufacturing"
  },
  {
    "code": "333514",
    "title": "Special Die and Tool, Die Set, Jig, and Fixture Manufacturing"
  },
  {
    "code": "333515",
    "title": "Cutting Tool and Machine Tool Accessory Manufacturing"
  },
  {
    "code": "333517",
    "title": "Machine Tool Manufacturing"
  },
  {
    "code": "333519",
    "title": "Rolling Mill and Other Metalworking Machinery Manufacturing"
  },
  {
    "code": "3336",
    "title": "Engine, Turbine, and Power Transmission Equipment Manufacturing"
  },
  {
    "code": "33361",
    "title": "Engine, Turbine, and Power Transmission Equipment Manufacturing"
  },
  {
    "code": "333611",
    "title": "Turbine and Turbine Generator Set Units Manufacturing"
  },
  {
    "code": "333612",
    "title": "Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing"
  },
  {
    "code": "333613",
    "title": "Mechanical Power Transmission Equipment Manufacturing"
  },
  {
    "code": "333618",
    "title": "Other Engine Equipment Manufacturing"
  },
  {
    "code": "3339",
    "title": "Other General Purpose Machinery Manufacturing"
  },
  {
    "code": "33391",
    "title": "Pump and Compressor Manufacturing"
  },
  {
    "code": "333912",
    "title": "Air and Gas Compressor Manufacturing"
  },
  {
    "code": "333914",
    "title": "Measuring, Dispensing, and Other Pumping Equipment Manufacturing"
  },
  {
    "code": "33392",
    "title": "Material Handling Equipment Manufacturing"
  },
  {
    "code": "333921",
    "title": "Elevator and Moving Stairway Manufacturing"
  },
  {
    "code": "333922",
    "title": "Conveyor and Conveying Equipment Manufacturing"
  },
  {
    "code": "333923",
    "title": "Overhead Traveling Crane, Hoist, and Monorail System Manufacturing"
  },
  {
    "code": "333924",
    "title": "Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing"
  },
  {
    "code": "33399",
    "title": "All Other General Purpose Machinery Manufacturing"
  },
  {
    "code": "333991",
    "title": "Power-Driven Handtool Manufacturing"
  },
  {
    "code": "333992",
    "title": "Welding and Soldering Equipment Manufacturing"
  },
  {
    "code": "333993",
    "title": "Packaging Machinery Manufacturing"
  },
  {
    "code": "333994",
    "title": "Industrial Process Furnace and Oven Manufacturing"
  },
  {
    "code": "333995",
    "title": "Fluid Power Cylinder and Actuator Manufacturing"
  },
  {
    "code": "333996",
    "title": "Fluid Power Pump and Motor Manufacturing"
  },
  {
    "code": "333997",
    "title": "Scale and Balance Manufacturing"
  },
  {
    "code": "333999",
    "title": "All Other Miscellaneous General Purpose Machinery Manufacturing"
  },
  {
    "code": "334",
    "title": "Computer and Electronic Product Manufacturing"
  },
  {
    "code": "3341",
    "title": "Computer and Peripheral Equipment Manufacturing"
  },
  {
    "code": "33411",
    "title": "Computer and Peripheral Equipment Manufacturing"
  },
  {
    "code": "334111",
    "title": "Electronic Computer Manufacturing"
  },
  {
    "code": "334112",
    "title": "Computer Storage Device Manufacturing"
  },
  {
    "code": "334118",
    "title": "Computer Terminal and Other Computer Peripheral Equipment Manufacturing"
  },
  {
    "code": "3342",
    "title": "Communications Equipment Manufacturing"
  },
  {
    "code": "33421",
    "title": "Telephone Apparatus Manufacturing"
  },
  {
    "code": "334210",
    "title": "Telephone Apparatus Manufacturing"
  },
  {
    "code": "33422",
    "title": "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing"
  },
  {
    "code": "334220",
    "title": "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing"
  },
  {
    "code": "33429",
    "title": "Other Communications Equipment Manufacturing"
  },
  {
    "code": "334290",
    "title": "Other Communications Equipment Manufacturing"
  },
  {
    "code": "3343",
    "title": "Audio and Video Equipment Manufacturing"
  },
  {
    "code": "33431",
    "title": "Audio and Video Equipment Manufacturing"
  },
  {
    "code": "334310",
    "title": "Audio and Video Equipment Manufacturing"
  },
  {
    "code": "3344",
    "title": "Semiconductor and Other Electronic Component Manufacturing"
  },
  {
    "code": "33441",
    "title": "Semiconductor and Other Electronic Component Manufacturing"
  },
  {
    "code": "334412",
    "title": "Bare Printed Circuit Board Manufacturing"
  },
  {
    "code": "334413",
    "title": "Semiconductor and Related Device Manufacturing"
  },
  {
    "code": "334416",
    "title": "Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing"
  },
  {
    "code": "334417",
    "title": "Electronic Connector Manufacturing"
  },
  {
    "code": "334418",
    "title": "Printed Circuit Assembly (Electronic Assembly) Manufacturing"
  },
  {
    "code": "334419",
    "title": "Other Electronic Component Manufacturing"
  },
  {
    "code": "3345",
    "title": "Navigational, Measuring, Electromedical, and Control Instruments Manufacturing"
  },
  {
    "code": "33451",
    "title": "Navigational, Measuring, Electromedical, and Control Instruments Manufacturing"
  },
  {
    "code": "334510",
    "title": "Electromedical and Electrotherapeutic Apparatus Manufacturing"
  },
  {
    "code": "334511",
    "title": "Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing"
  },
  {
    "code": "334512",
    "title": "Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use"
  },
  {
    "code": "334513",
    "title": "Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables"
  },
  {
    "code": "334514",
    "title": "Totalizing Fluid Meter and Counting Device Manufacturing"
  },
  {
    "code": "334515",
    "title": "Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals"
  },
  {
    "code": "334516",
    "title": "Analytical Laboratory Instrument Manufacturing"
  },
  {
    "code": "334517",
    "title": "Irradiation Apparatus Manufacturing"
  },
  {
    "code": "334519",
    "title": "Other Measuring and Controlling Device Manufacturing"
  },
  {
    "code": "3346",
    "title": "Manufacturing and Reproducing Magnetic and Optical Media"
  },
  {
    "code": "33461",
    "title": "Manufacturing and Reproducing Magnetic and Optical Media"
  },
  {
    "code": "334613",
    "title": "Blank Magnetic and Optical Recording Media Manufacturing"
  },
  {
    "code": "334614",
    "title": "Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing"
  },
  {
    "code": "335",
    "title": "Electrical Equipment, Appliance, and Component Manufacturing"
  },
  {
    "code": "3351",
    "title": "Electric Lighting Equipment Manufacturing"
  },
  {
    "code": "33511",
    "title": "Electric Lamp Bulb and Part Manufacturing"
  },
  {
    "code": "335110",
    "title": "Electric Lamp Bulb and Part Manufacturing"
  },
  {
    "code": "33512",
    "title": "Lighting Fixture Manufacturing"
  },
  {
    "code": "335121",
    "title": "Residential Electric Lighting Fixture Manufacturing"
  },
  {
    "code": "335122",
    "title": "Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing"
  },
  {
    "code": "335129",
    "title": "Other Lighting Equipment Manufacturing"
  },
  {
    "code": "3352",
    "title": "Household Appliance Manufacturing"
  },
  {
    "code": "33521",
    "title": "Small Electrical Appliance Manufacturing"
  },
  {
    "code": "335210",
    "title": "Small Electrical Appliance Manufacturing"
  },
  {
    "code": "33522",
    "title": "Major Household Appliance Manufacturing"
  },
  {
    "code": "335220",
    "title": "Major Household Appliance Manufacturing"
  },
  {
    "code": "3353",
    "title": "Electrical Equipment Manufacturing"
  },
  {
    "code": "33531",
    "title": "Electrical Equipment Manufacturing"
  },
  {
    "code": "335311",
    "title": "Power, Distribution, and Specialty Transformer Manufacturing"
  },
  {
    "code": "335312",
    "title": "Motor and Generator Manufacturing"
  },
  {
    "code": "335313",
    "title": "Switchgear and Switchboard Apparatus Manufacturing"
  },
  {
    "code": "335314",
    "title": "Relay and Industrial Control Manufacturing"
  },
  {
    "code": "3359",
    "title": "Other Electrical Equipment and Component Manufacturing"
  },
  {
    "code": "33591",
    "title": "Battery Manufacturing"
  },
  {
    "code": "335911",
    "title": "Storage Battery Manufacturing"
  },
  {
    "code": "335912",
    "title": "Primary Battery Manufacturing"
  },
  {
    "code": "33592",
    "title": "Communication and Energy Wire and Cable Manufacturing"
  },
  {
    "code": "335921",
    "title": "Fiber Optic Cable Manufacturing"
  },
  {
    "code": "335929",
    "title": "Other Communication and Energy Wire Manufacturing"
  },
  {
    "code": "33593",
    "title": "Wiring Device Manufacturing"
  },
  {
    "code": "335931",
    "title": "Current-Carrying Wiring Device Manufacturing"
  },
  {
    "code": "335932",
    "title": "Noncurrent-Carrying Wiring Device Manufacturing"
  },
  {
    "code": "33599",
    "title": "All Other Electrical Equipment and Component Manufacturing"
  },
  {
    "code": "335991",
    "title": "Carbon and Graphite Product Manufacturing"
  },
  {
    "code": "335999",
    "title": "All Other Miscellaneous Electrical Equipment and Component Manufacturing"
  },
  {
    "code": "336",
    "title": "Transportation Equipment Manufacturing"
  },
  {
    "code": "3361",
    "title": "Motor Vehicle Manufacturing"
  },
  {
    "code": "33611",
    "title": "Automobile and Light Duty Motor Vehicle Manufacturing"
  },
  {
    "code": "336111",
    "title": "Automobile Manufacturing"
  },
  {
    "code": "336112",
    "title": "Light Truck and Utility Vehicle Manufacturing"
  },
  {
    "code": "33612",
    "title": "Heavy Duty Truck Manufacturing"
  },
  {
    "code": "336120",
    "title": "Heavy Duty Truck Manufacturing"
  },
  {
    "code": "3362",
    "title": "Motor Vehicle Body and Trailer Manufacturing"
  },
  {
    "code": "33621",
    "title": "Motor Vehicle Body and Trailer Manufacturing"
  },
  {
    "code": "336211",
    "title": "Motor Vehicle Body Manufacturing"
  },
  {
    "code": "336212",
    "title": "Truck Trailer Manufacturing"
  },
  {
    "code": "336213",
    "title": "Motor Home Manufacturing"
  },
  {
    "code": "336214",
    "title": "Travel Trailer and Camper Manufacturing"
  },
  {
    "code": "3363",
    "title": "Motor Vehicle Parts Manufacturing"
  },
  {
    "code": "33631",
    "title": "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing"
  },
  {
    "code": "336310",
    "title": "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing"
  },
  {
    "code": "33632",
    "title": "Motor Vehicle Electrical and Electronic Equipment Manufacturing"
  },
  {
    "code": "336320",
    "title": "Motor Vehicle Electrical and Electronic Equipment Manufacturing"
  },
  {
    "code": "33633",
    "title": "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing"
  },
  {
    "code": "336330",
    "title": "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing"
  },
  {
    "code": "33634",
    "title": "Motor Vehicle Brake System Manufacturing"
  },
  {
    "code": "336340",
    "title": "Motor Vehicle Brake System Manufacturing"
  },
  {
    "code": "33635",
    "title": "Motor Vehicle Transmission and Power Train Parts Manufacturing"
  },
  {
    "code": "336350",
    "title": "Motor Vehicle Transmission and Power Train Parts Manufacturing"
  },
  {
    "code": "33636",
    "title": "Motor Vehicle Seating and Interior Trim Manufacturing"
  },
  {
    "code": "336360",
    "title": "Motor Vehicle Seating and Interior Trim Manufacturing"
  },
  {
    "code": "33637",
    "title": "Motor Vehicle Metal Stamping"
  },
  {
    "code": "336370",
    "title": "Motor Vehicle Metal Stamping"
  },
  {
    "code": "33639",
    "title": "Other Motor Vehicle Parts Manufacturing"
  },
  {
    "code": "336390",
    "title": "Other Motor Vehicle Parts Manufacturing"
  },
  {
    "code": "3364",
    "title": "Aerospace Product and Parts Manufacturing"
  },
  {
    "code": "33641",
    "title": "Aerospace Product and Parts Manufacturing"
  },
  {
    "code": "336411",
    "title": "Aircraft Manufacturing"
  },
  {
    "code": "336412",
    "title": "Aircraft Engine and Engine Parts Manufacturing"
  },
  {
    "code": "336413",
    "title": "Other Aircraft Parts and Auxiliary Equipment Manufacturing"
  },
  {
    "code": "336414",
    "title": "Guided Missile and Space Vehicle Manufacturing"
  },
  {
    "code": "336415",
    "title": "Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing"
  },
  {
    "code": "336419",
    "title": "Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing"
  },
  {
    "code": "3365",
    "title": "Railroad Rolling Stock Manufacturing"
  },
  {
    "code": "33651",
    "title": "Railroad Rolling Stock Manufacturing"
  },
  {
    "code": "336510",
    "title": "Railroad Rolling Stock Manufacturing"
  },
  {
    "code": "3366",
    "title": "Ship and Boat Building"
  },
  {
    "code": "33661",
    "title": "Ship and Boat Building"
  },
  {
    "code": "336611",
    "title": "Ship Building and Repairing"
  },
  {
    "code": "336612",
    "title": "Boat Building"
  },
  {
    "code": "3369",
    "title": "Other Transportation Equipment Manufacturing"
  },
  {
    "code": "33699",
    "title": "Other Transportation Equipment Manufacturing"
  },
  {
    "code": "336991",
    "title": "Motorcycle, Bicycle, and Parts Manufacturing"
  },
  {
    "code": "336992",
    "title": "Military Armored Vehicle, Tank, and Tank Component Manufacturing"
  },
  {
    "code": "336999",
    "title": "All Other Transportation Equipment Manufacturing"
  },
  {
    "code": "337",
    "title": "Furniture and Related Product Manufacturing"
  },
  {
    "code": "3371",
    "title": "Household and Institutional Furniture and Kitchen Cabinet Manufacturing"
  },
  {
    "code": "33711",
    "title": "Wood Kitchen Cabinet and Countertop Manufacturing"
  },
  {
    "code": "337110",
    "title": "Wood Kitchen Cabinet and Countertop Manufacturing"
  },
  {
    "code": "33712",
    "title": "Household and Institutional Furniture Manufacturing"
  },
  {
    "code": "337121",
    "title": "Upholstered Household Furniture Manufacturing"
  },
  {
    "code": "337122",
    "title": "Nonupholstered Wood Household Furniture Manufacturing"
  },
  {
    "code": "337124",
    "title": "Metal Household Furniture Manufacturing"
  },
  {
    "code": "337125",
    "title": "Household Furniture (except Wood and Metal) Manufacturing"
  },
  {
    "code": "337127",
    "title": "Institutional Furniture Manufacturing"
  },
  {
    "code": "3372",
    "title": "Office Furniture (including Fixtures) Manufacturing"
  },
  {
    "code": "33721",
    "title": "Office Furniture (including Fixtures) Manufacturing"
  },
  {
    "code": "337211",
    "title": "Wood Office Furniture Manufacturing"
  },
  {
    "code": "337212",
    "title": "Custom Architectural Woodwork and Millwork Manufacturing"
  },
  {
    "code": "337214",
    "title": "Office Furniture (except Wood) Manufacturing"
  },
  {
    "code": "337215",
    "title": "Showcase, Partition, Shelving, and Locker Manufacturing"
  },
  {
    "code": "3379",
    "title": "Other Furniture Related Product Manufacturing"
  },
  {
    "code": "33791",
    "title": "Mattress Manufacturing"
  },
  {
    "code": "337910",
    "title": "Mattress Manufacturing"
  },
  {
    "code": "33792",
    "title": "Blind and Shade Manufacturing"
  },
  {
    "code": "337920",
    "title": "Blind and Shade Manufacturing"
  },
  {
    "code": "339",
    "title": "Miscellaneous Manufacturing"
  },
  {
    "code": "3391",
    "title": "Medical Equipment and Supplies Manufacturing"
  },
  {
    "code": "33911",
    "title": "Medical Equipment and Supplies Manufacturing"
  },
  {
    "code": "339112",
    "title": "Surgical and Medical Instrument Manufacturing"
  },
  {
    "code": "339113",
    "title": "Surgical Appliance and Supplies Manufacturing"
  },
  {
    "code": "339114",
    "title": "Dental Equipment and Supplies Manufacturing"
  },
  {
    "code": "339115",
    "title": "Ophthalmic Goods Manufacturing"
  },
  {
    "code": "339116",
    "title": "Dental Laboratories"
  },
  {
    "code": "3399",
    "title": "Other Miscellaneous Manufacturing"
  },
  {
    "code": "33991",
    "title": "Jewelry and Silverware Manufacturing"
  },
  {
    "code": "339910",
    "title": "Jewelry and Silverware Manufacturing"
  },
  {
    "code": "33992",
    "title": "Sporting and Athletic Goods Manufacturing"
  },
  {
    "code": "339920",
    "title": "Sporting and Athletic Goods Manufacturing"
  },
  {
    "code": "33993",
    "title": "Doll, Toy, and Game Manufacturing"
  },
  {
    "code": "339930",
    "title": "Doll, Toy, and Game Manufacturing"
  },
  {
    "code": "33994",
    "title": "Office Supplies (except Paper) Manufacturing"
  },
  {
    "code": "339940",
    "title": "Office Supplies (except Paper) Manufacturing"
  },
  {
    "code": "33995",
    "title": "Sign Manufacturing"
  },
  {
    "code": "339950",
    "title": "Sign Manufacturing"
  },
  {
    "code": "33999",
    "title": "All Other Miscellaneous Manufacturing"
  },
  {
    "code": "339991",
    "title": "Gasket, Packing, and Sealing Device Manufacturing"
  },
  {
    "code": "339992",
    "title": "Musical Instrument Manufacturing"
  },
  {
    "code": "339993",
    "title": "Fastener, Button, Needle, and Pin Manufacturing"
  },
  {
    "code": "339994",
    "title": "Broom, Brush, and Mop Manufacturing"
  },
  {
    "code": "339995",
    "title": "Burial Casket Manufacturing"
  },
  {
    "code": "339999",
    "title": "All Other Miscellaneous Manufacturing"
  },
  {
    "code": "42",
    "title": "Wholesale Trade"
  },
  {
    "code": "423",
    "title": "Merchant Wholesalers, Durable Goods"
  },
  {
    "code": "4231",
    "title": "Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers"
  },
  {
    "code": "42311",
    "title": "Automobile and Other Motor Vehicle Merchant Wholesalers"
  },
  {
    "code": "423110",
    "title": "Automobile and Other Motor Vehicle Merchant Wholesalers"
  },
  {
    "code": "42312",
    "title": "Motor Vehicle Supplies and New Parts Merchant Wholesalers"
  },
  {
    "code": "423120",
    "title": "Motor Vehicle Supplies and New Parts Merchant Wholesalers"
  },
  {
    "code": "42313",
    "title": "Tire and Tube Merchant Wholesalers"
  },
  {
    "code": "423130",
    "title": "Tire and Tube Merchant Wholesalers"
  },
  {
    "code": "42314",
    "title": "Motor Vehicle Parts (Used) Merchant Wholesalers"
  },
  {
    "code": "423140",
    "title": "Motor Vehicle Parts (Used) Merchant Wholesalers"
  },
  {
    "code": "4232",
    "title": "Furniture and Home Furnishing Merchant Wholesalers"
  },
  {
    "code": "42321",
    "title": "Furniture Merchant Wholesalers"
  },
  {
    "code": "423210",
    "title": "Furniture Merchant Wholesalers"
  },
  {
    "code": "42322",
    "title": "Home Furnishing Merchant Wholesalers"
  },
  {
    "code": "423220",
    "title": "Home Furnishing Merchant Wholesalers"
  },
  {
    "code": "4233",
    "title": "Lumber and Other Construction Materials Merchant Wholesalers"
  },
  {
    "code": "42331",
    "title": "Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers"
  },
  {
    "code": "423310",
    "title": "Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers"
  },
  {
    "code": "42332",
    "title": "Brick, Stone, and Related Construction Material Merchant Wholesalers"
  },
  {
    "code": "423320",
    "title": "Brick, Stone, and Related Construction Material Merchant Wholesalers"
  },
  {
    "code": "42333",
    "title": "Roofing, Siding, and Insulation Material Merchant Wholesalers"
  },
  {
    "code": "423330",
    "title": "Roofing, Siding, and Insulation Material Merchant Wholesalers"
  },
  {
    "code": "42339",
    "title": "Other Construction Material Merchant Wholesalers"
  },
  {
    "code": "423390",
    "title": "Other Construction Material Merchant Wholesalers"
  },
  {
    "code": "4234",
    "title": "Professional and Commercial Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "42341",
    "title": "Photographic Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "423410",
    "title": "Photographic Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "42342",
    "title": "Office Equipment Merchant Wholesalers"
  },
  {
    "code": "423420",
    "title": "Office Equipment Merchant Wholesalers"
  },
  {
    "code": "42343",
    "title": "Computer and Computer Peripheral Equipment and Software Merchant Wholesalers"
  },
  {
    "code": "423430",
    "title": "Computer and Computer Peripheral Equipment and Software Merchant Wholesalers"
  },
  {
    "code": "42344",
    "title": "Other Commercial Equipment Merchant Wholesalers"
  },
  {
    "code": "423440",
    "title": "Other Commercial Equipment Merchant Wholesalers"
  },
  {
    "code": "42345",
    "title": "Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "423450",
    "title": "Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "42346",
    "title": "Ophthalmic Goods Merchant Wholesalers"
  },
  {
    "code": "423460",
    "title": "Ophthalmic Goods Merchant Wholesalers"
  },
  {
    "code": "42349",
    "title": "Other Professional Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "423490",
    "title": "Other Professional Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "4235",
    "title": "Metal and Mineral (except Petroleum) Merchant Wholesalers"
  },
  {
    "code": "42351",
    "title": "Metal Service Centers and Other Metal Merchant Wholesalers"
  },
  {
    "code": "423510",
    "title": "Metal Service Centers and Other Metal Merchant Wholesalers"
  },
  {
    "code": "42352",
    "title": "Coal and Other Mineral and Ore Merchant Wholesalers"
  },
  {
    "code": "423520",
    "title": "Coal and Other Mineral and Ore Merchant Wholesalers"
  },
  {
    "code": "4236",
    "title": "Household Appliances and Electrical and Electronic Goods Merchant Wholesalers"
  },
  {
    "code": "42361",
    "title": "Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers"
  },
  {
    "code": "423610",
    "title": "Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers"
  },
  {
    "code": "42362",
    "title": "Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers"
  },
  {
    "code": "423620",
    "title": "Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers"
  },
  {
    "code": "42369",
    "title": "Other Electronic Parts and Equipment Merchant Wholesalers"
  },
  {
    "code": "423690",
    "title": "Other Electronic Parts and Equipment Merchant Wholesalers"
  },
  {
    "code": "4237",
    "title": "Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "42371",
    "title": "Hardware Merchant Wholesalers"
  },
  {
    "code": "423710",
    "title": "Hardware Merchant Wholesalers"
  },
  {
    "code": "42372",
    "title": "Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers"
  },
  {
    "code": "423720",
    "title": "Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers"
  },
  {
    "code": "42373",
    "title": "Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "423730",
    "title": "Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "42374",
    "title": "Refrigeration Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "423740",
    "title": "Refrigeration Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "4238",
    "title": "Machinery, Equipment, and Supplies Merchant Wholesalers"
  },
  {
    "code": "42381",
    "title": "Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers"
  },
  {
    "code": "423810",
    "title": "Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers"
  },
  {
    "code": "42382",
    "title": "Farm and Garden Machinery and Equipment Merchant Wholesalers"
  },
  {
    "code": "423820",
    "title": "Farm and Garden Machinery and Equipment Merchant Wholesalers"
  },
  {
    "code": "42383",
    "title": "Industrial Machinery and Equipment Merchant Wholesalers"
  },
  {
    "code": "423830",
    "title": "Industrial Machinery and Equipment Merchant Wholesalers"
  },
  {
    "code": "42384",
    "title": "Industrial Supplies Merchant Wholesalers"
  },
  {
    "code": "423840",
    "title": "Industrial Supplies Merchant Wholesalers"
  },
  {
    "code": "42385",
    "title": "Service Establishment Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "423850",
    "title": "Service Establishment Equipment and Supplies Merchant Wholesalers"
  },
  {
    "code": "42386",
    "title": "Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers"
  },
  {
    "code": "423860",
    "title": "Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers"
  },
  {
    "code": "4239",
    "title": "Miscellaneous Durable Goods Merchant Wholesalers"
  },
  {
    "code": "42391",
    "title": "Sporting and Recreational Goods and Supplies Merchant Wholesalers"
  },
  {
    "code": "423910",
    "title": "Sporting and Recreational Goods and Supplies Merchant Wholesalers"
  },
  {
    "code": "42392",
    "title": "Toy and Hobby Goods and Supplies Merchant Wholesalers"
  },
  {
    "code": "423920",
    "title": "Toy and Hobby Goods and Supplies Merchant Wholesalers"
  },
  {
    "code": "42393",
    "title": "Recyclable Material Merchant Wholesalers"
  },
  {
    "code": "423930",
    "title": "Recyclable Material Merchant Wholesalers"
  },
  {
    "code": "42394",
    "title": "Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers"
  },
  {
    "code": "423940",
    "title": "Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers"
  },
  {
    "code": "42399",
    "title": "Other Miscellaneous Durable Goods Merchant Wholesalers"
  },
  {
    "code": "423990",
    "title": "Other Miscellaneous Durable Goods Merchant Wholesalers"
  },
  {
    "code": "424",
    "title": "Merchant Wholesalers, Nondurable Goods"
  },
  {
    "code": "4241",
    "title": "Paper and Paper Product Merchant Wholesalers"
  },
  {
    "code": "42411",
    "title": "Printing and Writing Paper Merchant Wholesalers"
  },
  {
    "code": "424110",
    "title": "Printing and Writing Paper Merchant Wholesalers"
  },
  {
    "code": "42412",
    "title": "Stationery and Office Supplies Merchant Wholesalers"
  },
  {
    "code": "424120",
    "title": "Stationery and Office Supplies Merchant Wholesalers"
  },
  {
    "code": "42413",
    "title": "Industrial and Personal Service Paper Merchant Wholesalers"
  },
  {
    "code": "424130",
    "title": "Industrial and Personal Service Paper Merchant Wholesalers"
  },
  {
    "code": "4242",
    "title": "Drugs and Druggists' Sundries Merchant Wholesalers"
  },
  {
    "code": "42421",
    "title": "Drugs and Druggists' Sundries Merchant Wholesalers"
  },
  {
    "code": "424210",
    "title": "Drugs and Druggists' Sundries Merchant Wholesalers"
  },
  {
    "code": "4243",
    "title": "Apparel, Piece Goods, and Notions Merchant Wholesalers"
  },
  {
    "code": "42431",
    "title": "Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers"
  },
  {
    "code": "424310",
    "title": "Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers"
  },
  {
    "code": "42432",
    "title": "Men's and Boys' Clothing and Furnishings Merchant Wholesalers"
  },
  {
    "code": "424320",
    "title": "Men's and Boys' Clothing and Furnishings Merchant Wholesalers"
  },
  {
    "code": "42433",
    "title": "Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers"
  },
  {
    "code": "424330",
    "title": "Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers"
  },
  {
    "code": "42434",
    "title": "Footwear Merchant Wholesalers"
  },
  {
    "code": "424340",
    "title": "Footwear Merchant Wholesalers"
  },
  {
    "code": "4244",
    "title": "Grocery and Related Product Merchant Wholesalers"
  },
  {
    "code": "42441",
    "title": "General Line Grocery Merchant Wholesalers"
  },
  {
    "code": "424410",
    "title": "General Line Grocery Merchant Wholesalers"
  },
  {
    "code": "42442",
    "title": "Packaged Frozen Food Merchant Wholesalers"
  },
  {
    "code": "424420",
    "title": "Packaged Frozen Food Merchant Wholesalers"
  },
  {
    "code": "42443",
    "title": "Dairy Product (except Dried or Canned) Merchant Wholesalers"
  },
  {
    "code": "424430",
    "title": "Dairy Product (except Dried or Canned) Merchant Wholesalers"
  },
  {
    "code": "42444",
    "title": "Poultry and Poultry Product Merchant Wholesalers"
  },
  {
    "code": "424440",
    "title": "Poultry and Poultry Product Merchant Wholesalers"
  },
  {
    "code": "42445",
    "title": "Confectionery Merchant Wholesalers"
  },
  {
    "code": "424450",
    "title": "Confectionery Merchant Wholesalers"
  },
  {
    "code": "42446",
    "title": "Fish and Seafood Merchant Wholesalers"
  },
  {
    "code": "424460",
    "title": "Fish and Seafood Merchant Wholesalers"
  },
  {
    "code": "42447",
    "title": "Meat and Meat Product Merchant Wholesalers"
  },
  {
    "code": "424470",
    "title": "Meat and Meat Product Merchant Wholesalers"
  },
  {
    "code": "42448",
    "title": "Fresh Fruit and Vegetable Merchant Wholesalers"
  },
  {
    "code": "424480",
    "title": "Fresh Fruit and Vegetable Merchant Wholesalers"
  },
  {
    "code": "42449",
    "title": "Other Grocery and Related Products Merchant Wholesalers"
  },
  {
    "code": "424490",
    "title": "Other Grocery and Related Products Merchant Wholesalers"
  },
  {
    "code": "4245",
    "title": "Farm Product Raw Material Merchant Wholesalers"
  },
  {
    "code": "42451",
    "title": "Grain and Field Bean Merchant Wholesalers"
  },
  {
    "code": "424510",
    "title": "Grain and Field Bean Merchant Wholesalers"
  },
  {
    "code": "42452",
    "title": "Livestock Merchant Wholesalers"
  },
  {
    "code": "424520",
    "title": "Livestock Merchant Wholesalers"
  },
  {
    "code": "42459",
    "title": "Other Farm Product Raw Material Merchant Wholesalers"
  },
  {
    "code": "424590",
    "title": "Other Farm Product Raw Material Merchant Wholesalers"
  },
  {
    "code": "4246",
    "title": "Chemical and Allied Products Merchant Wholesalers"
  },
  {
    "code": "42461",
    "title": "Plastics Materials and Basic Forms and Shapes Merchant Wholesalers"
  },
  {
    "code": "424610",
    "title": "Plastics Materials and Basic Forms and Shapes Merchant Wholesalers"
  },
  {
    "code": "42469",
    "title": "Other Chemical and Allied Products Merchant Wholesalers"
  },
  {
    "code": "424690",
    "title": "Other Chemical and Allied Products Merchant Wholesalers"
  },
  {
    "code": "4247",
    "title": "Petroleum and Petroleum Products Merchant Wholesalers"
  },
  {
    "code": "42471",
    "title": "Petroleum Bulk Stations and Terminals"
  },
  {
    "code": "424710",
    "title": "Petroleum Bulk Stations and Terminals"
  },
  {
    "code": "42472",
    "title": "Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)"
  },
  {
    "code": "424720",
    "title": "Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)"
  },
  {
    "code": "4248",
    "title": "Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers"
  },
  {
    "code": "42481",
    "title": "Beer and Ale Merchant Wholesalers"
  },
  {
    "code": "424810",
    "title": "Beer and Ale Merchant Wholesalers"
  },
  {
    "code": "42482",
    "title": "Wine and Distilled Alcoholic Beverage Merchant Wholesalers"
  },
  {
    "code": "424820",
    "title": "Wine and Distilled Alcoholic Beverage Merchant Wholesalers"
  },
  {
    "code": "4249",
    "title": "Miscellaneous Nondurable Goods Merchant Wholesalers"
  },
  {
    "code": "42491",
    "title": "Farm Supplies Merchant Wholesalers"
  },
  {
    "code": "424910",
    "title": "Farm Supplies Merchant Wholesalers"
  },
  {
    "code": "42492",
    "title": "Book, Periodical, and Newspaper Merchant Wholesalers"
  },
  {
    "code": "424920",
    "title": "Book, Periodical, and Newspaper Merchant Wholesalers"
  },
  {
    "code": "42493",
    "title": "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"
  },
  {
    "code": "424930",
    "title": "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"
  },
  {
    "code": "42494",
    "title": "Tobacco and Tobacco Product Merchant Wholesalers"
  },
  {
    "code": "424940",
    "title": "Tobacco and Tobacco Product Merchant Wholesalers"
  },
  {
    "code": "42495",
    "title": "Paint, Varnish, and Supplies Merchant Wholesalers"
  },
  {
    "code": "424950",
    "title": "Paint, Varnish, and Supplies Merchant Wholesalers"
  },
  {
    "code": "42499",
    "title": "Other Miscellaneous Nondurable Goods Merchant Wholesalers"
  },
  {
    "code": "424990",
    "title": "Other Miscellaneous Nondurable Goods Merchant Wholesalers"
  },
  {
    "code": "425",
    "title": "Wholesale Electronic Markets and Agents and Brokers"
  },
  {
    "code": "4251",
    "title": "Wholesale Electronic Markets and Agents and Brokers"
  },
  {
    "code": "42511",
    "title": "Business to Business Electronic Markets"
  },
  {
    "code": "425110",
    "title": "Business to Business Electronic Markets"
  },
  {
    "code": "42512",
    "title": "Wholesale Trade Agents and Brokers"
  },
  {
    "code": "425120",
    "title": "Wholesale Trade Agents and Brokers"
  },
  {
    "code": "44-45",
    "title": "Retail Trade"
  },
  {
    "code": "441",
    "title": "Motor Vehicle and Parts Dealers"
  },
  {
    "code": "4411",
    "title": "Automobile Dealers"
  },
  {
    "code": "44111",
    "title": "New Car Dealers"
  },
  {
    "code": "441110",
    "title": "New Car Dealers"
  },
  {
    "code": "44112",
    "title": "Used Car Dealers"
  },
  {
    "code": "441120",
    "title": "Used Car Dealers"
  },
  {
    "code": "4412",
    "title": "Other Motor Vehicle Dealers"
  },
  {
    "code": "44121",
    "title": "Recreational Vehicle Dealers"
  },
  {
    "code": "441210",
    "title": "Recreational Vehicle Dealers"
  },
  {
    "code": "44122",
    "title": "Motorcycle, Boat, and Other Motor Vehicle Dealers"
  },
  {
    "code": "441222",
    "title": "Boat Dealers"
  },
  {
    "code": "441228",
    "title": "Motorcycle, ATV, and All Other Motor Vehicle Dealers"
  },
  {
    "code": "4413",
    "title": "Automotive Parts, Accessories, and Tire Stores"
  },
  {
    "code": "44131",
    "title": "Automotive Parts and Accessories Stores"
  },
  {
    "code": "441310",
    "title": "Automotive Parts and Accessories Stores"
  },
  {
    "code": "44132",
    "title": "Tire Dealers"
  },
  {
    "code": "441320",
    "title": "Tire Dealers"
  },
  {
    "code": "442",
    "title": "Furniture and Home Furnishings Stores"
  },
  {
    "code": "4421",
    "title": "Furniture Stores"
  },
  {
    "code": "44211",
    "title": "Furniture Stores"
  },
  {
    "code": "442110",
    "title": "Furniture Stores"
  },
  {
    "code": "4422",
    "title": "Home Furnishings Stores"
  },
  {
    "code": "44221",
    "title": "Floor Covering Stores"
  },
  {
    "code": "442210",
    "title": "Floor Covering Stores"
  },
  {
    "code": "44229",
    "title": "Other Home Furnishings Stores"
  },
  {
    "code": "442291",
    "title": "Window Treatment Stores"
  },
  {
    "code": "442299",
    "title": "All Other Home Furnishings Stores"
  },
  {
    "code": "443",
    "title": "Electronics and Appliance Stores"
  },
  {
    "code": "4431",
    "title": "Electronics and Appliance Stores"
  },
  {
    "code": "44314",
    "title": "Electronics and Appliance Stores"
  },
  {
    "code": "443141",
    "title": "Household Appliance Stores"
  },
  {
    "code": "443142",
    "title": "Electronics Stores"
  },
  {
    "code": "444",
    "title": "Building Material and Garden Equipment and Supplies Dealers"
  },
  {
    "code": "4441",
    "title": "Building Material and Supplies Dealers"
  },
  {
    "code": "44411",
    "title": "Home Centers"
  },
  {
    "code": "444110",
    "title": "Home Centers"
  },
  {
    "code": "44412",
    "title": "Paint and Wallpaper Stores"
  },
  {
    "code": "444120",
    "title": "Paint and Wallpaper Stores"
  },
  {
    "code": "44413",
    "title": "Hardware Stores"
  },
  {
    "code": "444130",
    "title": "Hardware Stores"
  },
  {
    "code": "44419",
    "title": "Other Building Material Dealers"
  },
  {
    "code": "444190",
    "title": "Other Building Material Dealers"
  },
  {
    "code": "4442",
    "title": "Lawn and Garden Equipment and Supplies Stores"
  },
  {
    "code": "44421",
    "title": "Outdoor Power Equipment Stores"
  },
  {
    "code": "444210",
    "title": "Outdoor Power Equipment Stores"
  },
  {
    "code": "44422",
    "title": "Nursery, Garden Center, and Farm Supply Stores"
  },
  {
    "code": "444220",
    "title": "Nursery, Garden Center, and Farm Supply Stores"
  },
  {
    "code": "445",
    "title": "Food and Beverage Stores"
  },
  {
    "code": "4451",
    "title": "Grocery Stores"
  },
  {
    "code": "44511",
    "title": "Supermarkets and Other Grocery (except Convenience) Stores"
  },
  {
    "code": "445110",
    "title": "Supermarkets and Other Grocery (except Convenience) Stores"
  },
  {
    "code": "44512",
    "title": "Convenience Stores"
  },
  {
    "code": "445120",
    "title": "Convenience Stores"
  },
  {
    "code": "4452",
    "title": "Specialty Food Stores"
  },
  {
    "code": "44521",
    "title": "Meat Markets"
  },
  {
    "code": "445210",
    "title": "Meat Markets"
  },
  {
    "code": "44522",
    "title": "Fish and Seafood Markets"
  },
  {
    "code": "445220",
    "title": "Fish and Seafood Markets"
  },
  {
    "code": "44523",
    "title": "Fruit and Vegetable Markets"
  },
  {
    "code": "445230",
    "title": "Fruit and Vegetable Markets"
  },
  {
    "code": "44529",
    "title": "Other Specialty Food Stores"
  },
  {
    "code": "445291",
    "title": "Baked Goods Stores"
  },
  {
    "code": "445292",
    "title": "Confectionery and Nut Stores"
  },
  {
    "code": "445299",
    "title": "All Other Specialty Food Stores"
  },
  {
    "code": "4453",
    "title": "Beer, Wine, and Liquor Stores"
  },
  {
    "code": "44531",
    "title": "Beer, Wine, and Liquor Stores"
  },
  {
    "code": "445310",
    "title": "Beer, Wine, and Liquor Stores"
  },
  {
    "code": "446",
    "title": "Health and Personal Care Stores"
  },
  {
    "code": "4461",
    "title": "Health and Personal Care Stores"
  },
  {
    "code": "44611",
    "title": "Pharmacies and Drug Stores"
  },
  {
    "code": "446110",
    "title": "Pharmacies and Drug Stores"
  },
  {
    "code": "44612",
    "title": "Cosmetics, Beauty Supplies, and Perfume Stores"
  },
  {
    "code": "446120",
    "title": "Cosmetics, Beauty Supplies, and Perfume Stores"
  },
  {
    "code": "44613",
    "title": "Optical Goods Stores"
  },
  {
    "code": "446130",
    "title": "Optical Goods Stores"
  },
  {
    "code": "44619",
    "title": "Other Health and Personal Care Stores"
  },
  {
    "code": "446191",
    "title": "Food (Health) Supplement Stores"
  },
  {
    "code": "446199",
    "title": "All Other Health and Personal Care Stores"
  },
  {
    "code": "447",
    "title": "Gasoline Stations"
  },
  {
    "code": "4471",
    "title": "Gasoline Stations"
  },
  {
    "code": "44711",
    "title": "Gasoline Stations with Convenience Stores"
  },
  {
    "code": "447110",
    "title": "Gasoline Stations with Convenience Stores"
  },
  {
    "code": "44719",
    "title": "Other Gasoline Stations"
  },
  {
    "code": "447190",
    "title": "Other Gasoline Stations"
  },
  {
    "code": "448",
    "title": "Clothing and Clothing Accessories Stores"
  },
  {
    "code": "4481",
    "title": "Clothing Stores"
  },
  {
    "code": "44811",
    "title": "Men's Clothing Stores"
  },
  {
    "code": "448110",
    "title": "Men's Clothing Stores"
  },
  {
    "code": "44812",
    "title": "Women's Clothing Stores"
  },
  {
    "code": "448120",
    "title": "Women's Clothing Stores"
  },
  {
    "code": "44813",
    "title": "Children's and Infants' Clothing Stores"
  },
  {
    "code": "448130",
    "title": "Children's and Infants' Clothing Stores"
  },
  {
    "code": "44814",
    "title": "Family Clothing Stores"
  },
  {
    "code": "448140",
    "title": "Family Clothing Stores"
  },
  {
    "code": "44815",
    "title": "Clothing Accessories Stores"
  },
  {
    "code": "448150",
    "title": "Clothing Accessories Stores"
  },
  {
    "code": "44819",
    "title": "Other Clothing Stores"
  },
  {
    "code": "448190",
    "title": "Other Clothing Stores"
  },
  {
    "code": "4482",
    "title": "Shoe Stores"
  },
  {
    "code": "44821",
    "title": "Shoe Stores"
  },
  {
    "code": "448210",
    "title": "Shoe Stores"
  },
  {
    "code": "4483",
    "title": "Jewelry, Luggage, and Leather Goods Stores"
  },
  {
    "code": "44831",
    "title": "Jewelry Stores"
  },
  {
    "code": "448310",
    "title": "Jewelry Stores"
  },
  {
    "code": "44832",
    "title": "Luggage and Leather Goods Stores"
  },
  {
    "code": "448320",
    "title": "Luggage and Leather Goods Stores"
  },
  {
    "code": "451",
    "title": "Sporting Goods, Hobby, Musical Instrument, and Book Stores"
  },
  {
    "code": "4511",
    "title": "Sporting Goods, Hobby, and Musical Instrument Stores"
  },
  {
    "code": "45111",
    "title": "Sporting Goods Stores"
  },
  {
    "code": "451110",
    "title": "Sporting Goods Stores"
  },
  {
    "code": "45112",
    "title": "Hobby, Toy, and Game Stores"
  },
  {
    "code": "451120",
    "title": "Hobby, Toy, and Game Stores"
  },
  {
    "code": "45113",
    "title": "Sewing, Needlework, and Piece Goods Stores"
  },
  {
    "code": "451130",
    "title": "Sewing, Needlework, and Piece Goods Stores"
  },
  {
    "code": "45114",
    "title": "Musical Instrument and Supplies Stores"
  },
  {
    "code": "451140",
    "title": "Musical Instrument and Supplies Stores"
  },
  {
    "code": "4512",
    "title": "Book Stores and News Dealers"
  },
  {
    "code": "45121",
    "title": "Book Stores and News Dealers"
  },
  {
    "code": "451211",
    "title": "Book Stores"
  },
  {
    "code": "451212",
    "title": "News Dealers and Newsstands"
  },
  {
    "code": "452",
    "title": "General Merchandise Stores"
  },
  {
    "code": "4522",
    "title": "Department Stores"
  },
  {
    "code": "45221",
    "title": "Department Stores"
  },
  {
    "code": "452210",
    "title": "Department Stores"
  },
  {
    "code": "4523",
    "title": "General Merchandise Stores, including Warehouse Clubs and Supercenters"
  },
  {
    "code": "45231",
    "title": "General Merchandise Stores, including Warehouse Clubs and Supercenters"
  },
  {
    "code": "452311",
    "title": "Warehouse Clubs and Supercenters"
  },
  {
    "code": "452319",
    "title": "All Other General Merchandise Stores"
  },
  {
    "code": "453",
    "title": "Miscellaneous Store Retailers"
  },
  {
    "code": "4531",
    "title": "Florists"
  },
  {
    "code": "45311",
    "title": "Florists"
  },
  {
    "code": "453110",
    "title": "Florists"
  },
  {
    "code": "4532",
    "title": "Office Supplies, Stationery, and Gift Stores"
  },
  {
    "code": "45321",
    "title": "Office Supplies and Stationery Stores"
  },
  {
    "code": "453210",
    "title": "Office Supplies and Stationery Stores"
  },
  {
    "code": "45322",
    "title": "Gift, Novelty, and Souvenir Stores"
  },
  {
    "code": "453220",
    "title": "Gift, Novelty, and Souvenir Stores"
  },
  {
    "code": "4533",
    "title": "Used Merchandise Stores"
  },
  {
    "code": "45331",
    "title": "Used Merchandise Stores"
  },
  {
    "code": "453310",
    "title": "Used Merchandise Stores"
  },
  {
    "code": "4539",
    "title": "Other Miscellaneous Store Retailers"
  },
  {
    "code": "45391",
    "title": "Pet and Pet Supplies Stores"
  },
  {
    "code": "453910",
    "title": "Pet and Pet Supplies Stores"
  },
  {
    "code": "45392",
    "title": "Art Dealers"
  },
  {
    "code": "453920",
    "title": "Art Dealers"
  },
  {
    "code": "45393",
    "title": "Manufactured (Mobile) Home Dealers"
  },
  {
    "code": "453930",
    "title": "Manufactured (Mobile) Home Dealers"
  },
  {
    "code": "45399",
    "title": "All Other Miscellaneous Store Retailers"
  },
  {
    "code": "453991",
    "title": "Tobacco Stores"
  },
  {
    "code": "453998",
    "title": "All Other Miscellaneous Store Retailers (except Tobacco Stores)"
  },
  {
    "code": "454",
    "title": "Nonstore Retailers"
  },
  {
    "code": "4541",
    "title": "Electronic Shopping and Mail-Order Houses"
  },
  {
    "code": "45411",
    "title": "Electronic Shopping and Mail-Order Houses"
  },
  {
    "code": "454110",
    "title": "Electronic Shopping and Mail-Order Houses"
  },
  {
    "code": "4542",
    "title": "Vending Machine Operators"
  },
  {
    "code": "45421",
    "title": "Vending Machine Operators"
  },
  {
    "code": "454210",
    "title": "Vending Machine Operators"
  },
  {
    "code": "4543",
    "title": "Direct Selling Establishments"
  },
  {
    "code": "45431",
    "title": "Fuel Dealers"
  },
  {
    "code": "454310",
    "title": "Fuel Dealers"
  },
  {
    "code": "45439",
    "title": "Other Direct Selling Establishments"
  },
  {
    "code": "454390",
    "title": "Other Direct Selling Establishments"
  },
  {
    "code": "48-49",
    "title": "Transportation and Warehousing"
  },
  {
    "code": "481",
    "title": "Air Transportation"
  },
  {
    "code": "4811",
    "title": "Scheduled Air Transportation"
  },
  {
    "code": "48111",
    "title": "Scheduled Air Transportation"
  },
  {
    "code": "481111",
    "title": "Scheduled Passenger Air Transportation"
  },
  {
    "code": "481112",
    "title": "Scheduled Freight Air Transportation"
  },
  {
    "code": "4812",
    "title": "Nonscheduled Air Transportation"
  },
  {
    "code": "48121",
    "title": "Nonscheduled Air Transportation"
  },
  {
    "code": "481211",
    "title": "Nonscheduled Chartered Passenger Air Transportation"
  },
  {
    "code": "481212",
    "title": "Nonscheduled Chartered Freight Air Transportation"
  },
  {
    "code": "481219",
    "title": "Other Nonscheduled Air Transportation"
  },
  {
    "code": "482",
    "title": "Rail Transportation"
  },
  {
    "code": "4821",
    "title": "Rail Transportation"
  },
  {
    "code": "48211",
    "title": "Rail Transportation"
  },
  {
    "code": "482111",
    "title": "Line-Haul Railroads"
  },
  {
    "code": "482112",
    "title": "Short Line Railroads"
  },
  {
    "code": "483",
    "title": "Water Transportation"
  },
  {
    "code": "4831",
    "title": "Deep Sea, Coastal, and Great Lakes Water Transportation"
  },
  {
    "code": "48311",
    "title": "Deep Sea, Coastal, and Great Lakes Water Transportation"
  },
  {
    "code": "483111",
    "title": "Deep Sea Freight Transportation"
  },
  {
    "code": "483112",
    "title": "Deep Sea Passenger Transportation"
  },
  {
    "code": "483113",
    "title": "Coastal and Great Lakes Freight Transportation"
  },
  {
    "code": "483114",
    "title": "Coastal and Great Lakes Passenger Transportation"
  },
  {
    "code": "4832",
    "title": "Inland Water Transportation"
  },
  {
    "code": "48321",
    "title": "Inland Water Transportation"
  },
  {
    "code": "483211",
    "title": "Inland Water Freight Transportation"
  },
  {
    "code": "483212",
    "title": "Inland Water Passenger Transportation"
  },
  {
    "code": "484",
    "title": "Truck Transportation"
  },
  {
    "code": "4841",
    "title": "General Freight Trucking"
  },
  {
    "code": "48411",
    "title": "General Freight Trucking, Local"
  },
  {
    "code": "484110",
    "title": "General Freight Trucking, Local"
  },
  {
    "code": "48412",
    "title": "General Freight Trucking, Long-Distance"
  },
  {
    "code": "484121",
    "title": "General Freight Trucking, Long-Distance, Truckload"
  },
  {
    "code": "484122",
    "title": "General Freight Trucking, Long-Distance, Less Than Truckload"
  },
  {
    "code": "4842",
    "title": "Specialized Freight Trucking"
  },
  {
    "code": "48421",
    "title": "Used Household and Office Goods Moving"
  },
  {
    "code": "484210",
    "title": "Used Household and Office Goods Moving"
  },
  {
    "code": "48422",
    "title": "Specialized Freight (except Used Goods) Trucking, Local"
  },
  {
    "code": "484220",
    "title": "Specialized Freight (except Used Goods) Trucking, Local"
  },
  {
    "code": "48423",
    "title": "Specialized Freight (except Used Goods) Trucking, Long-Distance"
  },
  {
    "code": "484230",
    "title": "Specialized Freight (except Used Goods) Trucking, Long-Distance"
  },
  {
    "code": "485",
    "title": "Transit and Ground Passenger Transportation"
  },
  {
    "code": "4851",
    "title": "Urban Transit Systems"
  },
  {
    "code": "48511",
    "title": "Urban Transit Systems"
  },
  {
    "code": "485111",
    "title": "Mixed Mode Transit Systems"
  },
  {
    "code": "485112",
    "title": "Commuter Rail Systems"
  },
  {
    "code": "485113",
    "title": "Bus and Other Motor Vehicle Transit Systems"
  },
  {
    "code": "485119",
    "title": "Other Urban Transit Systems"
  },
  {
    "code": "4852",
    "title": "Interurban and Rural Bus Transportation"
  },
  {
    "code": "48521",
    "title": "Interurban and Rural Bus Transportation"
  },
  {
    "code": "485210",
    "title": "Interurban and Rural Bus Transportation"
  },
  {
    "code": "4853",
    "title": "Taxi and Limousine Service"
  },
  {
    "code": "48531",
    "title": "Taxi Service"
  },
  {
    "code": "485310",
    "title": "Taxi Service"
  },
  {
    "code": "48532",
    "title": "Limousine Service"
  },
  {
    "code": "485320",
    "title": "Limousine Service"
  },
  {
    "code": "4854",
    "title": "School and Employee Bus Transportation"
  },
  {
    "code": "48541",
    "title": "School and Employee Bus Transportation"
  },
  {
    "code": "485410",
    "title": "School and Employee Bus Transportation"
  },
  {
    "code": "4855",
    "title": "Charter Bus Industry"
  },
  {
    "code": "48551",
    "title": "Charter Bus Industry"
  },
  {
    "code": "485510",
    "title": "Charter Bus Industry"
  },
  {
    "code": "4859",
    "title": "Other Transit and Ground Passenger Transportation"
  },
  {
    "code": "48599",
    "title": "Other Transit and Ground Passenger Transportation"
  },
  {
    "code": "485991",
    "title": "Special Needs Transportation"
  },
  {
    "code": "485999",
    "title": "All Other Transit and Ground Passenger Transportation"
  },
  {
    "code": "486",
    "title": "Pipeline Transportation"
  },
  {
    "code": "4861",
    "title": "Pipeline Transportation of Crude Oil"
  },
  {
    "code": "48611",
    "title": "Pipeline Transportation of Crude Oil"
  },
  {
    "code": "486110",
    "title": "Pipeline Transportation of Crude Oil"
  },
  {
    "code": "4862",
    "title": "Pipeline Transportation of Natural Gas"
  },
  {
    "code": "48621",
    "title": "Pipeline Transportation of Natural Gas"
  },
  {
    "code": "486210",
    "title": "Pipeline Transportation of Natural Gas"
  },
  {
    "code": "4869",
    "title": "Other Pipeline Transportation"
  },
  {
    "code": "48691",
    "title": "Pipeline Transportation of Refined Petroleum Products"
  },
  {
    "code": "486910",
    "title": "Pipeline Transportation of Refined Petroleum Products"
  },
  {
    "code": "48699",
    "title": "All Other Pipeline Transportation"
  },
  {
    "code": "486990",
    "title": "All Other Pipeline Transportation"
  },
  {
    "code": "487",
    "title": "Scenic and Sightseeing Transportation"
  },
  {
    "code": "4871",
    "title": "Scenic and Sightseeing Transportation, Land"
  },
  {
    "code": "48711",
    "title": "Scenic and Sightseeing Transportation, Land"
  },
  {
    "code": "487110",
    "title": "Scenic and Sightseeing Transportation, Land"
  },
  {
    "code": "4872",
    "title": "Scenic and Sightseeing Transportation, Water"
  },
  {
    "code": "48721",
    "title": "Scenic and Sightseeing Transportation, Water"
  },
  {
    "code": "487210",
    "title": "Scenic and Sightseeing Transportation, Water"
  },
  {
    "code": "4879",
    "title": "Scenic and Sightseeing Transportation, Other"
  },
  {
    "code": "48799",
    "title": "Scenic and Sightseeing Transportation, Other"
  },
  {
    "code": "487990",
    "title": "Scenic and Sightseeing Transportation, Other"
  },
  {
    "code": "488",
    "title": "Support Activities for Transportation"
  },
  {
    "code": "4881",
    "title": "Support Activities for Air Transportation"
  },
  {
    "code": "48811",
    "title": "Airport Operations"
  },
  {
    "code": "488111",
    "title": "Air Traffic Control"
  },
  {
    "code": "488119",
    "title": "Other Airport Operations"
  },
  {
    "code": "48819",
    "title": "Other Support Activities for Air Transportation"
  },
  {
    "code": "488190",
    "title": "Other Support Activities for Air Transportation"
  },
  {
    "code": "4882",
    "title": "Support Activities for Rail Transportation"
  },
  {
    "code": "48821",
    "title": "Support Activities for Rail Transportation"
  },
  {
    "code": "488210",
    "title": "Support Activities for Rail Transportation"
  },
  {
    "code": "4883",
    "title": "Support Activities for Water Transportation"
  },
  {
    "code": "48831",
    "title": "Port and Harbor Operations"
  },
  {
    "code": "488310",
    "title": "Port and Harbor Operations"
  },
  {
    "code": "48832",
    "title": "Marine Cargo Handling"
  },
  {
    "code": "488320",
    "title": "Marine Cargo Handling"
  },
  {
    "code": "48833",
    "title": "Navigational Services to Shipping"
  },
  {
    "code": "488330",
    "title": "Navigational Services to Shipping"
  },
  {
    "code": "48839",
    "title": "Other Support Activities for Water Transportation"
  },
  {
    "code": "488390",
    "title": "Other Support Activities for Water Transportation"
  },
  {
    "code": "4884",
    "title": "Support Activities for Road Transportation"
  },
  {
    "code": "48841",
    "title": "Motor Vehicle Towing"
  },
  {
    "code": "488410",
    "title": "Motor Vehicle Towing"
  },
  {
    "code": "48849",
    "title": "Other Support Activities for Road Transportation"
  },
  {
    "code": "488490",
    "title": "Other Support Activities for Road Transportation"
  },
  {
    "code": "4885",
    "title": "Freight Transportation Arrangement"
  },
  {
    "code": "48851",
    "title": "Freight Transportation Arrangement"
  },
  {
    "code": "488510",
    "title": "Freight Transportation Arrangement"
  },
  {
    "code": "4889",
    "title": "Other Support Activities for Transportation"
  },
  {
    "code": "48899",
    "title": "Other Support Activities for Transportation"
  },
  {
    "code": "488991",
    "title": "Packing and Crating"
  },
  {
    "code": "488999",
    "title": "All Other Support Activities for Transportation"
  },
  {
    "code": "491",
    "title": "Postal Service"
  },
  {
    "code": "4911",
    "title": "Postal Service"
  },
  {
    "code": "49111",
    "title": "Postal Service"
  },
  {
    "code": "491110",
    "title": "Postal Service"
  },
  {
    "code": "492",
    "title": "Couriers and Messengers"
  },
  {
    "code": "4921",
    "title": "Couriers and Express Delivery Services"
  },
  {
    "code": "49211",
    "title": "Couriers and Express Delivery Services"
  },
  {
    "code": "492110",
    "title": "Couriers and Express Delivery Services"
  },
  {
    "code": "4922",
    "title": "Local Messengers and Local Delivery"
  },
  {
    "code": "49221",
    "title": "Local Messengers and Local Delivery"
  },
  {
    "code": "492210",
    "title": "Local Messengers and Local Delivery"
  },
  {
    "code": "493",
    "title": "Warehousing and Storage"
  },
  {
    "code": "4931",
    "title": "Warehousing and Storage"
  },
  {
    "code": "49311",
    "title": "General Warehousing and Storage"
  },
  {
    "code": "493110",
    "title": "General Warehousing and Storage"
  },
  {
    "code": "49312",
    "title": "Refrigerated Warehousing and Storage"
  },
  {
    "code": "493120",
    "title": "Refrigerated Warehousing and Storage"
  },
  {
    "code": "49313",
    "title": "Farm Product Warehousing and Storage"
  },
  {
    "code": "493130",
    "title": "Farm Product Warehousing and Storage"
  },
  {
    "code": "49319",
    "title": "Other Warehousing and Storage"
  },
  {
    "code": "493190",
    "title": "Other Warehousing and Storage"
  },
  {
    "code": "51",
    "title": "Information"
  },
  {
    "code": "511",
    "title": "Publishing Industries (except Internet)"
  },
  {
    "code": "5111",
    "title": "Newspaper, Periodical, Book, and Directory Publishers"
  },
  {
    "code": "51111",
    "title": "Newspaper Publishers"
  },
  {
    "code": "511110",
    "title": "Newspaper Publishers"
  },
  {
    "code": "51112",
    "title": "Periodical Publishers"
  },
  {
    "code": "511120",
    "title": "Periodical Publishers"
  },
  {
    "code": "51113",
    "title": "Book Publishers"
  },
  {
    "code": "511130",
    "title": "Book Publishers"
  },
  {
    "code": "51114",
    "title": "Directory and Mailing List Publishers"
  },
  {
    "code": "511140",
    "title": "Directory and Mailing List Publishers"
  },
  {
    "code": "51119",
    "title": "Other Publishers"
  },
  {
    "code": "511191",
    "title": "Greeting Card Publishers"
  },
  {
    "code": "511199",
    "title": "All Other Publishers"
  },
  {
    "code": "5112",
    "title": "Software Publishers"
  },
  {
    "code": "51121",
    "title": "Software Publishers"
  },
  {
    "code": "511210",
    "title": "Software Publishers"
  },
  {
    "code": "512",
    "title": "Motion Picture and Sound Recording Industries"
  },
  {
    "code": "5121",
    "title": "Motion Picture and Video Industries"
  },
  {
    "code": "51211",
    "title": "Motion Picture and Video Production"
  },
  {
    "code": "512110",
    "title": "Motion Picture and Video Production"
  },
  {
    "code": "51212",
    "title": "Motion Picture and Video Distribution"
  },
  {
    "code": "512120",
    "title": "Motion Picture and Video Distribution"
  },
  {
    "code": "51213",
    "title": "Motion Picture and Video Exhibition"
  },
  {
    "code": "512131",
    "title": "Motion Picture Theaters (except Drive-Ins)"
  },
  {
    "code": "512132",
    "title": "Drive-In Motion Picture Theaters"
  },
  {
    "code": "51219",
    "title": "Postproduction Services and Other Motion Picture and Video Industries"
  },
  {
    "code": "512191",
    "title": "Teleproduction and Other Postproduction Services"
  },
  {
    "code": "512199",
    "title": "Other Motion Picture and Video Industries"
  },
  {
    "code": "5122",
    "title": "Sound Recording Industries"
  },
  {
    "code": "51223",
    "title": "Music Publishers"
  },
  {
    "code": "512230",
    "title": "Music Publishers"
  },
  {
    "code": "51224",
    "title": "Sound Recording Studios"
  },
  {
    "code": "512240",
    "title": "Sound Recording Studios"
  },
  {
    "code": "51225",
    "title": "Record Production and Distribution"
  },
  {
    "code": "512250",
    "title": "Record Production and Distribution"
  },
  {
    "code": "51229",
    "title": "Other Sound Recording Industries"
  },
  {
    "code": "512290",
    "title": "Other Sound Recording Industries"
  },
  {
    "code": "515",
    "title": "Broadcasting (except Internet)"
  },
  {
    "code": "5151",
    "title": "Radio and Television Broadcasting"
  },
  {
    "code": "51511",
    "title": "Radio Broadcasting"
  },
  {
    "code": "515111",
    "title": "Radio Networks"
  },
  {
    "code": "515112",
    "title": "Radio Stations"
  },
  {
    "code": "51512",
    "title": "Television Broadcasting"
  },
  {
    "code": "515120",
    "title": "Television Broadcasting"
  },
  {
    "code": "5152",
    "title": "Cable and Other Subscription Programming"
  },
  {
    "code": "51521",
    "title": "Cable and Other Subscription Programming"
  },
  {
    "code": "515210",
    "title": "Cable and Other Subscription Programming"
  },
  {
    "code": "517",
    "title": "Telecommunications"
  },
  {
    "code": "5173",
    "title": "Wired and Wireless Telecommunications Carriers"
  },
  {
    "code": "51731",
    "title": "Wired and Wireless Telecommunications Carriers"
  },
  {
    "code": "517311",
    "title": "Wired Telecommunications Carriers"
  },
  {
    "code": "517312",
    "title": "Wireless Telecommunications Carriers (except Satellite)"
  },
  {
    "code": "5174",
    "title": "Satellite Telecommunications"
  },
  {
    "code": "51741",
    "title": "Satellite Telecommunications"
  },
  {
    "code": "517410",
    "title": "Satellite Telecommunications"
  },
  {
    "code": "5179",
    "title": "Other Telecommunications"
  },
  {
    "code": "51791",
    "title": "Other Telecommunications"
  },
  {
    "code": "517911",
    "title": "Telecommunications Resellers"
  },
  {
    "code": "517919",
    "title": "All Other Telecommunications"
  },
  {
    "code": "518",
    "title": "Data Processing, Hosting, and Related Services"
  },
  {
    "code": "5182",
    "title": "Data Processing, Hosting, and Related Services"
  },
  {
    "code": "51821",
    "title": "Data Processing, Hosting, and Related Services"
  },
  {
    "code": "518210",
    "title": "Data Processing, Hosting, and Related Services"
  },
  {
    "code": "519",
    "title": "Other Information Services"
  },
  {
    "code": "5191",
    "title": "Other Information Services"
  },
  {
    "code": "51911",
    "title": "News Syndicates"
  },
  {
    "code": "519110",
    "title": "News Syndicates"
  },
  {
    "code": "51912",
    "title": "Libraries and Archives"
  },
  {
    "code": "519120",
    "title": "Libraries and Archives"
  },
  {
    "code": "51913",
    "title": "Internet Publishing and Broadcasting and Web Search Portals"
  },
  {
    "code": "519130",
    "title": "Internet Publishing and Broadcasting and Web Search Portals"
  },
  {
    "code": "51919",
    "title": "All Other Information Services"
  },
  {
    "code": "519190",
    "title": "All Other Information Services"
  },
  {
    "code": "52",
    "title": "Finance and Insurance"
  },
  {
    "code": "521",
    "title": "Monetary Authorities-Central Bank"
  },
  {
    "code": "5211",
    "title": "Monetary Authorities-Central Bank"
  },
  {
    "code": "52111",
    "title": "Monetary Authorities-Central Bank"
  },
  {
    "code": "521110",
    "title": "Monetary Authorities-Central Bank"
  },
  {
    "code": "522",
    "title": "Credit Intermediation and Related Activities"
  },
  {
    "code": "5221",
    "title": "Depository Credit Intermediation"
  },
  {
    "code": "52211",
    "title": "Commercial Banking"
  },
  {
    "code": "522110",
    "title": "Commercial Banking"
  },
  {
    "code": "52212",
    "title": "Savings Institutions"
  },
  {
    "code": "522120",
    "title": "Savings Institutions"
  },
  {
    "code": "52213",
    "title": "Credit Unions"
  },
  {
    "code": "522130",
    "title": "Credit Unions"
  },
  {
    "code": "52219",
    "title": "Other Depository Credit Intermediation"
  },
  {
    "code": "522190",
    "title": "Other Depository Credit Intermediation"
  },
  {
    "code": "5222",
    "title": "Nondepository Credit Intermediation"
  },
  {
    "code": "52221",
    "title": "Credit Card Issuing"
  },
  {
    "code": "522210",
    "title": "Credit Card Issuing"
  },
  {
    "code": "52222",
    "title": "Sales Financing"
  },
  {
    "code": "522220",
    "title": "Sales Financing"
  },
  {
    "code": "52229",
    "title": "Other Nondepository Credit Intermediation"
  },
  {
    "code": "522291",
    "title": "Consumer Lending"
  },
  {
    "code": "522292",
    "title": "Real Estate Credit"
  },
  {
    "code": "522293",
    "title": "International Trade Financing"
  },
  {
    "code": "522294",
    "title": "Secondary Market Financing"
  },
  {
    "code": "522298",
    "title": "All Other Nondepository Credit Intermediation"
  },
  {
    "code": "5223",
    "title": "Activities Related to Credit Intermediation"
  },
  {
    "code": "52231",
    "title": "Mortgage and Nonmortgage Loan Brokers"
  },
  {
    "code": "522310",
    "title": "Mortgage and Nonmortgage Loan Brokers"
  },
  {
    "code": "52232",
    "title": "Financial Transactions Processing, Reserve, and Clearinghouse Activities"
  },
  {
    "code": "522320",
    "title": "Financial Transactions Processing, Reserve, and Clearinghouse Activities"
  },
  {
    "code": "52239",
    "title": "Other Activities Related to Credit Intermediation"
  },
  {
    "code": "522390",
    "title": "Other Activities Related to Credit Intermediation"
  },
  {
    "code": "523",
    "title": "Securities, Commodity Contracts, and Other Financial Investments and Related Activities"
  },
  {
    "code": "5231",
    "title": "Securities and Commodity Contracts Intermediation and Brokerage"
  },
  {
    "code": "52311",
    "title": "Investment Banking and Securities Dealing"
  },
  {
    "code": "523110",
    "title": "Investment Banking and Securities Dealing"
  },
  {
    "code": "52312",
    "title": "Securities Brokerage"
  },
  {
    "code": "523120",
    "title": "Securities Brokerage"
  },
  {
    "code": "52313",
    "title": "Commodity Contracts Dealing"
  },
  {
    "code": "523130",
    "title": "Commodity Contracts Dealing"
  },
  {
    "code": "52314",
    "title": "Commodity Contracts Brokerage"
  },
  {
    "code": "523140",
    "title": "Commodity Contracts Brokerage"
  },
  {
    "code": "5232",
    "title": "Securities and Commodity Exchanges"
  },
  {
    "code": "52321",
    "title": "Securities and Commodity Exchanges"
  },
  {
    "code": "523210",
    "title": "Securities and Commodity Exchanges"
  },
  {
    "code": "5239",
    "title": "Other Financial Investment Activities"
  },
  {
    "code": "52391",
    "title": "Miscellaneous Intermediation"
  },
  {
    "code": "523910",
    "title": "Miscellaneous Intermediation"
  },
  {
    "code": "52392",
    "title": "Portfolio Management"
  },
  {
    "code": "523920",
    "title": "Portfolio Management"
  },
  {
    "code": "52393",
    "title": "Investment Advice"
  },
  {
    "code": "523930",
    "title": "Investment Advice"
  },
  {
    "code": "52399",
    "title": "All Other Financial Investment Activities"
  },
  {
    "code": "523991",
    "title": "Trust, Fiduciary, and Custody Activities"
  },
  {
    "code": "523999",
    "title": "Miscellaneous Financial Investment Activities"
  },
  {
    "code": "524",
    "title": "Insurance Carriers and Related Activities"
  },
  {
    "code": "5241",
    "title": "Insurance Carriers"
  },
  {
    "code": "52411",
    "title": "Direct Life, Health, and Medical Insurance Carriers"
  },
  {
    "code": "524113",
    "title": "Direct Life Insurance Carriers"
  },
  {
    "code": "524114",
    "title": "Direct Health and Medical Insurance Carriers"
  },
  {
    "code": "52412",
    "title": "Direct Insurance (except Life, Health, and Medical) Carriers"
  },
  {
    "code": "524126",
    "title": "Direct Property and Casualty Insurance Carriers"
  },
  {
    "code": "524127",
    "title": "Direct Title Insurance Carriers"
  },
  {
    "code": "524128",
    "title": "Other Direct Insurance (except Life, Health, and Medical) Carriers"
  },
  {
    "code": "52413",
    "title": "Reinsurance Carriers"
  },
  {
    "code": "524130",
    "title": "Reinsurance Carriers"
  },
  {
    "code": "5242",
    "title": "Agencies, Brokerages, and Other Insurance Related Activities"
  },
  {
    "code": "52421",
    "title": "Insurance Agencies and Brokerages"
  },
  {
    "code": "524210",
    "title": "Insurance Agencies and Brokerages"
  },
  {
    "code": "52429",
    "title": "Other Insurance Related Activities"
  },
  {
    "code": "524291",
    "title": "Claims Adjusting"
  },
  {
    "code": "524292",
    "title": "Third Party Administration of Insurance and Pension Funds"
  },
  {
    "code": "524298",
    "title": "All Other Insurance Related Activities"
  },
  {
    "code": "525",
    "title": "Funds, Trusts, and Other Financial Vehicles"
  },
  {
    "code": "5251",
    "title": "Insurance and Employee Benefit Funds"
  },
  {
    "code": "52511",
    "title": "Pension Funds"
  },
  {
    "code": "525110",
    "title": "Pension Funds"
  },
  {
    "code": "52512",
    "title": "Health and Welfare Funds"
  },
  {
    "code": "525120",
    "title": "Health and Welfare Funds"
  },
  {
    "code": "52519",
    "title": "Other Insurance Funds"
  },
  {
    "code": "525190",
    "title": "Other Insurance Funds"
  },
  {
    "code": "5259",
    "title": "Other Investment Pools and Funds"
  },
  {
    "code": "52591",
    "title": "Open-End Investment Funds"
  },
  {
    "code": "525910",
    "title": "Open-End Investment Funds"
  },
  {
    "code": "52592",
    "title": "Trusts, Estates, and Agency Accounts"
  },
  {
    "code": "525920",
    "title": "Trusts, Estates, and Agency Accounts"
  },
  {
    "code": "52599",
    "title": "Other Financial Vehicles"
  },
  {
    "code": "525990",
    "title": "Other Financial Vehicles"
  },
  {
    "code": "53",
    "title": "Real Estate and Rental and Leasing"
  },
  {
    "code": "531",
    "title": "Real Estate"
  },
  {
    "code": "5311",
    "title": "Lessors of Real Estate"
  },
  {
    "code": "53111",
    "title": "Lessors of Residential Buildings and Dwellings"
  },
  {
    "code": "531110",
    "title": "Lessors of Residential Buildings and Dwellings"
  },
  {
    "code": "53112",
    "title": "Lessors of Nonresidential Buildings (except Miniwarehouses)"
  },
  {
    "code": "531120",
    "title": "Lessors of Nonresidential Buildings (except Miniwarehouses)"
  },
  {
    "code": "53113",
    "title": "Lessors of Miniwarehouses and Self-Storage Units"
  },
  {
    "code": "531130",
    "title": "Lessors of Miniwarehouses and Self-Storage Units"
  },
  {
    "code": "53119",
    "title": "Lessors of Other Real Estate Property"
  },
  {
    "code": "531190",
    "title": "Lessors of Other Real Estate Property"
  },
  {
    "code": "5312",
    "title": "Offices of Real Estate Agents and Brokers"
  },
  {
    "code": "53121",
    "title": "Offices of Real Estate Agents and Brokers"
  },
  {
    "code": "531210",
    "title": "Offices of Real Estate Agents and Brokers"
  },
  {
    "code": "5313",
    "title": "Activities Related to Real Estate"
  },
  {
    "code": "53131",
    "title": "Real Estate Property Managers"
  },
  {
    "code": "531311",
    "title": "Residential Property Managers"
  },
  {
    "code": "531312",
    "title": "Nonresidential Property Managers"
  },
  {
    "code": "53132",
    "title": "Offices of Real Estate Appraisers"
  },
  {
    "code": "531320",
    "title": "Offices of Real Estate Appraisers"
  },
  {
    "code": "53139",
    "title": "Other Activities Related to Real Estate"
  },
  {
    "code": "531390",
    "title": "Other Activities Related to Real Estate"
  },
  {
    "code": "532",
    "title": "Rental and Leasing Services"
  },
  {
    "code": "5321",
    "title": "Automotive Equipment Rental and Leasing"
  },
  {
    "code": "53211",
    "title": "Passenger Car Rental and Leasing"
  },
  {
    "code": "532111",
    "title": "Passenger Car Rental"
  },
  {
    "code": "532112",
    "title": "Passenger Car Leasing"
  },
  {
    "code": "53212",
    "title": "Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing"
  },
  {
    "code": "532120",
    "title": "Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing"
  },
  {
    "code": "5322",
    "title": "Consumer Goods Rental"
  },
  {
    "code": "53221",
    "title": "Consumer Electronics and Appliances Rental"
  },
  {
    "code": "532210",
    "title": "Consumer Electronics and Appliances Rental"
  },
  {
    "code": "53228",
    "title": "Other Consumer Goods Rental"
  },
  {
    "code": "532281",
    "title": "Formal Wear and Costume Rental"
  },
  {
    "code": "532282",
    "title": "Video Tape and Disc Rental"
  },
  {
    "code": "532283",
    "title": "Home Health Equipment Rental"
  },
  {
    "code": "532284",
    "title": "Recreational Goods Rental"
  },
  {
    "code": "532289",
    "title": "All Other Consumer Goods Rental"
  },
  {
    "code": "5323",
    "title": "General Rental Centers"
  },
  {
    "code": "53231",
    "title": "General Rental Centers"
  },
  {
    "code": "532310",
    "title": "General Rental Centers"
  },
  {
    "code": "5324",
    "title": "Commercial and Industrial Machinery and Equipment Rental and Leasing"
  },
  {
    "code": "53241",
    "title": "Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing"
  },
  {
    "code": "532411",
    "title": "Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing"
  },
  {
    "code": "532412",
    "title": "Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing"
  },
  {
    "code": "53242",
    "title": "Office Machinery and Equipment Rental and Leasing"
  },
  {
    "code": "532420",
    "title": "Office Machinery and Equipment Rental and Leasing"
  },
  {
    "code": "53249",
    "title": "Other Commercial and Industrial Machinery and Equipment Rental and Leasing"
  },
  {
    "code": "532490",
    "title": "Other Commercial and Industrial Machinery and Equipment Rental and Leasing"
  },
  {
    "code": "533",
    "title": "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
  },
  {
    "code": "5331",
    "title": "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
  },
  {
    "code": "53311",
    "title": "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
  },
  {
    "code": "533110",
    "title": "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
  },
  {
    "code": "54",
    "title": "Professional, Scientific, and Technical Services"
  },
  {
    "code": "541",
    "title": "Professional, Scientific, and Technical Services"
  },
  {
    "code": "5411",
    "title": "Legal Services"
  },
  {
    "code": "54111",
    "title": "Offices of Lawyers"
  },
  {
    "code": "541110",
    "title": "Offices of Lawyers"
  },
  {
    "code": "54112",
    "title": "Offices of Notaries"
  },
  {
    "code": "541120",
    "title": "Offices of Notaries"
  },
  {
    "code": "54119",
    "title": "Other Legal Services"
  },
  {
    "code": "541191",
    "title": "Title Abstract and Settlement Offices"
  },
  {
    "code": "541199",
    "title": "All Other Legal Services"
  },
  {
    "code": "5412",
    "title": "Accounting, Tax Preparation, Bookkeeping, and Payroll Services"
  },
  {
    "code": "54121",
    "title": "Accounting, Tax Preparation, Bookkeeping, and Payroll Services"
  },
  {
    "code": "541211",
    "title": "Offices of Certified Public Accountants"
  },
  {
    "code": "541213",
    "title": "Tax Preparation Services"
  },
  {
    "code": "541214",
    "title": "Payroll Services"
  },
  {
    "code": "541219",
    "title": "Other Accounting Services"
  },
  {
    "code": "5413",
    "title": "Architectural, Engineering, and Related Services"
  },
  {
    "code": "54131",
    "title": "Architectural Services"
  },
  {
    "code": "541310",
    "title": "Architectural Services"
  },
  {
    "code": "54132",
    "title": "Landscape Architectural Services"
  },
  {
    "code": "541320",
    "title": "Landscape Architectural Services"
  },
  {
    "code": "54133",
    "title": "Engineering Services"
  },
  {
    "code": "541330",
    "title": "Engineering Services"
  },
  {
    "code": "54134",
    "title": "Drafting Services"
  },
  {
    "code": "541340",
    "title": "Drafting Services"
  },
  {
    "code": "54135",
    "title": "Building Inspection Services"
  },
  {
    "code": "541350",
    "title": "Building Inspection Services"
  },
  {
    "code": "54136",
    "title": "Geophysical Surveying and Mapping Services"
  },
  {
    "code": "541360",
    "title": "Geophysical Surveying and Mapping Services"
  },
  {
    "code": "54137",
    "title": "Surveying and Mapping (except Geophysical) Services"
  },
  {
    "code": "541370",
    "title": "Surveying and Mapping (except Geophysical) Services"
  },
  {
    "code": "54138",
    "title": "Testing Laboratories"
  },
  {
    "code": "541380",
    "title": "Testing Laboratories"
  },
  {
    "code": "5414",
    "title": "Specialized Design Services"
  },
  {
    "code": "54141",
    "title": "Interior Design Services"
  },
  {
    "code": "541410",
    "title": "Interior Design Services"
  },
  {
    "code": "54142",
    "title": "Industrial Design Services"
  },
  {
    "code": "541420",
    "title": "Industrial Design Services"
  },
  {
    "code": "54143",
    "title": "Graphic Design Services"
  },
  {
    "code": "541430",
    "title": "Graphic Design Services"
  },
  {
    "code": "54149",
    "title": "Other Specialized Design Services"
  },
  {
    "code": "541490",
    "title": "Other Specialized Design Services"
  },
  {
    "code": "5415",
    "title": "Computer Systems Design and Related Services"
  },
  {
    "code": "54151",
    "title": "Computer Systems Design and Related Services"
  },
  {
    "code": "541511",
    "title": "Custom Computer Programming Services"
  },
  {
    "code": "541512",
    "title": "Computer Systems Design Services"
  },
  {
    "code": "541513",
    "title": "Computer Facilities Management Services"
  },
  {
    "code": "541519",
    "title": "Other Computer Related Services"
  },
  {
    "code": "5416",
    "title": "Management, Scientific, and Technical Consulting Services"
  },
  {
    "code": "54161",
    "title": "Management Consulting Services"
  },
  {
    "code": "541611",
    "title": "Administrative Management and General Management Consulting Services"
  },
  {
    "code": "541612",
    "title": "Human Resources Consulting Services"
  },
  {
    "code": "541613",
    "title": "Marketing Consulting Services"
  },
  {
    "code": "541614",
    "title": "Process, Physical Distribution, and Logistics Consulting Services"
  },
  {
    "code": "541618",
    "title": "Other Management Consulting Services"
  },
  {
    "code": "54162",
    "title": "Environmental Consulting Services"
  },
  {
    "code": "541620",
    "title": "Environmental Consulting Services"
  },
  {
    "code": "54169",
    "title": "Other Scientific and Technical Consulting Services"
  },
  {
    "code": "541690",
    "title": "Other Scientific and Technical Consulting Services"
  },
  {
    "code": "5417",
    "title": "Scientific Research and Development Services"
  },
  {
    "code": "54171",
    "title": "Research and Development in the Physical, Engineering, and Life Sciences"
  },
  {
    "code": "541713",
    "title": "Research and Development in Nanotechnology"
  },
  {
    "code": "541714",
    "title": "Research and Development in Biotechnology (except Nanobiotechnology)"
  },
  {
    "code": "541715",
    "title": "Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)"
  },
  {
    "code": "54172",
    "title": "Research and Development in the Social Sciences and Humanities"
  },
  {
    "code": "541720",
    "title": "Research and Development in the Social Sciences and Humanities"
  },
  {
    "code": "5418",
    "title": "Advertising, Public Relations, and Related Services"
  },
  {
    "code": "54181",
    "title": "Advertising Agencies"
  },
  {
    "code": "541810",
    "title": "Advertising Agencies"
  },
  {
    "code": "54182",
    "title": "Public Relations Agencies"
  },
  {
    "code": "541820",
    "title": "Public Relations Agencies"
  },
  {
    "code": "54183",
    "title": "Media Buying Agencies"
  },
  {
    "code": "541830",
    "title": "Media Buying Agencies"
  },
  {
    "code": "54184",
    "title": "Media Representatives"
  },
  {
    "code": "541840",
    "title": "Media Representatives"
  },
  {
    "code": "54185",
    "title": "Outdoor Advertising"
  },
  {
    "code": "541850",
    "title": "Outdoor Advertising"
  },
  {
    "code": "54186",
    "title": "Direct Mail Advertising"
  },
  {
    "code": "541860",
    "title": "Direct Mail Advertising"
  },
  {
    "code": "54187",
    "title": "Advertising Material Distribution Services"
  },
  {
    "code": "541870",
    "title": "Advertising Material Distribution Services"
  },
  {
    "code": "54189",
    "title": "Other Services Related to Advertising"
  },
  {
    "code": "541890",
    "title": "Other Services Related to Advertising"
  },
  {
    "code": "5419",
    "title": "Other Professional, Scientific, and Technical Services"
  },
  {
    "code": "54191",
    "title": "Marketing Research and Public Opinion Polling"
  },
  {
    "code": "541910",
    "title": "Marketing Research and Public Opinion Polling"
  },
  {
    "code": "54192",
    "title": "Photographic Services"
  },
  {
    "code": "541921",
    "title": "Photography Studios, Portrait"
  },
  {
    "code": "541922",
    "title": "Commercial Photography"
  },
  {
    "code": "54193",
    "title": "Translation and Interpretation Services"
  },
  {
    "code": "541930",
    "title": "Translation and Interpretation Services"
  },
  {
    "code": "54194",
    "title": "Veterinary Services"
  },
  {
    "code": "541940",
    "title": "Veterinary Services"
  },
  {
    "code": "54199",
    "title": "All Other Professional, Scientific, and Technical Services"
  },
  {
    "code": "541990",
    "title": "All Other Professional, Scientific, and Technical Services"
  },
  {
    "code": "55",
    "title": "Management of Companies and Enterprises"
  },
  {
    "code": "551",
    "title": "Management of Companies and Enterprises"
  },
  {
    "code": "5511",
    "title": "Management of Companies and Enterprises"
  },
  {
    "code": "55111",
    "title": "Management of Companies and Enterprises"
  },
  {
    "code": "551111",
    "title": "Offices of Bank Holding Companies"
  },
  {
    "code": "551112",
    "title": "Offices of Other Holding Companies"
  },
  {
    "code": "551114",
    "title": "Corporate, Subsidiary, and Regional Managing Offices"
  },
  {
    "code": "56",
    "title": "Administrative and Support and Waste Management and Remediation Services"
  },
  {
    "code": "561",
    "title": "Administrative and Support Services"
  },
  {
    "code": "5611",
    "title": "Office Administrative Services"
  },
  {
    "code": "56111",
    "title": "Office Administrative Services"
  },
  {
    "code": "561110",
    "title": "Office Administrative Services"
  },
  {
    "code": "5612",
    "title": "Facilities Support Services"
  },
  {
    "code": "56121",
    "title": "Facilities Support Services"
  },
  {
    "code": "561210",
    "title": "Facilities Support Services"
  },
  {
    "code": "5613",
    "title": "Employment Services"
  },
  {
    "code": "56131",
    "title": "Employment Placement Agencies and Executive Search Services"
  },
  {
    "code": "561311",
    "title": "Employment Placement Agencies"
  },
  {
    "code": "561312",
    "title": "Executive Search Services"
  },
  {
    "code": "56132",
    "title": "Temporary Help Services"
  },
  {
    "code": "561320",
    "title": "Temporary Help Services"
  },
  {
    "code": "56133",
    "title": "Professional Employer Organizations"
  },
  {
    "code": "561330",
    "title": "Professional Employer Organizations"
  },
  {
    "code": "5614",
    "title": "Business Support Services"
  },
  {
    "code": "56141",
    "title": "Document Preparation Services"
  },
  {
    "code": "561410",
    "title": "Document Preparation Services"
  },
  {
    "code": "56142",
    "title": "Telephone Call Centers"
  },
  {
    "code": "561421",
    "title": "Telephone Answering Services"
  },
  {
    "code": "561422",
    "title": "Telemarketing Bureaus and Other Contact Centers"
  },
  {
    "code": "56143",
    "title": "Business Service Centers"
  },
  {
    "code": "561431",
    "title": "Private Mail Centers"
  },
  {
    "code": "561439",
    "title": "Other Business Service Centers (including Copy Shops)"
  },
  {
    "code": "56144",
    "title": "Collection Agencies"
  },
  {
    "code": "561440",
    "title": "Collection Agencies"
  },
  {
    "code": "56145",
    "title": "Credit Bureaus"
  },
  {
    "code": "561450",
    "title": "Credit Bureaus"
  },
  {
    "code": "56149",
    "title": "Other Business Support Services"
  },
  {
    "code": "561491",
    "title": "Repossession Services"
  },
  {
    "code": "561492",
    "title": "Court Reporting and Stenotype Services"
  },
  {
    "code": "561499",
    "title": "All Other Business Support Services"
  },
  {
    "code": "5615",
    "title": "Travel Arrangement and Reservation Services"
  },
  {
    "code": "56151",
    "title": "Travel Agencies"
  },
  {
    "code": "561510",
    "title": "Travel Agencies"
  },
  {
    "code": "56152",
    "title": "Tour Operators"
  },
  {
    "code": "561520",
    "title": "Tour Operators"
  },
  {
    "code": "56159",
    "title": "Other Travel Arrangement and Reservation Services"
  },
  {
    "code": "561591",
    "title": "Convention and Visitors Bureaus"
  },
  {
    "code": "561599",
    "title": "All Other Travel Arrangement and Reservation Services"
  },
  {
    "code": "5616",
    "title": "Investigation and Security Services"
  },
  {
    "code": "56161",
    "title": "Investigation, Guard, and Armored Car Services"
  },
  {
    "code": "561611",
    "title": "Investigation Services"
  },
  {
    "code": "561612",
    "title": "Security Guards and Patrol Services"
  },
  {
    "code": "561613",
    "title": "Armored Car Services"
  },
  {
    "code": "56162",
    "title": "Security Systems Services"
  },
  {
    "code": "561621",
    "title": "Security Systems Services (except Locksmiths)"
  },
  {
    "code": "561622",
    "title": "Locksmiths"
  },
  {
    "code": "5617",
    "title": "Services to Buildings and Dwellings"
  },
  {
    "code": "56171",
    "title": "Exterminating and Pest Control Services"
  },
  {
    "code": "561710",
    "title": "Exterminating and Pest Control Services"
  },
  {
    "code": "56172",
    "title": "Janitorial Services"
  },
  {
    "code": "561720",
    "title": "Janitorial Services"
  },
  {
    "code": "56173",
    "title": "Landscaping Services"
  },
  {
    "code": "561730",
    "title": "Landscaping Services"
  },
  {
    "code": "56174",
    "title": "Carpet and Upholstery Cleaning Services"
  },
  {
    "code": "561740",
    "title": "Carpet and Upholstery Cleaning Services"
  },
  {
    "code": "56179",
    "title": "Other Services to Buildings and Dwellings"
  },
  {
    "code": "561790",
    "title": "Other Services to Buildings and Dwellings"
  },
  {
    "code": "5619",
    "title": "Other Support Services"
  },
  {
    "code": "56191",
    "title": "Packaging and Labeling Services"
  },
  {
    "code": "561910",
    "title": "Packaging and Labeling Services"
  },
  {
    "code": "56192",
    "title": "Convention and Trade Show Organizers"
  },
  {
    "code": "561920",
    "title": "Convention and Trade Show Organizers"
  },
  {
    "code": "56199",
    "title": "All Other Support Services"
  },
  {
    "code": "561990",
    "title": "All Other Support Services"
  },
  {
    "code": "562",
    "title": "Waste Management and Remediation Services"
  },
  {
    "code": "5621",
    "title": "Waste Collection"
  },
  {
    "code": "56211",
    "title": "Waste Collection"
  },
  {
    "code": "562111",
    "title": "Solid Waste Collection"
  },
  {
    "code": "562112",
    "title": "Hazardous Waste Collection"
  },
  {
    "code": "562119",
    "title": "Other Waste Collection"
  },
  {
    "code": "5622",
    "title": "Waste Treatment and Disposal"
  },
  {
    "code": "56221",
    "title": "Waste Treatment and Disposal"
  },
  {
    "code": "562211",
    "title": "Hazardous Waste Treatment and Disposal"
  },
  {
    "code": "562212",
    "title": "Solid Waste Landfill"
  },
  {
    "code": "562213",
    "title": "Solid Waste Combustors and Incinerators"
  },
  {
    "code": "562219",
    "title": "Other Nonhazardous Waste Treatment and Disposal"
  },
  {
    "code": "5629",
    "title": "Remediation and Other Waste Management Services"
  },
  {
    "code": "56291",
    "title": "Remediation Services"
  },
  {
    "code": "562910",
    "title": "Remediation Services"
  },
  {
    "code": "56292",
    "title": "Materials Recovery Facilities"
  },
  {
    "code": "562920",
    "title": "Materials Recovery Facilities"
  },
  {
    "code": "56299",
    "title": "All Other Waste Management Services"
  },
  {
    "code": "562991",
    "title": "Septic Tank and Related Services"
  },
  {
    "code": "562998",
    "title": "All Other Miscellaneous Waste Management Services"
  },
  {
    "code": "61",
    "title": "Educational Services"
  },
  {
    "code": "611",
    "title": "Educational Services"
  },
  {
    "code": "6111",
    "title": "Elementary and Secondary Schools"
  },
  {
    "code": "61111",
    "title": "Elementary and Secondary Schools"
  },
  {
    "code": "611110",
    "title": "Elementary and Secondary Schools"
  },
  {
    "code": "6112",
    "title": "Junior Colleges"
  },
  {
    "code": "61121",
    "title": "Junior Colleges"
  },
  {
    "code": "611210",
    "title": "Junior Colleges"
  },
  {
    "code": "6113",
    "title": "Colleges, Universities, and Professional Schools"
  },
  {
    "code": "61131",
    "title": "Colleges, Universities, and Professional Schools"
  },
  {
    "code": "611310",
    "title": "Colleges, Universities, and Professional Schools"
  },
  {
    "code": "6114",
    "title": "Business Schools and Computer and Management Training"
  },
  {
    "code": "61141",
    "title": "Business and Secretarial Schools"
  },
  {
    "code": "611410",
    "title": "Business and Secretarial Schools"
  },
  {
    "code": "61142",
    "title": "Computer Training"
  },
  {
    "code": "611420",
    "title": "Computer Training"
  },
  {
    "code": "61143",
    "title": "Professional and Management Development Training"
  },
  {
    "code": "611430",
    "title": "Professional and Management Development Training"
  },
  {
    "code": "6115",
    "title": "Technical and Trade Schools"
  },
  {
    "code": "61151",
    "title": "Technical and Trade Schools"
  },
  {
    "code": "611511",
    "title": "Cosmetology and Barber Schools"
  },
  {
    "code": "611512",
    "title": "Flight Training"
  },
  {
    "code": "611513",
    "title": "Apprenticeship Training"
  },
  {
    "code": "611519",
    "title": "Other Technical and Trade Schools"
  },
  {
    "code": "6116",
    "title": "Other Schools and Instruction"
  },
  {
    "code": "61161",
    "title": "Fine Arts Schools"
  },
  {
    "code": "611610",
    "title": "Fine Arts Schools"
  },
  {
    "code": "61162",
    "title": "Sports and Recreation Instruction"
  },
  {
    "code": "611620",
    "title": "Sports and Recreation Instruction"
  },
  {
    "code": "61163",
    "title": "Language Schools"
  },
  {
    "code": "611630",
    "title": "Language Schools"
  },
  {
    "code": "61169",
    "title": "All Other Schools and Instruction"
  },
  {
    "code": "611691",
    "title": "Exam Preparation and Tutoring"
  },
  {
    "code": "611692",
    "title": "Automobile Driving Schools"
  },
  {
    "code": "611699",
    "title": "All Other Miscellaneous Schools and Instruction"
  },
  {
    "code": "6117",
    "title": "Educational Support Services"
  },
  {
    "code": "61171",
    "title": "Educational Support Services"
  },
  {
    "code": "611710",
    "title": "Educational Support Services"
  },
  {
    "code": "62",
    "title": "Health Care and Social Assistance"
  },
  {
    "code": "621",
    "title": "Ambulatory Health Care Services"
  },
  {
    "code": "6211",
    "title": "Offices of Physicians"
  },
  {
    "code": "62111",
    "title": "Offices of Physicians"
  },
  {
    "code": "621111",
    "title": "Offices of Physicians (except Mental Health Specialists)"
  },
  {
    "code": "621112",
    "title": "Offices of Physicians, Mental Health Specialists"
  },
  {
    "code": "6212",
    "title": "Offices of Dentists"
  },
  {
    "code": "62121",
    "title": "Offices of Dentists"
  },
  {
    "code": "621210",
    "title": "Offices of Dentists"
  },
  {
    "code": "6213",
    "title": "Offices of Other Health Practitioners"
  },
  {
    "code": "62131",
    "title": "Offices of Chiropractors"
  },
  {
    "code": "621310",
    "title": "Offices of Chiropractors"
  },
  {
    "code": "62132",
    "title": "Offices of Optometrists"
  },
  {
    "code": "621320",
    "title": "Offices of Optometrists"
  },
  {
    "code": "62133",
    "title": "Offices of Mental Health Practitioners (except Physicians)"
  },
  {
    "code": "621330",
    "title": "Offices of Mental Health Practitioners (except Physicians)"
  },
  {
    "code": "62134",
    "title": "Offices of Physical, Occupational and Speech Therapists, and Audiologists"
  },
  {
    "code": "621340",
    "title": "Offices of Physical, Occupational and Speech Therapists, and Audiologists"
  },
  {
    "code": "62139",
    "title": "Offices of All Other Health Practitioners"
  },
  {
    "code": "621391",
    "title": "Offices of Podiatrists"
  },
  {
    "code": "621399",
    "title": "Offices of All Other Miscellaneous Health Practitioners"
  },
  {
    "code": "6214",
    "title": "Outpatient Care Centers"
  },
  {
    "code": "62141",
    "title": "Family Planning Centers"
  },
  {
    "code": "621410",
    "title": "Family Planning Centers"
  },
  {
    "code": "62142",
    "title": "Outpatient Mental Health and Substance Abuse Centers"
  },
  {
    "code": "621420",
    "title": "Outpatient Mental Health and Substance Abuse Centers"
  },
  {
    "code": "62149",
    "title": "Other Outpatient Care Centers"
  },
  {
    "code": "621491",
    "title": "HMO Medical Centers"
  },
  {
    "code": "621492",
    "title": "Kidney Dialysis Centers"
  },
  {
    "code": "621493",
    "title": "Freestanding Ambulatory Surgical and Emergency Centers"
  },
  {
    "code": "621498",
    "title": "All Other Outpatient Care Centers"
  },
  {
    "code": "6215",
    "title": "Medical and Diagnostic Laboratories"
  },
  {
    "code": "62151",
    "title": "Medical and Diagnostic Laboratories"
  },
  {
    "code": "621511",
    "title": "Medical Laboratories"
  },
  {
    "code": "621512",
    "title": "Diagnostic Imaging Centers"
  },
  {
    "code": "6216",
    "title": "Home Health Care Services"
  },
  {
    "code": "62161",
    "title": "Home Health Care Services"
  },
  {
    "code": "621610",
    "title": "Home Health Care Services"
  },
  {
    "code": "6219",
    "title": "Other Ambulatory Health Care Services"
  },
  {
    "code": "62191",
    "title": "Ambulance Services"
  },
  {
    "code": "621910",
    "title": "Ambulance Services"
  },
  {
    "code": "62199",
    "title": "All Other Ambulatory Health Care Services"
  },
  {
    "code": "621991",
    "title": "Blood and Organ Banks"
  },
  {
    "code": "621999",
    "title": "All Other Miscellaneous Ambulatory Health Care Services"
  },
  {
    "code": "622",
    "title": "Hospitals"
  },
  {
    "code": "6221",
    "title": "General Medical and Surgical Hospitals"
  },
  {
    "code": "62211",
    "title": "General Medical and Surgical Hospitals"
  },
  {
    "code": "622110",
    "title": "General Medical and Surgical Hospitals"
  },
  {
    "code": "6222",
    "title": "Psychiatric and Substance Abuse Hospitals"
  },
  {
    "code": "62221",
    "title": "Psychiatric and Substance Abuse Hospitals"
  },
  {
    "code": "622210",
    "title": "Psychiatric and Substance Abuse Hospitals"
  },
  {
    "code": "6223",
    "title": "Specialty (except Psychiatric and Substance Abuse) Hospitals"
  },
  {
    "code": "62231",
    "title": "Specialty (except Psychiatric and Substance Abuse) Hospitals"
  },
  {
    "code": "622310",
    "title": "Specialty (except Psychiatric and Substance Abuse) Hospitals"
  },
  {
    "code": "623",
    "title": "Nursing and Residential Care Facilities"
  },
  {
    "code": "6231",
    "title": "Nursing Care Facilities (Skilled Nursing Facilities)"
  },
  {
    "code": "62311",
    "title": "Nursing Care Facilities (Skilled Nursing Facilities)"
  },
  {
    "code": "623110",
    "title": "Nursing Care Facilities (Skilled Nursing Facilities)"
  },
  {
    "code": "6232",
    "title": "Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities"
  },
  {
    "code": "62321",
    "title": "Residential Intellectual and Developmental Disability Facilities"
  },
  {
    "code": "623210",
    "title": "Residential Intellectual and Developmental Disability Facilities"
  },
  {
    "code": "62322",
    "title": "Residential Mental Health and Substance Abuse Facilities"
  },
  {
    "code": "623220",
    "title": "Residential Mental Health and Substance Abuse Facilities"
  },
  {
    "code": "6233",
    "title": "Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly"
  },
  {
    "code": "62331",
    "title": "Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly"
  },
  {
    "code": "623311",
    "title": "Continuing Care Retirement Communities"
  },
  {
    "code": "623312",
    "title": "Assisted Living Facilities for the Elderly"
  },
  {
    "code": "6239",
    "title": "Other Residential Care Facilities"
  },
  {
    "code": "62399",
    "title": "Other Residential Care Facilities"
  },
  {
    "code": "623990",
    "title": "Other Residential Care Facilities"
  },
  {
    "code": "624",
    "title": "Social Assistance"
  },
  {
    "code": "6241",
    "title": "Individual and Family Services"
  },
  {
    "code": "62411",
    "title": "Child and Youth Services"
  },
  {
    "code": "624110",
    "title": "Child and Youth Services"
  },
  {
    "code": "62412",
    "title": "Services for the Elderly and Persons with Disabilities"
  },
  {
    "code": "624120",
    "title": "Services for the Elderly and Persons with Disabilities"
  },
  {
    "code": "62419",
    "title": "Other Individual and Family Services"
  },
  {
    "code": "624190",
    "title": "Other Individual and Family Services"
  },
  {
    "code": "6242",
    "title": "Community Food and Housing, and Emergency and Other Relief Services"
  },
  {
    "code": "62421",
    "title": "Community Food Services"
  },
  {
    "code": "624210",
    "title": "Community Food Services"
  },
  {
    "code": "62422",
    "title": "Community Housing Services"
  },
  {
    "code": "624221",
    "title": "Temporary Shelters"
  },
  {
    "code": "624229",
    "title": "Other Community Housing Services"
  },
  {
    "code": "62423",
    "title": "Emergency and Other Relief Services"
  },
  {
    "code": "624230",
    "title": "Emergency and Other Relief Services"
  },
  {
    "code": "6243",
    "title": "Vocational Rehabilitation Services"
  },
  {
    "code": "62431",
    "title": "Vocational Rehabilitation Services"
  },
  {
    "code": "624310",
    "title": "Vocational Rehabilitation Services"
  },
  {
    "code": "6244",
    "title": "Child Day Care Services"
  },
  {
    "code": "62441",
    "title": "Child Day Care Services"
  },
  {
    "code": "624410",
    "title": "Child Day Care Services"
  },
  {
    "code": "71",
    "title": "Arts, Entertainment, and Recreation"
  },
  {
    "code": "711",
    "title": "Performing Arts, Spectator Sports, and Related Industries"
  },
  {
    "code": "7111",
    "title": "Performing Arts Companies"
  },
  {
    "code": "71111",
    "title": "Theater Companies and Dinner Theaters"
  },
  {
    "code": "711110",
    "title": "Theater Companies and Dinner Theaters"
  },
  {
    "code": "71112",
    "title": "Dance Companies"
  },
  {
    "code": "711120",
    "title": "Dance Companies"
  },
  {
    "code": "71113",
    "title": "Musical Groups and Artists"
  },
  {
    "code": "711130",
    "title": "Musical Groups and Artists"
  },
  {
    "code": "71119",
    "title": "Other Performing Arts Companies"
  },
  {
    "code": "711190",
    "title": "Other Performing Arts Companies"
  },
  {
    "code": "7112",
    "title": "Spectator Sports"
  },
  {
    "code": "71121",
    "title": "Spectator Sports"
  },
  {
    "code": "711211",
    "title": "Sports Teams and Clubs"
  },
  {
    "code": "711212",
    "title": "Racetracks"
  },
  {
    "code": "711219",
    "title": "Other Spectator Sports"
  },
  {
    "code": "7113",
    "title": "Promoters of Performing Arts, Sports, and Similar Events"
  },
  {
    "code": "71131",
    "title": "Promoters of Performing Arts, Sports, and Similar Events with Facilities"
  },
  {
    "code": "711310",
    "title": "Promoters of Performing Arts, Sports, and Similar Events with Facilities"
  },
  {
    "code": "71132",
    "title": "Promoters of Performing Arts, Sports, and Similar Events without Facilities"
  },
  {
    "code": "711320",
    "title": "Promoters of Performing Arts, Sports, and Similar Events without Facilities"
  },
  {
    "code": "7114",
    "title": "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures"
  },
  {
    "code": "71141",
    "title": "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures"
  },
  {
    "code": "711410",
    "title": "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures"
  },
  {
    "code": "7115",
    "title": "Independent Artists, Writers, and Performers"
  },
  {
    "code": "71151",
    "title": "Independent Artists, Writers, and Performers"
  },
  {
    "code": "711510",
    "title": "Independent Artists, Writers, and Performers"
  },
  {
    "code": "712",
    "title": "Museums, Historical Sites, and Similar Institutions"
  },
  {
    "code": "7121",
    "title": "Museums, Historical Sites, and Similar Institutions"
  },
  {
    "code": "71211",
    "title": "Museums"
  },
  {
    "code": "712110",
    "title": "Museums"
  },
  {
    "code": "71212",
    "title": "Historical Sites"
  },
  {
    "code": "712120",
    "title": "Historical Sites"
  },
  {
    "code": "71213",
    "title": "Zoos and Botanical Gardens"
  },
  {
    "code": "712130",
    "title": "Zoos and Botanical Gardens"
  },
  {
    "code": "71219",
    "title": "Nature Parks and Other Similar Institutions"
  },
  {
    "code": "712190",
    "title": "Nature Parks and Other Similar Institutions"
  },
  {
    "code": "713",
    "title": "Amusement, Gambling, and Recreation Industries"
  },
  {
    "code": "7131",
    "title": "Amusement Parks and Arcades"
  },
  {
    "code": "71311",
    "title": "Amusement and Theme Parks"
  },
  {
    "code": "713110",
    "title": "Amusement and Theme Parks"
  },
  {
    "code": "71312",
    "title": "Amusement Arcades"
  },
  {
    "code": "713120",
    "title": "Amusement Arcades"
  },
  {
    "code": "7132",
    "title": "Gambling Industries"
  },
  {
    "code": "71321",
    "title": "Casinos (except Casino Hotels)"
  },
  {
    "code": "713210",
    "title": "Casinos (except Casino Hotels)"
  },
  {
    "code": "71329",
    "title": "Other Gambling Industries"
  },
  {
    "code": "713290",
    "title": "Other Gambling Industries"
  },
  {
    "code": "7139",
    "title": "Other Amusement and Recreation Industries"
  },
  {
    "code": "71391",
    "title": "Golf Courses and Country Clubs"
  },
  {
    "code": "713910",
    "title": "Golf Courses and Country Clubs"
  },
  {
    "code": "71392",
    "title": "Skiing Facilities"
  },
  {
    "code": "713920",
    "title": "Skiing Facilities"
  },
  {
    "code": "71393",
    "title": "Marinas"
  },
  {
    "code": "713930",
    "title": "Marinas"
  },
  {
    "code": "71394",
    "title": "Fitness and Recreational Sports Centers"
  },
  {
    "code": "713940",
    "title": "Fitness and Recreational Sports Centers"
  },
  {
    "code": "71395",
    "title": "Bowling Centers"
  },
  {
    "code": "713950",
    "title": "Bowling Centers"
  },
  {
    "code": "71399",
    "title": "All Other Amusement and Recreation Industries"
  },
  {
    "code": "713990",
    "title": "All Other Amusement and Recreation Industries"
  },
  {
    "code": "72",
    "title": "Accommodation and Food Services"
  },
  {
    "code": "721",
    "title": "Accommodation"
  },
  {
    "code": "7211",
    "title": "Traveler Accommodation"
  },
  {
    "code": "72111",
    "title": "Hotels (except Casino Hotels) and Motels"
  },
  {
    "code": "721110",
    "title": "Hotels (except Casino Hotels) and Motels"
  },
  {
    "code": "72112",
    "title": "Casino Hotels"
  },
  {
    "code": "721120",
    "title": "Casino Hotels"
  },
  {
    "code": "72119",
    "title": "Other Traveler Accommodation"
  },
  {
    "code": "721191",
    "title": "Bed-and-Breakfast Inns"
  },
  {
    "code": "721199",
    "title": "All Other Traveler Accommodation"
  },
  {
    "code": "7212",
    "title": "RV (Recreational Vehicle) Parks and Recreational Camps"
  },
  {
    "code": "72121",
    "title": "RV (Recreational Vehicle) Parks and Recreational Camps"
  },
  {
    "code": "721211",
    "title": "RV (Recreational Vehicle) Parks and Campgrounds"
  },
  {
    "code": "721214",
    "title": "Recreational and Vacation Camps (except Campgrounds)"
  },
  {
    "code": "7213",
    "title": "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
  },
  {
    "code": "72131",
    "title": "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
  },
  {
    "code": "721310",
    "title": "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
  },
  {
    "code": "722",
    "title": "Food Services and Drinking Places"
  },
  {
    "code": "7223",
    "title": "Special Food Services"
  },
  {
    "code": "72231",
    "title": "Food Service Contractors"
  },
  {
    "code": "722310",
    "title": "Food Service Contractors"
  },
  {
    "code": "72232",
    "title": "Caterers"
  },
  {
    "code": "722320",
    "title": "Caterers"
  },
  {
    "code": "72233",
    "title": "Mobile Food Services"
  },
  {
    "code": "722330",
    "title": "Mobile Food Services"
  },
  {
    "code": "7224",
    "title": "Drinking Places (Alcoholic Beverages)"
  },
  {
    "code": "72241",
    "title": "Drinking Places (Alcoholic Beverages)"
  },
  {
    "code": "722410",
    "title": "Drinking Places (Alcoholic Beverages)"
  },
  {
    "code": "7225",
    "title": "Restaurants and Other Eating Places"
  },
  {
    "code": "72251",
    "title": "Restaurants and Other Eating Places"
  },
  {
    "code": "722511",
    "title": "Full-Service Restaurants"
  },
  {
    "code": "722513",
    "title": "Limited-Service Restaurants"
  },
  {
    "code": "722514",
    "title": "Cafeterias, Grill Buffets, and Buffets"
  },
  {
    "code": "722515",
    "title": "Snack and Nonalcoholic Beverage Bars"
  },
  {
    "code": "81",
    "title": "Other Services (except Public Administration)"
  },
  {
    "code": "811",
    "title": "Repair and Maintenance"
  },
  {
    "code": "8111",
    "title": "Automotive Repair and Maintenance"
  },
  {
    "code": "81111",
    "title": "Automotive Mechanical and Electrical Repair and Maintenance"
  },
  {
    "code": "811111",
    "title": "General Automotive Repair"
  },
  {
    "code": "811112",
    "title": "Automotive Exhaust System Repair"
  },
  {
    "code": "811113",
    "title": "Automotive Transmission Repair"
  },
  {
    "code": "811118",
    "title": "Other Automotive Mechanical and Electrical Repair and Maintenance"
  },
  {
    "code": "81112",
    "title": "Automotive Body, Paint, Interior, and Glass Repair"
  },
  {
    "code": "811121",
    "title": "Automotive Body, Paint, and Interior Repair and Maintenance"
  },
  {
    "code": "811122",
    "title": "Automotive Glass Replacement Shops"
  },
  {
    "code": "81119",
    "title": "Other Automotive Repair and Maintenance"
  },
  {
    "code": "811191",
    "title": "Automotive Oil Change and Lubrication Shops"
  },
  {
    "code": "811192",
    "title": "Car Washes"
  },
  {
    "code": "811198",
    "title": "All Other Automotive Repair and Maintenance"
  },
  {
    "code": "8112",
    "title": "Electronic and Precision Equipment Repair and Maintenance"
  },
  {
    "code": "81121",
    "title": "Electronic and Precision Equipment Repair and Maintenance"
  },
  {
    "code": "811211",
    "title": "Consumer Electronics Repair and Maintenance"
  },
  {
    "code": "811212",
    "title": "Computer and Office Machine Repair and Maintenance"
  },
  {
    "code": "811213",
    "title": "Communication Equipment Repair and Maintenance"
  },
  {
    "code": "811219",
    "title": "Other Electronic and Precision Equipment Repair and Maintenance"
  },
  {
    "code": "8113",
    "title": "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"
  },
  {
    "code": "81131",
    "title": "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"
  },
  {
    "code": "811310",
    "title": "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"
  },
  {
    "code": "8114",
    "title": "Personal and Household Goods Repair and Maintenance"
  },
  {
    "code": "81141",
    "title": "Home and Garden Equipment and Appliance Repair and Maintenance"
  },
  {
    "code": "811411",
    "title": "Home and Garden Equipment Repair and Maintenance"
  },
  {
    "code": "811412",
    "title": "Appliance Repair and Maintenance"
  },
  {
    "code": "81142",
    "title": "Reupholstery and Furniture Repair"
  },
  {
    "code": "811420",
    "title": "Reupholstery and Furniture Repair"
  },
  {
    "code": "81143",
    "title": "Footwear and Leather Goods Repair"
  },
  {
    "code": "811430",
    "title": "Footwear and Leather Goods Repair"
  },
  {
    "code": "81149",
    "title": "Other Personal and Household Goods Repair and Maintenance"
  },
  {
    "code": "811490",
    "title": "Other Personal and Household Goods Repair and Maintenance"
  },
  {
    "code": "812",
    "title": "Personal and Laundry Services"
  },
  {
    "code": "8121",
    "title": "Personal Care Services"
  },
  {
    "code": "81211",
    "title": "Hair, Nail, and Skin Care Services"
  },
  {
    "code": "812111",
    "title": "Barber Shops"
  },
  {
    "code": "812112",
    "title": "Beauty Salons"
  },
  {
    "code": "812113",
    "title": "Nail Salons"
  },
  {
    "code": "81219",
    "title": "Other Personal Care Services"
  },
  {
    "code": "812191",
    "title": "Diet and Weight Reducing Centers"
  },
  {
    "code": "812199",
    "title": "Other Personal Care Services"
  },
  {
    "code": "8122",
    "title": "Death Care Services"
  },
  {
    "code": "81221",
    "title": "Funeral Homes and Funeral Services"
  },
  {
    "code": "812210",
    "title": "Funeral Homes and Funeral Services"
  },
  {
    "code": "81222",
    "title": "Cemeteries and Crematories"
  },
  {
    "code": "812220",
    "title": "Cemeteries and Crematories"
  },
  {
    "code": "8123",
    "title": "Drycleaning and Laundry Services"
  },
  {
    "code": "81231",
    "title": "Coin-Operated Laundries and Drycleaners"
  },
  {
    "code": "812310",
    "title": "Coin-Operated Laundries and Drycleaners"
  },
  {
    "code": "81232",
    "title": "Drycleaning and Laundry Services (except Coin-Operated)"
  },
  {
    "code": "812320",
    "title": "Drycleaning and Laundry Services (except Coin-Operated)"
  },
  {
    "code": "81233",
    "title": "Linen and Uniform Supply"
  },
  {
    "code": "812331",
    "title": "Linen Supply"
  },
  {
    "code": "812332",
    "title": "Industrial Launderers"
  },
  {
    "code": "8129",
    "title": "Other Personal Services"
  },
  {
    "code": "81291",
    "title": "Pet Care (except Veterinary) Services"
  },
  {
    "code": "812910",
    "title": "Pet Care (except Veterinary) Services"
  },
  {
    "code": "81292",
    "title": "Photofinishing"
  },
  {
    "code": "812921",
    "title": "Photofinishing Laboratories (except One-Hour)"
  },
  {
    "code": "812922",
    "title": "One-Hour Photofinishing"
  },
  {
    "code": "81293",
    "title": "Parking Lots and Garages"
  },
  {
    "code": "812930",
    "title": "Parking Lots and Garages"
  },
  {
    "code": "81299",
    "title": "All Other Personal Services"
  },
  {
    "code": "812990",
    "title": "All Other Personal Services"
  },
  {
    "code": "813",
    "title": "Religious, Grantmaking, Civic, Professional, and Similar Organizations"
  },
  {
    "code": "8131",
    "title": "Religious Organizations"
  },
  {
    "code": "81311",
    "title": "Religious Organizations"
  },
  {
    "code": "813110",
    "title": "Religious Organizations"
  },
  {
    "code": "8132",
    "title": "Grantmaking and Giving Services"
  },
  {
    "code": "81321",
    "title": "Grantmaking and Giving Services"
  },
  {
    "code": "813211",
    "title": "Grantmaking Foundations"
  },
  {
    "code": "813212",
    "title": "Voluntary Health Organizations"
  },
  {
    "code": "813219",
    "title": "Other Grantmaking and Giving Services"
  },
  {
    "code": "8133",
    "title": "Social Advocacy Organizations"
  },
  {
    "code": "81331",
    "title": "Social Advocacy Organizations"
  },
  {
    "code": "813311",
    "title": "Human Rights Organizations"
  },
  {
    "code": "813312",
    "title": "Environment, Conservation and Wildlife Organizations"
  },
  {
    "code": "813319",
    "title": "Other Social Advocacy Organizations"
  },
  {
    "code": "8134",
    "title": "Civic and Social Organizations"
  },
  {
    "code": "81341",
    "title": "Civic and Social Organizations"
  },
  {
    "code": "813410",
    "title": "Civic and Social Organizations"
  },
  {
    "code": "8139",
    "title": "Business, Professional, Labor, Political, and Similar Organizations"
  },
  {
    "code": "81391",
    "title": "Business Associations"
  },
  {
    "code": "813910",
    "title": "Business Associations"
  },
  {
    "code": "81392",
    "title": "Professional Organizations"
  },
  {
    "code": "813920",
    "title": "Professional Organizations"
  },
  {
    "code": "81393",
    "title": "Labor Unions and Similar Labor Organizations"
  },
  {
    "code": "813930",
    "title": "Labor Unions and Similar Labor Organizations"
  },
  {
    "code": "81394",
    "title": "Political Organizations"
  },
  {
    "code": "813940",
    "title": "Political Organizations"
  },
  {
    "code": "81399",
    "title": "Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)"
  },
  {
    "code": "813990",
    "title": "Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)"
  },
  {
    "code": "814",
    "title": "Private Households"
  },
  {
    "code": "8141",
    "title": "Private Households"
  },
  {
    "code": "81411",
    "title": "Private Households"
  },
  {
    "code": "814110",
    "title": "Private Households"
  },
  {
    "code": "92",
    "title": "Public Administration"
  },
  {
    "code": "921",
    "title": "Executive, Legislative, and Other General Government Support"
  },
  {
    "code": "9211",
    "title": "Executive, Legislative, and Other General Government Support"
  },
  {
    "code": "92111",
    "title": "Executive Offices"
  },
  {
    "code": "921110",
    "title": "Executive Offices"
  },
  {
    "code": "92112",
    "title": "Legislative Bodies"
  },
  {
    "code": "921120",
    "title": "Legislative Bodies"
  },
  {
    "code": "92113",
    "title": "Public Finance Activities"
  },
  {
    "code": "921130",
    "title": "Public Finance Activities"
  },
  {
    "code": "92114",
    "title": "Executive and Legislative Offices, Combined"
  },
  {
    "code": "921140",
    "title": "Executive and Legislative Offices, Combined"
  },
  {
    "code": "92115",
    "title": "American Indian and Alaska Native Tribal Governments"
  },
  {
    "code": "921150",
    "title": "American Indian and Alaska Native Tribal Governments"
  },
  {
    "code": "92119",
    "title": "Other General Government Support"
  },
  {
    "code": "921190",
    "title": "Other General Government Support"
  },
  {
    "code": "922",
    "title": "Justice, Public Order, and Safety Activities"
  },
  {
    "code": "9221",
    "title": "Justice, Public Order, and Safety Activities"
  },
  {
    "code": "92211",
    "title": "Courts"
  },
  {
    "code": "922110",
    "title": "Courts"
  },
  {
    "code": "92212",
    "title": "Police Protection"
  },
  {
    "code": "922120",
    "title": "Police Protection"
  },
  {
    "code": "92213",
    "title": "Legal Counsel and Prosecution"
  },
  {
    "code": "922130",
    "title": "Legal Counsel and Prosecution"
  },
  {
    "code": "92214",
    "title": "Correctional Institutions"
  },
  {
    "code": "922140",
    "title": "Correctional Institutions"
  },
  {
    "code": "92215",
    "title": "Parole Offices and Probation Offices"
  },
  {
    "code": "922150",
    "title": "Parole Offices and Probation Offices"
  },
  {
    "code": "92216",
    "title": "Fire Protection"
  },
  {
    "code": "922160",
    "title": "Fire Protection"
  },
  {
    "code": "92219",
    "title": "Other Justice, Public Order, and Safety Activities"
  },
  {
    "code": "922190",
    "title": "Other Justice, Public Order, and Safety Activities"
  },
  {
    "code": "923",
    "title": "Administration of Human Resource Programs"
  },
  {
    "code": "9231",
    "title": "Administration of Human Resource Programs"
  },
  {
    "code": "92311",
    "title": "Administration of Education Programs"
  },
  {
    "code": "923110",
    "title": "Administration of Education Programs"
  },
  {
    "code": "92312",
    "title": "Administration of Public Health Programs"
  },
  {
    "code": "923120",
    "title": "Administration of Public Health Programs"
  },
  {
    "code": "92313",
    "title": "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"
  },
  {
    "code": "923130",
    "title": "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"
  },
  {
    "code": "92314",
    "title": "Administration of Veterans' Affairs"
  },
  {
    "code": "923140",
    "title": "Administration of Veterans' Affairs"
  },
  {
    "code": "924",
    "title": "Administration of Environmental Quality Programs"
  },
  {
    "code": "9241",
    "title": "Administration of Environmental Quality Programs"
  },
  {
    "code": "92411",
    "title": "Administration of Air and Water Resource and Solid Waste Management Programs"
  },
  {
    "code": "924110",
    "title": "Administration of Air and Water Resource and Solid Waste Management Programs"
  },
  {
    "code": "92412",
    "title": "Administration of Conservation Programs"
  },
  {
    "code": "924120",
    "title": "Administration of Conservation Programs"
  },
  {
    "code": "925",
    "title": "Administration of Housing Programs, Urban Planning, and Community Development"
  },
  {
    "code": "9251",
    "title": "Administration of Housing Programs, Urban Planning, and Community Development"
  },
  {
    "code": "92511",
    "title": "Administration of Housing Programs"
  },
  {
    "code": "925110",
    "title": "Administration of Housing Programs"
  },
  {
    "code": "92512",
    "title": "Administration of Urban Planning and Community and Rural Development"
  },
  {
    "code": "925120",
    "title": "Administration of Urban Planning and Community and Rural Development"
  },
  {
    "code": "926",
    "title": "Administration of Economic Programs"
  },
  {
    "code": "9261",
    "title": "Administration of Economic Programs"
  },
  {
    "code": "92611",
    "title": "Administration of General Economic Programs"
  },
  {
    "code": "926110",
    "title": "Administration of General Economic Programs"
  },
  {
    "code": "92612",
    "title": "Regulation and Administration of Transportation Programs"
  },
  {
    "code": "926120",
    "title": "Regulation and Administration of Transportation Programs"
  },
  {
    "code": "92613",
    "title": "Regulation and Administration of Communications, Electric, Gas, and Other Utilities"
  },
  {
    "code": "926130",
    "title": "Regulation and Administration of Communications, Electric, Gas, and Other Utilities"
  },
  {
    "code": "92614",
    "title": "Regulation of Agricultural Marketing and Commodities"
  },
  {
    "code": "926140",
    "title": "Regulation of Agricultural Marketing and Commodities"
  },
  {
    "code": "92615",
    "title": "Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors"
  },
  {
    "code": "926150",
    "title": "Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors"
  },
  {
    "code": "927",
    "title": "Space Research and Technology"
  },
  {
    "code": "9271",
    "title": "Space Research and Technology"
  },
  {
    "code": "92711",
    "title": "Space Research and Technology"
  },
  {
    "code": "927110",
    "title": "Space Research and Technology"
  },
  {
    "code": "928",
    "title": "National Security and International Affairs"
  },
  {
    "code": "9281",
    "title": "National Security and International Affairs"
  },
  {
    "code": "92811",
    "title": "National Security"
  },
  {
    "code": "928110",
    "title": "National Security"
  },
  {
    "code": "92812",
    "title": "International Affairs"
  },
  {
    "code": "928120",
    "title": "International Affairs"
  }
];