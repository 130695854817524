import { cloneDeep } from 'lodash';
import { AuditDetails, AuditQuestion, AuditSection } from '../../../services/audit/auditModels';

export type AuditResponseUpdateCache = {
  [responseId: number]: string;
};

export type AuditQuestionSwaps = {[responseId: number]: AuditQuestion};

export function getMaxUpdatedAt(section: AuditSection): AuditResponseUpdateCache {
  const updateCache: AuditResponseUpdateCache = {};
  for (const question of section.content) {
    updateCache[question.responseId] = question.updatedAt.toString();
  }
  return updateCache;
}

/* eslint-disable no-prototype-builtins */
export function swapAuditContent(section: AuditSection, responsesToSwap: AuditQuestionSwaps): AuditSection {
  const newSection = cloneDeep(section);
  for (const question of newSection.content) {
    if (responsesToSwap.hasOwnProperty(question.responseId)) {
      question.answer = responsesToSwap[question.responseId].answer;
      question.answerJSON = responsesToSwap[question.responseId].answerJSON;
      question.updatedAt = responsesToSwap[question.responseId].updatedAt;
      question.updatedBy = responsesToSwap[question.responseId].updatedBy;
    }
  }
  return newSection;
}

export async function syncAssessmentContent(auditId: number, sectionId: string): Promise<ContentSyncResult> {
  try {
    const result = await fetch(`/assessment/content/${auditId}/${sectionId}`);
    if (!result.ok) {
      throw new Error('Could not get locks');
    } 
    const resp = await result.json();
    return resp;
  } catch (err) {
    // an error here should be logged on the server
    // and does not have a clear resolution path
    // either the user is trying to access something they do not have privilege for
    // or the server is down
  }
}

export interface ContentSyncResult {
  content: AuditSection;
  elements: AuditSection[];
  details: AuditDetails;
}