import React, { useEffect, useState } from 'react';
import { SiteType, siteTypeNames } from '../../services/company/companyModels';
import { StateInfo } from '../../services/system';
import { ProfileDetails } from '../../services/user/userModels';

export default function CreateSiteForm(props: CreateSiteFormProps): JSX.Element {
  const [isFederal, setIsFederal] = useState<boolean>();
  const [managerEmail, setManagerEmail] = useState<string>();
  const [managerFirstName, setManagerFirstName] = useState<string>();
  const [managerLastName, setManagerLastName] = useState<string>();
  const [siteManager, setSiteManager] = useState<string>(props.isCreatedBySysadmin ? 'other' : 'me');
  const [siteName, setSiteName] = useState<string>();
  const [siteType, setSiteType] = useState<SiteType>();
  const [stateId, setStateId] = useState<string>();

  const [invalidStateSelected, setInvalidStateSelected] = useState(false);
  const [selectedState, setSelectedState] = useState<StateInfo>();
  useEffect(() => {
    setSelectedState(props.states.find(state => state.stateId === stateId) as StateInfo);
    setInvalidStateSelected(!!selectedState && !selectedState.hasProtocol && !isFederal);
  }, [stateId, isFederal]);

  useEffect(() => {
    const knownUser = props.companyUsers.find(user => user.email.toLowerCase() === (managerEmail ?? '').toLowerCase());
    if (knownUser) {
      setManagerFirstName(knownUser.firstName);
      setManagerLastName(knownUser.lastName);
    }
  }, [managerEmail]);

  return <div className='card'>
    <header className='card-header'>
      <p className='card-header-title'>Add a site</p>
    </header>
    <div className='card-content'>
      <form action={props.endpoint} method='post'>
        <input type='hidden' name='companyId' value={props.companyId} />
        <div className='field'>
          <p className="control">
            <input
              className='input' required type='text' placeholder='Site name' name='siteName'
              value={siteName} onChange={evt => setSiteName(evt.currentTarget.value)} />
          </p>
        </div>
        <div className='field'>
          <div className='select'>
            <select value={siteType} onChange={evt => setSiteType(evt.currentTarget.value as SiteType)} name='siteType'>
              {Object.keys(siteTypeNames).map((siteType: SiteType) =>
                <option
                  key={siteType}
                  value={siteType}
                  label={siteTypeNames[siteType]}
                />
              )}
            </select>
          </div>
        </div>
        <div className='field'>
          <label className='checkbox'>
            <input type='checkbox' checked={isFederal} onChange={evt => setIsFederal(evt.currentTarget.checked)} name='isFederal' /> This is a Federal VPP Program site
          </label>
        </div>
        <div className='field'>
          <div className='select'>
            <select required name='state' value={stateId} onChange={evt => setStateId(evt.currentTarget.value)}>
              <option value=''>Select a state or region</option>
              {props.states.map(state => <option key={state.stateId} value={state.stateId}>{state.name}</option>)}
            </select>
          </div>
          {
            siteType === 'mobile' &&
            <p className='help'>If this site is a mobile workforce, please pick any state in which the workforce operates</p>
          }
          {
            selectedState && invalidStateSelected &&
            <p className='help is-danger'>
              Sorry, we don't have an annual self-evaluation form for {selectedState.name}.<br />
              If applicable, you may use the federal evaluation instead by selecting <em>This is a Federal VPP Program site</em>,
              or you can <a href={`mailto:vppsupport@auditsoft.co?subject=VPP self-evaluation form for ${selectedState.name}`}>contact AuditSoft support</a> for more information.
            </p>
          }
        </div>
        <h2 className='subtitle mt-3 mb-1'>Who will manage this site?</h2>
        {
          props.isCreatedBySysadmin ?
            <div className='field'>
              <div className='select'>
                <select required name='createdBy'>
                  <option value=''>Select a manager</option>
                  {props.companyUsers.map(user => <option key={user.userId} value={user.userId}>
                    {user.firstName} {user.lastName} &lt;{user.email}&gt;
                  </option>)}
                </select>
              </div>
            </div>
            : <>
              <div className='field'>
                <div className='control site-admin-radio-control'>
                  <label className={`radio ${siteManager === 'me' ? 'selected' : ''}`}>
                    <input
                      type='radio' name='siteManager' value='me'
                      checked={siteManager === 'me'} onChange={evt => evt.currentTarget.checked && setSiteManager('me')} />
                  &nbsp;Me
                  </label>
                  <label className={`radio ${siteManager === 'other' ? 'selected' : ''}`}>
                    <input
                      type='radio' name='siteManager' value='other'
                      checked={siteManager === 'other'} onChange={evt => evt.currentTarget.checked && setSiteManager('other')} />
                  &nbsp;Someone else
                  </label>
                </div>
              </div>
              <div className={`field is-grouped is-collapsible collapse-height-100 ${siteManager === 'other' ? '' : 'is-closed'}`}>
                {
                  siteManager === 'other' &&
                <React.Fragment>
                  <p className='control'>
                    <input
                      className='input' type='email' placeholder='Email' name='managerEmail' list='userlist' required
                      value={managerEmail} onChange={evt => setManagerEmail(evt.currentTarget.value)} />
                  </p>
                  <p className='control'>
                    <input
                      className='input' type='text' placeholder='First name' name='managerFirstName'
                      value={managerFirstName} onChange={evt => setManagerFirstName(evt.currentTarget.value)} />
                  </p>
                  <p className='control'>
                    <input
                      className='input' type='text' placeholder='Last name' name='managerLastName'
                      value={managerLastName} onChange={evt => setManagerLastName(evt.currentTarget.value)} />
                  </p>
                </React.Fragment>
                }
              </div>
            </>
        }
        <div className='field'>
          <button type='submit' className='button is-primary' disabled={invalidStateSelected}>
            {props.isCreatedBySysadmin ? 'Create' : 'Send request'}
          </button>
        </div>
        <datalist id='userlist'>
          {props.companyUsers.map(user => <option key={user.userId} value={user.email}>
            {user.firstName} {user.lastName} &lt;{user.email}&gt;
          </option>)}
        </datalist>
      </form>
    </div>
  </div>;
}

export type CreateSiteFormProps = {
  endpoint: string;
  companyId?: number;
  states: StateInfo[];
  companyUsers: ProfileDetails[];
  isCreatedBySysadmin: boolean;
};