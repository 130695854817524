import { cloneDeep } from 'lodash';
import * as Quill from 'quill';

type ImageUploadModuleOptions = {
  auditId: number;
};

export default class ImageUploadModule {
  private uploading = false;
  private readonly dataUrlRegex = /^data:image/;

  constructor(private quill: Quill.Quill, private options: ImageUploadModuleOptions) {
    quill.on('text-change', (delta) => {
      if (this.uploading) return;

      if (this.deltaIncludesDataUrls(delta)) {
        this.uploadAllImagesThatNeedUploading();
      }
    });
  }

  private findInsertDataUrlDeltaOperations(delta: Quill.Delta) {
    return delta.ops.filter(
      deltaOperation => deltaOperation?.insert?.image?.match(this.dataUrlRegex)
    );
  }

  public deltaIncludesDataUrls(delta: Quill.Delta): boolean {
    return delta.ops.some(
      deltaOperation => deltaOperation?.insert?.image?.match(this.dataUrlRegex)
    );
  }

  private async uploadAllImagesThatNeedUploading() {
    this.uploading = true;
    this.quill.disable();
    const cursorPos = this.quill.getSelection().index;
    const newDeltaClone = cloneDeep(this.quill.getContents()) as Quill.Delta;
    const dataUrlImageObjectsInClone = this.findInsertDataUrlDeltaOperations(newDeltaClone);

    try {
      await Promise.all(
        dataUrlImageObjectsInClone.map(async (deltaOperation: any) => {
          const dataUrl = deltaOperation.insert.image;
          const newUrl = await this.createUpload(dataUrl);
          return deltaOperation.insert.image = newUrl;
        })
      );
    } finally {
      this.quill.enable();
      this.uploading = false;
      this.quill.setContents(newDeltaClone, 'user');
      this.quill.focus();
      this.quill.setSelection(cursorPos, 0, 'user');
    }
  }

  private async createUpload(data: string): Promise<string> {
    // Split up string of format
    // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAaQAA
    const [metadata] = data.split(';');
    const mimetype = metadata.split(':')[1];
    const extension = '.' + mimetype.split('/')[1];
    const filename = `image${extension}`;

    const { url, attachment, downloadUrl } = await (await fetch(
      `/attachment`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          auditId: this.options.auditId,
          filename,
          mimetype,
          extension
        })
      }
    )).json();

    const imageBuffer = await (await fetch(data)).arrayBuffer();
    const file = new File([imageBuffer], filename, { type: mimetype });

    await fetch(url, { method: 'PUT', body: file });
    await fetch(
      '/attachment/finalise',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          auditId: this.options.auditId,
          attachmentId: attachment.attachmentId
        })
      }
    );

    return downloadUrl;
  }
}
