import React from 'react';
import { AssessmentSidebar } from '..';
import { Attachment, AuditSection, SiteContributorAssignments } from '../../../services/audit/auditModels';
import FileIcon from './fileIcon';

class AttachedFilesProps {
  // assessment sidebar items
  auditId: number;
  calendarYear?: number;
  canEdit: boolean;
  canLock: boolean;
  siteId: number;
  dueDate: string;
  elements: AuditSection[];
  isLockPage: boolean;
  isLinkedFilesPage: boolean;
  submittedOn: string;
  loggedInUserId: number;
  siteContributorAssignments: SiteContributorAssignments[];

  // attached files
  files: Attachment[];
}

export default class AttachedFilesView extends React.Component<AttachedFilesProps, null> {
  private files?: Attachment[];

  constructor(props: AttachedFilesProps) {
    super(props);
    this.files = props.files;
  }

  render(): JSX.Element {
    return <React.Fragment>
      <AssessmentSidebar
        auditId={this.props.auditId}
        calendarYear={this.props.calendarYear}
        canLock={this.props.canLock}
        siteId={this.props.siteId}
        dueDate={this.props.dueDate}
        elements={this.props.elements}
        isLockPage={this.props.isLockPage}
        isLinkedFilesPage={this.props.isLinkedFilesPage}
        submittedOn={this.props.submittedOn}
        siteContributorAssignments={this.props.siteContributorAssignments}
        loggedInUserId={this.props.loggedInUserId}
      />

      <div className='main'>
        <div className='section'>
          <div className='container'>
            <div className="card-content-title">
              <h1 className="attachment-list-title title mt-4">Linked Files</h1>
            </div>
            <div className="content">
              {this.files.length === 0 ?
                <div className="subtitle">This evaluation doesn't have any attachments</div> :
                <table className="table attachment-list-table">
                  <thead>
                    <tr>
                      <th>File</th>
                      <th>Linked to</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.files.map(file =>
                      <tr key={file.attachmentId}>
                        <td>
                          <div className="attachment-name-container">
                            <a href={`/attachment/${file.attachmentId}`}>
                              <div className='icon is-large'><FileIcon file={file} /></div>
                              <div className='attachment-name'>{file.filename}</div>
                            </a>
                          </div>
                        </td>
                        <td className="attachment-linked-to">
                          <a href={`/assessment/edit/${file.auditId}/${file.sectionId}#${file.questionId}-${file.attachmentId}`}>{file.sectionId}</a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>;
  }
}