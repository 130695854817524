import React from 'react';
import { AuditSection, SiteContributorAssignments } from '../../../services/audit/auditModels';
import { SiteSwitcherWidget } from '../siteSwitcherWidget';
import { clearCookie, getCookieValue, setCookieValue } from '../utility';

export default class AssessmentSidebar extends React.Component<AssessmentSidebarProps, AssessmentSidebarState> {
  constructor(props: AssessmentSidebarProps) {
    super(props);
    const filterCookieValue = getCookieValue('filterUserId');
    const selectedUserIdForFilter = filterCookieValue ? Number(filterCookieValue) : null;
    this.state = {
      selectedUserIdForFilter,
      showFilterDropdown: false
    };
  }

  componentDidMount(): void {
    const fieldRef = document.getElementsByClassName('assessment-element-selected')[0];
    if (fieldRef !== undefined) {
      fieldRef.scrollIntoView({ block: 'nearest' });
    }
  }

  render(): React.ReactElement {
    return <div className='sidebar' >
      <SiteSwitcherWidget 
        backLink={`/dashboard/site/${this.props.siteId}`}
        siteId={this.props.siteId}
        saveContent={() => this.props.saveContent()} />
      <div id='assessment-info' className={`assessment-info ${this.props.calendarYear ? '' : 'is-hidden'}`}>
        <aside className='is-size-7'>VPP annual self evaluation for calendar year</aside>
        <h1 id='calendar-year'>
          {this.props.calendarYear}
        </h1>
        <p>Due date: <strong>
          {this.props.dueDate}, <span id='next-calendar-year'>
            {this.props.calendarYear + 1}
          </span>
        </strong></p>
      </div>
      {
        this.areThereAuditAssignments() &&
        <div className='is-flex'>
          <div className='dropdown is-align-self-flex-end mb-2'>
            <div className='dropdown-trigger' onClick={() => this.setState({showFilterDropdown: true})}>
              <button className='button is-small' >
                <span className='icon is-small'>
                  <i className='fas fa-filter' aria-hidden='true'></i>
                </span>
                <span>Filter assigned sections</span>
              </button>
            </div>
            {
              this.state.showFilterDropdown &&
            <div className='dropdown-menu'>
              <div className='dropdown-content p-0'>
                {
                  this.getAssignmentFilterOptions()?.map((option, idx) => <button key={idx} type='button'
                    className={`dropdown-item is-size-7 ${option.userId === this.state.selectedUserIdForFilter ? 'is-active' : ''}`}
                    onClick={() => this.setFilterUserId(option.userId)}
                  >
                    {option.displayName}
                  </button>
                  )
                }
              </div>
            </div>
            }
          </div>
          {
            !!this.state.selectedUserIdForFilter &&
          <button className='button is-align-self-flex-start is-small ml-2' onClick={() => this.clearFilter()}>
            <span className='icon'>
              <i className='fas fa-trash'></i>
            </span>
            <span>Clear</span>
          </button>
          }
        </div>
      }
      <ul className='assessment-element-list'>
        {this.getNavigatableSections().map(element =>
          <li key={element.id} className={
            (this.props.isLockPage ? '' : (element.id === this.props.content?.id ? 'assessment-element-selected' : ''))
              + (element.colour ? ' assessment-element-indent' : '')
          } onClick={() => this.props.saveContent()}>
            <a href={`/assessment/edit/${this.props.auditId}/${element.id}`}>
              <span className='sidebar-section-number'>{element.id}</span>{element.title}
            </a>
          </li>
        )}
        <li className={
          (this.props.isLinkedFilesPage ? 'assessment-element-selected' : '')
        } onClick={() => this.props.saveContent()}>
          <a href={`/assessment/files/${this.props.auditId}`}><i className='fas fa-file mr-2' />Linked Files</a>
        </li>
        {
          this.props.canLock &&
          <li className={
            this.props.isLockPage ? 'assessment-element-selected' : '' +
              this.props.canLock ? '' : ' assessment-element-dimmed'
          } onClick={() => this.props.saveContent()}>
            {
              this.props.submittedOn
                ? <a href={`/assessment/unlock/${this.props.auditId}`}><i className='fas fa-lock-open mr-2' /> Unlock</a>
                : <a href={`/assessment/lock/${this.props.auditId}`}><i className='fas fa-lock mr-2' />Lock</a>
            }
          </li>
        }
      </ul>
      <div className='done-button' onClick={() => this.props.saveContent()}>
        <a href={`/dashboard/site/${this.props.siteId}`} title='Done'><i className="fas fa-sign-out-alt"></i> Done</a>
      </div>
      
    </div>;
  }

  setFilterUserId(filterUserId: number): void {
    setCookieValue('filterUserId', '/assessment', filterUserId);
    this.setState({selectedUserIdForFilter: filterUserId, showFilterDropdown: false});
  }

  getNavigatableSections(): AuditSection[] {
    const { elements } = this.props;
    const { selectedUserIdForFilter } = this.state;

    if (!selectedUserIdForFilter) {
      return elements;
    }
    const selectedUserForFilter = this.props.siteContributorAssignments?.find(contributor => contributor.userDetails.userId === selectedUserIdForFilter);
    const sectionsAssignedToUser = new Set<string>([
      ...selectedUserForFilter.sectionAssignments.map(assignment => assignment.sectionId),
      ...selectedUserForFilter.questionAssignments.map(assignment => assignment.sectionId)
    ]);
    return elements.filter(section => sectionsAssignedToUser.has(section.sectionId) || section.layout === 'guidelines only');
  }

  clearFilter() {
    clearCookie('filterUserId', '/assessment');
    this.setState({selectedUserIdForFilter: null});
  }

  getAssignmentFilterOptions(): FilterOption[] {
    const filterOptions: FilterOption[] = [];
    const contributorsWithAssignments = this.props.siteContributorAssignments
      ?.filter(contributor => contributor.questionAssignments?.length ?? contributor.sectionAssignments?.length);

    contributorsWithAssignments.forEach(contributor => {
      const isLoggedUser = contributor.userDetails.userId === this.props.loggedInUserId;
      const userName = isLoggedUser ? 'Assigned to me' : `Assigned to ${contributor.userDetails.name}`;

      const option: FilterOption = { userId: contributor.userDetails.userId, displayName: userName };
      if (isLoggedUser) {
        filterOptions.unshift(option);
      } else {
        filterOptions.push(option);
      }
    });

    return filterOptions;
  }

  areThereAuditAssignments(): boolean {
    const assignments = [];
    this.props.siteContributorAssignments.forEach(contributor => {
      if (contributor.sectionAssignments?.length || contributor.questionAssignments?.length) {
        assignments.push(contributor);
      }
    });
    return !!assignments.length;
  }
}

interface AssessmentSidebarProps {
  auditId: number;
  calendarYear: number;
  canLock: boolean;
  content?: AuditSection;
  siteId: number;
  dueDate: string;
  elements: AuditSection[];
  isLockPage: boolean;
  isLinkedFilesPage: boolean;
  submittedOn: string;
  loggedInUserId: number;
  siteContributorAssignments: SiteContributorAssignments[];
  saveContent?: () => Promise<void>;
}

export type AssessmentSidebarState = {
  selectedUserIdForFilter: number;
  showFilterDropdown: boolean;
};

type FilterOption = {
  userId: number;
  displayName: string;
};