import React from 'react';
import { MessageSource } from '.';

export default class NotifyWidget extends React.Component<NotifyWidgetProps, NotifyWidgetState> {
  constructor(props: NotifyWidgetProps) {
    super(props);
    this.state = new NotifyWidgetState;
    props.messageSource.register((message) => this.setMessage(message));
  }

  setMessage(message: string): void {
    this.setState({ message });
    setTimeout(() => {
      this.setState({ message: null });
    }, 3000);
  }

  render(): JSX.Element {
    return (this.state.message
      ? <div className='notify-widget tag is-medium'>
        {this.state.message}
      </div>
      : <React.Fragment />
    );
  }
}

interface NotifyWidgetProps {
  messageSource: MessageSource;
}

class NotifyWidgetState {
  message: string;
}
