import React from 'react';
import { Lock } from './editLock';

type AssessmentLockProps = {
  editLock: boolean;
  lock: Lock;
};

/* eslint-disable @typescript-eslint/naming-convention */
export function AssessmentLock(props: AssessmentLockProps): JSX.Element {
  const lockMsg = props.editLock ? `You are editing` : `Being edited by ${props.lock.username}`;
  const title = props.editLock ? 'No one else can edit this while you are.' : `You cannot edit this until ${props.lock.username} stops editing.`;
  return <span className='assessment-lock tag is-link' title={title}>{lockMsg}</span>;
}

type PlainTextLockProps = {
  editLock: boolean;
  lock: Lock;
};

/* eslint-disable @typescript-eslint/naming-convention */
export function PlainTextLock(props: PlainTextLockProps): JSX.Element {
  const lockMsg = props.editLock ? `You are editing` : `Being edited by ${props.lock.username}`;
  const title = props.editLock ? 'No one else can edit this while you are.' : `You cannot edit this until ${props.lock.username} stops editing.`;
  return <span className='plaintext-editor tag is-link' title={title}>{lockMsg}</span>;
}

type LockErrorModalProps = {
  isVisible: boolean;
  title: string;
  message?: string;
  children?: React.ReactNode;
  close: () => void;
};

/* eslint-disable @typescript-eslint/naming-convention */
export function LockErrorModal(props: LockErrorModalProps): JSX.Element {
  return <div className={props.isVisible ? 'modal is-active lock-error' : 'modal'}>
    <div className='modal-background lock-error' onClick={() => props.close()}></div>
    <div className='modal-content lock-error lock-error-msg'>
      <div className='has-text-centered'>
        <b>{props.title}</b> <br /><br />
      </div>
      {props.message}
      {props.children}
    </div>
    <button className='modal-close is-large lock-error' aria-label='close' onClick={() => props.close()}></button>
  </div>;
}