import { CompanySitesListItem } from '../../../services/company/companyModels';

export type SaveCompanySitesResult = {
  errors?: string[];
  companySites: CompanySitesListItem[];
};

export enum CompanySitesActionType {
  populate,
  updateSite
}

export type CompanySitesAction = {
  type: CompanySitesActionType.populate;
  payload: {
    companySites: CompanySitesListItem[];
  };
} | {
  type: CompanySitesActionType.updateSite;
  payload: {
    companyId: number;
    siteId: number;
    key: 'status' | 'memberId';
    newValue: string;
  };
};