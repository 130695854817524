import React from 'react';
import { ProfileDetails, siteRoles } from '../../services/user/userModels';

export default class InviteForm extends React.Component<InviteFormProps, null> {
  public render(): JSX.Element {
    return <div className='card'>
      <header className='card-header'>
        <p className='card-header-title'>{this.props.title || 'Invite a user'}</p>
      </header>
      <div className='card-content'>
        <form action='/user/invite' method='POST' className='invite-form'>
          {this.props.siteId && <input type='hidden' name='siteId' value={this.props.siteId} />}
          {this.props.redirectTo && <input type='hidden' name='redirectTo' value={this.props.redirectTo} />}
          <div className='field is-horizontal'>
            <div className='field-label is-normal'><label className='label'>Email address</label></div>
            <div className='field-body'><div className='field'><p className='control is-expanded'>
              <input type='email' required className='input' name='email' list='userlist' onChange={evt => this.setInviteEmail(evt.currentTarget.value)} />
            </p></div></div>
          </div>
          <div className='field is-horizontal'>
            <div className='field-label is-normal'><label className='label'>First name</label></div>
            <div className='field-body'><div className='field'><p className='control is-expanded'><input type='text' required className='input' name='firstName' /></p></div></div>
          </div>
          <div className='field is-horizontal'>
            <div className='field-label is-normal'><label className='label'>Last name</label></div>
            <div className='field-body'><div className='field'><p className='control is-expanded'><input type='text' required className='input' name='lastName' /></p></div></div>
          </div>
          {
            this.props.showRoles &&
            <div className='field is-horizontal'>
              <div className='field-label is-normal'><label className='label'>Role</label></div>
              <div className='field-body'><div className='field'><p className='control'>
                <div className='select'>
                  <select name='role'>
                    {siteRoles.map(role => <option key={role.value} value={role.value}>{role.name}</option>)}
                  </select>
                </div>
              </p></div></div>
            </div>
          }
          <div className='field is-horizontal'>
            <div className='field-label is-normal'></div>
            <div className='field-body'><div className='field'><p className='control'><button type='submit' className='button is-primary'>Invite</button></p></div></div>
          </div>
        </form>
      </div>
      <datalist id='userlist'>
        {this.props.users.map(user => <option key={user.userId} value={user.email}>
          {user.firstName} {user.lastName} &lt;{user.email}&gt;
        </option>)}
      </datalist>
    </div>;
  }

  setInviteEmail(email: string): void {
    const knownUser = this.props.users.find(user => user.email.toLowerCase() === email.toLowerCase());
    if (knownUser) {
      // Not using react state for this, as we're using these fields as a raw HTML form
      (document.querySelector('.invite-form input[name="firstName"]') as HTMLInputElement).value = knownUser.firstName;
      (document.querySelector('.invite-form input[name="lastName"]') as HTMLInputElement).value = knownUser.lastName;
    }
  }
}

class InviteFormProps {
  siteId?: number;
  redirectTo?: string;
  showRoles: boolean;
  users: ProfileDetails[];
  title?: string;
}
