import * as React from 'react';
import { SiteListItem } from 'services/company/companyModels';

export function InviteUserModal(props: InviteUserModalProps): JSX.Element {
  return <>
    <div className='modal is-active'>
      <div className='modal-background' onClick={() => props.onCancel()}></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Invite user</p>
          <button className='delete' aria-label='close' onClick={() => props.onCancel()}></button>
        </header>
        <section className='modal-card-body'>
          <form action='/admin/add_site_user' method='post' id='invite-user-form'>
            <input type='hidden' name='siteId' value={props.site.siteId}/>
            <input type='hidden' name='companyId' value={props.companyId}/>
            <input type='hidden' name='redirectUrl' value={props.redirectUrl}/>
            <div className='card-content'>
              <div className='field'>
                <p className='control has-icons-left has-icons-right'>
                  <input className='input' name='firstName' required type='text' placeholder='First name'></input>
                  <span className='icon is-small is-left'>
                    <i className='fas fa-user'></i>
                  </span>
                </p>
              </div>
              <div className='field'>
                <p className='control has-icons-left has-icons-right'>
                  <input className='input' name='lastName' required type='text' placeholder='Last name'></input>
                  <span className='icon is-small is-left'>
                    <i className='fas fa-user'></i>
                  </span>                   
                </p>
              </div>
              <div className='field'>
                <p className='control has-icons-left has-icons-right'>
                  <input className='input' name='email' required type='text' placeholder='Email'></input>
                  <span className='icon is-small is-left'>
                    <i className='fas fa-envelope'></i>
                  </span>
                </p>
              </div>
              <div className='select'>
                <select name='role' required>
                  <option value=''>Pick a role</option>
                  <option value='manager'>Site Manager</option>
                  <option value='auditor'>Contributor</option>
                  <option value='viewer'>Viewer</option>
                </select>
              </div>
            </div>
          </form>
        </section>
        <footer className='modal-card-foot'>
          <button className='button is-success' form='invite-user-form' type='submit'>Add</button>
          <button className='button' onClick={() => props.onCancel()}>Cancel</button>
        </footer>
      </div>
    </div>
  </>;
}

type InviteUserModalProps = {
  companyId: number;
  site: SiteListItem;
  redirectUrl: string;
  onCancel: () => void;
};