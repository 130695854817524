import * as React from 'react';
import { AdminSiteUpdateRequest, CompanySitesListItem, SiteListItem } from '../../../services/company/companyModels';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { reducer } from './reducer';
import { buildClassName } from '../utility';
import { CompanySitesActionType, SaveCompanySitesResult } from './models';
import { SiteRow } from './siteTable';

interface ManageSitesViewProps {
  companySites: CompanySitesListItem[];
}

export function ManageSitesView(props: ManageSitesViewProps): JSX.Element {
  const [errors, setErrors] = useState<string[]>([]);
  const [initialCompanySites, setInitialCompanySites] = useState(
    props.companySites
  );
  const [saving, setSaving] = useState(false);
  const [filterSearch, _setFilterSearch] = useState('');
  const setFilterSearch = (search: string) =>
    _setFilterSearch(search.toLocaleLowerCase());
  const [companySites, dispatch] = useReducer(reducer, initialCompanySites);
  const filteredCompanySites = useMemo(() => companySites.filter(company =>
    company.name.toLocaleLowerCase().includes(filterSearch) ||
    company.sites.some(site =>
      site.name.toLocaleLowerCase().includes(filterSearch) ||
      site.memberId.toLocaleLowerCase().includes(filterSearch) ||
      site.type.toLocaleLowerCase().includes(filterSearch)
    )
  ), [companySites, filterSearch]);

  // Prevent user from leaving page without saving changes
  useEffect(() => {
    const listener = (evt: BeforeUnloadEvent) => {
      const changedSites = getChangedSites(initialCompanySites, companySites);
      if (changedSites.length) {
        evt.preventDefault();
      }
    };
    window.addEventListener('beforeunload', listener);
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  });

  return <>
    {errors.map((message, i) =>
      <div key={i} className='notification is-danger mb-2 p-3'>
        {message}
      </div>
    )}
    <div className='is-flex mb-2'>
      <div className={buildClassName(
        'control has-icons-left is-flex-grow-1 mr-2',
        filterSearch.length && 'has-icons-right'
      )}>
        <input
          className="input"
          type="email"
          placeholder="Filter companies by company or site data"
          value={filterSearch}
          onChange={evt => setFilterSearch(evt.currentTarget.value)}
        />
        <span className="icon is-small is-left">
          <i className="fas fa-search"></i>
        </span>
        {filterSearch.length > 0 &&
          <span
            className="icon is-small is-right has-text-black is-clickable"
            onClick={() => setFilterSearch('')}
          >
            <i className="fas fa-times"></i>
          </span>
        }
      </div>
      <button
        className="button is-success mr-2"
        onClick={async () => {
          if (saving) return;
          setSaving(true);
          const changes = getChangedSites(initialCompanySites, companySites);
          let result: SaveCompanySitesResult;
          try {
            result = await save(changes);
          } catch (err) {
            console.error(err);
            setErrors(['Unable to save: Unknown error. Please try again.']);
            return;
          } finally {
            setSaving(false);
          }
          setErrors(result.errors ?? []);
          setInitialCompanySites(result.companySites);
          dispatch({
            type: CompanySitesActionType.populate,
            payload: {companySites: result.companySites}
          });
        }}
      >
        {saving && <span className="icon">
          <i className='fas fa-spinner fa-spin' />
        </span>}
        <span>{saving ? 'Saving' : 'Save'}</span>
      </button>
      <button
        className="button is-info"
        onClick={() => {
          const changes = getChangedSites(initialCompanySites, companySites);
          if (changes.length) {
            alert(
              'You have unsaved changes. Please save your changes before ' +
              'exporting.'
            );
            return;
          }
          window.location.href = '/admin/export_sites';
        }}
        disabled={saving}
      >Export to Excel</button>
    </div>
    <table className="table manage-sites-table is-fullwidth">
      <thead>
        <tr>
          <th>Company</th>
          <th>Site Name</th>
          <th>State</th>
          <th>Type</th>
          <th>Created on</th>
          <th>Users</th>
          <th>Status</th>
          <th>Member ID</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          filteredCompanySites.map(company => 
            company.sites.length
              ? company.sites.map((site, i) => <SiteRow key={i} site={site} forManageSites={true} saving={saving} totalUsers={site.siteUsers.length}
                siteType={site.type} isFirstRow={i === 0} companyId={company.companyId} companyName={company.name} totalCompanySites={company.sites.length}
                onSiteStatusChange={(siteStatus) => dispatch({
                  type: CompanySitesActionType.updateSite,
                  payload: {
                    companyId: company.companyId,
                    siteId: site.siteId,
                    key: 'status',
                    newValue: siteStatus
                  }
                })}
                onMemberIdChange={(id) => dispatch({
                  type: CompanySitesActionType.updateSite,
                  payload: {
                    companyId: company.companyId,
                    siteId: site.siteId,
                    key: 'memberId',
                    newValue: id
                  }
                })}
              />)
              : <tr key={company.companyId} className='first-site-row'>
                <td>
                  <a href={`/admin/company/${company.companyId}`}>
                    {company.name}
                  </a>
                </td>
                <td colSpan={7}>No sites</td>
              </tr>
          )
        }
      </tbody>
    </table>
  </>;
}

function getChangedSites(
  initialCompanySites: CompanySitesListItem[],
  companySites: CompanySitesListItem[]
): AdminSiteUpdateRequest[] {
  const oldSites = initialCompanySites.flatMap(company => company.sites);
  const newSites = companySites.flatMap(company => company.sites);

  const pairs = newSites.map(newSite => [
    oldSites.find(oldSite => oldSite.siteId === newSite.siteId),
    newSite
  ]);

  return pairs.filter(([oldSite, newSite]) =>
    oldSite && newSite &&
    Object.entries(newSite).some(([key, value]: [keyof SiteListItem, any]) =>
      oldSite[key] !== value
    )
  )
    .map(([_, newSite]) => newSite as SiteListItem)
    .map((newSite) => ({
      siteId: newSite.siteId,
      status: newSite.status,
      memberId: newSite.memberId
    }));
}

async function save(
  sites: AdminSiteUpdateRequest[]
): Promise<SaveCompanySitesResult> {
  const result = await fetch('/admin/sites', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(sites)
  });

  const saveResult: SaveCompanySitesResult = await result.json();
  return saveResult;
}