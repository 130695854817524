import React, { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function GeneratePdfWidget(
  {auditId}: GeneratePdfWidgetProps
): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [pdfObjectUrl, setPdfObjectUrl] = useState<string | undefined>();
  const [pdfFilename, setPdfFilename] = useState<string | undefined>();
  const [state, setState] = useState<GeneratePdfState>(GeneratePdfState.ready);

  const generatePdf = async () => {
    if (state === GeneratePdfState.generating) {
      return;
    }
    setErrorMessage(undefined);
    setPdfObjectUrl(undefined);
    setPdfFilename(undefined);
    setState(GeneratePdfState.generating);

    let response: Response;
    try {
      response = await fetch(`/assessment/pdf/${auditId}`);
    } catch (err) {
      console.log(err);
      setErrorMessage('Network Error');
      setState(GeneratePdfState.error);
      return;
    }

    let isServerError = response.redirected;
    let blob: Blob | undefined;
    if (!isServerError) {
      try {
        blob = await response.blob();
      } catch (err) {
        console.log(err);
        isServerError = true;
      }
    }

    if (!blob || isServerError) {
      setErrorMessage('Server Error');
      setState(GeneratePdfState.error);
      return;
    }

    setState(GeneratePdfState.success);

    const objectUrl = URL.createObjectURL(blob);
    setPdfObjectUrl(objectUrl);
    const filename = `report-${new Date().toISOString()}.pdf`;
    setPdfFilename(filename);

    // Automatically download PDF
    const linkEl = document.createElement('a');
    linkEl.href = objectUrl;
    linkEl.download = filename;
    linkEl.click();
  };

  switch (state) {
    case GeneratePdfState.ready:
      return <a href='#' onClick={generatePdf}>
        Generate PDF report
      </a>;
    case GeneratePdfState.generating:
      return <span>
        <i className='fas fa-spinner fa-spin mr-2'></i>
        Generating PDF
      </span>;
    case GeneratePdfState.error:
      return <a href='#' className='has-text-danger' onClick={generatePdf}>
        <i className='fas fa-exclamation-triangle mr-2'></i>
        {errorMessage}: Click to Retry
      </a>;
    case GeneratePdfState.success:
      return <a
        href={pdfObjectUrl}
        download={pdfFilename}
        className='has-text-success'
      >
        <i className='fas fa-check mr-2'></i>
        PDF Successfully Generated
      </a>;
  }
}

type GeneratePdfWidgetProps = {
  auditId: number;
};

enum GeneratePdfState {
  ready,
  generating,
  error,
  success
}
