import React from 'react';
import ReactDOM from 'react-dom';
import { PlainTextLock } from './assessmentLock';
import { DateEditor } from './dateEditor';
import { Lock } from './editLock';
import { FormattedTextEditor } from './formattedTextEditor';
import { PlainTextEditor } from './plainTextEditor';

export class RecommendationListEditor extends React.Component<RecommendationListEditorProps, RecommendationListEditorState> {
  private nextId = 0;
  public constructor(props: RecommendationListEditorProps) {
    super(props);
    this.state = {
      items: (props.value?.items || []).map(item => ({ ...item, id: this.nextId++, isExisting: true })),
      deletedIds: new Set<number>()
    };
  }

  render(): JSX.Element {
    return <div id={this.props.id}>
      {!!this.state.items.length && <strong>Recommendations for improvement</strong>}
      {this.state.items.map(item =>
        <RecommendationListItemEditor
          key={item.id}
          item={item}
          isBeingDeleted={this.state.deletedIds.has(item.id)}
          onChange={(field, value) => this.onChange(item, field, value)}
          onActiveChange={this.props.onActiveChange}
          delete={() => this.delete(item)}
          readOnly={this.props.readOnly}
          onFocus={this.props.onFocus}
          onBlur={evt => this.onBlur(evt)}
          lock={this.props.lock}
          auditId={this.props.auditId}
          questionId={this.props.questionId}
        />
      )}
      {
        !this.props.readOnly &&
        <button className='button is-primary m-2' onClick={() => this.addRecommendation()}>
          {this.state.items.length ? 'Add another recommendation' : 'Add recommendation'}
        </button>
      }
    </div>;
  }

  private onBlur(evt?: React.FocusEvent): void {
    // Don't fire blur event if we're staying within the same editor
    if (this.props.onBlur && !ReactDOM.findDOMNode(this).contains(evt?.relatedTarget as Node || document.activeElement)) {
      this.props.onBlur();
    }
  }

  private onChange(item: RecommendationListItem, field: string, value: string): void {
    if (item) {
      (item as any)[field] = value;
    }
    const items = this.state.items.map(item => ({
      recommendation: item.recommendation,
      owner: item.owner,
      targetDate: item.targetDate,
    }));
    this.props.onChange({ items });
  }

  private delete(item: RecommendationListItem): void {
    this.setState({ deletedIds: this.state.deletedIds.add(item.id) });

    setTimeout(() => {
      const items = this.state.items.filter(i => i !== item);
      this.setState({ items });
      this.props.onChange({ items });
    }, 600);
  }

  private async addRecommendation(): Promise<void> {
    const haveLock = await this.props.onFocus();
    if (!haveLock) {
      return;
    }
    this.setState({ items: this.state.items.concat([new RecommendationListItem(this.nextId++)]) });
  }
}

class RecommendationListAnswer {
  items: RecommendationListItem[];
}

class RecommendationListItem {
  recommendation: string;
  owner: string;
  targetDate: string;
  id?: number;
  isExisting = false;

  constructor(id: number) {
    this.id = id;
  }
}

class RecommendationListEditorProps {
  onChange?: (value: any) => void;
  onActiveChange?: (isActive: boolean) => void;
  readOnly?: boolean;
  value: RecommendationListAnswer;
  id: string;
  onFocus?: () => Promise<boolean>;
  onBlur?: () => void;
  lock?: Lock;
  auditId: number;
  questionId: string;
}

class RecommendationListEditorState {
  items: RecommendationListItem[];
  deletedIds: Set<number>;
}

class RecommendationListItemEditor extends React.Component<RecommendationListItemEditorProps, RecommendationListItemEditorState> {
  constructor(props: RecommendationListItemEditorProps) {
    super(props);
    this.state = {
      deleteHover: false
    };
  }

  render() {
    const deleteClass = this.state.deleteHover ? 'threaten-delete' : '';
    const fadeClass = this.props.isBeingDeleted
      ? 'fade-out'
      : (this.props.item.isExisting ? '' : 'fade-in');
    const editLock = this.props.lock?.lockType === 'WRITE';
    return <div className={`recommendation-list-item ${deleteClass} ${fadeClass}`}>
      <div className='recommendation-list-item-fields'>
        <div className='field'>
          <div className='control'>
            <div className='has-placeholder'>
              { this.props.lock && <PlainTextLock lock={this.props.lock} editLock={editLock} /> }
              <FormattedTextEditor
                placeholder='Recommendation for improvement'
                defaultValue={this.props.item.recommendation || ''}
                onActiveChange={this.props.onActiveChange}
                onChange={text => this.props.onChange('recommendation', text)}
                readOnly={this.props.readOnly}
                onFocus={this.props.onFocus}
                onBlur={(evt) => this.props.onBlur(evt)}
                lock={this.props.lock}
                auditId={this.props.auditId}
                questionId={this.props.questionId}
              />
            </div>
          </div>
        </div>
        { this.props.lock && <PlainTextLock lock={this.props.lock} editLock={editLock} /> }
        <PlainTextEditor
          label='Owner'
          value={this.props.item.owner || ''}
          onChange={text => this.props.onChange('owner', text)}
          readOnly={this.props.readOnly}
          onFocus={this.props.onFocus}
          onBlur={(_value, evt) => this.props.onBlur(evt)}
          lock={this.props.lock}
        />
        {
          this.props.readOnly
            ? <PlainTextEditor
              value={this.props.item.targetDate ? new Date(this.props.item.targetDate).toDateString() : ''}
              label='Target date for completion'
              readOnly={true}
              onFocus={this.props.onFocus}
              onBlur={(_value, evt) => this.props.onBlur(evt)} />
            : <DateEditor
              value={this.props.item.targetDate}
              onChange={date => this.props.onChange('targetDate', date)}
              placeholder='Target date for completion' />
        }
      </div>
      <button
        className='delete'
        onClick={() => this.props.delete()}
        onMouseEnter={() => this.setState({ deleteHover: true })}
        onMouseLeave={() => this.setState({ deleteHover: false })}
        title='Delete' />
    </div>;
  }
}

class RecommendationListItemEditorProps {
  onChange?: (field: string, value: string) => void;
  onActiveChange?: (isActive: boolean) => void;
  delete: () => void;
  isBeingDeleted: boolean;
  item: RecommendationListItem;
  readOnly?: boolean;
  onFocus?: () => Promise<boolean>;
  onBlur?: (evt?: React.FocusEvent) => void;
  lock?: Lock;
  auditId: number;
  questionId: string;
}

class RecommendationListItemEditorState {
  deleteHover: boolean;
}
