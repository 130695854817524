import React from 'react';
import { PlainTextLock } from './assessmentLock';
import { Lock } from './editLock';

export class PlainTextEditor extends React.Component<PlainTextEditorProps, PlainTextEditorState> {
  private static readonly dateFormat = 'MMM dd y';

  public constructor(props: PlainTextEditorProps) {
    super(props);
    this.state = {
      value: String(props.value ? props.value : '')
    };
  }

  render(): JSX.Element {
    const editLock = this.props.lock?.lockType === 'WRITE';
    const value = this.state.value.slice(0, this.props.maxLength);
    return <div className='field my-1' id={this.props.id} >
      <div className='control'>
        <div
          className={'has-placeholder ' + (this.state.value ? 'input-filled' : 'input-blank')}
          data-placeholder={this.props.label}>
          { this.props.lock && <PlainTextLock lock={this.props.lock} editLock={editLock} /> }
          <input
            className={'input ' + this.props.className}
            onChange={evt => this.onChange(evt.currentTarget.value)}
            onBlur={evt => this.props.onBlur && this.props.onBlur(evt.currentTarget.value, evt)}
            title={this.props.label}
            type={this.props.type || 'text'}
            value={value}
            list={this.props.list}
            autoComplete={this.props.list ? 'off' : undefined}
            readOnly={this.props.readOnly}
            onFocus={this.props.onFocus !== undefined ? () => this.props.onFocus() : undefined }
            min={this.props.min}
            max={this.props.max}
          />
        </div>
      </div>
    </div>;
  }

  private onChange(inputValue: string) {
    const value = inputValue.slice(0, this.props.maxLength);
    this.props.onChange(value);
    this.setState({ value });
  }
}

class PlainTextEditorProps {
  onChange?: (value: string) => void;
  onBlur?: (value: string, evt?: React.FocusEvent) => void;
  value: string;
  label: string;
  className?: string;
  list?: string;
  type?: string;
  id?: string;
  readOnly?: boolean;
  onFocus?: () => Promise<boolean>;
  lock?: Lock;
  min?: number;
  max?: number;
  maxLength?: number;
}

class PlainTextEditorState {
  value: string;
}
