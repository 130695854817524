import React from 'react';
import { Contact } from '../../../services/audit/contactModel';
import { AssessmentLock } from './assessmentLock';
import { Lock } from './editLock';
import { PlainTextEditor } from './plainTextEditor';

export default class ContactEditor extends React.Component<ContactEditorProps, null> {
  private contact: Contact;

  constructor(props: ContactEditorProps) {
    super(props);
    this.contact = props.value || new Contact;
  }

  changeHandler(editLock: boolean, key: keyof Contact, value: string): void {
    if (editLock) {
      this.setProp(key, value);
    }
  }

  render(): JSX.Element {
    const editLock = this.props.lock?.lockType === 'WRITE';
    return <div className='card my-4' id={this.props.id}>
      <div className='card-header'>
        <p className='card-header-title'>{this.props.label}</p>
        {
          this.props.lock && 
          <AssessmentLock lock={this.props.lock} editLock={editLock} />
        }
      </div>
      <div className='card-content'>
        <PlainTextEditor readOnly={this.props.readOnly || !editLock} value={this.contact.name} onChange={value => this.changeHandler(editLock, 'name', value)} onFocus={this.props.onFocus} label='Name' />
        {this.props.includeAddress && <PlainTextEditor readOnly={this.props.readOnly || !editLock} value={this.contact.address} onChange={value => this.setProp('address', value)} onFocus={this.props.onFocus} label='Address' />}
        <PlainTextEditor readOnly={this.props.readOnly || !editLock} value={this.contact.phone} onChange={value => this.setProp('phone', value)} onFocus={this.props.onFocus} label='Phone number' />
        {this.props.includeEmail && <PlainTextEditor readOnly={this.props.readOnly || !editLock} value={this.contact.email} onChange={value => this.setProp('email', value)} onFocus={this.props.onFocus} label='Email address' />}
        {this.props.includeEmail && <PlainTextEditor readOnly={this.props.readOnly || !editLock} value={this.contact.fax} onChange={value => this.setProp('fax', value)} onFocus={this.props.onFocus} label='Fax number' />}
      </div>
    </div>;
  }

  setProp(name: keyof Contact, value: string): void {
    this.contact[name] = value;
    this.props.onChange(this.contact);
    this.forceUpdate();
  }
}

class ContactEditorProps {
  public label: string;
  public value: Contact;
  onChange?: (value: any) => void;
  public includeAddress?: boolean;
  public includeEmail?: boolean;
  readOnly?: boolean;
  id: string;
  onFocus?: () => Promise<boolean>;
  lock?: Lock;
}
