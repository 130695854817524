import React from 'react';
import { ContractorInjuryRateTable, ContractorInjuryRateTableRow, InjuryRateTable, InjuryRateTableOptions } from '../../../services/audit/injuryRateModels';
import { Lock } from './editLock';
import ParticipantInjuryRateTableEditor from './participantInjuryRateTableEditor';

export default class ContractorInjuryRateTableEditor extends React.Component<ContractorInjuryRateTableEditorProps, ContractorInjuryRateTableEditorState> {
  table: ContractorInjuryRateTable;

  constructor(props: ContractorInjuryRateTableEditorProps) {
    super(props);
    this.table = new ContractorInjuryRateTable(props.value);
    this.state = new ContractorInjuryRateTableEditorState;
  }

  public render(): JSX.Element {
    const editLock = this.props.lock?.lockType === 'WRITE';
    return <div className='contractor-injury-table mt-4' id={this.props.id}>
      <div className='mb-2' dangerouslySetInnerHTML={{ __html: this.props.text }} />
      {
        this.state.removeConfirmModal && <div className='modal is-active'>
          <div className='modal-background'></div>
          <div className='modal-content'>
            <div className='card'>
              <div className='card-content'>
                <p>Remove injury table for {this.state.removeConfirmModal.contractorName || 'contractor'}?</p>
              </div>
              <footer className='card-footer'>
                <a href='#' className='card-footer-item has-text-danger' onClick={() => this.removeContractor(this.state.removeConfirmModal)}>Remove</a>
                <a href='#' className='card-footer-item' onClick={() => this.cancelRemove()}>Cancel</a>
              </footer>
            </div>
          </div>
          <button className='modal-close is-large' aria-label='close' onClick={() => this.cancelRemove()}></button>
        </div>
      }
      {this.table.rows.map((row, idx) => <div key={idx} className={`card mb-4 ${this.getCardClass(row)}`}>
        <div className='card-content'>
          <div>
            <label>Contractor's name</label>
            <input readOnly={this.props.readOnly || !editLock} className='input inline-input' value={row.contractorName} onChange={evt => this.setName(row, evt.currentTarget.value)} onFocus={this.props.onFocus} />
            {(!this.props.readOnly || editLock) && <button className='delete is-pulled-right' onClick={() => this.promptRemoveContractor(row)} title='Remove'></button>}
          </div>
          <ParticipantInjuryRateTableEditor
            value={row.table}
            auditId={this.props.auditId}
            onChange={value => this.setTable(row, value)}
            readOnly={this.props.readOnly || !editLock}
            onFocus={this.props.onFocus}
            lock={this.props.lock}
            auditCalendarYear={this.props.auditCalendarYear}
            auditStartedOn={this.props.auditStartedOn}
          />
        </div>
      </div>)}
      {
        !(this.props.readOnly) &&
        <button className='button is-primary' onClick={() => this.addContractor()}>Add contractor</button>
      }
    </div>;
  }

  private getCardClass(row: ContractorInjuryRateTableRow): string {
    if (this.state.removedRows.indexOf(row) >= 0) {
      return 'fade-out';
    }

    if (this.state.addedRows.indexOf(row) >= 0) {
      return 'fade-in';
    }

    return '';
  }

  private async addContractor(): Promise<void> {
    const haveLock = await this.props.onFocus();
    if (!haveLock) {
      return;
    }
    const row = new ContractorInjuryRateTableRow().configure(this.props.year, this.props.options);
    this.table.rows.push(row);
    this.setState({ addedRows: this.state.addedRows.concat([row]) });
    this.props.onChange(this.table);
  }

  private promptRemoveContractor(row: ContractorInjuryRateTableRow): void {
    this.setState({ removeConfirmModal: row });
  }

  private removeContractor(row: ContractorInjuryRateTableRow): void {
    this.setState({ removedRows: this.state.removedRows.concat([row]), removeConfirmModal: null });
    setTimeout(() => {
      this.table.rows = this.table.rows.filter(r => r !== row);
      this.props.onChange(this.table);
      this.forceUpdate();
    }, 500);
  }

  private cancelRemove(): void {
    this.setState({ removeConfirmModal: null });
  }

  private setName(row: ContractorInjuryRateTableRow, value: string): void {
    row.contractorName = value;
    this.props.onChange(this.table);
    this.forceUpdate();
  }

  private setTable(row: ContractorInjuryRateTableRow, value: InjuryRateTable): void {
    row.table = value;
    this.props.onChange(this.table);
    this.forceUpdate();
  }
}

class ContractorInjuryRateTableEditorProps {
  year: number;
  options: InjuryRateTableOptions;
  onChange?: (value: any) => void;
  value: ContractorInjuryRateTable;
  id: string;
  auditId: number;
  readOnly?: boolean;
  onFocus?: () => Promise<boolean>;
  lock?: Lock;
  text?: string;
  auditCalendarYear?: number;
  auditStartedOn?: number;
}

class ContractorInjuryRateTableEditorState {
  removeConfirmModal: ContractorInjuryRateTableRow;
  addedRows: ContractorInjuryRateTableRow[] = [];
  removedRows: ContractorInjuryRateTableRow[] = [];
}