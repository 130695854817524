import React from 'react';

export class DropDownEditor extends React.Component<DropDownEditorProps, DropDownEditorState> {

  public constructor(props: DropDownEditorProps) {
    super(props);
    this.state = {
      value: props.value ? props.value : props.defaultOption
    };
  }

  render(): JSX.Element {
    return <div className='field my-2 is-flex is-align-items-center' id={this.props.id}>
      <span className='mr-2'>{this.props.label}</span>
      <div className='select'>
        <select value={this.state.value} onChange={evt => this.onChange(evt.currentTarget.value)}>
          {this.props.options.map(option => <option
            hidden={option === this.props.defaultOption}
            disabled={option === this.props.defaultOption}
            key={`${this.props.id}-${option}`}
            value={option}>{option}</option>
          )}
        </select>
      </div>
    </div>;
  }

  private onChange(value: string) {
    this.props.onChange(value);
    this.setState({ value });
  }
}

class DropDownEditorProps {
  onChange?: (value: string) => void;
  value: string;
  label: string;
  className?: string;
  type?: string;
  id?: string;
  options: string[];
  defaultOption: string;
}

class DropDownEditorState {
  value: string;
}
