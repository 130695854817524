import { InjuryRateTable } from 'services/audit/injuryRateModels';
import { SiteType } from 'services/company/companyModels';

export interface ProtocolDetails {
  protocolId: string;
  name: string;
  version: string;
  author: string;
}

export class Protocol implements ProtocolDetails {
  protocolId: string;
  name: string;
  version: string;
  author: string;
  sections: ProtocolSection[];

  public constructor(data: unknown, version: string) {
    Object.assign(this, data, {version});
    this.protocolId = `${this.name}-${this.version}`;
  }

  public flatten(): FlattenedProtocolContent {
    const flattener = new ProtocolFlattener(this.protocolId);
    flattener.flattenProtocol(this.sections);

    return { sections: flattener.sections, questions: flattener.questions };
  }
}

export class ProtocolQuestion {
  questionId: string;             // Database id
  id: string;                     // Readable id
  sectionId: string;
  protocolId?: string;
  parentId?: string;
  text: string;
  answerType: AnswerType;
  position: number;
  options: any = null;
  conditions: QuestionConditionDetails[] = null;
  defaultAnswer?: string = null;
  canBeAssigned = false;
}

export class ProtocolSection {
  sectionId: string;              // Database id
  id: string;                     // Readable id
  protocolId?: string;
  parentId?: string;
  color: string = null;
  layout: string = null;
  guidelines: string = null;
  title: string;
  conditions: SectionCondition[] = null;
  questions?: ProtocolQuestion[];
  position: number;
  canBeAssigned = false;
}

export type AuditConditionDetails = {
  siteType?: SiteType;
  injuryRateTable?: InjuryRateTable;
};

export enum QuestionConditionType {
  isMobileOrConstruction = 'isMobileOrConstruction',
  isNonMobileAndNonConstruction = 'isNonMobileAndNonConstruction',
  tcirOrDartExceeds3YearRates = 'tcirOrDartExceeds3YearRates',
  tcirOrDartRateIncrease = 'tcirOrDartRateIncrease',
  sameSectionQuestionAnswer = 'sameSectionQuestionAnswer',
}

export type QuestionConditionDetails = {
  type: QuestionConditionType;
  answer?: string[];
  question?: string;
};

export enum SectionConditionType {
  questionAnswer = 'questionAnswer'
}

export interface SectionCondition {
  type: SectionConditionType;
  isIncluded(): boolean;
}

export interface FlattenedProtocolContent {
  sections: ProtocolSection[];
  questions: ProtocolQuestion[];
}

class ProtocolFlattener {
  private position = 0;
  public sections: ProtocolSection[] = [];
  public questions: ProtocolQuestion[] = [];
  public constructor(private protocolId: string) { }

  public flattenProtocol(sections: ProtocolSection[]): void {
    for (const nextSection of sections) {
      nextSection.protocolId = this.protocolId;
      nextSection.position = this.position++;
      nextSection.sectionId = `${this.protocolId}-${nextSection.id}`;
      (nextSection.conditions as any[])
        ?.filter(cond => cond.question)
        .forEach(cond => cond.question = `${this.protocolId}-${cond.question}`);
      const section: ProtocolSection = {... new ProtocolSection, ...nextSection};
      this.sections.push(section);
      for (const nextQuestion of section.questions) {
        nextQuestion.protocolId = this.protocolId;
        nextQuestion.questionId = `${this.protocolId}-${nextQuestion.id}`;
        nextQuestion.position = this.position++;
        nextQuestion.sectionId = `${this.protocolId}-${nextSection.id}`;
        const question: ProtocolQuestion = {... new ProtocolQuestion, ...nextQuestion};
        this.questions.push(question);
      }
    }
  }
}

export enum AnswerType {
  attachmentEditor = 'attachmentEditor',
  auditCalendarYear = 'auditCalendarYear',
  auditSubmissionDate = 'auditSubmissionDate',
  contactWithAddress = 'contactWithAddress',
  contactWithEmail = 'contactWithEmail',
  contractorInjuryRateTable = 'contractorInjuryRateTable',
  date = 'date',
  formattedText = 'formattedText',
  naicsCode = 'naicsCode',
  participantInjuryRateTable = 'participantInjuryRateTable',
  plainText = 'plainText',
  recommendationList = 'recommendationList',
  staticText = 'staticText',
  subsectionTitle = 'subsectionTitle',
  injuryRateReferenceTable = 'injuryRateReferenceTable',
  contractorInjuryRateReferenceTable = 'contractorInjuryRateReferenceTable',
  text = 'text',
  yesNo = 'yesNo',
  vppStatus = 'vppStatus',
  singleChoice = 'singleChoice',
}

const questionConditionSchema = {
  type: 'object',
  properties: {
    type: { enum: Object.values(QuestionConditionType) }
  },
  required: ['type']
};

const questionSchema = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    answerType: { enum: Object.values(AnswerType) },
    defaultAnswer: { type: 'string' },
    text: { type: 'string' },
    conditions: { type: 'array', items: questionConditionSchema },
    options: { type: 'object' },
    layout: { type: 'string' }
  },
  required: ['id', 'answerType', 'text']
};

const sectionConditionSchema = {
  type: 'object',
  properties: {
    type: { enum: Object.values(SectionConditionType) }
  },
  required: ['type']
};

const sectionSchema: any = {
  type: 'object',
  $id: '/section',
  properties: {
    id: { type: 'string' },
    title: { type: 'string' },
    color: { type: 'string' },
    layout: { type: 'string' },
    conditions: {
      type: 'array',
      items: sectionConditionSchema
    },
    questions: {
      type: 'array',
      items: questionSchema
    }
  },
  required: ['id']
};

export const protocolSchema = {
  type: 'object',
  properties: {
    name: { type: 'string' },
    author: { type: 'string' },
    state: { type: 'string', nullable: true },
    dueDate: { type: 'string' },
    sections: {
      type: 'array',
      items: sectionSchema
    }
  },
  required: ['name', 'author', 'sections']
};

export type QuestionUpdateRequest = {
  questionID: string;
  questionText: string;
  protocols: string[];
};
