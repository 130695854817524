import React from 'react';
import { ProtocolPublishRequst } from '../../../services/system';

type ProtocolRequestActionProps = {
  protocolPublishRequestId: number;
  action: 'Publish' | 'Reject';
};

/* eslint-disable @typescript-eslint/naming-convention */
function ProtocolRequestAction(props: ProtocolRequestActionProps): JSX.Element {
  const btnColor = props.action === 'Publish' ? 'is-success' : 'is-danger';
  return <form action='/admin/resolve-publish-request' method='post'>
    <input type='hidden' name='protocolPublishRequestId' value={props.protocolPublishRequestId} />
    <input type='hidden' name='action' value={props.action} />
    <button type='submit' className={`button ${btnColor} is-primary`}>{props.action}</button>
  </form>;
}

type ProtocolReviewProps = {
  reviewRequests: ProtocolPublishRequst[];
};

export function ProtocolReview(props: ProtocolReviewProps): JSX.Element {
  return <table className='table is-fullwidth'>
    <thead>
      <tr>
        <td className='relcol-40'>Protocol ID</td>
        <td className='relcol-20'>Requested By</td>
        <td className='relcol-10'>Status</td>
        <td className='relcol-15'>Approve</td>
        <td className='relcol-15'>Reject</td>
      </tr>
    </thead>
    <tbody>
      {props.reviewRequests.map(reviewRequest => <tr key={reviewRequest.protocolPublishRequestId}>
        <td className='text-row'>{reviewRequest.protocolId}</td>
        <td className='text-row'>{reviewRequest.requestedBy}</td>
        <td className='text-row'>{reviewRequest.completedAt === null ? 'Pending' : reviewRequest.isPublished ? 'Published' : 'Rejected'}</td>
        <td className='text-row'>{reviewRequest.completedAt === null && reviewRequest.isReviewer && <ProtocolRequestAction protocolPublishRequestId={reviewRequest.protocolPublishRequestId} action={'Publish'} />}</td>
        <td className='text-row'>{reviewRequest.completedAt === null && reviewRequest.isReviewer && <ProtocolRequestAction protocolPublishRequestId={reviewRequest.protocolPublishRequestId} action={'Reject'} />}</td>
      </tr>
      )}
    </tbody>
  </table>;
}