import React from 'react';
import { AuditResponseVersion } from '../../../services/audit/auditModels';
import { AnswerType } from '../../../services/protocol/protocolModels';
import { HistoryModalTypes } from './historyModals';
import { timeSince } from './utils';

type AssessmentHistoryRowProps = {
  row: AuditResponseVersion;
  toggleModal: (modal: HistoryModalTypes, value: AuditResponseVersion | string | null) => void;
  sectionFilter: string;
};

function buildFormattedPreview(markup: string): string {
  let preview = '';
  const parser = new DOMParser();
  const doc = parser.parseFromString(markup, 'text/html');
  
  const children = [...doc.body.children];
  while (preview.length < 100 && children.length > 0) {
    const nextElement = (children.splice(0,1))[0];
    if (nextElement?.children?.length > 0) {
      children.splice(0, 0, ...nextElement.children);
    } else {
      preview += `${nextElement.innerHTML} `;
    }
  }
  
  if (preview.length > 100) {
    preview = preview.slice(0,100);
  }
  return preview;
}

export class AssessmentHistoryRow extends React.Component<AssessmentHistoryRowProps, null> {
  constructor(props: AssessmentHistoryRowProps) {
    super(props);
  }

  renderAttachment(): JSX.Element {
    const isDeleted = this.props.row.answerJSON.deletedAt !== null;
    const rowColour = isDeleted ? 'has-background-danger-light' : '';
    return <td
      className={`${isDeleted ? 'is-clickable' : ''} ${rowColour} formatted-preview`}
      onClick={isDeleted ? () => this.props.toggleModal('attachment', this.props.row) : undefined } >
      {isDeleted ? '[DELETED]: ' : ''}{this.props.row.answerJSON.filename}{isDeleted ? ' (Click to restore)' : ''}
    </td>;
  }

  renderFormattedText(): JSX.Element {
    const preview = buildFormattedPreview(this.props.row.answer);
    return <td
      className='is-clickable has-background-link-light formatted-preview'
      onClick={() => this.props.toggleModal('formattedText', this.props.row.answer) } 
      dangerouslySetInnerHTML={{ __html: preview }}>
    </td>;
  }

  renderParticipantTable(): JSX.Element {
    return <td
      className='is-clickable has-background-link-light' 
      onClick={() => this.props.toggleModal('participantInjury', this.props.row) }>
        Click to see table.
    </td>;
  }

  renderContractorTable(): JSX.Element {
    return <td
      className='is-clickable has-background-link-light'
      onClick={() => this.props.toggleModal('contractorInjury', this.props.row) }>
        Click to see table.
    </td>;
  }

  renderRecommendationList(): JSX.Element {
    type RecListUI = {
      values: string;
      recommendation: string;
      formattedText: string;
    };
    const recList: RecListUI[] = [];
    for (const recommendation of this.props.row.answerJSON.items) {
      const nextRecList: RecListUI = {values: '', recommendation: '', formattedText: recommendation.recommendation};
      const values: string[] = [];
      for (const _key of Object.keys(recommendation).sort()) {
        if (_key !== 'recommendation') {
          values.push(`${_key}: ${recommendation[_key]}`);
        } else {
          nextRecList.recommendation = buildFormattedPreview(recommendation[_key]);
        }
      }
      nextRecList.values = values.join('\n');
      recList.push(nextRecList);
    }
    return <td className='history-multiline-cell'>
      {
        recList.map( (recUI, idx) => <div key={idx}>
          <p><b><u>Recommendation #{idx+1}</u></b></p>
          <span dangerouslySetInnerHTML={{ __html: recUI.values }}></span>
          <br />
          <div className='is-clickable has-background-link-light' onClick={() => this.props.toggleModal('formattedText', recUI.formattedText) } dangerouslySetInnerHTML={{ __html: recUI.recommendation }}></div>
          <br />
          <br />
        </div> )
      }
    </td>;
  }

  renderRow(): JSX.Element {
    if (this.props.row.answerType === AnswerType.attachmentEditor) {
      return this.renderAttachment();
    }
    if (this.props.row.answerType === AnswerType.formattedText) {
      return this.renderFormattedText();
    }
    if (this.props.row.answerType === AnswerType.participantInjuryRateTable) {
      return this.renderParticipantTable();
    }
    if (this.props.row.answerType === AnswerType.contractorInjuryRateTable) {
      return this.renderContractorTable();
    }
    if (this.props.row.answerType === AnswerType.recommendationList) {
      return this.renderRecommendationList();
    }
    if (this.props.row.answer === null) {
      if (this.props.row.answerJSON) {
        const values: string[] = [];
        for (const _key of Object.keys(this.props.row.answerJSON).sort()) {
          values.push(`${_key}: ${this.props.row.answerJSON[_key]}`);
        }
        return <td className='history-multiline-cell' dangerouslySetInnerHTML={{ __html: values.join('\n') }}></td>;
      } else {
        return <td>-</td>;
      }
    }
    if (this.props.row.answer !== null) {
      return <td>{this.props.row.answer}</td>;
    }
  }

  render(): JSX.Element {
    const timeNow = new Date();
    const timeSinceEdit = timeSince(timeNow, this.props.row.createdAt);
    const sectionId = this.props.row.sectionId;
    const isVisible = this.props.sectionFilter.toLowerCase() === 'all' || this.props.sectionFilter.toLowerCase() === sectionId.toLowerCase();
    return isVisible && <tr>
      <td>{this.props.row.createdBy}</td>
      <td title={this.props.row.createdAt.toString()}>{timeSinceEdit}</td>
      <td>{sectionId}</td>
      <td>{this.props.row.auditQuestionId}</td>
      { this.renderRow() }
    </tr>;
  }
}