import * as React from 'react';
import { AssignedUser, AssignmentComment, AuditAssignmentRequest, AuditQuestion, AuditSection, SiteContributorAssignments } from 'services/audit/auditModels';
import { timeSince } from '../assessmentHistory/utils';

type UserAssignmentProps = {
  auditId: number;
  section?: AuditSection;
  question?: AuditQuestion;
  auditContributors: SiteContributorAssignments[];
  loggedInUserId: number;
  isCurrentUserManager: boolean;
  canUserComment: boolean;
  siteId: number;
  assignedUsers: AssignedUser[];
  assignmentComments: AssignmentComment[];
  syncAssignmentDetails: () => Promise<void>;
};

type UserAssignmentState = {
  commentToPost: string;
};


export class UserAssignmentBox extends React.Component<UserAssignmentProps, UserAssignmentState> {
  constructor(props: UserAssignmentProps) {
    super(props);
    this.state = {
      commentToPost: ''
    };
  }

  render(): React.ReactNode {
    const { loggedInUserId, assignedUsers, assignmentComments } = this.props;
    const section = this.props.section;
    const question = this.props.question;

    const isSection = !!section;
    const assignedUserIds = new Set((assignedUsers || []).map(user => user.userId));
    const unassignedAuditContributors = this.props.auditContributors.filter(contributor => !assignedUserIds.has(Number(contributor.userDetails.userId)));

    return (
      <div className='user-assignment-box'>
        {
          !this.props.isCurrentUserManager && !assignedUsers?.length
            ? <></>
            : <div className='is-flex mb-1 mt-2 pb-2 assigned-user-list pl-1 pr-1'>
              <div className='field is-grouped is-grouped-multiline'>
                {
                  this.props.isCurrentUserManager && unassignedAuditContributors?.length
                    ? <div className='dropdown is-left mr-1 mb-2'>
                      <div className='dropdown-trigger'>
                        <button className='button is-small assign-user-button'>
                          <span className='icon mr-1'>
                            <i className='fas fa-plus'></i>
                          </span>
                              Assign { isSection ? section.id : question.id } to a user
                        </button>
                      </div>
                      <div className='dropdown-menu'>
                        <div className='dropdown-content'>
                          {
                            unassignedAuditContributors.map((user, idx) => <button key={idx} type='button' className='dropdown-item has-text-primary'
                              onClick={() => this.assignToUser(Number(user.userDetails.userId))}>
                              {`${user.userDetails.name} - ${user.userDetails.roles[0]}`}
                            </button>)
                          }
                        </div>
                      </div>
                    </div>
                    : assignedUsers?.length
                      ? <div className='assignment-label'>Assigned to :</div> 
                      : <></>
                }
                {
                  assignedUsers?.length &&
                assignedUsers.map((user, idx) => <div key={idx} className='control mr-1 mb-2'>
                  {
                    this.props.isCurrentUserManager
                      ? <div className='tags'>
                        <span className='tag is-primary'>
                          {user.name}
                          <button className='delete is-small' onClick={() => this.revokeAssignment(user.assignmentId)}></button>
                        </span>
                      </div>
                      : <div className='tags'>
                        <span className='tag is-primary'>{user.name}</span>
                      </div>
                  }
                </div>
                )
                }
              </div>
            </div>
        }
        <div className='assignment-comments'>
          <div className='comments-list is-flex is-flex-direction-column-reverse pr-1 pl-1'>
            {
              assignmentComments?.length &&
                assignmentComments.map((comment, idx) => {
                  const timeNow = new Date();
                  const timeSinceEdit = timeSince(timeNow, comment.postedAt);
                  return <div key={idx} className={`card mb-1 ${comment.userId === loggedInUserId ? 'message is-align-self-flex-end is-primary has-text-white' : 'message is-align-self-flex-start has-background-white'}`}>
                    <div className='card-content m-0 p-1 is-size-7'>
                      <div className='content'>
                        <div>
                          <p className='mb-0 pr-1 pl-1'>{comment.comment}</p>
                          {
                            comment.userId === loggedInUserId
                              ? <p className='size-10 pr-1 pl-1 has-text-right has-text-white'>{timeSinceEdit}</p>
                              : <p className='mb-0 pl-1 size-9'>{comment.userName} <small className='size-10'>{`(${timeSinceEdit})`}</small></p>
                          }

                        </div>
                      </div>
                    </div>
                    {
                      comment.userId === loggedInUserId &&
                        <span className='icon is-small delete-message-icon comment-box-icon is-primary is-clickable' onClick={() => this.deleteComment(comment.assignmentCommentId)}>
                          <i className='fas fa-trash'></i>
                        </span>
                    }
                  </div>;
                })
            }
          </div>
          {
            this.props.canUserComment &&
              <div className='user-comment-area pr-1 pl-1'>
                <div className='control'>
                  <textarea value={this.state.commentToPost} className='textarea user-comment-field' placeholder={`Comment on ${ isSection ? section.id : question.id }`}
                    onKeyUp={(evt) => {
                      if (evt.key === 'Enter') {
                        this.postComment(this.state.commentToPost);
                      }
                    }}
                    onChange={(evt) => this.setState({commentToPost: evt.currentTarget.value})}
                  />
                </div>
                <span className='icon comment-box-icon is-primary is-clickable post-comment-button'
                  onClick={() => this.postComment(this.state.commentToPost)}
                >
                  <i className='fas fa-paper-plane'></i>
                </span>
              </div>
          }
        </div>
      </div>
    );
  }

  private async assignToUser(userId: number) {
    const section = this.props.section;
    const question = this.props.question;
    const auditId = this.props.auditId;
    const siteId = this.props.siteId;
    const fetchUrl = section ? '/assessment/assign-section' : '/assessment/assign-question';
    const postBody: AuditAssignmentRequest = section
      ? { auditId, sectionId: section.sectionId, siteId, assignedToUserId: userId }
      : { auditId, questionId: question.questionId, sectionId: question.sectionId, siteId, assignedToUserId: userId };
    try {
      const response = await fetch(fetchUrl,
        {
          method: 'POST',
          body: JSON.stringify(postBody),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.ok) {
        this.props.syncAssignmentDetails();
      }
    } catch (err) {
      console.error(err);
    }
  }

  private async postComment(comment: string): Promise<void> {
    if (!comment.trim()) {
      return;
    }
    this.setState({commentToPost: ''});

    const section = this.props.section;
    const question = this.props.question;
    const auditId = this.props.auditId;
    const fetchUrl = section ? '/assessment/save-section-comments' : '/assessment/save-question-comments';
    const postBody = section
      ? { auditId, sectionId: section.sectionId, comment }
      : { auditId, questionId: question.questionId, sectionId: question.sectionId, comment };

    try {
      const response = await fetch(fetchUrl,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify(postBody)
        }
      );
      if (response.ok) {
        this.props.syncAssignmentDetails();
      }
    } catch (err) {
      console.error(err);
    }
  }

  private async deleteComment(assignmentCommentId: number): Promise<void> {
    try {
      const response = await fetch('/assessment/delete-assignment-comment',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({assignmentCommentId})
        }
      );
      if (response.ok) {
        this.props.syncAssignmentDetails();
      }
    } catch (err) {
      console.error(err);
    }
  }

  private async revokeAssignment(assignmentId: number) {
    try {
      const response = await fetch('/assessment/revoke-assignment',
        {
          method: 'POST',
          body: JSON.stringify({assignmentId, siteId: this.props.siteId}),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.ok) {
        this.props.syncAssignmentDetails();
      }
    } catch (err) {
      console.error(err, assignmentId);
    }
  }
}