import { CompanySitesListItem } from '../../../services/company/companyModels';
import { CompanySitesAction, CompanySitesActionType } from './models';

export function reducer(
  companySites: CompanySitesListItem[],
  action: CompanySitesAction
): CompanySitesListItem[] {
  switch (action.type) {
    case CompanySitesActionType.populate:
      return action.payload.companySites;
    case CompanySitesActionType.updateSite:
      return companySites.map(company =>
        company.companyId === action.payload.companyId ? {
          ...company,
          sites: company.sites.map(site =>
            site.siteId === action.payload.siteId ? {
              ...site,
              [action.payload.key]: action.payload.newValue
            } : site
          )
        } : company
      );
  }

  return companySites;
}