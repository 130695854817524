import { UserProfile } from 'services/user/userModels';

export interface CreateCompanyRequest {
  name: string;
}

export interface CreateSiteRequest {
  companyId: number;
  siteName: string;
  siteType: SiteType;
  isFederal: boolean;
  state: string;
  managerEmail: string;
  managerFirstName: string;
  managerLastName: string;
  createdBy: UserProfile;
  creatingUserIsManager: boolean;
}

export interface UpdateSiteRequest {
  siteId: number;
  siteName: string;
  siteType: SiteType;
  isFederal: boolean;
  state: string;
}

export interface Company {
  companyId: number;
  createdAt: string;
  name: string;
  sites?: Site[];
}

export interface Site {
  companyId: number;
  siteId: number;
  createdAt: string;
  name: string;
  state: string;
  status: SiteStatus;
  siteType: SiteType;
  isFederal: boolean;
  managers: SiteManager[];
  currentUserRoles?: string[];
  awaitingAccessRequest?: boolean;
}

export interface SiteManager {
  name: string;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
}

export type SiteType = 'non-construction' | 'construction' | 'mobile';

export type SiteStatus = 'active' | 'evaluation' | 'suspended';

export const siteTypeNames: Record<SiteType, string> = {
  'non-construction': 'Non construction fixed site',
  'construction': 'Construction',
  'mobile': 'Mobile workforce'
};

export class DuplicateCompanyError extends Error { }
export class DuplicateSiteError extends Error { }

/** Stripped down company interface for displaying summary in company list */
export interface CompanyListItem {
  companyId: number;
  createdAt: string;
  name: string;
  siteCount: number;
}

export interface SiteListItem {
  siteId: number;
  name: string;
  state: string;
  type: string;
  siteType?: string;
  createdAt: string;
  users: string[];
  siteUsers?: SiteUserDetails[];
  status: SiteStatus;
  memberId: string;
  managers: SiteManager[];
}

export type SiteUserDetails = {
  userId: number;
  name: string;
  email: string;
  roles: string[];
};
export interface CompanySitesListItem {
  companyId: number;
  name: string;
  sites: SiteListItem[];
}

export interface AdminSiteUpdateRequest {
  siteId: number;
  status?: SiteStatus;
  memberId?: string;
}

export type UpdateCompanyRequest = {
  companyId: number;
  companyName: string;
};