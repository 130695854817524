import { InjuryRate, InjuryRateRepo, NAICSSectorType } from '../../../services/audit/injuryRateModels';
import { codes, NAICSCode } from './bls/naics';

export type NAICSInjuryRateQuery = {
  naicsCode: string;
  sectorType?: NAICSSectorType;
  year?: string;
};

export type NAICSInjuryRateResult = {
  changedIndustry: boolean;
  rate: InjuryRate;
};

export class BLSRepo {
  public naicsCodes: NAICSCode[];
  private injuryRates: InjuryRateRepo;

  constructor(codes: NAICSCode[]) {
    this.naicsCodes = codes;
  }

  public async loadInjuryRates(auditId: number): Promise<void> {
    const response = await fetch('/assessment/injury-rates/' + auditId);
    this.injuryRates = await response.json();
  }

  getAvailableInjuryRateYears(): string[] {
    return Object.keys(this.injuryRates);
  }

  getDefaultInjuryRateYear(): string {
    const years = Object.keys(this.injuryRates);
    return years.sort()[years.length - 1];
  }

  getInjuryRate(lookup: NAICSInjuryRateQuery): NAICSInjuryRateResult {
    const sectorType = lookup.sectorType || 'private';
    const year = lookup.year !== undefined ? lookup.year : this.getDefaultInjuryRateYear();
    let resultFound = false;
    let endIndex = lookup.naicsCode.length;
    const baseSearch = this.injuryRates[year][sectorType];
    let rate: InjuryRate;
    while (!resultFound && endIndex > 0) {
      const _naics = lookup.naicsCode.substr(0, endIndex);
      if (baseSearch.hasOwnProperty(_naics) && baseSearch[_naics].tcir !== null && baseSearch[_naics].dart !== null) {
        resultFound = true;
        rate = baseSearch[_naics];
        break;
      }
      endIndex--;
    }
    return {
      changedIndustry: rate?.industry !== lookup.naicsCode,
      rate: rate
    };
  }

  haveValidBLSRates(assessmentYear: number): boolean {
    const requiredYears = 3;
    const blsYears = this.getAvailableInjuryRateYears();
    let haveValidBLSRates = true;
    for (let i = 1; i <= requiredYears; i++) {
      const requiredBLSYear = assessmentYear - i;
      if (!blsYears.includes(requiredBLSYear.toString())) {
        haveValidBLSRates = false;
      }
    }
    return haveValidBLSRates;
  }

  minimumAssessmentYear(): number {
    const blsYears = this.getAvailableInjuryRateYears();
    blsYears.sort();
    if (blsYears.length > 2) {
      return Number(blsYears[2]) + 1;
    } else {
      throw new Error('Not enough BLS Data available.');
    }
  }
}

let loadRepository: Promise<BLSRepo>;
const loadRepo = async function (auditId: number): Promise<BLSRepo> {
  const repo = new BLSRepo(codes);
  await repo.loadInjuryRates(auditId);
  return repo;
};

export default function getBLSRepo(auditId: number): Promise<BLSRepo> {
  if (!loadRepository) {
    loadRepository = loadRepo(auditId);
  }
  return loadRepository;
}