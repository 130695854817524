export function timeSince(timeNow: Date, timestamp: Date): string {
  const timeStart = new Date(timestamp);
  let timeSince = '';
  let units = '';
  const MINUTES = 60 * 1000;
  const HOURS = 60 * MINUTES;
  const DAYS = 24 * HOURS;
  const timeElapsed = timeNow.getTime() - timeStart.getTime();
  if (timeElapsed > DAYS) {
    const d = timeElapsed / (DAYS);
    units = parseInt(d.toFixed(0)) > 1 ? 'days' : 'day';
    timeSince = `${d.toFixed(0)} ${units} ago`;
  } else if (timeElapsed > HOURS) {
    const d = timeElapsed / (HOURS);
    units = parseInt(d.toFixed(0)) > 1 ? 'hours' : 'hour';
    timeSince = `${d.toFixed(0)} ${units} ago`;
  } else if (timeElapsed > MINUTES) {
    const d = timeElapsed / MINUTES;
    units = parseInt(d.toFixed(0)) > 1 ? 'minutes' : 'minute';
    timeSince = `${d.toFixed(0)} ${units} ago`;
  } else {
    timeSince = 'Just now';
  }
  return timeSince;
}
