import * as React from 'react';

export default class ProtocolContentUpdate extends React.Component<unknown, ProtocolContentUpdateState> {
  constructor(props: unknown) {
    super(props);
    this.state = new ProtocolContentUpdateState();
    this.fetchQuestions();
  }

  async fetchQuestions(): Promise<void> {
    const allQuestions = await (await fetch('/admin/all-questions')).json();
    this.setState({ allQuestions });
  }

  render(): JSX.Element {
    const { allQuestions, questionID, questionText, selectedProtocols } = this.state;

    return <div>
      { this.state.successMessage && <div className='message is-success'><div className='message-body'>{this.state.successMessage}</div></div> }
      { this.state.errorMessage && <div className='message is-danger'><div className='message-body'>{this.state.errorMessage}</div></div> }
      <div className='message mb-2'>
        <div className='message-body'>
          <p>This form will update question text in existing versions of protocols</p>
          <p><strong>This will immediately affect completed and in progress assessments</strong></p>
        </div>
      </div>
      <div className='control'>
        <input
          type='text' className='input' list='question-ids' placeholder='Question ID'
          value={questionID} onChange={evt => this.setQuestionID(evt)}
        />
      </div>
      {
        selectedProtocols &&
        <>
          {
            this.state.showUpdateConfirm &&
            <div className='modal is-active'>
              <div className='modal-background' onClick={() => this.setState({ showUpdateConfirm: false })} />
              <div className='modal-card'>
                <div className='modal-card-head'>
                  <p className='modal-card-title'>Confirm update</p>
                  <button className='delete' onClick={() => this.setState({ showUpdateConfirm: false })} />
                </div>
                <section className='modal-card-body'>
                  <p>Apply update question {questionID} in {Object.entries(selectedProtocols).filter(([_name, enabled]) => enabled).length} protocols?</p>
                  <p className='my-4'>Question text preview:</p>
                  <div className='box' dangerouslySetInnerHTML={{__html: questionText}} />
                </section>
                <footer className='modal-card-foot'>
                  <button className='button is-primary' onClick={() => this.apply()}>Update</button>
                  <button onClick={() => this.setState({ showUpdateConfirm: false })} className='button'>Cancel</button>
                </footer>
              </div>
            </div>
          }
          <div className='my-4'>
            Update {questionID} in the following protocols:
            <div className='is-pulled-right'>
              <button className='button is-small' onClick={() => this.toggleAllProtocols(true)}>Select all</button>
              <button className='button is-small' onClick={() => this.toggleAllProtocols(false)}>Deselect all</button>
            </div>
          </div>
          <div className='protocol-content-update-list'>
            {Object.entries(selectedProtocols).map(([protocol, enabled]) =>
              <div className='control' key={protocol}>
                <label className='checkbox'>
                  <input
                    type='checkbox' className='mr-1'
                    checked={enabled} onChange={evt => this.toggleProtocol(protocol, evt.target.checked)}
                  />
                  {protocol}
                </label>
              </div>
            )}
          </div>
          <div className='control mt-4'>
            <input
              type='text' className='input' placeholder='New question text'
              value={questionText} onChange={evt => this.setState({ questionText: evt.target.value })}
            />
          </div>
          <div className='message is-warning mt-4'>
            <div className='message-body'>
              <strong>Warning: </strong> Be sure to add new versions with this update in place before updating existing protocols
            </div>
          </div>
          <button
            className='button is-primary' onClick={() => this.setState({showUpdateConfirm: true})}
            disabled={!questionText}>
              Apply update
          </button>
        </>
      }
      <datalist id='question-ids'>
        {Object.keys(allQuestions).map(id => <option key={id} value={id} />)}
      </datalist>
    </div>;
  }

  toggleAllProtocols(isEnabled: boolean): void {
    this.setState({ selectedProtocols:
      Object.fromEntries(Object.entries(this.state.selectedProtocols as Record<string, boolean>).map(([protocol]) => [protocol, isEnabled]))
    });
  }

  private setQuestionID(evt: React.ChangeEvent<HTMLInputElement>): void {
    const questionID = evt.target.value;
    const protocolEntries = this.state.allQuestions[questionID]?.map(protocol => [protocol, true]);
    const selectedProtocols = protocolEntries ? Object.fromEntries(protocolEntries) : null;
    this.setState({ questionID, selectedProtocols });
  }

  private toggleProtocol(protocol: string, selected: boolean) {
    this.setState({
      selectedProtocols: { ...this.state.selectedProtocols, [protocol]: selected }
    });
  }

  private async apply() {
    this.setState({showUpdateConfirm: false, successMessage: '', errorMessage: '' });
    const selectedProtocols = this.state.selectedProtocols as Record<string, boolean>;
    const protocols = Object.entries(selectedProtocols).filter(([_protocol, enabled]) => enabled).map(([protocol]) => protocol);
    const { questionID, questionText } = this.state;
    try {
      const result = await fetch(
        '/admin/update-question',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ questionID, questionText, protocols })
        }
      );
      if (result.status < 300) {
        this.setState({ successMessage: 'Update applied' });
      } else {
        this.setState({ errorMessage: await result.text() });
      }
    } catch (err) {
      this.setState({ errorMessage: `Error: ${err.toString()}` });
    }
  }
}

class ProtocolContentUpdateState {
  allQuestions: Record<string, string[]> = {};
  questionID = '';
  selectedProtocols?: Record<string, boolean>;
  questionText: string;
  showUpdateConfirm = false;
  successMessage?: string;
  errorMessage?: string;
}