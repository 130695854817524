import React from 'react';
import { MessageCallback, MessageSource } from '..';
import { FlashMessages } from '../../../models/web';
import { AssignmentDetails, AuditQuestion, AuditSection, QuestionAssignmentDetails, SiteContributorAssignments } from '../../../services/audit/auditModels';
import { AnswerType } from '../../../services/protocol/protocolModels';
import { MessagesWidget } from '../messagesWidget';
import NotifyWidget from '../notifyWidget';
import { LockErrorModal } from './assessmentLock';
import AssessmentSidebar from './assessmentSidebar';
import { codes as naicsCodes } from './bls/naics';
import EditAssessmentComponents from './editAssessmentComponents';
import { AssessmentEditor, EditAssessmentRenderer } from './editAssessmentModels';
import { Lock, lockAPI, LOCK_FREE_EDITORS, QuestionLocks, refreshLock, syncLocks, unlockAPI, UnlockQueue } from './editLock';
import NotificationBar, { NotificationBarProps } from './notificationBar';
import { AuditResponseUpdateCache, getMaxUpdatedAt, swapAuditContent, syncAssessmentContent } from './syncAssessmentContent';
import ViewAssessmentComponents from './viewAssessmentComponents';
import { UserAssignmentBox } from './userAssignmentBox';
import { AsyncQueue } from '../utility';

type QueueKeys = 'save' | 'save_no_errors' | 'sync_all_content';

const QUICK_SAVE_DELAY_SECONDS = 2;
const SAVE_DELAY_SECONDS = 15;
const FORCE_LOCK_AFTER_MINUTES = 15;
const UNLOCK_DELAY_SECONDS = 5;
const FREE_LOCK_CHECK_DELAY_SECONDS = 120;
const SYNC_LOCK_DELAY_SECONDS = 10;
const QUICK_SYNC_CONTENT_DELAY_SECONDS = 2;
const SYNC_CONTENT_DELAY_SECONDS = 15;
const SYNC_ASSIGNMENT_DELAY_SECONDS = 10;

export default class EditAssessmentView
  extends React.Component<EditAssessmentViewProps, EditAssessmentViewState>
  implements MessageSource, AssessmentEditor {
  private pendingAnswers = new Set<number>();
  private messageCallbacks: MessageCallback[] = [];
  private answers: AnswerLookup = {};
  private readonly renderer: EditAssessmentRenderer;
  private unlockQueue: UnlockQueue = {};
  private updateCache: AuditResponseUpdateCache;
  private queue = new AsyncQueue<QueueKeys>();

  constructor(props: EditAssessmentViewProps) {
    super(props);

    const isLocked = !!this.props.submittedOn;
    const canEdit = this.props.canEdit && !isLocked;

    const lockMessage = isLocked ? 'This assessment is locked and cannot be edited' : undefined;
    const editMessage = !canEdit ? 'You do not have permission to edit this audit' : undefined;
    const message = lockMessage ?? editMessage;

    this.state = 
    {
      ...new EditAssessmentViewState(props.content, props.elements, props.calendarYear,
        props.sectionAssignments, props.questionAssignments),
      message : message ? {message, appearance: 'info'} : undefined
    };
    window.addEventListener('beforeunload', () => this.save(false));
    this.supressTransitions();


    this.renderer = canEdit ? new EditAssessmentComponents(this) : new ViewAssessmentComponents(this);
    const PAGE_SAVE_DELAY = this.props.content.id === 'B' ? QUICK_SAVE_DELAY_SECONDS : SAVE_DELAY_SECONDS;
    const PAGE_CONTENT_SYNC_DELAY = this.props.content.id === 'B' ? QUICK_SYNC_CONTENT_DELAY_SECONDS : SYNC_CONTENT_DELAY_SECONDS;
    setInterval(() => this.save(), PAGE_SAVE_DELAY * 1000);
    setInterval(() => this.unlockQuestion(), UNLOCK_DELAY_SECONDS * 1000);
    setInterval(() => this.freeAllLocks(), FREE_LOCK_CHECK_DELAY_SECONDS * 1000);
    setInterval(() => this.syncAllLocks(), SYNC_LOCK_DELAY_SECONDS * 1000);
    setInterval(() => this.syncAllContent(), PAGE_CONTENT_SYNC_DELAY * 1000 );
    setInterval(() => this.syncAssignmentDetails(), SYNC_ASSIGNMENT_DELAY_SECONDS * 1000);
    if (props.locks) {
      for (const lock of props.locks) {
        if (lock.responseId !== null) {
          this.state.assessmentLocks[lock.responseId] = lock;
        }
      }
    }
    this.toggleLockMessage = this.toggleLockMessage.bind(this);
    this.save = this.save.bind(this);
    this.syncAssignmentDetails = this.syncAssignmentDetails.bind(this);
    this.updateCache = getMaxUpdatedAt(props.content);
  }

  get auditId(): number {
    return this.props.auditId;
  }

  render(): JSX.Element {
    const section = this.state.content;
    const areThereAssignableQuestions = !!section.content.find(question => question.canBeAssigned);
    const result = <React.Fragment>
      <AssessmentSidebar
        auditId={this.props.auditId}
        calendarYear={this.state.calendarYear}
        canLock={this.props.canLock}
        content={this.props.content}
        siteId={this.props.siteId}
        dueDate={this.props.dueDate}
        elements={this.state.elements}
        isLockPage={this.props.isLockPage}
        isLinkedFilesPage={this.props.isLinkedFilesPage}
        submittedOn={this.props.submittedOn}
        saveContent={() => this.save(false)}
        siteContributorAssignments={this.props.siteContributorAssignments}
        loggedInUserId={this.props.loggedInUserId}
      />
      <div className='main'>
        <div id='escape-lock' className={`container section ${areThereAssignableQuestions ? 'edit-assessment-assignment-container' : ''}`}>
          <div>
            <div className='is-flex is-justify-content-space-between is-align-items-center'>
              <h1 className='title mt-4'>{this.props.content.id} - {this.props.content.title}</h1>
              <a href={`/assessment/history/${this.props.auditId}/${this.props.content.id}`}><span className='material-icons is-clickable m-2' title='Show history'>history</span></a>
            </div>
            <MessagesWidget {...this.props.messages} />
            <div className={`${!!this.state.toolbarVisible && 'has-toolbar'} colour-${this.props.content.colour}`}>
              {this.state.message && <NotificationBar {...this.state.message} />}
              {this.renderSection(section)}
              <NotifyWidget messageSource={this} />
              <datalist id='naics-codes'>
                {naicsCodes.map(row => <option key={row.code} value={row.code}>{row.code} - {row.title}</option>)}
              </datalist>
              <LockErrorModal isVisible={this.state.lockErrorVisible} close={this.toggleLockMessage}
                message={this.state.lockErrorMessage} title={this.state.lockErrorTitle} />
            </div>
          </div>
          {
            areThereAssignableQuestions&& <div className='assignment-dock'/> 
          }
        </div>
      </div>
    </React.Fragment>;
    this.resumeTransitions();
    return result;
  }

  renderQuestion(question: AuditQuestion): JSX.Element {
    const lock = this.state.assessmentLocks[question.responseId];
    const layoutOpt: string = question.options?.layout;
    let questionClass = '';
    switch (layoutOpt) {
      case 'half-width':
        questionClass = 'half-width-component';
        break;
      default:
        questionClass = 'full-width-component';
        break;
    }
    const assignmentDetails = this.state.questionAssignments?.find(assignment => assignment.questionId === question.id);
    return <div key={question.id} className={`${questionClass} ${question.canBeAssigned ? 'assigned-question' : ''}`}>
      {this.renderer.render(question, lock)}
      {
        question.canBeAssigned &&
          <UserAssignmentBox auditId={this.auditId} question={question} loggedInUserId={this.props.loggedInUserId}
            auditContributors={this.props.siteContributorAssignments} isCurrentUserManager={this.props.canLock}
            canUserComment={this.props.canEdit} siteId={this.props.siteId} syncAssignmentDetails={() => this.syncAssignmentDetails()}
            assignedUsers={assignmentDetails?.assignmentDetails.assignedUsers} assignmentComments={assignmentDetails?.assignmentDetails.assignmentComments}
          />
      }
    </div>;
  }

  renderSection(item: AuditSection): JSX.Element {
    const hasContent = item.content?.length > 0;
    return <div key={item.id} className={item.canBeAssigned ? 'assigned-section' : ''} >
      <div className={`${item.layout === 'columns' ? 'columns' : 'subsection-content'}`}>
        {item.guidelines && <div className='guidelines' dangerouslySetInnerHTML={{ __html: item.guidelines }} />}
        {hasContent && item.content.map(content => this.renderQuestion(content))}
      </div>
      {
        item.canBeAssigned &&
          <UserAssignmentBox auditId={this.auditId} section={item} auditContributors={this.props.siteContributorAssignments}
            loggedInUserId={this.props.loggedInUserId} isCurrentUserManager={this.props.canLock} canUserComment={this.props.canEdit}
            siteId={this.props.siteId} syncAssignmentDetails={() => this.syncAssignmentDetails()} assignedUsers={this.state.sectionAssignments?.assignedUsers}
            assignmentComments={this.state.sectionAssignments?.assignmentComments}
          />
      }
    </div>;
  }

  setEditorActive(isActive: boolean): void {
    return this.setState({ toolbarVisible: isActive });
  }

  setAnswer(item: AuditQuestion, value: string): void {
    if (!LOCK_FREE_EDITORS.has(item.answerType)) {
      refreshLock(this.state.assessmentLocks, item.responseId, this.props.auditId);
    }
    this.pendingAnswers.add(item.responseId);
    this.answers[item.responseId] = { answer: value, answerJSON: null, answerType: item.answerType };
  }

  setAnswerJSON(item: AuditQuestion, value: unknown): void {
    if (!LOCK_FREE_EDITORS.has(item.answerType)) {
      refreshLock(this.state.assessmentLocks, item.responseId, this.props.auditId);
    }
    this.pendingAnswers.add(item.responseId);
    this.answers[item.responseId] = { answer: null, answerJSON: JSON.stringify(value), answerType: item.answerType };
  }

  async setAnswerNow(item: AuditQuestion, value: string): Promise<void> {
    if (!LOCK_FREE_EDITORS.has(item.answerType)) {
      refreshLock(this.state.assessmentLocks, item.responseId, this.props.auditId);
    }
    this.pendingAnswers.add(item.responseId);
    this.answers[item.responseId] = { answer: value, answerJSON: null, answerType: item.answerType };
    await this.save();
    await this.syncAllContent();
  }

  private save(handleErrors = true): Promise<void> {
    const key: QueueKeys = handleErrors ? 'save' : 'save_no_errors';
    return this.queue.add(key, async () => {
      if (!this.pendingAnswers.size) {
        return;
      }

      const responses = [...this.pendingAnswers.values()].map(responseId => ({
        responseId,
        ...this.answers[responseId]
      }));
      try {
        this.pendingAnswers = new Set<number>();
        const response = await fetch(
          '/assessment/save-responses',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              auditId: this.props.auditId,
              responses
            })
          }
        );
        if (!response.ok) {
          let detailedError = null;
          try {
            detailedError = (await response.json()).error;
          } catch { /* Show default if error message unavailable */ }
          throw new Error(detailedError ?? response.statusText);
        }
        let results: SaveResponsesResult[];
        try {
          results = (await response.json()).results;
        } catch (err) {
          console.error(err);
          throw new Error('Unable to parse JSON response');
        }
        // Show success
        this.messageCallbacks.forEach(cb => cb('Saved'));
        this.updateTimingCache(results);
        this.setState({ message: undefined });
      } catch (err) {
        if (handleErrors) {
          // Show error and put unsaved answers back on the list
          responses.forEach(response => this.pendingAnswers.add(response.responseId));
          this.setState({ message: {
            message: 'Could not save response',
            children: err?.message ? <>
              <p>
                Your internet connection seems to have been interrupted.
                VPP Online we will try saving your most recent changes every 15 seconds.
                If this issue persists please contact your IT support team
                or contact VPP Online support at support@vpp.online
              </p>
              <p className='mt-2'>
                <strong>Details:</strong> {err.message}
              </p>
            </> : undefined,
            appearance: 'error',
            timeoutSeconds: SAVE_DELAY_SECONDS
          } });
        }
      }
    });
  }

  register(callback: MessageCallback): void {
    this.messageCallbacks.push(callback);
  }
  
  /**
   * lockQuestion attempts to acquire a lock from the server
   * @param responseId 
   */
  async lockQuestion(responseId: number): Promise<boolean> {
    let lock: Lock;
    try {
      // do not hit lockAPI right away, first check if a lock is awaiting release
      let alreadyHaveLock = false;
      let lockAcquired = false;
      let writeLockAcquired = false;
      for (const rId of Object.keys(this.unlockQueue)) {
        const toUnlockResponseId = +rId;
        if (toUnlockResponseId === responseId && this.unlockQueue[toUnlockResponseId]) {
          // toggle lock state so that we do not release from the server
          alreadyHaveLock = true;
          this.unlockQueue[toUnlockResponseId] = false;
          writeLockAcquired = this.state.assessmentLocks[responseId].lockType === 'WRITE' ? true : false;
        }
      }
      if (!alreadyHaveLock) {
        try {
          lock = await lockAPI(this.props.auditId, responseId);
          writeLockAcquired = lock.lockType === 'WRITE' ? true : false;
          lockAcquired = true;
          await this.syncAllContent();
        } catch (err) {
          throw new Error('Could not acquire lock from server.');
        }
      }
      if (lockAcquired) {
        this.setState({
          assessmentLocks: {
            ...this.state.assessmentLocks,
            [responseId]: lock
          }
        });
        for (const rId of Object.keys(this.state.assessmentLocks)) {
          const responseIdLockToRelease = +rId;
          if (responseIdLockToRelease !== responseId && this.state.assessmentLocks[responseIdLockToRelease].lockType === 'WRITE') {
            this.queueUnlockQuestion(responseIdLockToRelease);
          }
        }
      }
      if (!writeLockAcquired) {
        throw new Error('Could not acquire write lock.');
      }
    } catch (err) {
      this.raiseLockError('Could not acquire lock.', 'Someone else is editing this question now.  Please wait until they release the lock.');
      this.syncAllLocks();
      return false;
    }
    return true;
  }

  /**
   * queueUnlockQuestion pushes the question onto a queue that will be unlocked.
   * The idea is that we want to minimize locking/unlocking the same question if someone
   * pops in and out of the question, or is using a multi component question via answerJSON
   * (i.e. ContactEditor).
   * 
   * This puts a request into the unlock queue that can be be popped later for release.
   * 
   * The lock is only queued for release if it is a write lock.
   */
  queueUnlockQuestion(responseId: number): void {
    if (this.state.assessmentLocks[responseId] !== undefined && this.state.assessmentLocks[responseId].lockType === 'WRITE') {
      this.unlockQueue[responseId] = true;
    }
  }

  /**
   * unlockQuestion is polling style function that hits the server on a regular cadence
   * and attempts to release locks that are awaiting release.
   */
  private async unlockQuestion(): Promise<void> {
    let lockReleaseCount = 0;
    for (const rId of Object.keys(this.unlockQueue)) {
      const responseId = +rId;
      if (this.unlockQueue[responseId]) {
        let lockReleasedOnServer = false;
        const locks = this.state.assessmentLocks;
        const lock = locks[responseId];
        const lockId = lock.lockId;
        try {
          await unlockAPI(this.props.auditId, responseId, lockId);
          lockReleasedOnServer = true;
        } catch (err) {
          this.raiseLockError('Could not release lock.', 'Something went wrong releasing the lock.  Our team has been notified.  If this persists please refresh your window or contact our support team.');
        }
        if (lockReleasedOnServer) {
          delete locks[responseId];
          this.setState({
            assessmentLocks: locks
          });
          lockReleaseCount += 1;
        }
      }
      // at this point we clear the queue regardless of whether it was
      // cleared on the server or not.
      delete this.unlockQueue[responseId];
    }
    if (lockReleaseCount > 0) {
      this.save();
    }
  }

  /**
   * If someone holds a lock for some amount of time without editing
   * then we want to free the locks to prevent blocking the page forever.
   * While the server will force expired locks to unlock, this function
   * implements similar logic on the client for additional safety.
   */
  private freeAllLocks(): void {
    for (const lId of Object.keys(this.state.assessmentLocks)) {
      const lockId = +lId;
      const lock = this.state.assessmentLocks[lockId];
      if (lock.lockType === 'READ') {
        return;
      }
      const now = new Date();
      const then = new Date(this.state.assessmentLocks[lockId].lastUsedAt);
      if (then === undefined) {
        return;
      }
      if ( (now.getTime() - then.getTime()) > (FORCE_LOCK_AFTER_MINUTES * 60 * 1000)) {
        this.queueUnlockQuestion(lockId);
      }
    }
  }

  /**
   * This is a really simple approach that might benefit from future refinement. When we get the response back
   * we just swap in all locks, which will include locks we hold and any new locks added. 
   * 
   * Locks that are not owned by this client could immediately be out of date, but there should
   * not be any cases of a write lock that is out of sync. 
   * 
   * Locks that we own should be present on the server before they are present in our client, therefore
   * there should not exist a case where we acquired a lock that the server did not issue.
   */
  private async syncAllLocks(): Promise<void> {
    const sectionId = this.props.content.id;
    const locks = await syncLocks(this.props.auditId, sectionId);
    const newLocks: QuestionLocks = {};
    if (locks.length > 0) {
      for (const lock of locks) {
        newLocks[lock.responseId] = lock;
      }
    }
    this.setState({
      assessmentLocks: newLocks
    });    
  }

  private raiseLockError(title: string, message: string): void {
    this.setState({
      lockErrorTitle: title,
      lockErrorMessage: message,
      lockErrorVisible: true
    });
  }

  private updateTimingCache(results: SaveResponsesResult[]) {
    for (const result of results) {
      if (result.updatedAt > this.updateCache[result.responseId]) {
        this.updateCache[result.responseId] = result.updatedAt;
      }
    }
  }

  private async syncAssignmentDetails(): Promise<void> {
    const { auditId } = this.props;
    const fetchUrl = this.props.content.canBeAssigned ? `/assessment/section-assignments/${auditId}/${this.props.content.sectionId}` 
      : `/assessment/question-assignments-for-section/${auditId}/${this.props.content.sectionId}`;
    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    });
    const assignments = await response.json();
    if (this.props.content.canBeAssigned) {
      this.setState({sectionAssignments: assignments});
    } else {
      this.setState({questionAssignments: assignments});
    }
  }

  /**
   * syncAllContent is responsible for periodically fetching data
   * from the server, performing the required diff for the client,
   * and updating the React state to show the user the relevant responses.
   */
  private syncAllContent(): Promise<void> {
    return this.queue.add('sync_all_content', async () => {
      const sectionId = this.props.content.id;
      const { content: section, elements, details } = await syncAssessmentContent(this.props.auditId, sectionId);
      const responsesToSwap: {[responseId: number]: AuditQuestion} = {};
      const responsesToAdd: AuditQuestion[] = [];
      const responsesToRemove = new Set<number>();
      const incomingResponses = new Set<number>();

      // iterate over the data fetched via API, and
      // decide whether the incoming data is new or newer than our copy
      for (const response of section.content) {
        incomingResponses.add(response.responseId);
        if (response.updatedAt.toString() > this.updateCache[response.responseId]) {
          responsesToSwap[response.responseId] = response;
        } else if (!this.updateCache.hasOwnProperty(response.responseId)) {
          responsesToAdd.push(response);
        }
      }

      // iterate over the content we have in the browser, and
      // decide whether each response should stay
      for (const currentResponse of this.state.content.content) {
        if (!incomingResponses.has(currentResponse.responseId)) {
          responsesToRemove.add(currentResponse.responseId);
        }
      }

      // only swap responses if we do not have the lock
      // otherwise there may be unsaved local edits
      if (Object.keys(responsesToSwap).length > 0) {
        for (const rId of Object.keys(responsesToSwap)) {
          const responseId = +rId;
          if (this.state.assessmentLocks[responseId] === undefined || this.state.assessmentLocks[responseId].lockType === 'READ') {
            // it is safe to swap.
          } else if (this.state.assessmentLocks[responseId].lockType === 'WRITE') {
            // we should not delete this so delete from the swap set
            delete responsesToSwap[responseId];
          }
        }
      }

      // if any responses are new, to be deleted, or to be swapped
      // then we perform the mutation
      if (Object.keys(responsesToSwap).length > 0 || responsesToAdd.length > 0 || responsesToRemove.size > 0) {
        const newContent = swapAuditContent(this.state.content, responsesToSwap);
        const removedContent = newContent.content.filter(q => !responsesToRemove.has(q.responseId));
        newContent.content = removedContent;
        newContent.content.push(...responsesToAdd);
        newContent.content.sort((a: AuditQuestion, b: AuditQuestion) => {
          if (a.position > b.position) {
            return 1;
          } else if (a.position < b.position) {
            return -1;
          } else {
            return 0;
          }
        });
        this.updateTimingCache(newContent.content?.map(question => ({
          responseId: question.responseId,
          updatedAt: String(question.updatedAt)
        })));
        this.updateCache = getMaxUpdatedAt(newContent);
        this.setState({ content: newContent });
      }
      this.setState({ elements, calendarYear: details.calendarYear });
    });
  }

  public toggleLockMessage(): void {
    this.setState({ lockErrorVisible: !this.state.lockErrorVisible });
  }

  /**
   * Prevents all field label transitions from firing on page load
   */
  private supressTransitions(): void {
    document.body.classList.add('supress-transition');
  }

  private resumeTransitions(): void {
    setTimeout(() => document.body.classList.remove('supress-transition'), 1);
  }

  get calendarYear(): number {
    return this.props.calendarYear;
  }
  get startedOn(): string {
    return this.props.startedOn;
  }
}

interface EditAssessmentViewProps {
  auditId: number;
  calendarYear: number;
  canEdit: boolean;
  canLock: boolean;
  content: AuditSection;
  dueDate: string;
  elements: AuditSection[];
  isLockPage: boolean;
  isLinkedFilesPage: boolean;
  locks: Lock[];
  siteId: number;
  startedById: number;
  startedOn: string;
  state: string;
  submittedOn: string;
  messages: FlashMessages;
  siteContributorAssignments: SiteContributorAssignments[];
  sectionAssignments?: AssignmentDetails;
  questionAssignments?: QuestionAssignmentDetails[];
  loggedInUserId: number;
}

class EditAssessmentViewState {
  calendarYear: number;
  message?: NotificationBarProps;
  toolbarVisible: boolean;
  assessmentLocks: QuestionLocks;
  lockErrorVisible: boolean;
  lockErrorMessage: string;
  lockErrorTitle: string;
  updateCache: AuditResponseUpdateCache;
  loadComments: boolean;
  content: AuditSection;
  elements: AuditSection[];
  sectionAssignments?: AssignmentDetails;
  questionAssignments?: QuestionAssignmentDetails[];
  constructor(content: AuditSection, elements: AuditSection[], calendarYear: number,
              sectionAssignments: AssignmentDetails, questionAssignments: QuestionAssignmentDetails[]) {
    this.message = undefined;
    this.toolbarVisible = false;
    this.assessmentLocks = {};
    this.lockErrorVisible = false;
    this.lockErrorMessage = '';
    this.lockErrorTitle = '';
    this.calendarYear = calendarYear;
    this.content = content;
    this.elements = elements;
    this.sectionAssignments = sectionAssignments;
    this.questionAssignments = questionAssignments;
  }
}

type AnswerLookup = { [questionId: string]: Answer };

interface Answer {
  answer: string;
  answerJSON: string;
  answerType: AnswerType;
}

export interface SaveResponsesResult {
  responseId: number;
  updatedAt: string;
}