import React from 'react';
import { AuditQuestion } from '../../../services/audit/auditModels';
import { PlainTextLock } from './assessmentLock';
import { AttachmentEditor } from './attachmentEditor';
import ContactEditor from './contactEditor';
import ContractorInjuryRateTableEditor from './contractorInjuryRateTableEditor';
import { AssessmentEditor, EditAssessmentRenderer } from './editAssessmentModels';
import { Lock } from './editLock';
import { FormattedTextEditor } from './formattedTextEditor';
import ParticipantInjuryRateTableEditor from './participantInjuryRateTableEditor';
import { PlainTextEditor } from './plainTextEditor';
import { RecommendationListEditor } from './recommendationListEditor';
import { SubsectionTitle } from './subsectionTitle';
import YesNoEditor from './yesNoEditor';

async function noOpFocus(): Promise<boolean> {
  return false;
}

export default class ViewAssessmentComponents implements EditAssessmentRenderer {
  constructor(private editor: AssessmentEditor) { }

  render(item: AuditQuestion, lock: Lock = null): JSX.Element {
    const renderFunc = this[item.answerType as keyof ViewAssessmentComponents];
    return renderFunc ? renderFunc.bind(this)(item, lock) : this.default(item, lock);
  }

  public attachmentEditor(item: AuditQuestion): JSX.Element {
    return <AttachmentEditor
      className='mt-4'
      auditId={this.editor.auditId}
      responseId={item.responseId}
      title={item.text}
      existingAttachments={item.attachments}
      readOnly={true}
    />;
  }

  private contactWithAddress(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <ContactEditor
      label={item.text}
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      value={item.answerJSON}
      includeAddress={true}
      readOnly={true}
      lock={lock}
    />;
  }

  private contactWithEmail(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <ContactEditor
      label={item.text}
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      value={item.answerJSON}
      includeEmail={true}
      readOnly={true}
      lock={lock}
    />;
  }

  private contractorInjuryRateTable(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <ContractorInjuryRateTableEditor
      year={this.editor.calendarYear || parseInt(this.editor.startedOn.split('-', 1)[0])}
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      auditId={this.editor.auditId}
      value={item.answerJSON}
      options={item.options}
      readOnly={true}
      lock={lock}
      text={item.text}
    />;
  }

  private date(item: AuditQuestion): JSX.Element {
    return this.renderDate(item);
  }

  private auditSubmissionDate(item: AuditQuestion): JSX.Element {
    return this.renderDate(item);
  }

  private renderDate(item: AuditQuestion): JSX.Element {
    return <PlainTextEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      value={item.answer ? new Date(item.answer).toDateString() : ''}
      label={item.text}
      readOnly={true}
    />;
  }

  private formattedText(item: AuditQuestion, lock: Lock | null): JSX.Element {
    const editLock = lock?.lockType === 'WRITE';
    return <div className='field' key={`${item.id}-${item.updatedAt.toString()}`} id={'question-' + item.id}>
      <div className='control'>
        <div className='has-placeholder'>
          { lock !== null && <PlainTextLock lock={lock} editLock={editLock} /> }
          <FormattedTextEditor
            placeholder={item.text}
            defaultValue={item.answer || ''}
            readOnly={true}
            lock={lock}
            onFocus={noOpFocus}
            auditId={this.editor.auditId}
            questionId={item.id}
          />
        </div>
      </div>
    </div>;
  }

  private participantInjuryRateTable(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <ParticipantInjuryRateTableEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      auditId={this.editor.auditId}
      value={item.answerJSON}
      onChange={value => this.editor.setAnswerJSON(item, value)}
      readOnly={true}
      lock={lock}
    />;
  }

  private recommendationList(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <RecommendationListEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      value={item.answerJSON}
      readOnly={true}
      lock={lock}
      auditId={this.editor.auditId}
      questionId={item.id}
    />;
  }

  private staticText(item: AuditQuestion): JSX.Element {
    return <div className='static-text' key={`${item.id}-${item.updatedAt.toString()}`} id={'question-' + item.id} dangerouslySetInnerHTML={{ __html: item.text }} />;
  }

  private yesNo(item: AuditQuestion): JSX.Element {
    return <YesNoEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      value={item.answer}
      label={item.text}
      {...item.options}
      readOnly={true}
    />;
  }

  private injuryRateReferenceTable(): JSX.Element {
    return null;
  }

  private contractorInjuryRateReferenceTable(): JSX.Element {
    return null;
  }

  private subsectionTitle(item: AuditQuestion): JSX.Element {
    return <SubsectionTitle id={item.id} title={item.text} />;
  }

  private default(item: AuditQuestion, lock: Lock | null): JSX.Element {
    return <PlainTextEditor
      key={`${item.id}-${item.updatedAt.toString()}`}
      id={'question-' + item.id}
      label={item.text}
      value={item.answer}
      readOnly={true}
      lock={lock}
    />;
  }
}