import { AnswerType } from '../../../services/protocol/protocolModels';

const MINUTES_REMAINING_TO_REFRESH_LOCK = 10;

export const LOCK_FREE_EDITORS: Set<AnswerType> = new Set<AnswerType>([
  AnswerType.yesNo,
  AnswerType.auditSubmissionDate,
  AnswerType.date,
  AnswerType.vppStatus,
]);

export type LockType = 'READ' | 'WRITE';

export type Lock = {
  lockId: string;
  username: string;
  lastUsedAt: Date;
  lockType: LockType;
  responseId?: number;
  lockExpiresAt: Date;
};

export type QuestionLocks = { [responseId: number]: Lock };

export type UnlockQueue = { [responseId: number]: boolean };

/* eslint-disable no-useless-catch */
export async function lockAPI(auditId: number, responseId: number): Promise<Lock> {
  try {
    const result = await fetch(
      `/assessment/lock/${auditId}/${responseId}`,
      {
        method: 'POST'
      }
    );
    if (!result.ok) {
      throw new Error('Could not get lock');
    } 
    const resp = await result.json();    
    return resp;
  } catch (err) {
    throw err;
  }
}

/* eslint-disable no-useless-catch */
export async function unlockAPI(auditId: number, responseId: number, lockId: string): Promise<void> {
  try {
    const result = await fetch(
      `/assessment/unlock/${auditId}/${responseId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          lockId
        })
      }
    );
    if (!result.ok) {
      throw new Error('Could not release lock');
    } 
  } catch (err) {
    throw err;
  }
}

export async function syncLocks(auditId: number, sectionId: string): Promise<Lock[]> {
  try {
    const result = await fetch(
      `/assessment/locks/${auditId}/${sectionId}`,
      {
        method: 'GET'
      }
    );
    if (!result.ok) {
      throw new Error('Could not get locks');
    } 
    const resp = await result.json();
    return resp.locks;
  } catch (err) {
    // an error here should be logged on the server
    // and does not have a clear resolution path
    // either the user is trying to access something they do not have privilege for
    // or the server is down
  }
}

export function refreshLock(locks: QuestionLocks, lockId: number, auditId: number): void {
  const lock = locks[lockId];
  if (lock !== undefined) {
    const now = new Date();
    const expires = new Date(lock.lockExpiresAt);
    const minutesUntilLockExpiry = ((expires.getTime() - now.getTime()) / 1000) / 60;
    lock.lastUsedAt = new Date();
    if (minutesUntilLockExpiry < MINUTES_REMAINING_TO_REFRESH_LOCK) {
      const result = fetch(
        `/assessment/lock/refresh/${auditId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            lockId: lock.lockId
          })
        }
      );
      result.catch(err => console.log(err) );
    }
  }
}