import React from 'react';
import { AuditResponseVersion } from '../../../services/audit/auditModels';
import { InjuryRateTableOptions } from '../../../services/audit/injuryRateModels';
import ContractorInjuryRateTableEditor from '../editAssessment/contractorInjuryRateTableEditor';
import ParticipantInjuryRateTableEditor from '../editAssessment/participantInjuryRateTableEditor';

export type HistoryModalTypes = 'none' | 'formattedText' | 'participantInjury' | 'contractorInjury' | 'attachment';

type FormattedTextModalProps = {
  isActive: boolean;
  formattedText: string;
  toggleClose: () => void;
};

// React components need upper case functions
/* eslint-disable @typescript-eslint/naming-convention */
function FormattedTextModal(props: FormattedTextModalProps): JSX.Element {
  return props.formattedText ?
    <div className={props.isActive ? 'modal is-active' : 'modal'}>
      <div className='modal-background is-flex is-justify-content-flex-end is-align-content-flex-start' onClick={() => props.toggleClose()}>
        <button className='delete m-5' aria-label='close' onClick={() => props.toggleClose()}></button>
      </div>
      <div className='modal-content formatted-text-modal '>
        <div className='content formatted-text-modal-container'>
          <div className='history-multiline-cell' dangerouslySetInnerHTML={{ __html: props.formattedText }}></div>
        </div>
      </div>
    </div>
    : <div></div>
  ;
}

type ParticipantInjuryRateModalProps = {
  row: AuditResponseVersion;
  isActive: boolean;
  toggleClose: () => void;
};

function ParticipantInjuryRateModal(props: ParticipantInjuryRateModalProps): JSX.Element {
  return props.row && props.row.answerJSON ?
    <div className={props.isActive ? 'modal is-active' : 'modal'}>
      <div className='modal-background' onClick={() => props.toggleClose()}></div>
      <div className='modal-content history-injury-table'>
        <div className='box'>
          {
            props.row && 
            <ParticipantInjuryRateTableEditor
              key={props.row.versionId}
              id={'question-' + props.row.versionId}
              auditId={props.row.auditId}
              value={props.row.answerJSON}
              readOnly={true}
            />
          }
        </div>
      </div>
      <button className='modal-close is-large' aria-label='close' onClick={() => props.toggleClose()}></button>
    </div>
    : <div></div>
  ;
}

type ContractorInjuryRateModalProps = {
  row: AuditResponseVersion;
  isActive: boolean;
  toggleClose: () => void;
};

function ContractorInjuryRateModal(props: ContractorInjuryRateModalProps): JSX.Element {
  const opts: InjuryRateTableOptions = new InjuryRateTableOptions();
  opts.numberOfYears = props.row?.answerJSON?.table?.rows?.length;
  const startYear: number = parseInt(props.row?.answerJSON?.table?.rows[0]?.year, 10);
  return props.row && props.row.answerJSON ?
    <div className={props.isActive ? 'modal is-active' : 'modal'}>
      <div className='modal-background' onClick={() => props.toggleClose()}></div>
      <div className='modal-content history-injury-table'>
        <div className='box'>
          {
            props.row &&
            <ContractorInjuryRateTableEditor
              year={startYear}
              key={props.row.versionId}
              id={'question-' + props.row.versionId}
              auditId={props.row.auditId}
              value={props.row.answerJSON}
              options={opts}
              readOnly={true}
            />
          }
        </div>
        <button className='modal-close is-large' aria-label='close' onClick={() => props.toggleClose()}></button>
      </div>
    </div>
    :<div></div>
  ;
}

type AttachmentModalProps = {
  isActive: boolean;
  row: AuditResponseVersion;
  toggleClose: () => void;
};

// React components need upper case functions
/* eslint-disable @typescript-eslint/naming-convention */
function AttachmentModal(props: AttachmentModalProps): JSX.Element {
  return props.row && props.row.answerJSON ?
    <div className={props.isActive ? 'modal is-active' : 'modal'}>
      <div className='modal-background is-flex is-justify-content-flex-end is-align-content-flex-start' onClick={() => props.toggleClose()}>
        <button className='delete m-5' aria-label='close' onClick={() => props.toggleClose()}></button>
      </div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Restore Attachment</p>
        </header>
        <section className='modal-card-body'>
          {props.row.answerJSON.filename}
        </section>
        <footer className='modal-card-foot'>
          <form action='/attachment/restore' method='post'>
            <input type='hidden' value={props.row.auditId} name='auditId' />
            <input type='hidden' value={props.row.answerJSON.attachmentId} name='attachmentId' />
            <button type='submit' className='button is-success m-2'>Restore Attachment</button>
          </form>
          <button className='button is-danger m-2' onClick={() => props.toggleClose()}>Cancel</button>
        </footer>
      </div>
    </div>:
    <div></div>
  ;
}

export {
  FormattedTextModal,
  ParticipantInjuryRateModal,
  ContractorInjuryRateModal,
  AttachmentModal
};

